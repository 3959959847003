import React from "react"
import {IDetail} from "../TransactionModel"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {Tag} from "antd"
import {Link} from "react-router-dom"

function TableColumns(
    handleRefreshStatus: (id:string, fitur:string) => void
    // handleUpdate:(values:{
    //     id: string,
    //     isActive: boolean,
    //     logo: string,
    //     margin: number
    // }) => void,
    // loading:string
) {
    const columns = [
        {
            title: "Nomor Invoice",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>
                        {data.invoiceNumber}
                    </div>
                    <div style={{marginTop: "5px"}}>{FormatDate(data.createdAt)}</div>
                </div>
            )
        },
        {
            title: "Nama Produk",
            render: (data:IDetail) => (
                <div>
                    <div>{data.product.productName}</div>
                    <Tag color="green">{data.product.category}</Tag>
                </div>
            )
        },
        {
            title: "Status",
            render: (data:IDetail) => (
                <div>
                    <div>Ayopop: {data.ayopopStatus} <i className="fas fa-sync" style={{color: "green"}} onClick={() => handleRefreshStatus(data.id, "order")} /></div>
                    <div>Payment: {data.payment.paymentState} <i className="fas fa-sync" style={{color: "green"}} onClick={() => handleRefreshStatus(data.id, "payment")} /></div>
                </div>
            )
        },
        {
            title: "Payment",
            render: (data:IDetail) => (
                <div>
                    <div>Provider: {data.payment.paymentProvider} </div>
                    <div>Method: {data.payment.paymentMethod}</div>
                    {data.payment.paymentMethod === "va" ? <div>Bank: {data.payment.bankName}</div> : null}
                </div>
            )
        },
        {
            title: "Total Tagihan",
            render: (data:IDetail) => (
                <div>{FormatRp(data.total)}</div>
            )
        },
        {
            title: "Aksi",
            render: (data:IDetail) => (
                <Link to={`/ayopop-transaction/detail?id=${data.id}`} >
                    <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                        <i className="far fa-eye" />
                    </div>
                </Link>
            )
        },
    ]

    return {
        columns
    }
}

export default TableColumns