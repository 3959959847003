import {useState, useEffect} from "react"
import {IList} from "../MerchantCategoryModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchantcategory} from "../../../../config/endpoint"
import {notification} from "antd"
 
export const useModelList = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IList>({
        currentPage: 1,
        limit: 10,
        totalPage: 1,
        payload: []
    })
    const [params, setParams] = useState<{
        sortBy:string,
        sortType:string,
        page: number,
        limit: number,
        q: string
    }>({
        sortBy: "sort",
        sortType: "ASC",
        page: 1,
        limit: 10,
        q: ""
    })
    const [visible, setVisible] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantcategory}`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            const response = {
                currentPage: res.data?.payload?.currentPage,
                limit: res.data?.payload?.limit,
                totalPage: res.data?.payload?.totalPage,
                payload: res.data?.payload?.data
            }
            setFields(response)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data kategori',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleUpdateStatus = async(id:string, values:{}) => {
        try {
            const url = `${base_url}/${merchantcategory}/${id}`

            await axios.put(url, values, {
                headers: headers,
            })
            fetchData(params)
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Update status kategori berhasil.',
            });
        } catch (error) {
            fetchData(params)
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat update status kategori.',
            });
        }
    }

    return {
        loading,
        fields,
        visible,
        fetchData,
        params,
        handleSubmitSearch,
        handlePageChange,
        toggle,
        handleUpdateStatus
    }
}