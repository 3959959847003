import React, {useContext} from 'react'
import {useHistory} from "react-router"
import {useQuery} from "../../../../../helpers/Formatter"
import {AyopopTransactionContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../../templates"
import {Row, Col} from "antd"
import {Loading, BackButton} from "../../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"

const Detail: React.FC = () => {
    const history = useHistory()
    const query = useQuery()
    const id = query.get("id")
    const context = useContext(AyopopTransactionContext)
    const {
        loading,
        fields
    } = context.useModelDetail(id)

    return (
        <DetailPageLayout>
            {loading ? <Loading /> : (
                <div>
                    <Row>
                        <Col span={24}>
                            <DetailTitleSection title="Detail Order" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Nomer Invoice" value={fields.invoiceNumber} />
                            <ItemDetail label="Tanggal Transaksi" value={FormatDate(fields.createdAt)} />
                            <ItemDetail label="Status Ayopop" value={fields.ayopopStatus} />
                            <ItemDetail label="Tagihan Ayopop" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.bill)}</div>} />
                            <ItemDetail label="Marjin" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.margin)}</div>} />
                            <ItemDetail label="Total" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.total)}</div>} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <DetailTitleSection title="Detail Produk" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Nama Produk" value={fields.product.productName} />
                            <ItemDetail label="Kode Produk" value={fields.product.productCode} />
                            <ItemDetail label="Harga Produk" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.product.amount)}</div>} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <DetailTitleSection title="Detail Pembayaran" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Provider Pembayaran" value={fields.payment.paymentProvider} />
                            <ItemDetail label="Provider Pembayaran" value={fields.payment.paymentMethod} />
                            {fields.payment.paymentMethod === "va" ? <ItemDetail label="Bank" value={fields.payment.bankName} /> : null}
                            <ItemDetail label="Tagihan Ayopop" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.payment.invoiceAmount)}</div>} />
                            <ItemDetail label="Biaya Admin" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.payment.adminFee)}</div>} />
                            <ItemDetail label="Total Pembayaran" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.payment.totalAmount)}</div>} />
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                {/* <Link to="/order"> */}
                                    <BackButton
                                        onClick={() => history.goBack()}
                                    >
                                        Kembali
                                    </BackButton>
                                {/* </Link> */}
                            </Col>
                        </Row>
                </div>
            )}
        </DetailPageLayout>
    )
}

export default Detail
