import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IListPasar, initialDataList} from "../ModelPasar"
import {notification} from "antd"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import moment from "moment"
import { listpasar } from "../../../../config/endpoint"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [fields, setFields] = useState<IListPasar>(initialDataList)
    const [loading, setLoading] = useState<string>("")
    const [params, setParams] = useState<{
        pageLimit: number
        pageNumber: number
        // search:string
    }>({
        pageLimit: 10,
        pageNumber: 1,
        // search: ""
    })
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async(params:{}) => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${listpasar}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {...params, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data pasar',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const handleFilter = (values:{isActive?:any, createdAt?:any[], startDate?:string, endDate?:string}) => {
        values["isActive"] = values.isActive === "true" ? true : values.isActive === "false" ? false : undefined
        values["startDate"] = values.createdAt !== undefined ? moment(values.createdAt[0]).format("YYYY-MM-DD") : undefined
        values["endDate"] = values.createdAt !== undefined ? moment(values.createdAt[1]).format("YYYY-MM-DD") : undefined
        delete values.createdAt
        
        if (values.startDate === undefined && values.endDate === undefined) {
            delete values.startDate
            delete values.endDate 
        } else if (values.isActive === undefined) {
            delete values.isActive
        }
        
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            pageLimit: 10,
            pageNumber: 1,
            // search: ""
        })
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSetMarketId = (marketID:number) => {
        localStorage.setItem("marketID", marketID.toString());
    }

    const handleUpdateStatus = async(newdata:{}, id:number) => {
        setLoading(`status${id}`)
        try {
            await axios.put(`${base_url}/${listpasar}/${id}`, newdata, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data pasar',
                placement:"topRight"
            });
            setLoading("")
            fetchData(params)
        }
    }

    const handleDelete = async(id:number) => {
        console.log(id);
        setLoading("delete")
        try {
            await axios.delete(`${base_url}/${listpasar}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            setVisible(false)
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus data`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menghapus data pasar',
                placement:"topRight"
            });
        }
    }

    return {
        fields,
        params,
        fetchData,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        handleSetMarketId,
        handleUpdateStatus,
        visible,
        setVisible,
        handleDelete
    }
}