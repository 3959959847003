import axios from "axios"
import {useState, useEffect} from "react"
import {base_url} from "../../../../../config/url-config"
import {refundorderpasar} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelRefund() {
    const [modal, setModal] = useState<boolean>(false)
    const [itemOrderAll, setItemOrderAll] = useState<{
        itemPrice: number
        itemDiscount: number
        qty: number
        isDiscountPercentage: boolean
    }[]>([])
    const [fields, setFields] = useState<{
        orderID: string
        refundNote: string
        total: number
        refundOrderDetail: {
            itemID: number
            itemName: string
            itemPrice: number
            itemDiscount: number
            itemQty: number
        }[]
    }>({
        orderID: "",
        refundNote: "",
        total: 0,
        refundOrderDetail: [
            {
                itemID: 0,
                itemName: "",
                itemPrice: 0,
                itemDiscount: 0,
                itemQty: 0
            }
        ]
    })
    const [loading, setLoading] = useState<string>("")

    useEffect(() => {
        var total = 0
        for (var i = 0; i < fields.refundOrderDetail.length; i++) {
            total += fields.refundOrderDetail[i].itemQty*(fields.refundOrderDetail[i].itemPrice - fields.refundOrderDetail[i].itemDiscount)
        }
        
        setFields({...fields, total: total})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields.refundOrderDetail])

    const toggle = () => {
        setModal(!modal)
        setFields({
            orderID: "",
            refundNote: "",
            total: 0,
            refundOrderDetail: [
                {
                    itemID: 0,
                    itemName: "",
                    itemPrice: 0,
                    itemDiscount: 0,
                    itemQty: 0
                }
            ]
        })
    }

    const handleSelect = (value:{}, option:any) => {
        // key from option using format like this "0,0" 
        // value before comma is index of itemorder data
        // value after comma is where we will put the data
        const keyone = parseInt(option.key.split(",")[0])
        const keytwo = parseInt(option.key.split(",")[1])
        const itemOrders = [...fields.refundOrderDetail]

        itemOrders[keytwo].itemID = option.value
        itemOrders[keytwo].itemName = option.children
        itemOrders[keytwo].itemQty = itemOrderAll[keyone].qty
        itemOrders[keytwo].itemPrice = itemOrderAll[keyone].itemPrice
        itemOrders[keytwo].itemDiscount = itemOrderAll[keyone].isDiscountPercentage ? itemOrderAll[keyone].itemPrice * itemOrderAll[keyone].itemDiscount / 100 : itemOrderAll[keyone].itemDiscount
        
        setFields({...fields, refundOrderDetail: itemOrders})
    }

    const handleUpdateQty = (e:any, index:number) => {
        const refunditem = [...fields.refundOrderDetail]
        refunditem[index].itemQty = parseInt(e.target.value)
        
        setFields({...fields, refundOrderDetail: refunditem})
    }

    const handleAddRow = () => {
        setFields({
            ...fields,
            refundOrderDetail: [
                ...fields.refundOrderDetail, 
                {
                    itemID: 0,
                    itemName: "",
                    itemPrice: 0,
                    itemDiscount: 0,
                    itemQty: 0
                }
            ]
        })
    }

    const handleDeleteRow = (index:number) => {
        const refunditem = [...fields.refundOrderDetail]
        refunditem.splice(index, 1)

        setFields({
            ...fields,
            refundOrderDetail: refunditem
        })
    }

    const handleNote = (e:any) => {
        setFields({...fields, refundNote: e.target.value})
    }

    const handleSubmit = async() => {
        setLoading("submit")
        
        try {
            await axios.post(`${base_url}/${refundorderpasar}`, fields, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Data refund order berhasil dibuat.`,
            });
            toggle()
        } catch(error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  `Terjadi kesalahan saat membuat data refund`,
            });
            setLoading("")
        }
    }

    return {
        itemOrderAll,
        setItemOrderAll,
        fields,
        setFields,
        modal,
        toggle,
        handleSelect,
        handleUpdateQty,
        handleAddRow,
        handleDeleteRow,
        handleNote,
        loading,
        handleSubmit
    }
}