import React, {createContext} from "react"
import {useModelList} from "./ContextList"
import {useModelDetail} from './ContextDetail'
import {useModelCategory} from './ContextCategory'

export const KasproContext = createContext<any>(null)

const ContextProvider:React.FC<{children:any}> = (props) => {
    const context = {
        useModelList,
        useModelDetail,
        useModelCategory
    }

    return (
        <KasproContext.Provider value={context}>
            {props.children}
        </KasproContext.Provider>
    )
}

export default ContextProvider