import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IListItem, initialDataItemList} from "../ModelItem"
import {notification} from "antd"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itempasar} from "../../../../../config/endpoint"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [fields, setFields] = useState<IListItem>(initialDataItemList)
    const [loading, setLoading] = useState<string>("")
    const [params, setParams] = useState<{
        marketID: string | null
        pageLimit: number
        pageNumber: number
        // search:string
    }>({
        marketID: localStorage.getItem("marketID"),
        pageLimit: 10,
        pageNumber: 1,
        // search: ""
    })
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async(params:{}) => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${itempasar}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {...params, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data item pasar',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const handleFilter = (values:{isActive:any, categoryID?:number}) => {
        values["isActive"] = values.isActive === "true" ? true : values.isActive === "false" ? false : undefined
        if (values.categoryID === undefined) {
            delete values.categoryID 
        } else if (values.isActive === undefined) {
            delete values.isActive
        }
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            marketID: localStorage.getItem("marketID"),
            pageLimit: 10,
            pageNumber: 1,
            // search: ""
        })
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleUpdateStatus = async(id:number, checked:boolean) => {
        setLoading("status")
        try {
            await axios.put(`${base_url}/${itempasar}/${id}`, {isActive: checked}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data pasar',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    const handleDelete = async(id:number) => {
        setLoading("delete")
        try {
            await axios.delete(`${base_url}/${itempasar}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus data`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data pasar',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    const handleDownloadTemplate = async() => {
        function generateCSV(res:any) {
            const url = window.URL.createObjectURL(
                new Blob([res.data], { type: 'application/csv' })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Template_Item.csv`);
            document.body.appendChild(link);
            link.click();
      
            let data = {
                code: 200,
                message: 'Download CSV Success',
            };
            return data;
        }

        setLoading("downloadBulk")
        try {
            const res = await axios.get(`${base_url}/${itempasar}/template`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            generateCSV(res)
            setLoading("")
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengunduh template',
                placement:"topRight"
            });
        }
    }

    const handleUploadTemplate = async(file:any) => {
        // console.log(info);
        const marketId:any = localStorage.getItem("marketID") !== null ? localStorage.getItem("marketID") : ""
        const data = new FormData()
        data.append("template", file)
        data.append("marketID", marketId)

        setLoading("uploadBulk")
        try {
            await axios.post(`${base_url}/${itempasar}/bulk`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil mengunggah template`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengunggah template',
                placement:"topRight"
            })
            fetchData(params)
        }
    }

    return {
        fields,
        fetchData,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        handleUpdateStatus,
        visible,
        setVisible,
        handleDelete,
        handleDownloadTemplate,
        handleUploadTemplate
    }
}