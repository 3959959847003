import {useEffect, useState} from "react"
import axios from "axios"
import {notification} from "antd"
import {base_url} from "../config/url-config"
import {registTokenFCM, getNotifList} from "../config/endpoint"
import {requestFirebaseNotificationPermission, messaging} from "../firebase"
// import {useModelNotification} from "./NotificationContext"
// import {getNotifList} from "../config/endpoint"

export function useModelMainApp() {
    // const {fetchNotification} = useModelNotification()
    const [notif, setNotif] = useState<{
        id: string
        userID: string
        title: string
        body: string
        notifData: {
            orderID: string
            invoiceNumber: string
        },
        isRead: boolean
        createdAt: string
    }[]>([])
    const [notifToken, setNotifToken] = useState<any>("")

    const fetchData = async () => {
        try {
            const data = new FormData()
            data.append("refreshToken", `${localStorage.getItem("refreshToken")}`)
            const res = await axios.post(`${base_url}/v3/client/auth/refresh`, data, {
                headers: {'Content-Type': 'multipart/form-data' }
            })
          
            localStorage.setItem("accessToken", res.data.payload.token.accessToken)
            localStorage.setItem("refreshToken", res.data.payload.token.refreshToken)
        } catch (error) {
            localStorage.clear();
        }
    }

    const fetchNotification = async() => {
        try {
            const res = await axios.get(`https://api.manyoption.co.id/${getNotifList}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {isRead: false, isAll:true}
            })
            // console.log(res);
            setNotif(res.data.payload)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data notifikasi',
                placement:"topRight"
            });
        }
    }

    messaging.onMessage((payload) => {
        console.log("payload from firebase",payload);
        notification["success"]({
            message: 'Hola!',
            description:
              `Ada order baru di pasar`,
        });
        fetchNotification()
    });

    // messaging.onBackgroundMessage((payload) => {
    //     console.log(payload);
        
    // })

    useEffect(() => {
        if (localStorage.getItem("refreshToken") !== null) {
          fetchData()
        }

        localStorage.getItem("accessToken") !== null && fetchNotification()

        requestFirebaseNotificationPermission().then((firebaseToken) => {
            // eslint-disable-next-line no-console
            // console.log(firebaseToken);
            const registToken = async(firebaseToken:any) => {
                try {
                    await axios.post(`https://api.manyoption.co.id/${registTokenFCM}`, {token: firebaseToken}, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                        },
                    })
                } catch (error) {
                    console.log(error);
                }
            }

            console.log(localStorage.getItem("accessToken"));
            

            localStorage.getItem("accessToken") !== null && registToken(firebaseToken)
            setNotifToken(firebaseToken)
            }).catch((err) => {
              return err;
            });
    }, [])

    const handleUpdateStatusRead = async(id:string, isRead:boolean) => {
        console.log(id, isRead);
        
        try {
            await axios.put(`https://api.manyoption.co.id/${getNotifList}/${id}`, {isRead: isRead}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            
            fetchNotification()
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data notifikasi',
                placement:"topRight"
            });
        }
    }

    const handleDeleteTokenNotif = async() => {
        try {
            await axios.delete(`https://api.manyoption.co.id/${registTokenFCM}/${notifToken}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
        } catch(error) {
            console.log(error);
        }
    }

    return {
        fetchData,
        fetchNotification,
        notif,
        notifToken,
        handleUpdateStatusRead,
        handleDeleteTokenNotif
    }
}