import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detailmerchantjasa/DetailMerchantJasa"
import Create from "./createmerchantjasa/CreateMerchantJasa"
import Edit from "./editmerchantjasa/EditMerchantJasa"
import Item from "./item/Item"
import Voucher from "./voucher/Voucher"
import Akun from "./akun/Akun"
import Transaction from "./transaction/Transaction"

const MerchantJasa: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/jasa" exact={true} component={List} />
            <Route path="/jasa/detail" exact={true} component={Detail} />
            <Route path="/jasa/tambah" exact={true} component={Create} />
            <Route path="/jasa/ubah" exact={true} component={Edit} />
            <Route path="/jasa/item" exact={false} component={Item} />
            <Route path="/jasa/voucher" exact={false} component={Voucher} />
            <Route path="/jasa/akun" exact={false} component={Akun} />
            <Route path="/jasa/transaksi" exact={false} component={Transaction} />
        </ContextProvider>
    )
}

export default MerchantJasa
