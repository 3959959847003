import React, { useState, useContext } from 'react'
import {useQueryParams} from "../../../../helpers/Formatter"
import {FoodContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import { PageLayout } from "../../../templates"
import { FeatureButton, Search, Table } from "../../../../components/atoms"
import { Link } from "react-router-dom"
import { Pagination } from "antd"
import Category from '../category/Category'
import { FormFilterFood } from '../../../organisms'

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(FoodContext)
    const {
        fields,
        loading,
        visible,
        handleSubmitSearch,
        handleFilter,
        isReset,
        handleReset,
        handlePageChange,
        handleSetMerchantId,
        toggle,
        handleUpdateStatus,
        handleDelete
    } = context.useModelList()
    const {columns} = TableColumns(
        loading,
        visible,
        toggle,
        handleDelete,
        handleSetMerchantId,
        handleUpdateStatus
    )
    const [hideMenu, setHideMenu] = useState<boolean>(false)

    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    console.log(fields.payload)

    return (
        <React.Fragment>
            <PageLayout
                leftTop={
                    <div>
                        <Link to="/food/tambah">
                            <FeatureButton
                                icon={<i className="fas fa-plus-square" style={{ marginRight: "5px" }} />}
                                label="Tambah"
                            />
                        </Link>
                        <FormFilterFood
                            title="Filter Merchant Food"
                            isReset={isReset}
                            action={handleFilter} 
                        />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                        {hideMenu ? (
                            <span>
                                <Category />
                                <i
                                    className="fas fa-chevron-circle-left"
                                    style={{ cursor: "pointer", color: "#009b00" }}
                                    onClick={handleHideMenu}
                                />
                            </span>
                        ) : (
                            <i
                                className="fas fa-chevron-circle-right"
                                style={{ cursor: "pointer", color: "#009b00" }}
                                onClick={handleHideMenu}
                            />
                        )}
                    </div>
                }
                rightTop={<Search
                    onKeyPress={(e: any) => handleSubmitSearch(e)}
                    value={qp.queryParams.get('keyword')?.toString()}
                />}
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.payload}
                            rowKey="id"
                            pagination={false}
                            loading={loading === "fetch" ? true : false}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination
                            current={fields.pagination.page + 1}
                            pageSize={fields.pagination.limit}
                            total={fields.pagination.totalData}
                            onChange={handlePageChange}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default List
