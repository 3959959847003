import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {bannerConfig} from "../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = () => {
    const [params, setParams] = useState<{
        pageNumber:number
        pageLimit:number
        search:string
    }>({
        pageNumber: 1,
        pageLimit: 10,
        search: ""
    })
    const [fields, setFields] = useState<{
        totalData: number
        data: {}[]
    }>({
        totalData: 0,
        data: []
    })
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${bannerConfig}`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields({
                ...fields,
                totalData:res.data.payload.total_record,
                data:res.data.payload.records
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data payment config',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, search: e.target.value, pageNumber: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleDelete = async(id?:string) => {
        try {
            const url = `${base_url}/${bannerConfig}/${id}`

            await axios.delete(url, {
                headers: headers
            })
            fetchData(params)
            setVisible(!visible);
            notification.success({
                message: "Yuhuuu",
                description:
                    'Menghapus banner berhasil!',
                placement: "bottomRight",
            });
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan saat menghaputs data banner!',
                placement: "bottomRight",
            });
        }
    }

    const toggle = () => {
        setVisible(!visible)
    }

    return {
        params,
        fields,
        visible,
        loading,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleDelete,
        toggle
    }
}