import {
    Beranda,
    // Category
    MerchantCategory,
    // HOBBY
    MerchantHobby,
    // TransactionDetailHobby,
    // JASA
    MerchantJasa,
    CreateMerchantJasa,
    // EditMerchantJasa,
    // ItemJasa,
    DetailItemJasa,
    CreateItemJasa,
    EditItemJasa,
    VoucherJasa,
    AkunJasa,
    TransactionJasa,
    TransactionDetailJasa,

    // PASAR
    Pasar,
    TransactionPasar,
    OnTransactionPasar,

    // SHOPPER
    Shopper,
    
    // TRANSACTION HOBBY
    TransactionHobbyAll,
    DetailTransactionHobby,
    // TRANSACTION JASA
    TransactionJasaAll,
    DetailTransactionJasa,
    Settlement,
    PaymentConfig,
    TransactionPayment,
    BannerConfig,
    Account,
    MerchantFood,
    // TransactionFood,
    ItemFood,
    CreateItemFood,
    CreateAccountFood,
    UpdateItemFood,
    DetailItemFood,
    TransactionFoodAll,
    Ayopop,
    TransactionAyopop,
    // USER APPS
    Voucher,
    Kaspro,
    Customer,
    DetailCustomer,
    Transaksi,
} from "../containers/pages";

const routes = [
    { name: "Beranda", path: "/beranda", component: Beranda },

    // MERCHANT CATEGORY
    { name: "Merchant Category", path: "/kategori", component: MerchantCategory },

    // MERCHANT HOBBY
    { name: "Merchant Hobby", path: "/hobi", component: MerchantHobby },
    // { name: "Transaction Detail Hobby", path: "/hobi/transaksi/detail", component: TransactionDetailHobby },

    // MERCHANT JASA
    { name: "Merchant Jasa", path: "/jasa", component: MerchantJasa },
    { name: "Create Merchant Jasa", path: "/jasa/tambah", component: CreateMerchantJasa },
    // { name: "Edit Merchant Jasa", path: "/jasa/ubah", component: EditMerchantJasa },
    // { name: "Item Merchant Jasa", path: "/jasa/item", component: ItemJasa },
    { name: "Detail Item Merchant Jasa", path: "/jasa/item/detail", component: DetailItemJasa },
    { name: "Create Item Merchant Jasa", path: "/jasa/item/tambah", component: CreateItemJasa },
    { name: "Edit Item Merchant Jasa", path: "/jasa/item/edit", component: EditItemJasa },
    { name: "Voucher Merchant Jasa", path: "/jasa/voucher", component: VoucherJasa },
    { name: "Akun Merchant Jasa", path: "/jasa/akun", component: AkunJasa },
    { name: "Transaction Jasa", path: "/jasa/transaksi", component: TransactionJasa },
    { name: "Transaction Detail Jasa", path: "/jasa/transaksi/detail", component: TransactionDetailJasa },

    // PASAR
    { name: "Pasar", path: "/pasar", component: Pasar },
    { name: "Transaction Pasar", path: "/transaksi-pasar", component: TransactionPasar },
    { name: "On Transaction Pasar", path: "/transaksi-ongoing", component: OnTransactionPasar },

    // SHOPPER
    { name: "Shopper", path: "/shopper", component: Shopper },

    // ALL TRANSACTION HOBBY
    { name: "Transaction Hobby All", path: "/booking", component: TransactionHobbyAll },
    { name: "Detail Transaction Hobby All", path: "/booking/detail", component: DetailTransactionHobby },

    // ALL TRANSACTION JASA
    { name: "Transaction Jasa All", path: "/order", component: TransactionJasaAll },
    { name: "Detail Transaction Jasa All", path: "/order/detail", component: DetailTransactionJasa },
    { name: "Account", path: "/akun", component: Account },

    // ALL TRANSACTION FOOD
    { name: "Transaction Food", path: "/order-food", component: TransactionFoodAll },

    // SETTLEMENT
    { name: "Settlement", path: "/settlement", component: Settlement },

    // PAYMENT
    { name: "Payment Config", path: "/payment-config", component: PaymentConfig },
    { name: "Payment Transaction", path: "/payment-transaction", component: TransactionPayment },

    // USER APPS
    { name: "Banner Config", path: "/banner-config", component: BannerConfig },
    
    // VOUCHER APPS
    { name: "Voucher", path: "/voucher", component: Voucher},
    { name: "Tambah Voucher", path: "/voucher/tambah", component: Voucher},
    { name: "Ubah Voucher", path: "/voucher/ubah", component: Voucher},
    { name: "Detail Voucher", path: "/voucher/detail", component: Voucher},
    { name: "Transaksi Voucher", path: "/voucher/transaksi", component: Voucher},
    
    // KASPRO APPS
    { name: "Kaspro", path: "/kaspro", component: Kaspro},
    { name: "Tambah Kaspro", path: "/kaspro/tambah", component: Kaspro},
    { name: "Ubah Kaspro", path: "/kaspro/ubah", component: Kaspro},
    { name: "Detail Kaspro", path: "/kaspro/detail", component: Kaspro},

    // CUSTOMER APPS
    { name: "Customer", path: "/customer", component: Customer},
    { name: "Detail Customer", path: "/customer/detail", component: DetailCustomer},
    { name: "Transaksi", path: "/customer/transaksi", component: Transaksi},

    //Merchant Food
    { name: "Merchant Food", path: "/food", component: MerchantFood },
    { name: "Item Merchant Food", path: "/food/item", component: ItemFood },
    { name: "Create Item Merchant Food", path: "/food/item/tambah", component: CreateItemFood },
    { name: "Detail Item Merchant Food", path: "/food/item/detail", component: DetailItemFood },
    
    { name: "Akun Merchant Food", path: "/food/akun", component: CreateAccountFood },
    { name: "Update Item Food", path: "/food/item/update", component: UpdateItemFood },

    { name: "Ayopop Product", path: "/ayopop-product", component: Ayopop },
    { name: "Ayopop Transaction", path: "/ayopop-transaction", component: TransactionAyopop },
];

export default routes;
