import React, {useState, useEffect} from 'react'
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Row, Col, Select, DatePicker, notification, Input} from "antd"
import axios from "axios"
import moment from "moment"
import {base_url} from "../../../../config/url-config"
import {merchantjasa, paymentProviderJasa} from "../../../../config/endpoint"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"

const { RangePicker } = DatePicker;

interface Props {
    handleFilter: (values:any) => void
}

const FilterTransactionJasa: React.FC<Props> = (props) => {
    const qp = useQueryParams()
    const location = useLocation()
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false);
    const [merchant, setMerchant] = useState<{
        name:string,
        id:string
    }[]>([])
    const [paymentProvider, setPaymentProvider] = useState<string[]>([])

    useEffect(() => {
        fetchDataMerchant();
        fetchDataPaymentProvider()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
          date: qp.queryParams.get('startDate')?.toString() === undefined ? undefined : [moment(qp.queryParams.get('startDate')?.toString()), moment(qp.queryParams.get('endDate')?.toString())],
          nominalFrom: qp.queryParams.get('nominalFrom')?.toString(),
          nominalTo: qp.queryParams.get('nominalTo')?.toString(),
          merchantID: qp.queryParams.get('merchantID')?.toString(),
          paymentProvider: qp.queryParams.get('paymentProvider')?.toString(),
          status: qp.queryParams.get('status')?.toString(),
          paymentState: qp.queryParams.get('paymentState')?.toString(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:any) => {
        props.handleFilter(values)
        toggle()
    }

    const fetchDataMerchant = async() => {
         try {
            const url = `${base_url}/${merchantjasa}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {
                    page: 1,
                    limit: 100,
                    q: "",
                }
            })
            setMerchant(res?.data?.payload?.data)
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
        }
    }

    const fetchDataPaymentProvider = async () => {
        try {
            const url = `${base_url}/${paymentProviderJasa}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
            })

            setPaymentProvider(res?.data?.payload?.paymentProvider)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data provider pembayaran jasa',
                placement:"topRight"
            });
        }
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-filter" style={{marginRight: "5px"}} />}
                label="Filter" 
                onClick={toggle}
            />
            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="date"
                                        label="Tanggal Transaksi"
                                    >
                                        <RangePicker 
                                            style={{width: "100%"}} 
                                            placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>Range harga</Col>
                            </Row>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="nominalFrom"
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Harga awal"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{paddingLeft: "10px"}}>
                                    <Form.Item
                                        name="nominalTo"
                                    >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Harga akhir"
                                    />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="merchantID"
                                        label="Merchant"
                                    >
                                        <Select placeholder="Pilih Merchant" style={{width: "100%"}}>
                                            {merchant.map((merch, key) => (
                                                <Select.Option key={key} value={merch.id}>{merch.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="paymentProvider"
                                        label="Cara Bayar"
                                    >
                                        <Select placeholder="Pilih Cara Bayar" style={{width: "100%"}}>
                                            {paymentProvider.map((pay, key) => (
                                                <Select.Option key={key} value={pay}>{pay}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status Order"
                                    >
                                        <Select placeholder="Pilih status order" style={{width: "100%"}}>
                                            <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                            <Select.Option value="CANCELED">CANCELED</Select.Option>
                                            <Select.Option value="REJECTED">REJECTED</Select.Option>
                                            <Select.Option value="UNPAID">UNPAID</Select.Option>
                                            <Select.Option value="APPROVED">APPROVED</Select.Option>
                                            <Select.Option value="PAID">PAID</Select.Option>
                                            <Select.Option value="PROCESS">PROCESS</Select.Option>
                                            <Select.Option value="PROCESS_DONE">PROCESS_DONE</Select.Option>
                                            <Select.Option value="PROCESS_REFUND">PROCESS_REFUND</Select.Option>
                                            <Select.Option value="REFUND">REFUND</Select.Option>
                                            <Select.Option value="COMPLAINED">COMPLAINED</Select.Option>
                                            <Select.Option value="COMPLAINED_APPEAL">COMPLAINED_APPEAL</Select.Option>
                                            <Select.Option value="DONE">DONE</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                             <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="paymentState"
                                        label="Status Transaksi"
                                    >
                                        <Select placeholder="Pilih status transaksi" style={{width: "100%"}}>
                                            <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                            <Select.Option value="UNPAID">UNPAID</Select.Option>
                                            <Select.Option value="PAID">PAID</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px", textAlign: "center"}}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>{" "}
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterTransactionJasa
