import React, {useState} from 'react'
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Row, Col, Select} from "antd"

interface Props {
    handleFilter: (values:{}) => void
}

const FilterVoucher: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{}) => {
        props.handleFilter(values)
        form.resetFields()
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-filter" style={{marginRight: "5px"}} />}
                label="Filter" 
                onClick={toggle}
            />
            <Modal
                title="Filter Voucher"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                    form={form}
                >
                    {/* <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Tipe Transaksi"
                                name="transactionType"
                            >
                                <Select placeholder="Pilih tipe transaksi">
                                    <Select.Option value="TOP-UP">Top-Up</Select.Option>
                                    <Select.Option value="WITHDRAW">Withdraw</Select.Option>
                                    <Select.Option value="ORDER">Order</Select.Option>
                                    <Select.Option value="SHARING-FEE">Sharing-Fee</Select.Option>
                                    <Select.Option value="SHARING+ADMIN-FEE">Sharing Admin-Fee</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Jenis Transaksi"
                                name="type"
                            >
                                <Select placeholder="Pilih jenis transaksi">
                                    <Select.Option value="IN">IN</Select.Option>
                                    <Select.Option value="OUT">OUT</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Status"
                                name="depositStatus"
                            >
                                <Select placeholder="Pilih deposit status">
                                    <Select.Option value="SUCCESS">SUCCESS</Select.Option>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="FAILED">FAILED</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="PENDING">PENDING</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>{" "}
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterVoucher
