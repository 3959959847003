import React from "react"
import {IDetail} from "../TransactionPaymentModel"
import {FormatDate, FormatRp} from "../../../../helpers/Formatter"
import {Link} from "react-router-dom"

function TableColumns(
    handleRefreshPayment: (paymentMethod:string, value:{invoiceNumber:string, origin:string}) => void
) {
    const columns = [
        {
            title: "No. Invoice",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.origin === "ipl" ? data.invoice_ipl.invoiceNumber : data.invoice.invoiceNumber}</div>
                    <div>Origin : <span style={{fontWeight: "bold", color: "black"}}>{data.origin}</span></div>
                </div>
            )
        },
        {
            title: "Tanggal Transaksi",
            render: (data:IDetail) => (
                <div>{FormatDate(data.transDt)}</div>
            )
        },
        {
            title: "Payment Provider",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.paymentProvider}</div>
                    <div>{data.payment_method} {data.payment_method === "va" ? `- ${data.bank}` : null}</div>
                </div>
            )
        },
        {
            title: "Biaya Admin",
            render: (data:IDetail) => (
                <div>{FormatRp(data.adminFee)}</div>
            )
        },
        {
            title: "Total",
            render: (data:IDetail) => (
                <div>{FormatRp(data.amount)}</div>
            )
        },
        {
            title: "Status Payment",
            render: (data:IDetail) => {
                const value = {invoiceNumber: data.origin === "ipl" ? data.invoice_ipl.invoiceNumber : data.invoice.invoiceNumber, origin: data.origin}

                return (
                    <div>
                        <span>
                            {data.payment_state}
                        </span>{" "}
                        <span>
                            {data.paymentProvider !== "dana" ? 
                                    <i 
                                        className="fas fa-sync" 
                                        style={{color: "green"}} 
                                        onClick={() => handleRefreshPayment(data.paymentProvider, value)}
                                    /> 
                                : null
                            }
                        </span>
                    </div>
                )
            }
        },
        {
            title: "Aksi",
            render: (data:IDetail) => {
                return (
                    <Link to={`/payment-transaction/detail?payment_id=${data.payment_id}`} >
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="far fa-eye" />
                        </div>
                    </Link>
                )
            }
        }
    ]

    return {
        columns
    }
}

export default TableColumns