import React, {useContext} from 'react'
import {ItemJasaContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {Pagination, Row, Col} from "antd"
import {Link} from "react-router-dom"
import {PageLayout} from "../../../../templates"
import FilterItem from "../filteritem/FilterItem"
import UploadItem from "../uploaditem/UploadItem"
import Category from "../category/Category"
import {FeatureButton, Search, Table, BackButton} from "../../../../../components/atoms"
import {useQueryParams} from "../../../../../helpers/Formatter"

import "./list.scss"

const Item: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(ItemJasaContext)
    const {
        loading,
        fields,
        visible,
        hideMenu,
        handlePageChange,
        toggle,
        handleUpdateStatus,
        handleDelete,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handleHideMenu,
        handleDownloadBulk,
        handleUploadTemplate
    } = context.useModelList()
    const {columns} = TableColumns(loading, visible, toggle, handleUpdateStatus, handleDelete)

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <Link to="/jasa/item/tambah">
                            <FeatureButton 
                                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                                label="Tambah"
                            /> 
                        </Link>
                        <FilterItem handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                        {hideMenu ? (
                            <span>
                                <Category />
                                <UploadItem 
                                    handleDownloadTemplate={handleDownloadBulk}
                                    handleUploadTemplate={handleUploadTemplate}
                                    loading={loading}
                                />
                                <i 
                                    className="fas fa-chevron-circle-left"
                                    style={{cursor: "pointer", color: "#009b00"}}
                                    onClick={handleHideMenu} 
                                />
                            </span>
                         ) : (
                            <i 
                                className="fas fa-chevron-circle-right" 
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={handleHideMenu}
                            />
                         )}
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('q')?.toString()}
                />}
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.payload}
                            rowKey="id"
                            pagination={false}
                            loading={loading === "fetch"}
                            // scroll={{y: 550}}
                        />
                    </div>   
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/jasa">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.currentPage} 
                                pageSize={fields.limit} 
                                total={fields.totalPage * fields.limit} 
                                onChange={handlePageChange} 
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default Item
