import React, {useContext} from 'react'
import {useQueryParams} from "../../../../helpers/Formatter"
import {AyopopContext} from "../context/ContextProvider"
import {PageLayout} from "../../../templates"
import Filter from "../filter/Filter"
import {Search, Table, FeatureButton} from "../../../../components/atoms"
import Bulk from "../bulk/Bulk"
import {FormatRp} from "../../../../helpers/Formatter"
import TableColumns from "./Columns"
import {Pagination} from "antd"

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(AyopopContext)
    const {
        loading,
        fields,
        params,
        balance,
        hideMenu,
        biller,
        category,
        handleFilter,
        handleReset,
        handleSubmitSearch,
        handleHideMenu,
        handlePageChange,
        handleUpdate,
        handleParams,
        handleRefreshProduct,
        handleDownloadBulk,
        handleUploadTemplate
    } = context.useModelList()
    const {columns} = TableColumns(handleUpdate, loading)

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <Filter 
                            handleParams={handleParams}
                            biller={biller}
                            category={category}
                            handleFilter={handleFilter}
                        />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                        <FeatureButton
                            icon={<i className="fas fa-sync-alt" style={{ marginRight: "5px" }} />}
                            label="Produk"
                            onClick={handleRefreshProduct}
                        />
                        {hideMenu ? (
                            <span>
                                <Bulk 
                                    handleDownloadTemplate={handleDownloadBulk}
                                    handleUploadTemplate={handleUploadTemplate}
                                    loading={loading}
                                />
                                <i
                                    className="fas fa-chevron-circle-left"
                                    style={{ cursor: "pointer", color: "#009b00" }}
                                    onClick={handleHideMenu}
                                />
                            </span>
                        ) : (
                            <i
                                className="fas fa-chevron-circle-right"
                                style={{ cursor: "pointer", color: "#009b00" }}
                                onClick={handleHideMenu}
                            />
                        )}
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e: any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                content={
                    <div>
                        <div style={{marginBottom: "10px", fontSize: "17px", fontWeight: "bold"}}>
                            Balance: {FormatRp(balance)}
                        </div>
                        <Table 
                            columns={columns}
                            data={fields.data}
                            loading={loading === "fetch" ? true : false}
                            rowKey="id"
                            pagination={false}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination
                            current={params.pageNumber}
                            pageSize={10}
                            total={fields.totalData}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default List
