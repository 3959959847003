import React, {useContext} from 'react'
import {TransactionJasaContext} from "../context/ContextProvider"
import {useLocation} from "react-router-dom"
import {DetailPageLayout} from "../../../../templates"
import {Row, Col} from "antd"
import {Loading, CancelButton, BackButton, OkButton} from "../../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"

const TransactionDetail: React.FC = () => {
    const location = useLocation()
    const bookingId = location.search.split("=")[1]
    const context = useContext(TransactionJasaContext)
    const {
        fields,
        loading,
        handleAccept,
        handleReject,
        handleNextStep
    } = context.useModelDetail(bookingId)
    
    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        {fields.status === "UNPAID" ? (
                            <Row>
                                <Col span={24}>
                                    <OkButton 
                                        onClick={handleAccept}
                                    >
                                        Terima
                                    </OkButton>
                                    <CancelButton 
                                        label="Batal" 
                                        onClick={handleReject} 
                                    />
                                </Col>
                            </Row>
                        ) : fields.status === "PAID" || fields.status === "PROCESS" ? (
                            <OkButton 
                                onClick={handleNextStep}
                            >
                                {fields.status === "PAID" ? "Proses" : "Selesai"}
                            </OkButton>
                        ) : null}
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomer Order" value={fields.invoiceNumber === "" ? "-" : fields.invoiceNumber} />
                                <ItemDetail label="User" value={fields.userName === "" ? "-" : fields.userName} />
                                <ItemDetail label="Tanggal Order" value={FormatDate(fields.orderDate)} />
                                <ItemDetail label="Status Order" value={fields.status} />
                                <ItemDetail label="Alamat Order" value={fields.address} />
                                <ItemDetail label="Catatan Order" value={fields.orderNote === "" ? "-" : fields.orderNote} />
                                <ItemDetail label="Sharing Fee" value={<div style={{textAlign:"right"}}>{FormatRp(fields.sharingFee)}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Item Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Nama Item</Col>
                                            <Col span={7}>Note</Col>
                                            <Col span={4}>Kuantiti</Col>
                                            <Col span={4}>Diskon</Col>
                                            <Col span={4}><div style={{float: "right"}}>Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.items.map((item:any, key:number) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{border: "1px solid black"}}>
                                                <Col span={5}>{item.name}</Col>
                                                <Col span={7}>{item.note === "" ? "-" : item.note}</Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={4}>{FormatRp(item.discount)}</Col>
                                                <Col span={4}><div style={{float: "right"}}>{FormatRp(item.price)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Total Order" value={<div style={{textAlign:"right"}}>{FormatRp(fields.totalPrice)}</div>} />
                                <ItemDetail label="Total Diskon" value={<div style={{textAlign:"right", color: "red"}}>{FormatRp(fields.totalDiscount)}</div>} />
                                <ItemDetail label="Total Promo" value={<div style={{textAlign:"right", color: "red"}}>{FormatRp(fields.totalPromo)}</div>} />
                                <ItemDetail label="Total" value={<div style={{textAlign:"right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.totalPrice - fields.totalDiscount - fields.totalPromo)}</div>} />
                            </Col>
                        </Row>
                        {fields.paymentData !== null ? (
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <DetailTitleSection title="Detail Pembayaran" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <ItemDetail label="Status Pembayaran" value={fields.paymentData.paymentState} />
                                        <ItemDetail label="Metode Pembayaran" value={fields.paymentData.paymentMethod === "" ? "-" : fields.paymentData.paymentMethod} />
                                        <ItemDetail label="Provider Pembayaran" value={fields.paymentData.paymentProvider} />
                                        <ItemDetail label="Total Pesananan" value={<div style={{textAlign:"right"}}>{FormatRp(fields.paymentData.invoiceAmount)}</div>} />
                                        <ItemDetail label="Biaya Admin" value={<div style={{textAlign:"right"}}>{FormatRp(fields.paymentData.adminFee)}</div>} />
                                        <ItemDetail label="Total Bayar" value={<div style={{textAlign:"right", color: "black", fontWeight: "bold"}}>{FormatRp(fields.paymentData.totalAmount)}</div>} />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                <Link to="/jasa/transaksi">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default TransactionDetail
