

export interface IListItem {
    total_record: number
    total_page: number
    records: IDetailItem[]
}

export interface ICategory {
    id?: number
    categoryID?: number | null
    categoryName?: string
    marketID?: number | null
}

export interface IDetailItem {
    id: number
    imageURL: string[]
    name: string
    itemCategory: ICategory[]
    categoryID: any
    description: string
    marketID: any
    price: any//float
    discount: any //float
    isDiscountPercentage: boolean | string
    isActive: boolean
    tags: string[]
    createdBy?: string
    updatedBy?: string
    deletedBy?: string
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
}

export const initialDataItemList = {
    total_record: 0,
    total_page: 1,
    records: []
}

export const initialDataCategory = {
    id: 0,
    categoryName: "",
    marketID: 0
}

export const initialDataDetailItem = {
    id: 1,
    imageURL: [""],
    name: "",
    itemCategory: [],
    categoryID: null,
    description: "",
    marketID: null,
    price: null, //float
    discount: null, //float
    isDiscountPercentage: false,
    isActive: true,
    tags: [],
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: ""
}



