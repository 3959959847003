import React, {useContext, useState} from 'react'
import {useQueryParams} from "../../../../../helpers/Formatter"
import {ItemContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {Link} from "react-router-dom"
import {PageLayout} from "../../../../templates"
import Filter from "../filter/Filter"
import Category from "../category/Category"
import Bulk from "../bulk/Bulk"
import {Search, Table, FeatureButton, BackButton} from "../../../../../components/atoms"
import {Pagination, Row, Col} from "antd"

import "./list.scss"

const ListItem: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(ItemContext)
    const {
        fields,
        loading,
        handleUpdateStatus,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        visible,
        setVisible,
        handleDelete,
        handleDownloadTemplate,
        handleUploadTemplate
    } = context.useModelList()
    const {columns} = TableColumns(
        handleUpdateStatus, 
        loading,
        visible,
        setVisible,
        handleDelete
    )
    const [hideMenu, setHideMenu] = useState<boolean>(false)

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)}
                    value={qp.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <Link to="/pasar/item/tambah">
                            <FeatureButton 
                                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                                label="Tambah"
                            /> 
                        </Link>
                        <Filter 
                            handleFilter={handleFilter}
                        />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                        {hideMenu ? (
                            <span>
                                <Category />
                                <Bulk 
                                    handleDownloadTemplate={handleDownloadTemplate}
                                    handleUploadTemplate={handleUploadTemplate}
                                    loading={loading}
                                />
                                <i 
                                    className="fas fa-chevron-circle-left"
                                    style={{cursor: "pointer", color: "#009b00"}}
                                    onClick={() => setHideMenu(!hideMenu)} 
                                />
                            </span>
                         ) : (
                            <i 
                                className="fas fa-chevron-circle-right" 
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={() => setHideMenu(!hideMenu)}
                            />
                         )}
                    </div>
                }
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.records}
                            rowKey="id"
                            loading={loading === "fetch" ? true : false}
                            pagination={false}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/pasar">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.page} 
                                pageSize={10} 
                                total={fields.total_record} 
                                onChange={handlePageChange} 
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default ListItem
