import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../config/url-config"
import {PageLayout} from "../../templates"
import {Search, FeatureButton, Table} from "../../../components/atoms"

import "./account.scss"

const Account: React.FC = () => {
    const [fields, setFields] = useState<{}[]>([])


    // FUNCTION FOR GET LIST OF MERCHANT HOBBY //
    const fetchData = async() => {
        try {
            const res = await axios.get(`${base_url}/v3/client/user`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {downline: true}
            })
            setFields(res.data.payload)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    // ---------------------------------- //


    

    console.log(fields);

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search />}
                leftTop={<FeatureButton label={<div><i className="fas fa-plus-square" /> Tambah</div>} /> }
                content={
                    <Table

                    />
                }
            />
        </React.Fragment>
    )
}

export default Account
