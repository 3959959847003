import React from "react"
import {IDetail} from "../../merchantjasa/transaction/TransactionModel"
import {FormatDate, FormatRp} from "../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import UpdateStatusPesan from '../updatestatuspesan/UpdateStatusPesan';

function TableColumns(
    setDataEdit:(data:{}) => void,
    handleAccept:() => void,
    handleReject:() => void,
    handleNextStep:() => void,
) {

    const columns = [
        {
            title: "No. Order",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.invoiceNumber}</div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.merchantName}</div>
                    <div>{FormatDate(data.orderDate)}</div>
                </div>
            ),
            width: "22%",
        },
        {
            title: "Total Pesanan",
            render: (data:IDetail) => (
                <div>
                    {FormatRp(data.totalPrice)}
                </div>
            ),
            width: "15%"
        },
        {
            title: "Biaya Layanan",
            render: (data:IDetail) => (
                <div>{FormatRp(data.deliveryFee)}</div>
            ),
            width: "13%"
        },
        {
            title: "Status",
            render: (data:IDetail) => {

            return(
                <div>
                    <UpdateStatusPesan 
                        data={data}
                        setDataEdit={setDataEdit}
                        handleAccept={handleAccept}
                        handleReject={handleReject}
                        handleNextStep={handleNextStep}
                    />
                </div>
            )},
            width: "42%"
        },
        {
            title: "Aksi",
            render: (data:IDetail) => (
                <Link to={`/order/detail?bookingID=${data.id}`} >
                    <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                        <i className="far fa-eye" />
                    </div>
                </Link>
            )
        }
    ]

    return {
        columns
    }
}   

export default TableColumns