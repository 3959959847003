import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import { layanan } from "../../../../config/endpoint"
import {notification} from "antd"

export const useModelLayanan = () => {
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        name:string,
        id:string
    }[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async() => {
        setLoading(true)
        try {
            const url = `${base_url}/${layanan}`

            const res = await axios.get(url, {
                headers: headers,
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data Layanan.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async(values:{}) => {
        try {
            const url = `${base_url}/${layanan}`

            await axios.post(url, values, {
                headers: headers,
            })
            fetchData()
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat membuat Layanan.',
            });
        }
    }

    const handleEdit = async(id:string, values:{}) => {
        try {
            const url = `${base_url}/${layanan}/${id}`

            await axios.put(url, values, {
                headers: headers,
            })
            fetchData()
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat membuat Layanan.',
            });
        }
    }

    const toggle = () => {
        setModal(!modal)
    }

    return {
        modal,
        fields,
        loading,
        handleSubmit,
        toggle,
        handleEdit
    }
}