import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {userrole, account} from "../../../../../config/endpoint"
import {notification} from "antd"

export const useModelDetail = (
    merchantId: string,
    fetchData: (params:{}, merchantId:string) => void,
    params: {}
) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)
    const [role, setRole] = useState<{id:string, name:string}[]>([])
    const [pass, setPass] = useState<string>("")
    const [passconfirm, setPassconfirm] = useState<boolean|null>(null)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchRole = async () => {
        try {
            const url = `${base_url}/${userrole}`

            const res = await axios.get(url, {
                headers: headers,
                params: {downline: true}
            })
            setRole(res.data.payload)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data role!',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchRole()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setpass = (e:any) => {
        e.target.name === "password" ? setPass(e.target.value) : (
            e.target.value === pass ? setPassconfirm(true) : setPassconfirm(false)
        )
    }

    const handleSubmit = async (values: {entityID:string, passwordConfirm?:string}) => {
        values["entityID"] = merchantId
        delete values.passwordConfirm
        console.log(values);
        if (passconfirm === true) {
            setLoading(true)
            try {
                const url = `${base_url}/${account}/role`

                await axios.post(url, values, {
                    headers: headers
                })
                notification.success({
                    message: 'Yuhuu!',
                    description:
                        'Menambahkan data akun berhasil!',
                    placement:"topRight"
                });
                setLoading(false)
                toggle()
                fetchData(params, merchantId)
            } catch (error) {
                setLoading(false)
                notification.error({
                    message: 'Ups!',
                    description:
                      'Pastikan form yang anda isi telah benar!',
                    placement:"topRight"
                });
            }
        } else {
            notification.error({
                message: 'Ups!',
                description:
                  'Pastikan form yang anda isi telah benar!',
                placement:"topRight"
            });
        }
    }

    const toggle = () => {
        setModal(!modal)
    }

    return {
        loading,
        modal,
        role,
        pass,
        passconfirm,
        setpass,
        handleSubmit,
        toggle
    }
}