import React, {useContext} from 'react'
import {useLocation, Link} from "react-router-dom"
import {VoucherContext} from '../context/ContextProvider'
import {Row, Col, Image} from "antd"
import {DetailPageLayout} from "../../../templates"
import {Loading, BackButton} from "../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import moment from "moment";
import "./detail.scss"

const Detail: React.FC = () => {
    const location = useLocation()
    const voucherId = location.search.split("=")[1]
    const context = useContext(VoucherContext)
    const {
        loading,
        fields,
    } = context.useModelDetail(voucherId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> :
                <div>
                    <Row>
                        <Col span={24}>
                            <DetailTitleSection title="General" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Kode Promo" value={fields.promo_code} />
                            <ItemDetail label="Nama Promo" value={fields.name} />
                            <ItemDetail borderNone label="Gambar" value={
                                <Image 
                                    src={fields.image} 
                                    alt="voucher-pict" 
                                    width={80}
                                    height={80}
                                    style={{borderRadius: "10px"}} 
                                />
                            } />
                            <ItemDetail label="Deskripsi" value={
                                <div dangerouslySetInnerHTML={{ __html: fields.description}} />
                            } />
                            <ItemDetail label="Jenis Voucher" value={fields.voucher_category} />
                            <ItemDetail label="Nominal" value={fields.nominal} />
                            <ItemDetail label="Maksimal Nominal" value={fields.maximal_nominal} />
                            <ItemDetail label="Periode Voucher" value={moment(fields.start_date).format("DD-MM-YYYY HH:mm") + " sampai " + moment(fields.expired_date).format("DD-MM-YYYY HH:mm")} />
                            <ItemDetail label="Status Voucher" value={fields.is_active === "1" ? "Active" : "InActive"} />
                            <ItemDetail label="Tampil di List" value={fields.is_visible === "1" ? "Ya" : "Tidak"} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <DetailTitleSection title="Pembatasan Penggunaan" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Minimal Transaksi" value={fields.minimal_transaction} />
                            <ItemDetail label="Maksimal Transaksi" value={fields.maximal_transaction} />
                            <ItemDetail label="Layanan" value={fields?.included_layanan_data?.map((ele:any) => {return ele.name}).join(", ") || "-"} />
                            <ItemDetail label="Kategori Layanan" value={fields.included_category || "-"} />
                            <ItemDetail label="Kecualikan Layanan" value={fields?.excluded_layanan_data?.map((ele:any) => {return ele.name}).join(", ") || "-"} />
                            <ItemDetail label="Kecualikan Kategori Layanan" value={fields.excluded_category || "-"} />
                            <ItemDetail label="Merchant" value={fields.merchant || "-"} />
                            <ItemDetail label="Provider Pembayaran" value={fields.payment_provider} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <DetailTitleSection title="Limit Penggunaan" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Limit Promo" value={fields.limit_promo_total} />
                            <ItemDetail label="Limit Perhari" value={fields.limit_per_days} />
                            <ItemDetail label="Limit Per User" value={fields.limit_per_user} />
                        </Col>
                    </Row> 
                    <Row style={{paddingTop: "20px"}}>
                        <Col>
                            <Link to="/voucher">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                    </Row>
                </div>}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
