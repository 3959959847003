import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detailtransactionjasa/DetailTransactionJasa"
import Chat from "./chattransactionjasa/ChatTransactionJasa"

function TransactionJasaAll() {
    return (
        <ContextProvider>
            <Route path="/order" exact={true} component={List} />
            <Route path="/order/detail" exact={true} component={Detail} />
            <Route path="/order/detail/pesan" exact={true} component={Chat} />
        </ContextProvider>
    )
}

export default TransactionJasaAll
