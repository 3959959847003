import  { useState} from 'react';

export interface IPagination {
    page: number;
    limit: number;
    totalPage: number;
    totalData: number;
}

export interface IListMerchant {
    pagination: IPagination
    payload: ModelDataMerchant[]
    statusCode: number
}

export interface ModelQueryParams {
    pagination:IPagination,
    data:{}[]
}

export interface ModelDataMerchant {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    description: string;
    urlPhoto: string;
    phoneNumber: string;
    email: string;
    status: boolean;
    open: boolean;
    orderFeeIsInPercentage: boolean;
    sharingFeeIsInPercentage: boolean;
    tags: any[];
    location?: ILocation;
    distance?: number;
    operationHours: IOperational[];
    categories?:  ICategories[];
    code?: string;
    address?:string;
    orderFee?:Number | null
    sharingFee?:Number | null
    name?:string,
    latitude?:string,
    longitude?:string,
}

export interface IListCategories {
    statusCode: number
    pagination: IPagination
    payload: ICategories[]
}

export interface ICategories {
    id: string ;
    name: string ;
}

export interface IBankDetail {
    accountName:string,
    accountNumber:string,
    bankName:string
}

export interface IOperational{
    active?:boolean
    day: number
    merchantID?: string | null | undefined
    openHour?: string | null
    closeHour?: null | string
}

export interface ILocation {
    latitude: number | null
    longitude: number | null
}

export const initialDataList = {
    pagination: {
        limit: 10,
        page: 1,
        totalData: 0,
        totalPage: 1
    },
    payload: [],
    statusCode: 0
}

export const initialDataMerchant = {
    id: "",
    address: "",
    categories: [],
    description: "",
    email: "",
    location: {
        latitude: null,
        longitude: null
    },
    name: "",
    open: false,
    operationHours: [],
    orderFee: null,
    phoneNumber: "",
    rating: 0,
    sharingFee: null,
    distance:0,
    orderFeeIsInPercentage: false,
    sharingFeeIsInPercentage: false,
    status: false,
    tags: [],
    urlPhoto: "",
}

export const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];



export function useModelMerchant(){
    const [fields, setFields] = useState<ModelDataMerchant>(initialDataMerchant);

    return {
        fields, 
        setFields,
    }
}