import React from "react"
import {IDetail} from "../ModelTransaction"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {Row, Col} from "antd"
import {Link} from "react-router-dom"

function TableColumns() {
    const columns = [
        {
            title: "No. Order",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.invoiceNumber}</div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.merchantName}</div>
                    <div>{FormatDate(data.createdAt)}</div>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Total Pesanan",
            render: (data:IDetail) => (
                <div>
                    {FormatRp(data.totalPrice)}
                </div>
            ),
            width: "20%"
        },
        {
            title: "Biaya Layanan",
            render: (data:IDetail) => (
                <div>
                    {FormatRp(data.adminFee)}
                </div>
            ),
            width: "15%"
        },
        {
            title: "Pembayaran",
            render: (data:IDetail) => (
                <div>
                    <div>{data.paymentDetail !== null ? data.paymentDetail.paymentProvider : "-"}</div>
                    <div>{data.paymentDetail !== null ? data.paymentDetail.paymentMethod : "-"}</div>
                </div>
            ),
            width: "10%"
        },
        {
            title: "Status",
            render: (data:IDetail) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.status} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.paymentDetail !== null ? data.paymentDetail.paymentState : "-"}
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            title: "Aksi",
            render: (data:IDetail) => {
                return (
                    <Link to={`/food/transaksi/detail?orderID=${data.id}`} >
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="far fa-eye" />
                        </div>
                    </Link>
                )
            }
        }
    ]

    return {
        columns
    }
}


export default TableColumns