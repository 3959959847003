import React, {useContext} from 'react'
import {CategoryContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {PageLayout} from "../../../templates"
import CreateMerchantCategory from "../createmerchantcategory/CreateMerchantCategory"
import {Search, Table} from "../../../../components/atoms"
import {Pagination} from "antd"

import "./list.scss"

const List: React.FC = () => {
    const context = useContext(CategoryContext)
    const {
        loading,
        fields,
        params,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleUpdateStatus
    } = context.useModelList()
    const {columns} = TableColumns(fetchData, params, handleUpdateStatus)
    
    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                leftTop={
                    <CreateMerchantCategory
                        fetchData={fetchData}
                        params={params}
                    />
                }
                content={
                    <React.Fragment>
                        <div>
                            <Table 
                                columns={columns} 
                                data={fields.payload}
                                rowKey="id"
                                pagination={false}
                                loading={loading}
                                scroll={{y: 'calc(100vh - 23em)'}}
                            />
                        </div>
                    </React.Fragment>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            current={fields.currentPage} 
                            pageSize={fields.limit} 
                            total={fields.totalPage * fields.limit} 
                            onChange={handlePageChange} 
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default List
