import React, { FunctionComponent } from 'react';
import Row from "antd/lib/row";
import Col from "antd/lib/col";


interface IRowCol {
    label?: string,

}

const RowCol: FunctionComponent<IRowCol> = ({ label, children }) => {
    return <Row className="row-form">

        {label && <Col span={7}>{label}</Col>}
        <Col span={label ? 14 : 24}>
            {children}
        </Col>
    </Row>
}

export default RowCol;