import {useState, useEffect} from "react"
import {useHistory} from "react-router"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {listpasar} from "../../../../config/endpoint"
import {IDetailPasar, initialDataDetail, IOperation, initialDataOperationHours} from "../ModelPasar"
import {notification} from "antd"

export function useModelDetail(id:number | null = null) {
    const history = useHistory()
    const [fields, setFields] = useState<IDetailPasar>(initialDataDetail)
    const [loading, setLoading] = useState<boolean>(false)
    const [newTag, setNewTag] = useState<string>("")
    const [operationHours, setOperationHours] = useState<IOperation[]>(initialDataOperationHours)
    
    const fetchData = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${listpasar}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setFields(res.data.payload)
            if (res.data.payload.operation.length > 0) {
                let newOperationHours = [
                    {active: false, day: 0, openHour: null, closeHour: null},
                    {active: false, day: 1, openHour: null, closeHour: null},
                    {active: false, day: 2, openHour: null, closeHour: null},
                    {active: false, day: 3, openHour: null, closeHour: null},
                    {active: false, day: 4, openHour: null, closeHour: null},
                    {active: false, day: 5, openHour: null, closeHour: null},
                    {active: false, day: 6, openHour: null, closeHour: null},
                ]
                for (let i = 0; i < res.data.payload.operation.length; i++) {
                    newOperationHours[res.data.payload.operation[i].day].active = true
                    newOperationHours[res.data.payload.operation[i].day].openHour = res.data.payload.operation[i].openHour
                    newOperationHours[res.data.payload.operation[i].day].closeHour = res.data.payload.operation[i].closeHour
                }
                setOperationHours(newOperationHours)
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data pasar',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        id !== null && fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSetImageURL = (url:string) => {
        setFields({...fields, imageURL: [url]})
    }

    const submitOperationHourStatus = (e:any, index:number) => {
        const newOperationHours = [...operationHours]
        newOperationHours[index].active = e.target.checked
        setOperationHours(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time:any, timeString:any, index:number) => {
        const newOperationHours = [...operationHours]
        newOperationHours[index].openHour = timeString[0]
        newOperationHours[index].closeHour = timeString[1]
        setOperationHours(newOperationHours)
    }

    const submitOperationalHour = () => {
        const newOperationHours = (operationHours.filter(operationHour => operationHour.active === true))
        newOperationHours.forEach(newOperationHour => delete newOperationHour.active)
        setFields({...fields, operation: newOperationHours})
    }

    const handleEditTag = (tag:string, index:number) => {
        const newarr = [...fields.tags]
        newarr[index] = tag
        setFields({...fields, tags: newarr})
    }

    const handleDeleteTag = (tag:string) => {
        const newarr = fields.tags.filter((oldtag:string) => oldtag !== tag)
        setFields({...fields, tags: newarr})
    }

    const handleChangeTag = (e:any) => {
        setNewTag(e.target.value)
    }

    const submitNewTag = () => {
        if (newTag !== "") {
            const newarr = [...fields.tags, newTag]
            setFields({...fields, tags: newarr})
            setNewTag("")
        }
    }

    const handleSubmit = async(values:IDetailPasar) => {
        values["imageURL"] = fields.imageURL
        values["operation"] = fields.operation
        values["isServiceFeePercentage"] = values.isServiceFeePercentage === "%" ? true : false
        values["tags"] = fields.tags
        values["serviceFee"] = parseInt(values.serviceFee)
        values["latitude"] = parseFloat(values.latitude)
        values["longitude"] = parseFloat(values.longitude)
        // console.log(values);
        setLoading(true)
        try {
            if (id !== null) {
                await axios.put(`${base_url}/${listpasar}/${id}`, values, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    }
                })
            } else {
                await axios.post(`${base_url}/${listpasar}`, values, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    }
                })
            }
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Data pasar berhasil ${id !== null ? "diperbaharui" : "dibuat"}.`,
            });
            setLoading(false)
            history.goBack()
        } catch(error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  `Terjadi kesalahan pada saat ${id !== null ? "memperbaharui" : "membuat"} data pasar.`,
            });
            setLoading(false)
        }
    }

    return {
        fields,
        setFields,
        fetchData,
        loading,
        newTag,
        setNewTag,
        handleEditTag,
        handleDeleteTag,
        operationHours,
        handleChangeTag,
        submitNewTag,
        handleSetImageURL,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        handleSubmit
    }
}