import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detail/Detail"

import "./transaction.scss"

const Transaction: React.FC = () => {
    return (
        <React.Fragment>
            <ContextProvider>
                <Route path="/transaksi-pasar" exact={true} render={() => <List />} />
                <Route path="/transaksi-pasar/detail" exact={true} render={() => <Detail />} />
            </ContextProvider>
        </React.Fragment>
    )
}

export default Transaction
