import React, {useState, useEffect, useContext} from 'react'
import {TransactionPaymentContext} from "../context/ContextProvider"
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Row, Col, Select, DatePicker} from "antd"

const { RangePicker } = DatePicker;

interface Props {
    loading: string
    // fetchOrigin: () => void
    // origin: string[]
    // handleChangeFormFilter: (value:any) => void
    handleDownloadReport: (values:{}) => void
}

const Report: React.FC<Props> = (props) => {
    const context = useContext(TransactionPaymentContext)
    const {
        origin,
        fetchOrigin
    } = context.useModelFilter()
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    useEffect(() => {
        modal && fetchOrigin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    const handleSubmit = (values:{}) => {
        props.handleDownloadReport(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-download" /> Report</div>}
                onClick={toggle}
            />

            <Modal
                title="Form Download Report"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="createdAt"
                                label="Tanggal Transaksi"
                                rules={[{ required: true, message: 'Tanggal transaksi wajib diisi!' }]}
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="origin"
                                label="Origin Transaksi"
                            >
                                <Select 
                                    placeholder="Pilih origin" 
                                    style={{ width: "100%" }}
                                    // onChange={props.handleChangeFormFilter}
                                >
                                    {origin.map((each:string, key:number) => (
                                        <Select.Option key={key} value={each}>{each}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentState"
                                label="Status Payment"
                            >
                                <Select placeholder="Pilih status payment" style={{ width: "100%" }}>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="UNPAID">UNPAID</Select.Option>
                                    <Select.Option value="FAILED">FAILED</Select.Option>
                                    <Select.Option value="PAID">PAID</Select.Option>
                                    <Select.Option value="CANCELED">CANCELED</Select.Option>
                                    <Select.Option value="REFUND">REFUND</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton
                                    loading={props.loading === "download" ? true : false}
                                >
                                    Simpan
                                </OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Report
