import {useState} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {paymentconfig} from "../../../../config/endpoint"
import {notification} from "antd"

export const useModelForm = () => {
    const [paymentOrigins, setPaymentOrigins] = useState<string[]>([])
    const [apartments, setApartments] = useState<{id:string, name:string}[]>([])
    const [paymentProviders, setPaymentProviders] = useState<string[]>([])
    const [paymentMethods, setPaymentMethods] = useState<{paymentMethodCode:string, paymentMethod:string}[]>([])
    const [banks, setBanks] = useState<{
        code: string
        id: string
        name: string
        provider: string
        type: string
    }[]>([])
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchpaymentorigin = async() => {
        try {
            const url = `${base_url}/${paymentconfig}/get`

            const res = await axios.get(url, {
                headers: headers,
            })
            setPaymentOrigins(res.data.payload.paymentOrigin)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengakses data origin',
                placement: "bottomRight",
            });
        }
    }

    const fetchApartment = async() => {
        try {
            const url = `${base_url}/apartment/option`

            const res = await axios.get(url, {
                headers: headers,
            })
            setApartments(res.data.payload)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengakses data apartment',
                placement: "bottomRight",
            });
        }
    }

    const fetchProvider = async(origin:string) => {
        try {
            const url = `${base_url}/${paymentconfig}/get/${origin}`

            const res = await axios.get(url, {
                headers: headers,
            })
            setPaymentProviders(res.data.payload.paymentProvider)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengakses data provider',
                placement: "bottomRight",
            });
        }
    }

    const fetchPaymentMethod = async(origin:string, provider:string) => {
        try {
            const url = `${base_url}/${paymentconfig}/get/${origin}/${provider}`

            const res = await axios.get(url, {
                headers: headers,
            })
            setPaymentMethods(res.data.payload)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengakses data metode pembayaran',
                placement: "bottomRight",
            });
        }
    }

    const fetchBank = async(paymentProvider:string) => {
        try {
            const url = `${base_url}/${paymentconfig}/types`

            const res = await axios.get(url, {
                headers: headers,
                params: {
                    type: "bank",
                    provider: paymentProvider
                }
            })
            setBanks(res.data.payload)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengakses data bank',
                placement: "bottomRight",
            });
        }
    }

    return {
        paymentOrigins,
        apartments,
        paymentProviders,
        paymentMethods,
        banks,
        fetchpaymentorigin,
        fetchApartment,
        fetchProvider,
        fetchPaymentMethod,
        fetchBank
    }
}