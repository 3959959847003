import React, { useEffect, useState } from 'react'
import { IDetailPasar } from "../../ModelPasar"
import { FeatureButton, Modal, OkButton, BackButton } from "../../../../../components/atoms"
import { Form, Row, Col, Select, DatePicker } from "antd"

import "./filter.scss"
import { useQueryParams } from '../../../../../helpers/Formatter'
import moment from 'moment'

interface Props {
    handleFilter: (values: {}) => void
    pasar: { records: IDetailPasar[] },
    // fields?: any

}

const Filter: React.FC<Props> = (props) => {
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false);
    const qp = useQueryParams();

    const toggle = () => {
        setModal(!modal)
    }

    const handleShowModal = () => {
        toggle();
        var value = { ...qp.value };
        if (qp.queryParams.get("startDate") && qp.queryParams.get("endDate")) {
            value["createdAt"] = [moment(qp.queryParams.get('startDate')), moment(qp.queryParams.get('endDate'))];
        }

        if (qp.queryParams.get("marketID")) {
            value["marketID"] = Number(qp.queryParams.get("marketID"))
        }


        form.setFieldsValue(value);
    }


    const handleSubmit = (values: {}) => {
        props.handleFilter(values)
        form.resetFields();
        toggle();
    }

    useEffect(() => {
        if (Object.keys(qp.value).length < 1) {
            form.resetFields();
        }
    }, [qp.value, form])


    return (
        <React.Fragment>
            <FeatureButton
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={handleShowModal}
            />

            <Modal
                title="Filter Transaksi"
                onCancel={toggle}
                visible={modal}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Row className="row-form" >
                                <Col span={24}>
                                    <Form.Item
                                        name="createdAt"
                                        label="Tanggal Transaksi"
                                    >
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            format="DD-MM-YYYY"
                                            placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status Transaksi"
                                    >
                                        <Select placeholder="Pilih status transaksi" style={{ width: "100%" }}>
                                            <Select.Option value="CREATED">CREATED</Select.Option>
                                            <Select.Option value="UNPAID">UNPAID</Select.Option>
                                            <Select.Option value="PAID">PAID</Select.Option>
                                            <Select.Option value="APPROVED">APPROVED</Select.Option>
                                            <Select.Option value="PROCESS">PROCESS</Select.Option>
                                            <Select.Option value="SEND">SEND</Select.Option>
                                            <Select.Option value="DONE">DONE</Select.Option>
                                            <Select.Option value="CANCELED">CANCELED</Select.Option>
                                            <Select.Option value="REJECTED">REJECTED</Select.Option>
                                            <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="paymentState"
                                        label="Status Pembayaran"
                                    >
                                        <Select placeholder="Pilih status pembayaran" style={{ width: "100%" }}>
                                            <Select.Option value="CREATED">CREATED</Select.Option>
                                            <Select.Option value="UNPAID">UNPAID</Select.Option>
                                            <Select.Option value="PAID">PAID</Select.Option>
                                            <Select.Option value="PROCESS">PROCESS</Select.Option>
                                            <Select.Option value="CANCELED">CANCELED</Select.Option>
                                            <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                            <Select.Option value="REFUND">REFUND</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="paymentMethod"
                                        label="Metode Pembayaran"
                                    >
                                        <Select placeholder="Pilih metode pembayaran" style={{ width: "100%" }}>
                                            <Select.Option value="ewallet">ewallet</Select.Option>
                                            <Select.Option value="va">va</Select.Option>
                                            <Select.Option value="cash">cash</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="marketID"
                                        label="Pasar"
                                    >
                                        <Select placeholder="Pilih pasar" style={{ width: "100%" }} >
                                            {props.pasar.records.map((each, key) => (
                                                <Select.Option key={key} value={each.id}>{each.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>{" "}
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
