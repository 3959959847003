import React, {useContext} from 'react'
import {ContextStore} from "../../../context/context-store"
import {
    Row, 
    Col, 
    Dropdown, 
    Menu, 
    // Badge
} from "antd"
import ModalNotification from "../modalnotification/ModalNotification"
import ProfileLogo from "../../../assets/profil.png"
import {Link} from "react-router-dom"

import "./useraccount.scss"

const UserAccount: React.FC = () => {
    const {
        setLoginStatus, 
        notif, 
        handleUpdateStatusRead,
        handleDeleteTokenNotif
    } = useContext(ContextStore)

    const clearLocalStorage = () => {
        handleDeleteTokenNotif()
        localStorage.clear();
        setLoginStatus(false)
    }

    // console.log("fields inside userAccount",fields, fields.length);
    

    const menu = (
        <Menu style={{width: "130px"}}>
        {/*   <Menu.Item>
            <ModalNotification 
                fields={notif} 
                handleUpdateStatusRead={handleUpdateStatusRead}
            />
          </Menu.Item>*/}
          {/* <Menu.Item>
            <Row>
                <Col span={6}>
                    <i className="fas fa-user-edit" />
                </Col>
                <Col span={18}>
                    Edit Profile
                </Col>
            </Row>
          </Menu.Item> */}
          <Menu.Item>
            <Link to="/login" onClick={clearLocalStorage}>
                <Row>
                    <Col span={6}>
                        <i className="fas fa-sign-out-alt" />
                    </Col>
                    <Col span={18}>
                        Keluar
                    </Col>
                </Row>
            </Link>        
          </Menu.Item>
        </Menu>
      );

    return (
        <Row className="user-account-container" align="middle">
            <Col span={24} className="user-container">
                <Row className="account-section" justify="space-between">
                    <Col span={15} style={{display: "flex"}}>
                        <div style={{paddingTop: "3px", marginRight: "7px"}}>
                            <img src={ProfileLogo} alt="profile-logo"  style={{width: "36px", height: "36px"}} />
                        </div>
                        <div>
                            <div className="user-name">{localStorage.getItem("userName")}</div>
                            <div className="user-role">Admin</div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <ModalNotification 
                            fields={notif} 
                            handleUpdateStatusRead={handleUpdateStatusRead}
                        />
                    </Col>
                    <Col span={1} className="menu-section">
                        <Dropdown overlay={menu} placement="bottomLeft">
                            {/*<Badge size="small" count={notif.length}>*/}
                                <i className="fas fa-ellipsis-v" style={{cursor: "pointer", fontSize: "25px", paddingTop: "5px"}} />
                            {/*</Badge>*/}
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default UserAccount
