import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {notification} from "antd"
import { kaspro } from "../../../../config/endpoint"
import {IListVoucher, initialDataList} from "../ModelKaspro"

export const useModelList = () => {
    const [params, setParams] = useState<{
        page:number
        limit:number
        q:string
    }>({
        page: 1,
        limit: 10,
        q: ""
    })
    const [fields, setFields] = useState<IListVoucher>(initialDataList)
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<string>("")
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${kaspro}/top-up`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields({
                pagination: res.data,
                data:res.data.payload
            })

            setLoading("")
        } catch (error) {
            setLoading("false")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data kaspro',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleFilter = (values:any) => {
        setParams({...params, ...values, page: 1})
    }

    const handleDelete = async(id:string) => {
        setLoading("delete")
        try {
            await axios.delete(`${base_url}/${kaspro}/top-up/${id}`, {
                headers: headers
            })
            setLoading("")
            setVisible(false)
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus data kaspro`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menghapus data kaspro',
                placement:"topRight"
            });
        }
    }

    const handleUpdateStatus = async(checked:boolean, data:any) => {
        setLoading(`switch${data.id}`)

        try {
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const url =`${base_url}/${kaspro}/top-up/${data.id}`;

            await axios.put(url, {...data, status:checked}, { 
                headers: headers 
            })

            notification["success"]({
                message: 'Yeay!',
                description:
                    `Status kaspro berhasil diperbaharui`,
            });
            setLoading("")
        } catch (error) {
            if (error.response) {

                notification["error"]({
                    message: 'Ups!',
                    description:
                        `Status kaspro gagal diperbaharui`,
                });
            }
            setLoading("")
        }
    }

    const handleReset = () => {
        setParams({
            limit: 10,
            page: 1,
            q: ""
        })
    }

    const toggle = () => {
        setVisible(!visible)
    }

    return {
        params,
        fields,
        visible,
        loading,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleDelete,
        handleUpdateStatus,
        handleFilter,
        handleReset,
        toggle
    }
}