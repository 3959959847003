import React, {useState, useContext} from 'react'
import {ItemContext} from "../context/ContextProvider"
import {IDetailCategory} from "../category/ModelCategory"
import {IDetailItem} from "../ModelItem"
import {Form, Row, Col, Input, Select, Switch, Button} from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Tag from "./tag/Tag"
import {OkButton, BackButton, UploadPhoto} from "../../../../../components/atoms"
import {Link} from "react-router-dom"

interface Props {
    fields: IDetailItem
    categoryValue: number[]
    handleSetImage: (url:string) => void
    newTag: string
    handleDeleteTag: (tag:string) => void
    handleEditTag: (tag:string, index:number) => void
    handleChangeTag: (e:any) => void
    submitNewTag: () => void
    handleSubmit: (values:{}) => void
}

const FormItem: React.FC<Props> = (props) => {
    const context = useContext(ItemContext)
    const {
        categories
    } = context.useModelListCategory(true)
    const [disabled, setDisabled] = useState<boolean>(props.fields.price === null ? true : false)
    const [price, setPrice] = useState<string>(props.fields.price === null ? "" : String(props.fields.price))
    const [discount, setDiscount] = useState<string>(props.fields.discount === null ? "0" : `${props.fields.discount}`)
    const [isPercentage, setIsPercentage] = useState<string>(props.fields.isDiscountPercentage ? "true" : "false")

    const handlePrice = (e:any) => {
        setPrice(e.target.value)
        e.target.value === "" ? setDisabled(true) : setDisabled(false)
    }

    const handleDiscount = (e:any) => {
        setDiscount(e.target.value)
    }

    const handleDiscountUnit = (value:string) => {
        setIsPercentage(value)
    }

    return (
        <Form onFinish={props.handleSubmit}>
            <Row>
                <Col span={16}>
                    <Row className="row-form">
                        <Col>
                            <Form.Item
                                name="imageURL"
                                valuePropName="file"
                                initialValue={props.fields.imageURL[0]}
                            >
                                <UploadPhoto 
                                    featureName="Item-Pasar"
                                    imageURL={props.fields.imageURL[0]}
                                    getLink={props.handleSetImage}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={7}>Nama Item</Col>
                        <Col span={14}>
                            <Form.Item
                                name="name"
                                initialValue={props.fields.name}
                                rules={[{ required: true, message: 'Nama item wajib diisi!' }]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama item"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={7}>Deskripsi</Col>
                        <Col span={14}>
                            <Form.Item
                                name="description"
                                initialValue={props.fields.description}
                                rules={[
                                    { required: true, message: 'Deskripsi wajib diisi!' },
                                    { max: 500, message: 'Deskripsi item masimal 500 karakter!' },
                                ]}
                            >
                                <Input.TextArea 
                                    className="form-input" 
                                    placeholder="Input deskripsi"
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={7}>Kategori</Col>
                        <Col span={14}>
                            <Form.Item
                                name="itemCategory"
                                initialValue={props.categoryValue}
                                rules={[{ required: true, message: 'Kategori wajib dipilih!' }]}
                            >   
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="Pilih kategori"
                                >
                                    {categories.records.map((category:IDetailCategory, key:number) => (
                                        <Select.Option value={category.id} key={key}>{category.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={7}>Tag</Col>
                        <Col span={14}>
                            <Row style={{paddingBottom: "5px", paddingLeft: "2px"}}>
                                {props.fields.tags.map((tag:string, key:number) => (
                                    <Col span={12} key={key} style={{paddingRight: "8px", paddingBottom: "10px"}}>
                                        <Tag
                                            index={key}
                                            tag={tag}
                                            handleEditTag={props.handleEditTag}
                                            handleDeleteTag={props.handleDeleteTag}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col span={17}>
                                    <Input
                                        className="form-input"
                                        placeholder="Input tag baru" 
                                        onChange={props.handleChangeTag} 
                                        value={props.newTag}
                                    />
                                </Col>
                                <Col span={7} style={{paddingLeft: "12px"}}>
                                    <Button
                                        className="button-save-edit" 
                                        style={{width: "100%"}} 
                                        onClick={props.submitNewTag}
                                    >
                                        +
                                    </Button>
                                </Col>
                            </Row>
                        </Col>       
                    </Row>
                    <Row className="row-form">
                        <Col span={7}>Harga</Col>
                        <Col span={14}>
                            <Form.Item
                                name="price"
                                initialValue={props.fields.price}
                                rules={[
                                    { required: true, message: 'Harga wajib diisi!' },
                                    { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Harga yang anda input melebihi batas!'}
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="form-input" 
                                    placeholder="Input harga"
                                    onChange={e => handlePrice(e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={7}>Diskon</Col>
                        <Col span={11}>
                            <Form.Item
                                name="discount"
                                initialValue={props.fields.discount}
                                rules={[
                                    { required: true, message: 'Diskon wajib diisi!' },
                                    {
                                        validator: (_, value) =>
                                            isPercentage === "false" ? parseInt(value) >= parseInt(price) ? Promise.reject('Diskon tidak boleh lebih dari harga!') : Promise.resolve() : value >= 100 ? Promise.reject('Persen tidak boleh lebih dari 100!') : Promise.resolve()
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="form-input" 
                                    placeholder="Input diskon"
                                    disabled={disabled}
                                    onChange={(e:any) => handleDiscount(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3} style={{paddingLeft: "7px"}}>
                            <Form.Item
                                name="isDiscountPercentage"
                                initialValue={props.fields.isDiscountPercentage ? "true" : "false"}
                            >
                                <Select
                                    value={isPercentage}
                                    onChange={handleDiscountUnit}
                                    disabled={disabled}
                                    style={{width: "100%"}}
                                >
                                    <Select.Option value="true">%</Select.Option>
                                    <Select.Option value="false">IDR</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {discount  !== "0" ? (
                        <Row className="row-form">
                            <Col span={7}></Col>
                            <Col span={14}>
                                <Input 
                                    className="form-input"
                                    value={isPercentage === "false" ? 
                                        `Diskon dalam persen : ${parseInt(discount) / parseInt(price) * 100}%` 
                                        : 
                                        `Diskon dalam Rupiah : Rp ${parseInt(discount) / 100 * parseInt(price)}`
                                    }
                                    style={{color: "#009b00"}}
                                    disabled
                                />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={7}>Status Aktif</Col>
                        <Col span={14}>
                            <Form.Item
                                name="isActive"
                                valuePropName= "checked"
                                initialValue={props.fields.isActive}
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <OkButton>Simpan</OkButton>
                                <Link to="/pasar/item">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row> 
                </Col>
            </Row>
        </Form>
    )
}

export default FormItem
