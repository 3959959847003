import {useState, useEffect} from "react"
import {useHistory} from "react-router"
import {IDetailSHopper, initialDataDetail} from "../ModelSHopper"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {shopper} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(id:number | null = null) {
    const history = useHistory()
    const [fields, setFields] = useState<IDetailSHopper>(initialDataDetail)
    const [loading, setLoading] = useState<string>("")

    const fetchData = async(id:number) => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${shopper}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setFields(res.data.payload)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const handleSetImageURL = (url:string) => {
        setFields({...fields, imageURL: url}) 
    }

    const handleSetIdentityURL = (url:string) => {
        setFields({...fields, identityURL: url})
    }

    const handleSetDrivingLicenseURL = (url:string) => {
        setFields({...fields, drivingLicenseURL: url})
    }

    useEffect(() => {
        id !== null && fetchData(id)
    }, [id])

    const handleSubmit = async(values:IDetailSHopper) => {
        values["imageURL"] = fields.imageURL
        values["identityURL"] = fields.identityURL
        values["drivingLicenseURL"] = fields.drivingLicenseURL
        setLoading("fetch")
        try {
            id !== null ? (
                await axios.put(`${base_url}/${shopper}/${id}`, values, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    },
                })
            ) : (
                await axios.post(`${base_url}/${shopper}`, values, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    },
                })
            )
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            history.goBack()
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data',
                placement:"topRight"
            });
        }
    }

    return {
        fields,
        loading,
        handleSetImageURL,
        handleSetIdentityURL,
        handleSetDrivingLicenseURL,
        handleSubmit
    }
}