
import React from 'react';
import { Form, Input } from "antd"

interface ITextInput {
    name: string,
    initialValue: any,
    message?: {}[],
    placeholder?: string,
    component: any,
    rows?: number,
    type?: string
}

function TextInput(props: ITextInput) {

    const children = props.component === Input.TextArea ?
        <Input.TextArea className="form-input" rows={props.rows ? props.rows : 3} placeholder={props.placeholder} /> :
        <props.component className="form-input" placeholder={props.placeholder} type={props.type} />



    return <Form.Item
        name={props.name}
        initialValue={props.initialValue}
        rules={props.message}

    >
        {children}
    </Form.Item>
}

export default TextInput;