import React, {useState} from 'react'
// import {MainAppContext} from "../../../context/ContextProvider"
import {Row, Col, Modal, Badge} from "antd"
import {FormatDate} from "../../../helpers/Formatter"
import {Link} from "react-router-dom"
// import {Modal} from "../../atoms"

import "./modalnotification.scss"

interface Props {
    fields:[]
    handleUpdateStatusRead: (id:string, isRead: boolean) => void
}

const ModalNotification: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleUpdateStatus = (id:string, isRead:boolean) => {
        props.handleUpdateStatusRead(id, true)
        toggle()
    }

    return (
        <React.Fragment>
         {/*   <Row onClick={toggle}>
                <Col span={6}>
                    <i className="fas fa-bell" style={{}} />
                </Col>
                <Col span={18}>
                    <Badge size="small" count={props.fields.length}>
                        Notifkasi
                    </Badge>
                    
                </Col>
            </Row>*/}
            <Row style={{marginTop: "5px"}} onClick={toggle}>
            <Badge size="small" count={props.fields.length}>
               <i className="fas fa-bell" style={{color: "#009B00", paddingTop: "7px", cursor: "pointer", fontSize: "25px"}} />
            </Badge>
            </Row>

            <Modal
                title="Notifikasi"
                visible={modal}
                onCancel={toggle}
                className="modal-notifcation"
                footer={[<div key="footer"></div>]}
            >
                <div className="container-body-notif">
                    {props.fields.map((field:{id: string, createdAt:string, notifData:{invoiceNumber:string, orderID:string}}, key) => (
                        <Row className="item-box-notif" key={key}>
                            <Col span={24}>
                                <Row>
                                    <Col span={22}>
                                        <div className="order-number-notif">
                                            {field.notifData.invoiceNumber}
                                        </div>
                                        <div className="order-date-notif">
                                            {FormatDate(field.createdAt)}
                                        </div>
                                    </Col>
                                    <Col span={2} style={{textAlign: "center"}}>
                                        <Link 
                                            to={`/transaksi-pasar/detail?id=${field.notifData.orderID}`} 
                                            onClick={() => handleUpdateStatus(field.id, true)}
                                        >
                                            <i className="far fa-eye see-detail-notif" />
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default ModalNotification
