import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {ShopperContext} from "../context/ContextProvider"
import {Loading} from "../../../../components/atoms"
import {DetailPageLayout} from "../../../templates"
import {Row, Col} from "antd"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import {FormatDate} from "../../../../helpers/Formatter"
import ModalDetailPhoto from "./modaldetailphoto/ModalDetailPhoto"
import {Link} from "react-router-dom"
import {BackButton} from "../../../../components/atoms"

import "./detail.scss"

const Detail: React.FC = () => {
    const location = useLocation()
    const shopperId = location.search.split("=")[1]
    const context = useContext(ShopperContext)
    const {
        fields,
        loading
    } = context.useModelDetail(shopperId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading === "fetch" ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.imageURL} alt="merchant-pict" style={{width: "100px", height: "130px", borderRadius: "7px"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Shopper" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama" value={fields.name} />
                                <ItemDetail label="Nomor Telepon" value={fields.phoneNumber} />
                                <ItemDetail label="Email" value={fields.email} />
                                <ItemDetail label="Status" value={fields.isActive ? "Aktif" : "Tidak Aktif"} />
                                <ItemDetail label="Area" value={fields.area.name} />
                                <ItemDetail label="Alamat" value={fields.address} />
                                <ItemDetail label="Tanggal Daftar" value={FormatDate(fields.registerDate)} />
                                <ItemDetail 
                                    label="Dokumen Pendukung" 
                                    value={
                                        <Row>
                                            <Col span={12}>
                                                <ModalDetailPhoto 
                                                    imglink={fields.identityURL}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <ModalDetailPhoto 
                                                    imglink={fields.drivingLicenseURL}
                                                />
                                            </Col>
                                        </Row>
                                    } 
                                />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col>
                                <Link to="/shopper">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
