import React, {useState, useContext} from 'react'
import {useQueryParams} from "../../../../helpers/Formatter"
import {HobbyContext} from "../context/ContextProvider"
import {Pagination} from "antd"
import {Link} from "react-router-dom"
import {PageLayout} from "../../../templates"
import {FeatureButton, Search, Table} from "../../../../components/atoms"
import FilterMerchantHobby from "../filtermerchanthobby/FilterMerchantHobby"
import Category from "../category/Category"
import TableColumns from "./Columns"

import "./list.scss"

const ListMerchantHobby: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(HobbyContext)
    const {
        fields,
        params,
        loading,
        handleSubmitSearch,
        handleUpdateStatus,
        loadingSwitch,
        handlePageChange,
        visible,
        setVisible,
        handleSetMerchantId,
        handleDelete,
        deleteLoading,
        handleFilter,
        handleReset
    } = context.useModelList()
    const {columns} = TableColumns(
        handleUpdateStatus, 
        loadingSwitch, 
        visible, 
        setVisible, 
        handleDelete,
        deleteLoading,
        handleSetMerchantId
    )
    const [hideMenu, setHideMenu] = useState<boolean>(false)

    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    return (
        <React.Fragment>
            <PageLayout
                leftTop={
                    <div>
                        <Link to="/hobi/tambah">
                            <FeatureButton icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />} label=" Tambah" /> 
                        </Link>
                        <FilterMerchantHobby handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                        {hideMenu ? (
                            <span>
                                <Category />
                                <i 
                                    className="fas fa-chevron-circle-left"
                                    style={{cursor: "pointer", color: "#009b00"}}
                                    onClick={handleHideMenu} 
                                />
                            </span>
                         ) : (
                            <i 
                                className="fas fa-chevron-circle-right" 
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={handleHideMenu}
                            />
                         )}
                    </div>
                }
                rightTop={
                    <Search 
                        onKeyPress={(e:any) => handleSubmitSearch(e)} 
                        value={qp.queryParams.get('search')?.toString()}
                    />
                }
                content={
                    <div style={{height: "100%"}}>
                        <Table 
                            columns={columns}
                            data={fields.records}
                            rowKey="ID"
                            pagination={false}
                            // scroll={{y: "60vh"}}
                            loading={loading}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            current={params.pageNumber} 
                            pageSize={10} 
                            total={fields.total_record} 
                            onChange={handlePageChange} 
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default ListMerchantHobby
