import {useState, useEffect} from "react"
import axios from "axios"
import {IListCategories} from "../ModelMerchantFood"
import {base_url} from "../../../../config/url-config"
import {merchantFoodCategory} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelListCategory() {
    const [categories, setCategories] = useState<IListCategories>({
        statusCode: 0,
        pagination: {
            page: 0,
            limit: 5,
            totalPage: 1,
            totalData: 0
        },
        payload: []
    })
    const [params, setParams] = useState<{page: number, limit: number}>({
        page: 0,
        limit: 5
    })
    const [modal, setModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async(queryParams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantFoodCategory}`
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }

            const res = await axios.get(url, {
                headers: headers,
                params: queryParams
            })
            setCategories(res.data)
            setLoading(false)
        } catch {

        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async(values:{}) => {
        try {
            const url = `${base_url}/${merchantFoodCategory}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.post(url, values, {
                headers: headers
            })
            if (res.status === 200 || res.status === 201) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                      `Berhasil menambahkan data ketegori.`,
                });
                fetchData(params)
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan pada saat membuat kategori.',
            });
            fetchData(params)
        }
    }

    const handleEdit = async(id:string, categoryName:string) => {
        try {
            const url = `${base_url}/${merchantFoodCategory}/update/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.put(url, {name: categoryName}, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil mengubah data ketegori.`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan pada saat memperbaharui kategori.',
            });
            fetchData(params)
        }
    }

    const handleDelete = async(id:string) => {
        try {
            const url = `${base_url}/${merchantFoodCategory}/delete/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.delete(url, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus data ketegori.`,
            });
            fetchData(params)
        } catch {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan pada saat menghapus kategori.',
            });
            fetchData(params)
        }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page - 1})
    }

    return {
        categories,
        modal,
        loading,
        toggle,
        handleSubmit,
        handleEdit,
        handleDelete,
        handlePageChange
    }
}