import React, {useState, useContext} from 'react'
import {useQueryParams,FormatRp, FormatDate} from "../../../../helpers/Formatter"
import {VoucherContext} from "../context/ContextProvider"
import {Row, Col, Menu, Switch, Dropdown, Pagination} from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {PageLayout} from "../../../templates"
import {Link} from 'react-router-dom'
import {Search, Table, FeatureButton} from "../../../../components/atoms"
import {ModalConfirmation} from "../../../../components/atoms"
import LayananVoucher from '../layanan/Layanan';
import FilterVoucher from '../filter/Filter'

import "./list.scss"

interface Props {
}

interface PropsStatus {
    loading: string,
    data: any,
    handleUpdateStatus: (checked:boolean, data:any) => void,
}

interface PropsVisible {
    loading: string,
    data: any,
    handleUpdateVisible: (checked:boolean, data:any) => void,
}

interface PropsDelete {
    id: string,
    loading: string,
    visible: boolean,
    toggle: () => void,
    handleDelete: (id:string) => void,
}

const DeleteVoucher: React.FC<PropsDelete> = (props) => {
    return (
        <React.Fragment>
            <Row onClick={props.toggle}>
                <Col span={8}>
                    <i className="far fa-trash-alt" />
                </Col>
                <Col span={16}>
                    Hapus
                </Col>
            </Row>
            <ModalConfirmation
                loading={props.loading === "delete"}
                visible={props.visible}
                toggle={props.toggle}
                submitDelete={() => props.handleDelete(props.id)}
            />
        </React.Fragment>
    )
}

const UpdateStatusVoucher: React.FC<PropsStatus> = (props) => {
   const handleChange = (checked:boolean) => {
        props.handleUpdateStatus(checked, props.data)
    }

    return (
        <Switch
            loading={props.loading === `switch${props.data.id}` ? true : false}
            // defaultChecked={props.data.is_active === "1"}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={props.data.is_active === "1"}
            onChange={handleChange} 
        />
    )
}

const UpdateVisibleVoucher: React.FC<PropsVisible> = (props) => {
   const handleChange = (checked:boolean) => {
        props.handleUpdateVisible(checked, props.data)
    }

    return (
        <Switch
            loading={props.loading === `switch${props.data.id}` ? true : false}
            // defaultChecked={props.data.is_visible === "1"}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={props.data.is_visible === "1"}
            onChange={handleChange} 
        />
    )
}

const List: React.FC<Props> = () => {
    const qp = useQueryParams()
    const context = useContext(VoucherContext)
    const {
        fields,
        loading,
        visible,
        toggle,
        handleFilter,
        handleReset,
        handleSubmitSearch,
        handlePageChange,
        handleDelete,
        handleUpdateStatus,
        handleUpdateVisible
    } = context.useModelList()

    const columns = [
        {
            title: "Code",
            render: (data:{promo_code:string}) => (
                <div style={{fontWeight: "bold", color: "black"}}>{data.promo_code}</div>
            ),
            width: "13%"
        },
        {
            title: "Jenis Voucher",
            render: (data:{voucher_category:string}) => (
                <div>{data.voucher_category === "cashback_point" ? "Cashback Point" : "Potongan" }</div>
            ),
            width: "13%"
        },
        {
            title: "Nominal",
            render: (data:{nominal:number, nominal_type:string}) => (
                <div>
                    {data.nominal_type === "percentage_rates" ? data.nominal + "%" : FormatRp(data.nominal)}
                </div>
            ),
            width: "10%"
        },
        {
            title: "Usage",
            render: (data: { 
                limit_per_days: number, 
                limit_days_used: number, 
                limit_promo_total: number, 
                limit_promo_total_used: number 
            }) => (
                <div>
                    <Row>
                        <Col span={24} style={{ color: "black" }}>
                            Semua : {data.limit_promo_total_used + "/" + data.limit_promo_total}
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "15px" }}>
                        <Col span={24} style={{ color: "black" }}>
                            Hari : {data.limit_days_used + "/" + data.limit_per_days}
                        </Col>
                    </Row>
                </div>
            ),
            width: "15%"
        },
        {
            title: "Layanan",
            render: (data:{layanan:string}) => (
                <div style={{textTransform: "capitalize"}}>
                    {data.layanan}
                </div>
            ),
            width: "10%"
        },
        {
            title: "Aktif",
            render: (data: { id:number, is_active:boolean }) => (
                <UpdateStatusVoucher loading={loading} handleUpdateStatus={handleUpdateStatus} data={data} />
            ),
            width: "10%"
        },
        {
            title: "Terlihat",
            render: (data: { id:number, is_active:boolean }) => (
                <UpdateVisibleVoucher loading={loading} handleUpdateVisible={handleUpdateVisible} data={data} />
            ),
            width: "10%"
        },
        {
            title: "Periode",
            render: (data: { start_date: string, expired_date: string }) => (
                <div>
                    <Row>
                        <Col span={24} style={{ color: "black" }}>
                            Tanggal Mulai :
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {FormatDate(data.start_date)}
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "15px" }}>
                        <Col span={24} style={{ color: "black" }}>
                            Tanggal Berakhir :
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {FormatDate(data.expired_date)}
                        </Col>
                    </Row>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Aksi",
            width: "10%",
            render: (data: { id: string, paymentAccountID: string, name: String, promo_code: string }) => {
                const menu = (
                    <Menu style={{ width: "130px" }}>
                        <Menu.Item>
                            <Link to={`/voucher/ubah?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-edit" />
                                    </Col>
                                    <Col span={16}>
                                        Ubah
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={`/voucher/detail?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Detail
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={`/voucher/transaksi?id=${data.id}&code=${data.promo_code}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Transaksi
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <DeleteVoucher
                                id={data.id}
                                loading={loading}
                                visible={visible}
                                toggle={toggle}
                                handleDelete={handleDelete}
                             />
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{ color: "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer" }}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        },
    ]
    const [hideMenu, setHideMenu] = useState<boolean>(false)


    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    return (
        <React.Fragment>
            <PageLayout
                leftTop={
                    <div>
                        <Link to="/voucher/tambah">
                            <FeatureButton icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />} label=" Tambah" /> 
                        </Link>
                        <LayananVoucher />
                        <FilterVoucher handleFilter={handleFilter} />
                        {hideMenu ? (
                            <span>
                                <FeatureButton 
                                    icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                                    label="Reset" 
                                    onClick={handleReset}
                                />
                                <i 
                                    className="fas fa-chevron-circle-left"
                                    style={{cursor: "pointer", color: "#009b00"}}
                                    onClick={handleHideMenu} 
                                />
                            </span>
                         ) : (
                            <i 
                                className="fas fa-chevron-circle-right" 
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={handleHideMenu}
                            />
                         )}
                    </div>
                } 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('q')?.toString()}
                />}
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            // rowKey="id"
                            pagination={false}
                            scroll={{y: 'calc(100vh - 23em)'}}
                            loading={loading === "fetch" ? true : false}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <span>{`Total ${fields.pagination.perpage_found} Voucher`}</span>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.pagination.current_page} 
                                pageSize={10} 
                                total={fields.pagination.total_page * fields.pagination.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
