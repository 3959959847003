export interface IPagination {
    currentPage: number;
    limit: number;
    totalPage: number;
}

export interface IListVoucher {
    pagination: IPagination;
    data: ModelDataVoucher[];
}

export interface ModelDataVoucher {
    id: string;
    category:any;
    image: string;
    bankOrMitra: string;
    kode: string;
    description: string;
    status: boolean;
}

export const initialDataList = {
    pagination: {
        currentPage: 1,
        limit: 10,
        totalPage: 1
    },
    data: [],
}

export const initialDataVoucher = {
    id: "",
    category: null,
    image: "",
    bankOrMitra: "",
    kode: "",
    description: "",
    status: false,
}