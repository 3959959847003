import React, {useContext} from 'react'
import {PaymentConfigContext} from "../context/ContextProvider"
import {IDetail} from "../PaymentConfigModel"
import {Form} from "antd"
import {Row, Col} from "antd"
import {Modal, OkButton, BackButton} from "../../../../components/atoms"
import FormPaymentConfig from "../formpaymentconfig/FormPaymentConfig"

import "./editpaymentconfig.scss"

interface Props {
    data: IDetail
    fetchData: (queryparams:{}) => void
    params: {
        pageNumber:number
        pageLimit:number
        search:string
    }
}

const EditPaymentConfig: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const context = useContext(PaymentConfigContext)
    const {
        modal,
        fields,
        toggle,
        handleChange,
        handleDirectChange,
        handleSubmit
    } = context.useModelDetail("edit", props.data, props.fetchData, props.params)

    return (
        <React.Fragment>
            <Row onClick={toggle}>
                <Col span={8}>
                    <i className="far fa-edit" />
                </Col>
                <Col span={16}>
                    Ubah
                </Col>
            </Row>

            <Modal
                onCancel={toggle}
                title="Edit Config"
                visible={modal}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                >
                    <FormPaymentConfig 
                        fields={fields} 
                        handleDirectChange={handleDirectChange}
                    />
                    <Row>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default EditPaymentConfig
