import React, {useContext} from 'react'
import {HobbyContext} from "../context/ContextProvider"
import {useLocation} from "react-router-dom"
import {DetailPageLayout} from "../../../templates"
import {Loading} from "../../../../components/atoms"
import FormMerchantHobby from "../formmerchanthobby/FormMerchantHobby"

import "./editmerchanthobby.scss"

const EditMerchantHobby: React.FC = () => {
    const merchantId = useLocation().search.split("=")[1]
    const context = useContext(HobbyContext)
    const {
        fields,
        loading,
        operationHours,
        newTag,
        submitPhoto,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        submitBank,
        handleDeleteTag,
        handleEditTag,
        handleChangeTag,
        submitNewTag,
        handleSubmit
    } = context.useModelDetail(merchantId)

    return (
        <React.Fragment>
            {loading ? <Loading /> : (
                <DetailPageLayout>
                    <FormMerchantHobby
                        data={fields}
                        operationHours={operationHours}
                        submitPhoto={submitPhoto}
                        submitOperationHourStatus={submitOperationHourStatus}
                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                        submitOperationalHour={submitOperationalHour}
                        submitBank={submitBank}
                        newTag={newTag}
                        handleDeleteTag={handleDeleteTag}
                        handleEditTag={handleEditTag}
                        handleChangeTag={handleChangeTag}
                        submitNewTag={submitNewTag}
                        handleSubmit={handleSubmit}
                    />
                </DetailPageLayout>
            )}
        </React.Fragment>
    )
}

export default EditMerchantHobby
