import React, {useContext} from 'react'
import {PaymentConfigContext} from "../context/ContextProvider"
import {Form, Row, Col} from "antd"
import {FeatureButton, Modal, OkButton, BackButton} from "../../../../components/atoms"
import FormPaymentConfig from "../formpaymentconfig/FormPaymentConfig"

import "./createpaymentconfig.scss"

interface Props {
    fetchData: (queryparams:{}) => void
    params: {
        pageNumber:number
        pageLimit:number
        search:string
    }
}

const CreatePaymentConfig: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const context = useContext(PaymentConfigContext)
    const {
        modal,
        fields,
        toggle,
        handleChange,
        handleDirectChange,
        handleSubmit
    } = context.useModelDetail("create", {}, props.fetchData, props.params)

    

    
    
    // const handleSubmit = async(values:{
    //     isPercentage:string|boolean
    //     maxValue:any
    //     paymentTimeMinute:any
    //     paymentValue:any
    //     apartmentID:undefined|string
    //     apartmentName:any
    //     paymentMethodCode:undefined|string
    //     paymentMethod:any
    //     bank:any
    // }) => {
    //     values["isPercentage"] = values.isPercentage === "true" ? true : false
    //     values["maxValue"] = values.maxValue === null ? 0 : parseInt(values.maxValue)
    //     values["paymentTimeMinute"] = values.paymentTimeMinute === null ? 0 : parseInt(values.paymentTimeMinute)
    //     values["paymentValue"] = values.paymentValue === null ? 0 : parseInt(values.paymentValue)
    //     if (values.apartmentID !== undefined) {
    //         values["apartmentName"] = fields.apartmentName
    //     }
    //     if (values.paymentMethodCode !== undefined) {
    //         values["paymentMethod"] = fields.paymentMethod
    //     }
    //     values["bank"] = fields.bank
    //     // console.log(values);
    //     // setLoading(true)
    //     try {
    //         const res = await axios.post(`${base_url}/${paymentconfig}/create`, values, {
    //             headers: {
    //                 'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    //             },
    //         })
    //         if (res.status === 200) {
    //             // setLoading(false)
    //             toggle()
    //             // form.resetFields()
    //             // setFields({
    //             //     origin: null,
    //             //     paymentProvider: null,
    //             //     paymentMethod: null,
    //             //     paymentMethodCode: null,
    //             //     isPercentage: false,
    //             //     paymentValue: null,
    //             //     paymentTimeMinute: null,
    //             //     isActive: false,
    //             //     isMaxValue: false,
    //             //     apartmentID: null,
    //             //     apartmentName: "",
    //             //     maxValue: null,
    //             //     bankName: "",
    //             // })
    //             notification.success({
    //                 message: "Yuhuuu",
    //                 description:
    //                   'Menambahkan data payment config berhasil!',
    //                 placement: "bottomRight",
    //             });
    //             props.fetchData(props.params)
    //         }
    //     } catch (error) {
    //         // setLoading(false)
    //         notification.error({
    //             message: "Ups",
    //             description:
    //               'Terjadi kesalahan pada saat membuat data',
    //             placement: "bottomRight",
    //         });
    //     }
    // }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                label="Tambah"
                onClick={toggle}
            />

            <Modal
                title="Tambah Config"
                onCancel={toggle}
                visible={modal}
            > 
                {/* {loading ? <Loading /> : null} */}
                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                >
                    <FormPaymentConfig 
                        fields={fields} 
                        handleDirectChange={handleDirectChange}
                    />
                    <Row>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreatePaymentConfig
