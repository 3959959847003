import React, { createContext, useContext, useState } from "react"
import { IDetailTransaction, initialDataDetail } from "../containers/pages/pasar/transaksi/ModelTransaction";

const MainAppContext = createContext<any>(null)

const ContextInvoice: React.FC = (props) => {

    const [invoice, setInvoice] = useState<{ isReady: boolean, data: IDetailTransaction }>({ isReady: false, data: initialDataDetail });
    const [bulkInvoice, setBulkInvoice] = useState<{ isReady: boolean, data: IDetailTransaction[] }>({ isReady: false, data: [] });

    // console.log(bulkInvoice.data);

    const value: {
        invoice: { isReady: boolean, data: IDetailTransaction },
        bulkInvoice: {
            isReady: boolean,
            data: IDetailTransaction[]
        },
        setBulkInvoice: any,
        setInvoice: any
    } = {
        invoice,
        setInvoice,
        bulkInvoice,
        setBulkInvoice
    }

    return (
        <MainAppContext.Provider value={value}>
            {props.children}
        </MainAppContext.Provider>
    )
}


export const useInvoice = () => {
    return useContext<{
        invoice: { isReady: boolean, data: IDetailTransaction },
        bulkInvoice: {
            isReady: boolean,
            data: IDetailTransaction[]
        },
        setBulkInvoice: any,
        setInvoice: any
    }>(MainAppContext);
}

export default ContextInvoice