import React from "react"
import {
  HomeOutlined,
  // UserOutlined,
  // ContainerOutlined,
  ShopOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  SolutionOutlined,
  AppstoreFilled,

  // PicLeftOutlined,
  // PlusSquareOutlined,
  // CarryOutOutlined,
  // MessageOutlined
} from "@ant-design/icons";

const menus = [
  { name: "Beranda", url: "/beranda", icon: <HomeOutlined /> },
  {
    name: "Merchant", icon: <ShopOutlined />, submenus: [
      { name: "Merchant Hobi", url: "/hobi" },
      { name: "Merchant Jasa", url: "/jasa" },
      { name: "Kategori Jasa", url: "/kategori" },
      { name: "Merchant Food", url: "/food" },
    ]
  },
  {
    name: "Pasar Rakyat", icon: <ShoppingOutlined />, submenus: [
      { name: "Pasar", url: "/pasar" },
      { name: "Shopper", url: "/shopper" },
      { name: "Transaksi", url: "/transaksi-pasar" },
      { name: "Transaksi Ongoing", url: "/transaksi-ongoing" },
    ]
  },
  {
    name: "Transaksi", icon: <ShoppingCartOutlined />, submenus: [
      { name: "Booking Hobi", url: "/booking" },
      { name: "Order Jasa", url: "/order" },
      { name: "Transaksi Food", url: "/order-food" },
    ]
  },
  {
    name: "Payment", icon: <DollarCircleOutlined />, submenus: [
      { name: "Payment Config", url: "/payment-config" },
      { name: "Transaksi", url: "/payment-transaction" },
    ]
  },
  {
    name: "Ayopop", icon: <SolutionOutlined />, submenus: [
      { name: "Produk", url: "/ayopop-product" },
      { name: "Transaksi", url: "/ayopop-transaction" },
    ]
  },
  {
    name: "User Apps", icon: <AppstoreFilled />, submenus: [
      { name: "Banner Config", url: "/banner-config" },
      { name: "Voucher", url: "/voucher" },
      { name: "Customer", url: "/customer"},
      { name: "Kaspro", url: "/kaspro" },
    ]
  },
  // { name: "Settlement", url: "/settlement", icon: <CarryOutOutlined /> },
  // { name: "Akun", url: "/akun", icon: <UserOutlined /> },
]

export default menus
