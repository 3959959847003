import React, {createContext} from "react"
import {useModelList} from "./ListContext"

export const AccountContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList
    }

    return (
        <AccountContext.Provider value={context}>
            {props.children}
        </AccountContext.Provider>
    )
}

export default ContextProvider