import React, {useState} from 'react'
import {Button, Checkbox, Row, Col, TimePicker} from "antd"
import {Modal} from "../../../../../components/atoms"
import moment from "moment"

import "./formoperationalhour.scss"

const {RangePicker} = TimePicker

interface Props {
    data?: any
    submitOperationHourStatus?:any
    submitOperationalHour?:any
    submitOperationHourOpenCLose?:any
}

const OperationalHourJasa:React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = () => {
        props.submitOperationalHour()
        toggle()
    }

    return (
        <React.Fragment>
            <Button className="button-modal-edit-hobby" onClick={toggle}>
                <i className="fas fa-cog" style={{fontSize: "16px"}} />
            </Button>
            <Modal title="Jam Operasional" visible={modal} onCancel={toggle}>
                <Row className="table-head-operation-hour">
                    <Col span={24}>
                        <Row className="table-head-container">
                            <Col span={1}></Col>
                            <Col span={6} className="head-col-day" style={{paddingLeft: "10px"}}>Hari</Col>
                            <Col span={15} className="head-col-hours">Jam Operasional</Col>
                        </Row>
                    </Col>
                </Row>
                {props.data.map((datum:{active:boolean, day:number, open:string, close:string}, key:number) => (
                    <Row key={key} className="table-body-data">
                        <Col span={7}>
                            <Checkbox
                                defaultChecked={datum.active}
                                onChange={(e) => props.submitOperationHourStatus(e, datum.day)}
                            >
                                {datum.day === 0 ? "Minggu" : datum.day === 1 ? "Senin" : datum.day === 2 ? "Selasa" : datum.day === 3 ? "Rabu" : datum.day === 4 ? "Kamis" : datum.day === 5 ? "Jumat" : datum.day === 6 ? "Sabtu" : "Minggu"}
                            </Checkbox>
                        </Col>
                        <Col>
                            <RangePicker 
                                placeholder={["Buka", "Tutup"]} 
                                format={"H:mm"}
                                defaultValue={[datum.open !== null ? moment(datum.open, "H:mm") : null, datum.close !== null ? moment(datum.close, "H:mm") : null]}
                                onChange={(time:any, timeString:any) => props.submitOperationHourOpenCLose(time, timeString, datum.day)}
                                disabled={datum.active ? false : true}
                            />
                        </Col>
                    </Row>
                ))}
                <Row style={{paddingTop: "10px"}}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Button onClick={toggle} className="cancel-button-operationhours">Kembali</Button>
                        <Button 
                            className="save-button-operationhours"
                            onClick={() => handleSubmit()}
                        >
                            Simpan
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default OperationalHourJasa
