import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IListMerchant, initialDataList} from "../ModelMerchantFood"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchantFood} from "../../../../config/endpoint"
import {customQueryParams} from "../../../../helpers/Formatter"
import {notification} from "antd"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [fields, setFields] = useState<IListMerchant>(initialDataList)
    const [params, setParams] = useState<{}>({
        page: 0,
        limit: 10
    })
    const [loading, setLoading] = useState<string>("")
    const [isReset, setReset] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async (queryParams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${merchantFood}`;
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-Type': "application/json"
            }

            const res = await axios.get(url, {
                headers: headers,
                params: {...customQueryParams(queryParams), ...qp.value}
            })
            setFields(res.data)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                    'Terjadi kesalahan saat mengakses data merchant',
                placement: "topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            // setParams({ ...params, keyword: e.target.value })
            qp.queryParams.set("keyword", e.target.value);
            qp.handleQueryParams();
            setParams({...params, page: 0})
        }
    }

    const handleFilter = (values: any) => {
        setParams({ ...params, ...values, page: 0 });
    }

    const handleReset = () => {
        setParams({ page: 0, limit: 10 })
        setReset(true);
    }

    const handlePageChange = (page: number, pageSize: number | undefined) => {
        //pagination mulai dari 0;
        const currentPage = page - 1;
        setParams({ ...params, page: currentPage })
    }

    const handleSetMerchantId = (id: string) => {
        localStorage.setItem("merchantId", id)
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleUpdateStatus = async(id:string, status:boolean) => {
        setLoading("status")
        try {
            const url = `${base_url}/${merchantFood}/update/${id}`
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-Type': "application/json"
            }

            const res = await axios.put(url, {status: status}, {
                headers: headers,
            })
            if (res.status === 200) {
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Memperbaharui status merchant berhasil!',
                });
                setLoading("")
                fetchData(params)
            }
        } catch(error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan saat memperbaharui data merchant',
            });
            setLoading("")
            fetchData(params)
        }
    }

    const handleDelete = async (id: string) => {
        setLoading("delete")
        try {
            const res = await axios.delete(`${base_url}/${merchantFood}/delete/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            if (res.status === 200) {
                // toggle()
                fetchData(params)
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Menghapus data service berhasil!',
                    placement: "bottomRight",
                });
                setLoading("")
            }
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
            setLoading("")
        }
    }

    return {
        fields,
        loading,
        visible,
        setVisible,
        setLoading,
        fetchData,
        handleSubmitSearch,
        handleFilter,
        isReset,
        handleReset,
        handlePageChange,
        handleSetMerchantId,
        toggle,
        handleUpdateStatus,
        handleDelete
    }
}