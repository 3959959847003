import {useState, useEffect} from "react"
import {IListArea, initialDataList} from "../area/ModelArea"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {area} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelListArea(isAll:boolean = false) {
    const [areas, setAreas] = useState<IListArea>(initialDataList)
    const [modal, setModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<string>("")
    const [params, setParams] = useState<{
        pageLimit: number
        pageNumber: number
        isAll: boolean
    }>({
        pageLimit: 5,
        pageNumber: 1,
        isAll: isAll
    })

    const toggle = () => {
        setModal(!modal)
    }

    const fetchData = async(params:{}) => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${area}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            setAreas(res.data.payload)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSubmit = async(values: {}) => {
        setLoading("submit")
        try {
            await axios.post(`${base_url}/${area}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menambahkan data`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menambahkan data',
                placement:"topRight"
            });
        }
    }

    const handleEdit = async(id:number, values:{}) => {
        try {
            await axios.put(`${base_url}/${area}/${id}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    const handleDelete = async(id:number) => {
        try {
            await axios.delete(`${base_url}/${area}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus data`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menghapus data',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    return {
        modal,
        areas, 
        loading,
        toggle,
        fetchData,
        handlePageChange,
        handleSubmit,
        handleEdit,
        handleDelete
    }
}