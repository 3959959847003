import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {PasarContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../templates"
import {Row, Col} from "antd"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import {FormatDate} from "../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {BackButton, Loading} from "../../../../components/atoms"

import "./detailpasar.scss"

const DetailPasar: React.FC = () => {
    const location = useLocation()
    const pasarId = location.search.split("=")[1]
    const context = useContext(PasarContext)
    const {
        fields,
        loading
    } = context.useModelDetail(pasarId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.imageURL[0]} alt="merchant-pict" className="merchant-photo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Pasar" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Pasar" value={fields.name} />
                                <ItemDetail label="Deskripsi Pasar" value={fields.description} />
                                <ItemDetail label="Alamat" value={fields.address} />
                                <ItemDetail label="Latitude" value={fields.latitude} />
                                <ItemDetail label="Longitude" value={fields.longitude} />
                                <ItemDetail 
                                    label="Tags" 
                                    value={
                                        <Row>
                                            {fields.tags.map((field:string, key:number) => (
                                                <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                    <div className="tag">
                                                        {field}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    } 
                                />
                                <ItemDetail label="Tanggal Dibuat" value={fields.createdAt === "" ? "-" : FormatDate(fields.createdAt)} />
                                <ItemDetail label="Dibuat Oleh" value={fields.createdBy === "" ? "-" : fields.createdBy} />
                                <ItemDetail label="Tanggal Diubah" value={fields.updatedAt === "" ? "-" : FormatDate(fields.updatedAt)} />
                                <ItemDetail label="Diubah Oleh" value={fields.updatedBy === "" ? "-" : fields.updatedBy} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Jam Operasional" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={14}>
                                        <Row className="custom-table-head-container">
                                            <Col span={8}>Hari</Col>
                                            <Col span={11}>Jam Buka</Col>
                                            <Col span={5}>Jam Tutup</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {fields.operation !== null ? (
                                    <div>
                                        {fields.operation.map((operational:{day:number, openHour:string, closeHour:string}, key:number) => (
                                            <Row className="custom-table-body-section" key={key}>
                                                <Col span={14}>
                                                    <Row className="custom-table-body-container">
                                                        <Col span={8}>{operational.day === 0 ? "Minggu" : operational.day === 1 ? "Senin" : operational.day === 2 ? "Selasa" : operational.day === 3 ? "Rabu" : operational.day === 4 ? "Kamis" : operational.day === 5 ? "Jumat" : operational.day === 6 ? "Sabtu" : "-" }</Col>
                                                        <Col span={11}>
                                                            {operational.openHour}
                                                        </Col>
                                                        <Col span={5}>
                                                            {operational.closeHour}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col>
                                <Link to="/pasar">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
            )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailPasar
