import React, {useContext} from 'react'
import {CategoryContext} from "../context/ContextProvider"
import {ModalConfirmation} from "../../../../components/atoms"
import {Row, Col} from "antd"

interface Props {
    fetchData:(queryparams:{}) => void,
    params:{},
    data:{}
}

const DeleteMerchantCategory: React.FC<Props> = (props) => {
    const context = useContext(CategoryContext)
    const {
        modal,
        fields,
        setDataEdit,
        toggleModal,
        handleDelete
    } = context.useModelDetail(props.fetchData, props.params)

    return (
        <React.Fragment>
            <Row
               onClick={() => {
                 toggleModal();
                 setDataEdit(props.data)
                }}
            >
                <Col span={8}>
                    <i 
                        className="far fa-trash-alt" 
                        style={{color: "red", cursor: "pointer"}} 
                    />
                </Col>
                <Col span={16}>
                    Hapus
                </Col>
            </Row>
            <ModalConfirmation 
                visible={modal} 
                toggle={toggleModal}
                submitDelete={() => handleDelete(fields.id)}
            />
        </React.Fragment>
    )
}

export default DeleteMerchantCategory
