import React, { useState } from 'react'
import {Row, Col, Button, Radio, Input, Space} from "antd"
import {transactionStatusName, transactionStatusPaymentName, transactionButtonName} from "../../../../helpers/Formatter"
import ModalDisclaimer from "../detailtransactionjasa/ModalDisclaimer";

interface Props {
    data:any,
    setDataEdit:(data:{}) => void,
    handleAccept:() => void,
    handleReject:(status:string, reason:string) => void,
    handleNextStep:(status:string) => void,
}

const UpdateStatusPesan: React.FC<Props> = (props) => {
    const [modalDisclaimer, setModalDisclaimer] = useState({name: "", value: false});
    const [valueRadioReason, setValueRadioReason] = useState("Layanan tidak tersedia");
    const [valueReason, setValueReason] = useState("Layanan tidak tersedia");
    const transactionButtonNameValue = transactionButtonName(props.data?.status, props.data?.paymentData === null ? null : props.data?.paymentData?.paymentState, props.data?.orderComplaint?.isRefund);

    const butttonOk = (buttonOutsideModal?:boolean) => {
        if(transactionButtonNameValue.primary !== null) {
            const submitAccept = () => {
                props.handleAccept();
                setModalDisclaimer({ name: "", value:!modalDisclaimer.value })
            }

            const updateStatusNextStep = () => {
                if(transactionButtonNameValue.primary === "Proses Selesai") {
                    return "PROCESS"
                } else if(transactionButtonNameValue.primary === "Proses Refund") {
                    return "COMPLAINED"
                } else if(transactionButtonNameValue.primary === "Selesaikan Refund") {
                    return "PROCESS_REFUND"
                } else {
                    return ""
                }
            }

            const submitNextStep = () => {
                props.handleNextStep(updateStatusNextStep());
                setModalDisclaimer({ name: "", value:!modalDisclaimer.value })
            }
            const modalOpen = () => {
                props.setDataEdit(props.data)
                setModalDisclaimer({ name: transactionButtonNameValue.primary, value:!modalDisclaimer.value })
            }

            return (
                <Button 
                    style={{margin: 5, fontWeight: "bold", marginBottom: -10, minWidth: 100}} 
                    type="primary"
                    disabled={transactionButtonNameValue.primaryDisabled}
                    onClick={
                        buttonOutsideModal ? () => modalOpen() : 
                        (props.data?.status === "UNPAID" ? () => submitAccept() : () => submitNextStep())
                    }
                >
                   {!buttonOutsideModal && "Ya, "} {transactionButtonNameValue.primary}
                </Button>
            )
        }
    }

    const butttonCancel = (buttonOutsideModal?:boolean) => {
        if(transactionButtonNameValue.danger !== null) {
            const submitReject = () => {
                if(valueReason.length !== 0) {
                    props.handleReject(transactionButtonNameValue.danger === "Tolak" ? "REJECT" : "CANCEL", valueReason);
                    setModalDisclaimer({ name: "", value:!modalDisclaimer.value })
                }
            }

            const getReasonInput = () => {
                setModalDisclaimer({ name: transactionButtonNameValue.danger === "Tolak" ? "Reason Reject" : "Reason Cancel", value: true })
            }

            const modalOpen = () => {
                props.setDataEdit(props.data)
                setModalDisclaimer({ name: transactionButtonNameValue.danger, value:!modalDisclaimer.value })
            }

            const customCancelButton = () => {
                if(!buttonOutsideModal && (modalDisclaimer.name === "Reason Reject" || modalDisclaimer.name === "Reason Cancel")) {
                    return {
                        name: "Konfirmasi Penolakan",
                        action: () => submitReject()
                    }
                } else if(!buttonOutsideModal) {
                    return {
                        name: "Ya, " + transactionButtonNameValue.danger,
                        action: () => getReasonInput()
                    }
                } else {
                    return {
                        name: transactionButtonNameValue.danger,
                        action: () => modalOpen()
                    }
                }
            }

            return (
                <Button 
                    style={{margin: 5, fontWeight: "bold", marginTop: -10, minWidth: 100, background: "#FBEAE9", color: "#D81712"}} 
                    type="link"
                    onClick={customCancelButton().action}
                >
                    {customCancelButton().name}
                </Button>
            )
        }
    }

    const buttonBack = (type:string) => {
        const modalClose = () => {
            setModalDisclaimer({ name: "", value:!modalDisclaimer.value })
        }

        if(type === "danger") {
            return (
                <Button 
                    style={{margin: 5, fontWeight: "bold", minWidth: 100, background: "#FBEAE9", color: "#D81712"}} 
                    type="link"
                    onClick={() => modalClose()}
                >
                    Kembali
                </Button>
            )
        } else {
            return (
                <Button 
                    style={{margin: 5, fontWeight: "bold", marginBottom: -10, minWidth: 100}} 
                    type="primary"
                    onClick={() => modalClose()}
                >
                    Kembali
                </Button>
            )
        }
    }

    const footerButtonModal = () => {
        if(modalDisclaimer.name === "Proses" || modalDisclaimer.name === "Terima") {
            return (
                <>
                    {buttonBack("danger")}
                    {butttonOk()}
                </>
            )
        } else if(modalDisclaimer.name === "Batal" || modalDisclaimer.name === "Tolak") {
            return (
                <>
                    {buttonBack("primary")}
                    {butttonCancel()}
                </>
            )
        } else if(modalDisclaimer.name === "Reason Reject" || modalDisclaimer.name === "Reason Cancel") {
            return (
                <>
                    {butttonCancel()}
                </>
            )
        } else if(modalDisclaimer.name === "Proses Selesai") {
            return (
                <>
                    {buttonBack("danger")}
                    {butttonOk()}
                </>
            )
        } else {
            return (
                <>
                    {butttonCancel()}
                    {butttonOk()}
                </>
            )
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col span={6}>
                    Pesanan
                </Col>
                <Col span={11} style={{fontWeight: "bold", color: "black"}}>
                    : {transactionStatusName(props.data?.status)} 
                </Col>
                <Col span={7}>
                    {butttonOk(true)}
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    Pembayaran
                </Col>
                <Col span={11} style={{fontWeight: "bold", color: "black"}}>
                    : {transactionStatusPaymentName(props.data?.paymentData !== null ? props.data?.paymentData?.paymentState : null)}
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    Cara Bayar
                </Col>
                <Col span={11} style={{fontWeight: "bold", color: "black", textTransform: "capitalize"}}>
                    : {props.data?.paymentData !== null ? props.data?.paymentData?.bankName === "" ? props.data?.paymentData?.paymentProvider : props.data?.paymentData?.paymentMethod + " " + props.data?.paymentData?.bankName  : "-"}
                </Col>
                <Col span={7}>
                   {butttonCancel(true)}
                </Col>
            </Row>
            <ModalDisclaimer
                type={modalDisclaimer.name}
                visible={modalDisclaimer.value}
                onCancel={() => setModalDisclaimer({ name: "", value:!modalDisclaimer.value })}
                footerButton={footerButtonModal()}
                inputReason={modalDisclaimer.name === "Reason Reject" || modalDisclaimer.name === "Reason Cancel" ? 
                    <div>
                        <Radio.Group className="radio-custom-transaction" style={{marginTop: 10}} onChange={(e:any) => {setValueRadioReason(e.target.value); setValueReason(e.target.value === "Lainnya" ? "" : e.target.value);}} value={valueRadioReason}>
                            <Space direction="vertical">
                              <Radio value={"Layanan tidak tersedia"}>Layanan tidak tersedia</Radio>
                              <Radio value={"Layanan"}>Layanan</Radio>
                              <Radio value={"Status"}>Status</Radio>
                              <Radio value={"Lainnya"}>
                                Lainnya
                              </Radio>
                            </Space>
                        </Radio.Group>  
                        {valueRadioReason === "Lainnya" && <Input.TextArea value={valueReason} onChange={(e:any) => setValueReason(e.target.value)} style={{width: "100%", marginTop: 10}} rows={4} placeholder="Tulis Disini" />}
                        {valueRadioReason === "Lainnya" && valueReason.length === 0 && <span style={{color: "red"}}>Lainnya wajib diisi!</span>}
                    </div>
                : null}
            />
        </React.Fragment>
    )
}

export default UpdateStatusPesan
