import React, { useEffect, useState } from 'react'
import { FeatureButton, Modal, BackButton, OkButton, RowCol } from "../../../components/atoms"
import { Form, Select, Row, Col } from "antd"
// import { value } from 'numeral'

interface Props {
    title: string,
    action: (values: any) => void,
    isReset?: boolean
}

const FilterFood: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values: any) => {
        props.action(values)
        toggle()
    }

    useEffect(() => {
        if (props.isReset) {
            form.resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isReset])

    return (
        <React.Fragment>
            <FeatureButton
                icon={<i className="fas fa-filter" style={{ marginRight: "5px" }} />}
                label="Filter"
                onClick={toggle}
            />
            <Modal
                title={props.title}
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                    form={form}
                >
                    <RowCol>
                        <Form.Item
                            label="Status Merchant"
                            name="status"
                        >
                            <Select placeholder="Pilih status merchant">
                                <Select.Option value="true">Aktif</Select.Option>
                                <Select.Option value="false">Tidak Aktif</Select.Option>
                            </Select>
                        </Form.Item>
                    </RowCol>
                    {/* <RowCol>
                        <Col span={24}>
                            <Form.Item
                                label="Status Buka/Tutup"
                                name="open"
                            >
                                <Select placeholder="Pilih status merchant">
                                    <Select.Option value="OPEN">Buka</Select.Option>
                                    <Select.Option value="CLOSE">Tutup</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </RowCol> */}
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterFood
