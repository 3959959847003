import React, {useState} from 'react'
import {Modal, BackButton, OkButton} from "../../../../../../components/atoms"
import {Button, Form, Input, Row, Col} from "antd"

import "./createcategory.scss"

interface Props {
    handleSubmit: (values:{}) => void
}

const CreateCategory: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async(values:{}) => {
        props.handleSubmit(values)
        form.resetFields()
        toggle()
    }

    return (
        <React.Fragment>
            <Button 
                className="btn-create-category"
                onClick={toggle}
            >
                Tambah Kategori
            </Button>

            <Modal
                title="Tambah Kategori"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nama Kategori"
                                rules={[{ required: true, message: 'Nama kategori wajib diisi!' }]}
                            >
                                <Input 
                                    placeholder="Input nama kategori" 
                                    className="form-input-category"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateCategory
