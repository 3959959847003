import React, {createContext} from "react"
import {useModelList} from "./ListContext"
import {useModelDetail} from "./DetailContext"
import {useModelListArea} from "./AreaContext"

export const ShopperContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList,
        useModelDetail,
        useModelListArea
    }

    return (
        <ShopperContext.Provider value={context}>
            {props.children}
        </ShopperContext.Provider>
    )
}

export default ContextProvider