import React, {useContext} from 'react'
import {ShopperContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../templates"
import {Loading} from "../../../../components/atoms"
import Form from "../form/Form"

import "./create.scss"

const Create: React.FC = () => {
    const context = useContext(ShopperContext)
    const {
        fields,
        loading,
        handleSetImageURL,
        handleSetIdentityURL,
        handleSetDrivingLicenseURL,
        handleSubmit
    } = context.useModelDetail()

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading === "fetch" ? <Loading /> : (
                    <Form 
                        fields={fields}
                        handleSetImageURL={handleSetImageURL}
                        handleSetIdentityURL={handleSetIdentityURL}
                        handleSetDrivingLicenseURL={handleSetDrivingLicenseURL}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Create
