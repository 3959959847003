import {useState, useEffect} from "react"
import {useHistory} from "react-router"
import {IDetailMerchant, IListMerchant, initialDataDetail, initialDataList} from "../MerchantJasaModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchantjasa} from "../../../../config/endpoint"
import {notification} from "antd"
import moment from 'moment'
import { useQueryParams } from "../../../../helpers/Formatter"
import { operationalDays } from "../helper/enum";
import { operationalEnum } from "../helper/merchantjasa"

export function useModelDetail(merchantId:string = "") {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetailMerchant>(initialDataDetail)
    const [operational, setOperational] = useState<{
        active?:boolean, 
        day:number, 
        open:string|null, 
        close:null|string
    }[]>([
        {active: false, day: 0, open: null, close: null},
        {active: false, day: 1, open: null, close: null},
        {active: false, day: 2, open: null, close: null},
        {active: false, day: 3, open: null, close: null},
        {active: false, day: 4, open: null, close: null},
        {active: false, day: 5, open: null, close: null},
        {active: false, day: 6, open: null, close: null},
    ])
    const [newTag, setNewTag] = useState<string>("")
    const [schedule, setSchedule] = useState<any>([{time: null, timeString: []}]);
    const qp = useQueryParams()
    const [fieldsList, setFieldsList] = useState<IListMerchant>(initialDataList)
    const params:
    {
        page: number
        q: string
        sortBy: string
        limit: number
    } = {
        page: 1,
        q: "",
        sortBy: "-updatedAt",
        limit: 100
    }

    const fetchDataList = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantjasa}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {...queryparams, ...qp.value}
            })
            setFieldsList(res.data.payload.data)
            setLoading(false)
            // console.log(res);
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    const createNewOperational = (operationals:{}) => {
        const weekdays:string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const keys = Object.keys(operationals)
        const values:{open:string, close:string}[] = Object.values(operationals)
        const operationalCopy = [...operational]

        for (let i = 0; i < keys.length; i++) {
            operationalCopy[weekdays.indexOf(keys[i])].active = true
            operationalCopy[weekdays.indexOf(keys[i])].open = values[i].open
            operationalCopy[weekdays.indexOf(keys[i])].close = values[i].close
        }
        setOperational(operationalCopy)
    }

    const createNewSchedule = (schedules:[]) => {
        if(schedules.length !== 0) {
            const temp:{time:[object, object], timeString:string[]}[] = [];
            for (let index = 0; index < 7; index++) {
                const currentId = operationalDays[index].id;
                const currentData:string = schedules[currentId];
                if (currentData) {
                    let timeString:string[] = currentData.split(' - ');

                    temp.push({
                        time: [moment(timeString[0], "H:mm"), moment(timeString[1], "H:mm")],
                        timeString: timeString
                    });
                } else {
                    const m = moment().utcOffset(0);
                    m.set({hour:0,minute:0,second:0,millisecond:0})
                    m.toISOString()
                    m.format()
                    temp.push({
                        time: [m, m],
                        timeString: ['00:00', '00:00']
                    });
                }
            }
            setSchedule(temp);
            // setSchedule(schedules.map((ele:string) => {
            //     let timeString:string[] = ele.split(' - ');

            //     return {
            //         time: [moment(timeString[0], "H:mm"), moment(timeString[1], "H:mm")],
            //         timeString: timeString 
            //     }
            // }))
        }
    }

    const fetchData = async(id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantjasa}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {isAll: 1}
            })
            setFields({ 
                ...fields, 
                ...res.data.payload,
                schedule: res.data.payload.processSchedule === null ? [] : res.data.payload.processSchedule?.rangeTime
            })
            createNewOperational(res.data.payload.operational)
            createNewSchedule(res.data.payload.processSchedule === null ? [] : res.data.payload.processSchedule?.rangeTime)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        merchantId !== "" && fetchData(merchantId)

        fetchDataList(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])

    const submitPhoto = (path:string) => {
        setFields({...fields, image: path})
    }

    const handleDeleteTag = (tag:string) => {
        const newarr = fields.tags.filter(oldtag => oldtag !== tag)
        setFields({...fields, tags: newarr})
    }

    const handleEditTag = (tag:string, index:number) => {
        const newarr = [...fields.tags]
        newarr[index] = tag
        setFields({...fields, tags: newarr})
    }

    const handleChangeTag = (e:any) => {
        setNewTag(e.target.value)
    }

    const submitNewTag = () => {
        if (newTag !== "") {
            const newarr = [...fields.tags, newTag]
            setFields({...fields, tags: newarr})
            setNewTag("")
        }
    }

    const submitBank = (values:{bankName: string, accountName: string, accountNumber: string}) => {
        setFields({...fields, bankDetail: values})
    }

    const submitOperationHourStatus = (e:any, index:number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].active = e.target.checked
        setOperational(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time:any, timeString:any, index:number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].open = timeString[0]
        newOperationHours[index].close = timeString[1]
        setOperational(newOperationHours)
    }

    const submitOperationalHour = () => {
        const weekdays:string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const newOperationHours = (operational.filter(operationHour => operationHour.active === true))
        const newFixOperational:any = {}
        for (let i = 0; i < newOperationHours.length; i++) {
            newFixOperational[weekdays[newOperationHours[i].day]] = {open: newOperationHours[i].open, close: newOperationHours[i].close}
        }
        setFields({...fields, operational: newFixOperational})
    }

    const submitChangeProcessSchedule = (time:any, timeString:any, key:number) => {
        const rowDataCopy = schedule.slice(0);
        rowDataCopy[key] = Object.assign({}, rowDataCopy[key], {
            time: time,
            timeString: timeString
        });
        setSchedule(rowDataCopy)
    }

    const submitAddProcessSchedule = () => {
        setSchedule([
            ...schedule,
            {time: null, timeString: []}
        ])
    }

    const submitDeleteProcessSchedule = (key:number) => {
        setSchedule(schedule.filter((ele:any, keys:number) => keys !== key))
    }

    const submitProcessSchedule = () => {
        const rangeTime:any = []

        schedule.forEach((ele:any) => {
            rangeTime.push(ele.timeString.join(' - '))
        })
        setFields({ ...fields, schedule: rangeTime})
    }

    const reconstructSchedule = (data:number[]) => operationalEnum.map((val:number) => data[val])

    const handleSubmit = async(values:{
        image:string, 
        isActive:boolean,
        status:any,
        tags:string[], 
        operational:{},
        merchantFee:any,
        maxDistance:any,
        latitude:any,
        longitude:any,
        bankDetail:{},
        type:string,
        processSchedule:any,
        categories:any
    }) => {
        // values["ID"] = fields.ID
        values["image"] = fields.image
        values["merchantFee"] = parseInt(values.merchantFee)
        values["maxDistance"] = parseInt(values.maxDistance)
        values["latitude"] = parseFloat(values.latitude)
        values["longitude"] = parseFloat(values.longitude)
        values["tags"] = fields.tags
        values["operational"] = fields.operational
        values["bankDetail"] = fields.bankDetail
        values["status"] = values.status ? "OPEN" : "CLOSE"
        values["type"] = "JASA"
        values["processSchedule"] = {
            "merchantID": merchantId,
            "rangeTime": reconstructSchedule(fields.schedule)
        }
        values["categories"] =  values.categories.map((ele:any) => { return { id: ele }})

        setLoading(true)
        try {
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            if (merchantId !== "") {
                const url = `${base_url}/${merchantjasa}/${fields.id}/admin`
    
                await axios.put(url, values, {
                    headers: headers,
                })
            } else {
                const url = `${base_url}/${merchantjasa}/admin`
    
                await axios.post(url, values, {
                    headers: headers,
                })
            }
            notification["success"]({
                message: 'Yeay!',
                description:
                    `Data merchant berhasil ${merchantId !== "" ? "diperbaharui" : "ditambah"}.`,
            });
            setLoading(false)
            history.goBack()
        } catch (error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  `'Terjadi kesalahan pada saat ${merchantId !== "" ? "memperbaharui" : "menambah"} data merchant.'`,
            });
            setLoading(false)
        }
    }

    return {
        fields,
        operational,
        schedule,
        newTag,
        loading,
        submitPhoto,
        handleDeleteTag,
        handleEditTag,
        handleChangeTag,
        submitNewTag,
        submitBank,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        submitChangeProcessSchedule,
        submitAddProcessSchedule,
        submitDeleteProcessSchedule,
        submitProcessSchedule,
        handleSubmit,
        fieldsList,
        paramMerchantId: merchantId,
    }
}