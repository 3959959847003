import React, {useState, useEffect} from 'react'
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Select, Row, Col, notification, DatePicker} from "antd"
import {base_url} from "../../../../config/url-config"
import {merchantcategory} from "../../../../config/endpoint"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import axios from "axios"
import moment from "moment"

const { RangePicker } = DatePicker;

interface Props {
    handleFilter:(values:{isActive:boolean|string, status:string, categoryID:string}) => void
}

const FilterMerchantJasa: React.FC<Props> = (props) => {
    const qp = useQueryParams()
    const location = useLocation()
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false);
    const [categories, setCategories] = useState<{
        name:string,
        id:string
    }[]>([])

    useEffect(() => {
        getCategory()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
          categoryID: qp.queryParams.get('categoryID')?.toString(),
          isActive: qp.queryParams.get('isActive')?.toString(),
          status: qp.queryParams.get('status')?.toString(),
          createdAt: qp.queryParams.get('startDate')?.toString() === undefined ? undefined : [moment(qp.queryParams.get('startDate')?.toString()), moment(qp.queryParams.get('endDate')?.toString())]
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{isActive:boolean|string, status:string, categoryID:string}) => {
        props.handleFilter(values)
        toggle()
    }

   const getCategory = async () => {
        try {
            const url = `${base_url}/${merchantcategory}`

            const res = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {
                    sortBy: "sort",
                    sortType: "ASC",
                    limit: 100
                }
            })
            const response = {
                currentPage: res.data?.payload?.currentPage,
                limit: res.data?.payload?.limit,
                totalPage: res.data?.payload?.totalPage,
                payload: res.data?.payload?.data
            }
            setCategories(response.payload)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data kategori',
                placement:"topRight"
            });
        }
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-filter" style={{marginRight: "5px"}} />}
                label="Filter" 
                onClick={toggle}
            />
            <Modal
                title="Filter Merchant Jasa"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                    form={form}
                >
                    <Row className="row-edit-hobby">
                         <Col span={24}>
                            <Form.Item
                                label="Kategori"
                                name="categoryID"
                            >
                                <Select placeholder="Pilih kategori">
                                    {categories.map((category, key) => (
                                        <Select.Option key={key} value={category.id}>{category.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Status Merchant"
                                name="isActive"
                            >
                                <Select placeholder="Pilih status merchant">
                                    <Select.Option value="true">Aktif</Select.Option>
                                    <Select.Option value="false">Tidak Aktif</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Status Buka/Tutup"
                                name="status"
                            >
                                <Select placeholder="Pilih status merchant">
                                    <Select.Option value="OPEN">Buka</Select.Option>
                                    <Select.Option value="CLOSE">Tutup</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                name="createdAt"
                                label="Tanggal Buat"
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton 
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterMerchantJasa
