import React, {useContext} from 'react'
import {ShopperContext} from "../../context/ContextProvider"
import {FeatureButton, Modal, Loading} from "../../../../../components/atoms"
import CreateArea from "../create/Create"
import {IDetailArea} from "../ModelArea"
import AreaBox from "./AreaBox"
import {Pagination} from "antd"

const List: React.FC = () => {
    const context = useContext(ShopperContext)
    const {
        modal,
        toggle,
        areas,
        loading,
        handlePageChange,
        handleSubmit,
        handleEdit,
        handleDelete
    } = context.useModelListArea()

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-map-marker-alt" style={{marginRight: "5px"}} />}
                label="Area"
                onClick={toggle}
            />

            <Modal
                title="Area"
                onCancel={toggle}
                visible={modal}
            >
                <CreateArea 
                    handleSubmit={handleSubmit}
                    loading={loading}
                />
                {loading === "fetch" ? <Loading /> : (
                    <div>
                        {areas.records.map((area:IDetailArea, key:number) => (
                            <AreaBox 
                                key={key}
                                id={area.id}
                                name={area.name}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
                <div style={{paddingTop: "10px", paddingBottom: "20px", textAlign: "right"}}>
                    <Pagination 
                        current={areas.page} 
                        pageSize={5} 
                        total={areas.total_record} 
                        onChange={handlePageChange} 
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default List
