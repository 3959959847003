import React, {useState} from 'react'
import {Button, Row, Col, TimePicker} from "antd"
import {Modal} from "../../../../../components/atoms"
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import "./formprocessschedule.scss"
import { operationalDays } from '../../helper/enum';
import { defaultMinutesDisabled } from '../../helper/merchantjasa';

const {RangePicker} = TimePicker

interface Props {
    data?: any
    submitChangeProcessSchedule?: any
    submitAddProcessSchedule?: any
    submitDeleteProcessSchedule?: any
    submitProcessSchedule?: any
    dataOperational?: any
}

const ScheduleHourJasa:React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false);

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = () => {
        props.submitProcessSchedule()
        toggle()
    }

    const disableHours = (val:any) => {
        const result:number[] = [];
        if (val.active) {
            const openTime = parseInt((val.open || '00:00').split(':')[0], 10);
            const closeTime = parseInt((val.close || '00:00').split(':')[0], 10);
            for (let time = 0; time < 24; time++) {
                if (time < openTime || time > closeTime) {
                    result.push(time);
                }
            }
        }
        return result;
    }

    const disableMinutes = (selectedHour:number, val:any) => {
        const result:number[] = [];
        if (selectedHour === 0) return defaultMinutesDisabled;

        if (val.active) {
            const openTime = (val.open || '00:00').split(':');
            const closeTime = (val.close || '00:00').split(':');
            
            if (selectedHour === parseInt(openTime[0], 10)) {
                for (let time:number = parseInt(openTime[1], 10) - 1; time >= 0; time--) {
                    result.push(time);
                }
            }

            if (selectedHour === parseInt(closeTime[0], 10)) {
                for (let time = parseInt(closeTime[1], 10) + 1; time < 60; time++) {
                    result.push(time);
                }
            }

        }
        return result;
    }
    
    return (
        <React.Fragment>
            <Button className="button-modal-edit-hobby" onClick={toggle}>
                <i className="fas fa-cog" style={{fontSize: "16px"}} />
            </Button>
            <Modal className="modal-processsechedule" title="Waktu Pengerjaan" visible={modal} onCancel={toggle}>
               <Row>
                    {props.data.map((val:any, index:number) => {
                        return(
                             <Row gutter={24} key={index} align="middle" className="row-edit-hobby col-time-hour">
                                <Col span={6}>{operationalDays[index].name}</Col>
                                <Col span={18}>
                                    <RangePicker
                                        id="date"
                                        name="date" 
                                        placeholder={["Dari", "Sampai"]} 
                                        format={"H:mm"}
                                        disabledHours={() => disableHours(props.dataOperational[index])}
                                        disabledMinutes={(val:number) => disableMinutes(val, props.dataOperational[index])}
                                        // value={props.data[val.id] ? props.data[val.id].time : null}
                                        value={val.time}
                                        disabled={!props.dataOperational[index].active}
                                        onChange={(time:any, timeString:any) => props.submitChangeProcessSchedule(time, timeString, index)}
                                    />
                                </Col>
                                 {/* <Col span={2} style={{paddingLeft: "12px"}}>
                                    <Button 
                                        type="primary" 
                                        shape="circle" 
                                        size="small" 
                                        icon={<PlusOutlined />} 
                                        style={{paddingTop: "2px"}}
                                        onClick={() => props.submitAddProcessSchedule()}
                                    />
                                </Col> */}
                                 {/* <Col span={3} style={{paddingLeft: "12px", zIndex: index === 0 ? -99 : 1}}>
                                    <Button 
                                        type="ghost" 
                                        shape="circle" 
                                        size="small" 
                                        icon={<CloseOutlined />} 
                                        style={{paddingTop: "2px"}}
                                        onClick={() => props.submitDeleteProcessSchedule(index)}
                                    />
                                </Col> */}
                            </Row>
                        )
                    })}
                </Row>
                <Row style={{paddingTop: "10px"}}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Button onClick={toggle} className="cancel-button-operationhours">Kembali</Button>
                        <Button 
                            className="save-button-operationhours"
                            onClick={() => handleSubmit()}
                        >
                            Simpan
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default ScheduleHourJasa
