import React, { useContext } from 'react'
import {ItemContext} from "../context/ContextProvider"
import { FeatureButton, Modal, Loading } from "../../../../../components/atoms"
import CreateCategory from "./createcategory/CreateCategory"
import CategoryBox from "./CategoryBox"
import {Pagination} from "antd"

import "./category.scss"
import { useQuery } from '../../../../../helpers/Formatter'

interface IProps {
    path: string,
    isItem?: boolean
}

const Category = (props: IProps) => {
    const query = useQuery();
    const context = useContext(ItemContext)
    const {
        categories, 
        params,
        loading,
        modal,
        toggle,
        fetchData,
        handleEdit,
        handleDelete,
        handlePageChange
    } = context.useModelListCategory(query.get("id"), props.isItem)

    console.log(categories);

    return (
        <React.Fragment>
            <FeatureButton
                icon={<i className="fas fa-clipboard-list" style={{ marginRight: "5px" }} />}
                label="Kategori"
                onClick={toggle}
            />

            <Modal
                title="Kategori"
                onCancel={toggle}
                visible={modal}
            >
                {/* <Button className="btn-create-category">Tambah Kategori</Button> */}
                <CreateCategory 
                    fetchData={fetchData} 
                    path={props.path} 
                    isItem={props.isItem} 
                    params={params}
                />
                {loading ? <Loading /> : (
                    <div>
                        {categories.payload.map((field:{id:string, name:string}, key:number) => (
                            <CategoryBox
                                key={key}
                                name={field.name}
                                id={field.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
                <div style={{paddingTop: "10px", paddingBottom: "20px", textAlign: "right"}}>
                    <Pagination 
                        current={categories.pagination.page + 1} 
                        pageSize={5} 
                        total={categories.pagination.totalData} 
                        onChange={handlePageChange} 
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Category
