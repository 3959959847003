import React from 'react'
import {Route} from "react-router-dom"
import ServiceContextProvider from "./context/ContextProvider"
import ListService from "./list/List"
import DetailService from "./detailservice/DetailService"
import CreateService from "./createservice/CreateService"
import EditService from "./editservice/EditService"

const MerchantHobby: React.FC = () => {
    return (
        <ServiceContextProvider>
            <Route path="/hobi/service" exact={true} component={ListService} />
            <Route path="/hobi/service/detail" exact={true} component={DetailService} />
            <Route path="/hobi/service/tambah" exact={true} component={CreateService} />
            <Route path="/hobi/service/ubah" exact={true} component={EditService} />
        </ServiceContextProvider>
    )
}

export default MerchantHobby
