import React, {useState} from 'react'
import {ModalConfirmation} from "../../../../../components/atoms"

interface Props {
    handleDelete: () => void,
}

const DeleteCategory: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const handleDelete = () => {
        toggle();
        props.handleDelete();
    }

    const toggle = () => {
        setModal(!modal) 
    }

    return (
        <React.Fragment>
            <i className="far fa-trash-alt delete-icon" onClick={toggle} />
            <ModalConfirmation 
                visible={modal} 
                toggle={toggle}
                submitDelete={() => handleDelete()}
            />
        </React.Fragment>
    )
}

export default DeleteCategory
