import React, { useContext } from 'react'
import {FoodContext} from "../context/ContextProvider"
import { Link } from "react-router-dom"
import { Row, Col } from "antd"
import { DetailPageLayout } from "../../../templates"
import { Loading, BackButton } from "../../../../components/atoms"
import { DetailTitleSection, ItemDetail } from "../../../../components/molecules"
import { FormatDate, FormatRp, useQuery } from "../../../../helpers/Formatter"

import "./detail.scss"
import { days, IOperational } from '../ModelMerchantFood'
import { ICategories } from '../item/ModelItem'

const Detail: React.FC = () => {
    const query = useQuery();
    const context = useContext(FoodContext)
    const {
        fields,
        loading,
    } = context.useModelDetail(query.get("id"))

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.urlPhoto} alt="merchant-pict" className="merchant-photo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Merchant" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Merchant" value={fields.name} />
                                <ItemDetail label="Deskripsi Merchant" value={fields.description} />
                                {/* <ItemDetail label="Tipe Merchant" value={fields.} /> */}
                                <ItemDetail label="No. Telepon" value={fields.phoneNumber} />
                                <ItemDetail label="Email" value={fields.email} />
                                <ItemDetail label="Alamat" value={fields.address} />
                                <ItemDetail label="Latitude" value={fields.location?.latitude} />
                                <ItemDetail label="Longitude" value={fields.location?.longitude} />
                                <ItemDetail label="Jarak Maksimal" value={`${fields.distance} meter`} />
                                <ItemDetail label="Sharing Fee" value={<div style={{ textAlign: "right" }}>{FormatRp(Number(fields.sharingFee))}</div>} />
                                <ItemDetail label="Order Fee" value={<div style={{ textAlign: "right" }}>{FormatRp(Number(fields.orderFee))}</div>} />
                                <ItemDetail
                                    label="Kategori"
                                    value={
                                        <Row>
                                            {fields.categories?.map((field: ICategories, key:number) => (
                                                <Col key={key} span={11} style={{
                                                    paddingLeft: key === 0 ? "0px" : "10px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px"
                                                }}>
                                                    <div className="tag">
                                                        {field.name}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    }
                                />
                                <ItemDetail
                                    label="Tags"
                                    value={
                                        <Row>
                                            {fields.tags.map((field:string, key:number) => (
                                                <Col key={key} span={11} style={{
                                                    paddingLeft: key === 0 ? "0px" : "10px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px"
                                                }}>
                                                    <div className="tag">
                                                        {field}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    }
                                />
                                <ItemDetail label="Tanggal Dibuat" value={FormatDate(fields.createdAt)} />
                                <ItemDetail label="Tanggal Diubah" value={FormatDate(fields.updatedAt)} />
                                {/* <ItemDetail label="Diubah Oleh" value={fields.UpdatedBy === "" ? "-" : fields.UpdatedBy} /> */}
                            </Col>
                        </Row>
                        {fields.operationHours !== undefined ? (
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <DetailTitleSection title="Jam Operasional" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <Row className="custom-table-head-section">
                                            <Col span={14}>
                                                <Row className="custom-table-head-container">
                                                    <Col span={8}>Hari</Col>
                                                    <Col span={11}>Jam Buka</Col>
                                                    <Col span={5}>Jam Tutup</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {fields.operationHours.map((operational:IOperational, key:number) => (
                                            <Row className="custom-table-body-section" key={key}>
                                                <Col span={14}>
                                                    <Row className="custom-table-body-container">
                                                        <Col span={8}>{days[operational.day]}</Col>
                                                        <Col span={11}>
                                                            {operational.openHour}
                                                        </Col>
                                                        <Col span={5}>
                                                            {operational.closeHour}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Row style={{ paddingTop: "20px" }}>
                            <Col>
                                <Link to="/food">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
