import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Edit from "./edititem/EditItem"
import Create from "./createitem/CreateItem"
import Detail from "./detailitem/DetailItem"

const Item: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/jasa/item" exact={true} component={List} />
            <Route path="/jasa/item/edit" exact={true} component={Edit} />
            <Route path="/jasa/item/tambah" exact={true} component={Create} />
            <Route path="/jasa/item/detail" exact={true} component={Detail} />
        </ContextProvider>
    )
}

export default Item
