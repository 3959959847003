import React, {useContext} from 'react'
import {JasaContext} from "../context/ContextProvider"
import {useLocation, Link} from "react-router-dom"
import {Row, Col} from "antd"
import {DetailPageLayout} from "../../../templates"
import {Loading, BackButton} from "../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import {FormatDate, FormatRp} from "../../../../helpers/Formatter"

import "./detailmerchantjasa.scss"

const DetailMerchantJasa: React.FC = () => {
    const location = useLocation()
    const merchantId = location.search.split("=")[1]
    const context = useContext(JasaContext)
    const {
        fields,
        operational,
        loading
    } = context.useModelDetail(merchantId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.image} alt="merchant-pict" className="merchant-photo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Merchant" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Merchant" value={fields.name} />
                                <ItemDetail label="Deskripsi Merchant" value={fields.description} />
                                <ItemDetail label="Tipe Merchant" value={fields.type} />
                                <ItemDetail label="Alamat" value={fields.address} />
                                <ItemDetail label="Email" value={fields.email} />
                                <ItemDetail label="No. Telepon" value={fields.phone} />
                                <ItemDetail label="Latitude" value={fields.latitude} />
                                <ItemDetail label="Longitude" value={fields.longitude} />
                                <ItemDetail label="Jarak Maksimal" value={`${fields.maxDistance} meter`} />
                                <ItemDetail label="Rating" value={fields.avgReview} />
                                <ItemDetail label="Sharing Fee" value={<div style={{textAlign: "right"}}>{FormatRp(fields.merchantFee)}</div>} />
                                <ItemDetail 
                                    label="Tags" 
                                    value={
                                        <Row>
                                            {fields.tags.map((field:string, key:number) => (
                                                <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                    <div className="tag">
                                                        {field}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    } 
                                />
                                <ItemDetail label="Tanggal Dibuat" value={FormatDate(fields.createdAt)} />
                                <ItemDetail label="Tanggal Diubah" value={FormatDate(fields.updatedAt)} />
                                {/* <ItemDetail label="Diubah Oleh" value={fields.UpdatedBy === "" ? "-" : fields.UpdatedBy} /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Info Akun Bank" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Bank" value={fields.bankDetail.bankName} />
                                <ItemDetail label="Nama Akun" value={fields.bankDetail.accountName} />
                                <ItemDetail label="Nomor Akun" value={fields.bankDetail.accountNumber} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Jam Operasional" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={14}>
                                        <Row className="custom-table-head-container">
                                            <Col span={8}>Hari</Col>
                                            <Col span={11}>Jam Buka</Col>
                                            <Col span={5}>Jam Tutup</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {operational.filter((operation:{active:boolean}) => operation.active === true).map((operational:any, key:number) => (
                                    <Row className="custom-table-body-section" key={key}>
                                        <Col span={14}>
                                            <Row className="custom-table-body-container">
                                                <Col span={8}>{operational.day === 0 ? "Minggu" : operational.day === 1 ? "Senin" : operational.day === 2 ? "Selasa" : operational.day === 3 ? "Rabu" : operational.day === 4 ? "Kamis" : operational.day === 5 ? "Jumat" : operational.day === 6 ? "Sabtu" : "-" }</Col>
                                                <Col span={11}>
                                                    {operational.open}
                                                </Col>
                                                <Col span={5}>
                                                    {operational.close}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col>
                                <Link to="/jasa">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailMerchantJasa
