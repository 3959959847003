
export interface IListCategory {
    total_record: number
    total_page: number
    records: IDetailCategory[]
}

export interface IDetailCategory {
    id: number
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
    deletedBy: string
    name: string
    marketID: string
}

export const initialDataList = {
    total_record: 0,
    total_page: 1,
    records: []
}

