import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IListMerchant, initialDataList, IParams, initialParams} from "../MerchantHobbyModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchanthobby} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [fields, setFields] = useState<IListMerchant>(initialDataList)
    const [params, setParams] = useState<IParams>(initialParams)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingSwitch, setLoadingSwitch] = useState<string>("")
    const [visible, setVisible] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${merchanthobby}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {...queryparams, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const handleFilter = (values:{isActive:boolean|string, status:string}) => {
        if (values.isActive !== undefined && values.status !== undefined) {
            values["isActive"] = values.isActive === "true" ? true : false
            setParams({...params, ...values, pageNumber: 1})
        } else if (values.status !== undefined && values.isActive === undefined) {
            setParams({...params, ...{status: values.status}, pageNumber: 1})
        } else if (values.isActive !== undefined && values.status === undefined) {
            values["isActive"] = values.isActive === "true" ? true : false
            setParams({...params, ...{isActive: values.isActive}, pageNumber: 1})
        }
    }

    const handleReset = () => {
        setParams({
            pageLimit: 10,
            pageNumber: 1,
            search: ""
        })
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleUpdateStatus = async(checked:boolean, id:string) => {
        const values = {ID:id, isActive: checked}
        setLoadingSwitch(id)
        try {
            await axios.patch(`${base_url}/${merchanthobby}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoadingSwitch("")
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Memperbaharui status merchant berhasil!',
                placement: "bottomRight",
            });
        } catch {
            setLoadingSwitch("")
            fetchData(params)
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan dalam memperbaharui data payment config',
                placement: "bottomRight",
            });
        }
    }

    const handleSetMerchantId = (id:string) => {
        localStorage.setItem("merchantId", id);
    }

    const handleDelete = async(id:string) => {
        setDeleteLoading(true)
        try {
            await axios.delete(`${base_url}/${merchanthobby}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            setDeleteLoading(false)
            setVisible(false)
            fetchData(params)
            notification.success({
                message: 'Yeay!',
                description:
                    'Berhasil menghapus data merchant.',
            });
        } catch (error) {
            setDeleteLoading(false)
            setVisible(false)
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat menghapus data merchant',
                placement:"topRight"
            });
        }
    }

    return {
        loading,
        fields,
        params,
        fetchData,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        handleUpdateStatus,
        loadingSwitch,
        handleSetMerchantId,
        handleDelete,
        visible,
        setVisible,
        deleteLoading
    }
} 