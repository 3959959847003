import React, {useContext} from 'react'
import {PaymentConfigContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {Pagination} from "antd"
import {PageLayout} from "../../../templates"
import CreatePaymentConfig from "../createpaymentconfig/CreatePaymentConfig"
import {Search, Table} from "../../../../components/atoms"

import "./list.scss"

const PaymentConfig: React.FC = () => {
    const context = useContext(PaymentConfigContext)
    const {
        params,
        fields,
        visible,
        loading,
        loadingSwitch,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleUpdateStatus,
        handleDelete,
        toggle
    } = context.useModelList()
    const {columns} = TableColumns(
        handleUpdateStatus,
        loadingSwitch,
        fetchData,
        params,
        toggle,
        visible,
        handleDelete
    )

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <CreatePaymentConfig 
                            fetchData={fetchData} 
                            params={params}
                        />
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)}     
                />}
                content={
                    <div>
                        <Table 
                            data={fields.data}
                            columns={columns}
                            rowKey="payment_id"
                            pagination={false}
                            loading={loading}
                            // scroll={{y: 550}}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            current={params.pageNumber} 
                            pageSize={10} 
                            total={fields.totalData} 
                            onChange={handlePageChange} 
                            showSizeChanger={false}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default PaymentConfig
