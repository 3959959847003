import {useState, useEffect} from "react"
import {IList} from "../ItemModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itemjasa} from "../../../../../config/endpoint"
import {notification} from "antd"
import {useQueryParams} from "../../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import moment from "moment"

export const useModelList = () => {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<string>("")
    const [params, setParams] = useState<{}>({
        merchantID: localStorage.getItem("merchantId"),
        page: 1,
        limit: 10,
        q: ""
    })
    const [fields, setFields] = useState<IList>({
        currentPage: 1,
        limit: 10, 
        totalPage: 1,
        payload: []
    })
    const [visible, setVisible] = useState<boolean>(false)
    const [hideMenu, setHideMenu] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(params:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${itemjasa}`
            
            const res = await axios.get(url, {
                headers: headers,
                params: {...params, ...qp.value}
            })
            setFields(res.data)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses item merchant',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handlePageChange = (page:number|undefined, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            qp.queryParams.set("q", e.target.value);
            qp.handleQueryParams();
            setParams({...params, page: 1})
         }
    }

    const handleFilter = (values:any) => {
        // console.log(values)

        values.priceFrom && qp.queryParams.set("priceFrom", values.priceFrom.toString());
        values.priceTo && qp.queryParams.set("priceTo", values.priceTo.toString());
        values.categoryID && qp.queryParams.set("categoryID", values.categoryID.toString());
        values.isActive && qp.queryParams.set("isActive", values.isActive.toString());
        values.isPromo && qp.queryParams.set("isPromo", values.isPromo.toString());
        qp.handleQueryParams();
        setParams({...params, ...values, page: 1})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantId"),
            page: 1,
            limit: 10,
            q: ""
        })
        qp.reset()
    }

    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    const handleUpdateStatus = async(id:string, values:{}) => {
        setLoading(`status${id}`)
        try {
            const url = `${base_url}/${itemjasa}/${id}`

            await axios.put(url, values, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            fetchData(params)
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Update status item berhasil.',
            });
            setLoading("")
        } catch (error) {
            fetchData(params)
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat update status item.',
            });
            setLoading("")
        }
    }

    const handleDelete = async(id:string) => {
        setLoading(`delete${id}`)
        try {
            const url = `${base_url}/${itemjasa}/${id}`

            await axios.delete(url, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            toggle()
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Menghapus data item berhasil!',
                placement: "bottomRight",
            });
            setLoading("")
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam menghapus data item',
                placement: "bottomRight",
            });
            setLoading("")
        }
    }

    const handleDownloadBulk = async() => {
        function generateCSV(res:any) {
            const url = window.URL.createObjectURL(
                new Blob([res.data], { type: 'application/csv' })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `template-items-${moment().format('YYYY-MM-DD')}.csv`);
            document.body.appendChild(link);
            link.click();
      
            let data = {
                code: 200,
                message: 'Download CSV Success',
            };
            return data;
        }

        setLoading("downloadBulk")
        try {
            const url = `${base_url}/v3/merchant/item/download/csv`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
            const param = {merchantID: localStorage.getItem("merchantId")}

            const res = await axios.get(url, {
                headers: headers,
                params: param,
            })
            generateCSV(res)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat download bulk',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const handleUploadTemplate = async(file:any) => {
        // console.log(info);
        const data = new FormData()
        const merchantId:any = localStorage.getItem("merchantId");
        data.append("file", file)
        data.append("merchantID", merchantId)

        setLoading("uploadBulk")
        try {
            await axios.post(`${base_url}/v3/merchant/item/csv/upload`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil mengunggah template`,
            });
            fetchData(params)
        } catch(error) {
            const { response: { data } } = error;

            setLoading("")
            notification.error({
                message: 'Wops!',
                description: data.message,
                placement:"topRight"
            })
            fetchData(params)
        }
    }

    return {
        loading,
        params,
        fields,
        visible,
        hideMenu,
        handlePageChange,
        toggle,
        handleUpdateStatus,
        handleDelete,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handleHideMenu,
        handleDownloadBulk,
        handleUploadTemplate
    }
}