import React, {useContext} from 'react'
import {ServiceContext} from "../context/ContextProvider"
import {useLocation, Link} from "react-router-dom"
import {Loading} from "../../../../../components/atoms"
import {DetailPageLayout} from "../../../../templates"
import {Row, Col} from "antd"
import {ItemDetail, DetailTitleSection} from "../../../../../components/molecules"
import {IOperation} from "../ServiceModel"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {BackButton} from "../../../../../components/atoms"
// import {data} from "./DetailServiceModel"

import "./detailservice.scss"

const DetailService: React.FC = () => {
    const id = parseInt(useLocation().search.split("=")[1])
    const context = useContext(ServiceContext)
    const {
        loading,
        fields
    } = context.useModelDetail(id)

    return (
        <React.Fragment>
            {loading ? <Loading /> : (
                <DetailPageLayout>
                    <Row>
                        {fields.venue.picURL.map((pict:string, key:number) => (
                            <Col key={key} style={{paddingRight: "10px"}}>
                                <img src={pict} alt="venue-pict" className="venue-photo" />
                            </Col>
                        ))}
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col span={15}>
                            <ItemDetail label="Nama Service" value={fields.venue.name} />
                            <ItemDetail label="Deskripsi" value={fields.venue.description} />
                            <ItemDetail label="Alamat" value={fields.venue.address} />
                            <ItemDetail label="Nomor Telp" value={fields.venue.phone} />
                            <ItemDetail label="Maksimal User Yang Memesan" value={fields.venue.maxBooking} />
                            <ItemDetail label="Batas Waktu Sebelum Booking" value={`${fields.venue.durationBfrReservation/3600} Jam`} />
                            <ItemDetail 
                                label="Status" 
                                value={fields.venue.isActive ? "Aktif" : "Tidak Aktif"} 
                            />
                            <ItemDetail 
                                label="Status Buka/Tutup" 
                                value={fields.venue.isActive ? "Buka" : "Tutup"} 
                            />
                            <ItemDetail label="Dibuat Tanggal" value={FormatDate(fields.venue.createdAt)} />
                            <ItemDetail label="Dibuat Oleh" value={fields.venue.createBy === "" ? "-" : fields.venue.createBy} />
                            <ItemDetail label="Diubah Tanggal" value={FormatDate(fields.venue.updatedAt)} />
                            <ItemDetail label="Diubah Oleh" value={fields.venue.updatedBy === "" ? "-" : fields.venue.updatedBy} />
                        </Col>
                    </Row>

                    <DetailTitleSection title="Schedule & Harga" />
                    <Row>
                        <Col span={15}>
                            <Row className="custom-table-head-section">
                                <Col span={24}>
                                    <Row className="custom-table-head-container">
                                        <Col span={4}>Hari</Col>
                                        <Col span={5}>Jam</Col>
                                        <Col span={5}>Deposit</Col>
                                        <Col span={5}>Harga</Col>
                                        <Col>Discount</Col>
                                    </Row>
                                </Col>
                            </Row>
                            {fields.operation.map((datum:IOperation, key:number) => (
                                <Row className="custom-table-body-section" key={key}>
                                    <Col span={24}>
                                        <Row className="custom-table-body-container">
                                            <Col span={4}>{datum.day === 0 ? "Minggu" : datum.day === 1 ? "Senin" : datum.day === 2 ? "Selasa" : datum.day === 3 ? "Rabu" : datum.day === 4 ? "Kamis" : datum.day === 5 ? "Jumat" : datum.day === 6 ? "Sabtu" : "-" }</Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{timerate.timeStart} - {timerate.timeEnd}</div>
                                                ))}
                                            </Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{timerate.deposit !== null ? FormatRp(timerate.deposit) : "-"}</div>
                                                ))}
                                            </Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{timerate.rate !== null ? FormatRp(timerate.rate) : "-"}</div>
                                                ))}
                                            </Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{timerate.isDiscPercentage ? `${timerate.discount}%` : timerate.discount !== null ? FormatRp(timerate.discount) : "-"}</div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>

                    <Row style={{paddingTop: "20px"}}>
                        <Col>
                            <Link to="/hobi/service">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                    </Row>
                </DetailPageLayout>
            )}
        </React.Fragment>
    )
}

export default DetailService
