import React, {useState} from 'react'
import {FeatureButton, Modal, OkButton, BackButton} from "../../../../../components/atoms"
import {Form, Row, Col, Select} from "antd"

import "./filterservice.scss"

interface Props {
    handleFilter: (values:{isActive:string|boolean, isOpen:string|boolean}) => void
}

const FilterService: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{isActive:string|boolean, isOpen:string|boolean}) => {
        props.handleFilter(values)
        form.resetFields();
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton
                icon={<i className="fas fa-filter" style={{marginRight: "5px"}} />}
                label="Filter" 
                onClick={toggle}
            />
            <Modal
                title="Filter Service"
                onCancel={toggle}
                visible={modal}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Status Merchant"
                                name="isActive"
                            >
                                <Select placeholder="Pilih status merchant">
                                    <Select.Option value="true">Aktif</Select.Option>
                                    <Select.Option value="false">Tidak Aktif</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Status Buka/Tutup"
                                name="isOpen"
                            >
                                <Select placeholder="Pilih status buka tutup">
                                    <Select.Option value="true">Buka</Select.Option>
                                    <Select.Option value="false">Tutup</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton 
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterService
