import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {notification} from "antd"

export const useModelList = () => {
    const [params, setParams] = useState<{
        page:number
        limit:number
        q:string
    }>({
        page: 1,
        limit: 10,
        q: ""
    })
    const [fields, setFields] = useState<{
        pagination: any
        data: {}[]
    }>({
        pagination: {},
        data: []
    })
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/v3/client/user`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields({
                pagination: res.data,
                data:res.data.payload
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data customer',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    // const handleDelete = async(id?:string) => {
    //     try {
    //         const url = `${base_url}/${layanan}/${id}`

    //         await axios.delete(url, {
    //             headers: headers
    //         })
    //         fetchData(params)
    //         setVisible(!visible);
    //         notification.success({
    //             message: "Yuhuuu",
    //             description:
    //                 'Menghapus banner berhasil!',
    //             placement: "bottomRight",
    //         });
    //     } catch (error) {
    //         notification.error({
    //             message: "Ups",
    //             description:
    //               'Terjadi kesalahan saat menghaputs data banner!',
    //             placement: "bottomRight",
    //         });
    //     }
    // }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleReset = () => {
        setParams({
            limit: 10,
            page: 1,
            q: ""
        })
    }

    return {
        params,
        fields,
        visible,
        loading,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleReset,
        toggle
    }
}