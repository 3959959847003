import React from 'react'
import Table from "antd/lib/table"

import "./tablemodule.scss"

interface Props {
    columns?: {}[];
    data?: {}[];
    pagination?: any
    rowKey?:string
    rowClassName?:any
    scroll?:{}
    loading?:boolean
    onRow?:any
    rowSelection?:any
}

const TableModule: React.FC<Props> = (props) => {
    return <Table 
        columns={props.columns} 
        dataSource={props.data} 
        pagination={props.pagination} 
        rowKey={props.rowKey}
        rowClassName={props.rowClassName} 
        scroll={props.scroll}
        loading={props.loading}
        onRow={props.onRow}
        rowSelection={props.rowSelection}
    />;
}

export default TableModule
