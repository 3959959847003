import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import { itemjasa } from '../../../../../config/endpoint'
import {notification, Form, Select, Row, Col, Input} from "antd"
import {FeatureButton, Modal, Loading, BackButton, OkButton} from "../../../../../components/atoms"
import {useQueryParams} from "../../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import "./filteritem.scss"

interface Props {
    handleFilter: (values:{}) => void
}

const FilterItem: React.FC<Props> = (props) => {
    const qp = useQueryParams()
    const location = useLocation()
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [categories, setCategories] = useState<{
        name:string,
        id:string
    }[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchDataCategory()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
          priceFrom: qp.queryParams.get('priceFrom')?.toString(),
          priceTo: qp.queryParams.get('priceTo')?.toString(),
          categoryID: qp.queryParams.get('categoryID')?.toString(),
          isActive: qp.queryParams.get('isActive')?.toString(),
          isPromo: qp.queryParams.get('isPromo')?.toString(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const toggle = () => {
        setModal(!modal)
    }

    const fetchDataCategory = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${itemjasa}/category`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {entityID: localStorage.getItem("merchantId")}
            })
            // console.log(res);
            setCategories(res.data.payload)
            setLoading(false)
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    const handleSubmit = (values:{}) => {
        props.handleFilter(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                title="Filter Item"
                onCancel={toggle}
                visible={modal}
            >
                {loading ? <Loading /> : (
                    <div>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={handleSubmit}
                        >
                            <Row>
                                <Col span={12}>Range harga</Col>
                            </Row>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="priceFrom"
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Harga awal"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{paddingLeft: "10px"}}>
                                    <Form.Item
                                        name="priceTo"
                                    >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Harga akhir"
                                    />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="categoryID"
                                        label="Kategori Item"
                                    >
                                        <Select placeholder="Pilih kategori" style={{width: "100%"}}>
                                            {categories.map((category, key) => (
                                                <Select.Option key={key} value={category.id}>{category.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={24}>
                                    <Form.Item
                                        label="Status Item"
                                        name="isActive"
                                    >
                                        <Select placeholder="Pilih status item">
                                            <Select.Option value="true">Aktif</Select.Option>
                                            <Select.Option value="false">Tidak Aktif</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                              <Row className="row-edit-hobby">
                                <Col span={24}>
                                    <Form.Item
                                        label="Status Promo"
                                        name="isPromo"
                                    >
                                        <Select placeholder="Pilih promo item">
                                            <Select.Option value="true">Aktif</Select.Option>
                                            <Select.Option value="false">Tidak Aktif</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="button-section-filter-merchant">
                                <Col span={24}>
                                    <Form.Item>
                                        <BackButton 
                                            onClick={toggle}
                                        >
                                            Kembali
                                        </BackButton>
                                        <OkButton>Simpan</OkButton>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </Modal>
        </React.Fragment>
    )
}

export default FilterItem
