import React, {useState} from 'react'
import {Modal} from "antd"

import "./modaldetailphoto.scss"

interface Props {
    imglink: string
}

const ModalDetailPhoto: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    return (
        <React.Fragment>
            <div onClick={toggle} style={{cursor: "pointer", width: "100%", padding: "3px"}}>
                <img src={props.imglink} alt="img-doc" style={{width: "100%", height: "100px"}} />
            </div>

            <Modal
                visible={modal}
                onCancel={toggle}
                className="custom-modal-detailphoto"
                footer={[<div key="footer"></div>]}
            >
                <img src={props.imglink} alt="img-doc-detail" className="img-doc-detail" />
            </Modal>
        </React.Fragment>
    )
}

export default ModalDetailPhoto
