import React, {useContext} from 'react'
import {ItemJasaContext} from "../context/ContextProvider"
import {useLocation} from "react-router-dom"
import {DetailPageLayout} from "../../../../templates"
import {Loading} from "../../../../../components/atoms"
import FormItem from "../formitem/FormItem"

import "./edititem.scss"

const EditItem: React.FC = () => {
    const itemId = useLocation().search.split("=")[1]
    const context = useContext(ItemJasaContext)
    const {
        fields, 
        category, 
        loading,
        submitPhoto,
        handleSubmit
    } = context.useModelDetail(itemId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormItem 
                        data={fields}
                        category={category}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default EditItem
