import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./transactiondetail/TransactionDetail"

const Transaction: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/jasa/transaksi" exact={true} component={List} />
            <Route path="/jasa/transaksi/detail" exact={true} component={Detail} />
        </ContextProvider>
    )
}

export default Transaction
