import {useState, useEffect, useContext} from "react"
import {OnGoingPasarTransactionContext} from "./ContextProvider"
import {IListArea, initialDataList} from "../../../shopper/area/ModelArea"
import {IListShopper, initialDataList as initialDataListShopper} from "../../../shopper/ModelSHopper"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {area, shopper, transaksipasar} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelListAssignShopper(id:string) {
    const context = useContext(OnGoingPasarTransactionContext)
    const {
        fetchData,
        params
    } = context.useModelList()
    const [fields, setFields] = useState<{
        areaID: number | null,
        shopperID: number | null
    }>({
        areaID: null,
        shopperID: null
    })
    const [areas, setAreas] = useState<IListArea>(initialDataList)
    const [shoppers, setShoppers] = useState<IListShopper>(initialDataListShopper)
    const [loading, setLoading] = useState<string>("")
    const [modal, setModal] = useState<boolean>(false)
    const [active, setActive] = useState<boolean>(true)

    const toggle = () => {
        setModal(!modal)
        setFields({
            areaID: null,
            shopperID: null
        })
    }

    const fetchDataArea = async() => {
        setLoading("fetchArea")
        try {
            const res = await axios.get(`${base_url}/${area}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {isAll: true}
            })
            setAreas(res.data.payload)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchDataArea()
    }, [])

    const fetchDataShopper = async(params: {}) => {
        setLoading("fetchShopper")
        try {
            const res = await axios.get(`${base_url}/${shopper}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            setShoppers(res.data.payload)
            setLoading("")
        } catch {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data shopper',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const handleChangeArea = (value:any) => {
        setActive(false)
        setFields({...fields, areaID: value})
        fetchDataShopper({isAll: true, areaID: value, isActive: true})
    }

    const handleChangeShopper = (value:any) => {
        setFields({...fields, shopperID: value})
    }

    const handleSubmit = async() => {
        setLoading("submit")
        try {
            await axios.put(`${base_url}/${transaksipasar}/${id}`, {shopperID: fields.shopperID}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil assign shopper`,
            });
            toggle()
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat assing shopper',
                placement:"topRight"
            });
        }
    }

    return {
        areas,
        fields,
        shoppers, 
        loading,
        fetchDataArea,
        modal,
        active,
        handleChangeArea,
        handleChangeShopper,
        toggle,
        handleSubmit
    }
}