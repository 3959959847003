import {useState, useEffect} from "react"
import {IList, IDetail} from "../PaymentConfigModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {paymentconfig} from "../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = () => {
    const [params, setParams] = useState<{
        pageNumber:number
        pageLimit:number
        search:string
    }>({
        pageNumber: 1,
        pageLimit: 10,
        search: ""
    })
    const [fields, setFields] = useState<IList>({
        totalData: 0,
        data: []
    })
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingSwitch, setLoadingSwitch] = useState<string>("")
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${paymentconfig}/list`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data payment config',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, search: e.target.value, pageNumber: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleUpdateStatus = async(id: string, data: IDetail) => {
        // const config = {...data, ...{isActive: checked}}
        setLoadingSwitch(data.payment_id)
        try {
            const url = `${base_url}/${paymentconfig}/update/${id}`

            await axios.put(url, data, {
                headers: headers
            })
            setLoadingSwitch("")
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Memperbaharui status payment config berhasil!',
                placement: "bottomRight",
            });
        } catch {
            setLoadingSwitch("")
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam memperbaharui data payment config',
                placement: "bottomRight",
            });
        }
    }

    const handleDelete = async(id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${paymentconfig}/delete/${id}`

            await axios.delete(url, {
                headers: headers
            })
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Menghapus payment config berhasil!',
                placement: "bottomRight",
            });
            toggle()
        } catch (error) {
            setLoading(false)
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam menghapus data payment config',
                placement: "bottomRight",
            });
        }
    }

    const toggle = () => {
        setVisible(!visible)
    }

    return {
        params,
        fields,
        visible,
        loading,
        loadingSwitch,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleUpdateStatus,
        handleDelete,
        toggle
    }
}