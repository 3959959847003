import React, {useContext} from 'react'
import {VoucherContext} from '../context/ContextProvider'
import {FeatureButton, Modal, Loading} from "../../../../components/atoms"
import CreateLayanan from "./createlayanan/CreateLayanan"
import LayananBox from "./LayananBox"

import "./layanan.scss"

const Layanan: React.FC = () => {
    const context = useContext(VoucherContext)
    const {
        modal,
        fields,
        loading,
        handleSubmit,
        handleEdit,
        handleDelete,
        toggle
    } = context.useModelLayanan()

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                label="Layanan" 
                onClick={toggle} 
            />

            <Modal
                title="Layanan"
                onCancel={toggle}
                visible={modal}
            >
                <CreateLayanan handleSubmit={handleSubmit} />
                {loading ? <Loading /> : (
                    <div>
                        {fields.map((field:{id:string, name:string, is_category:string}, key:number) => (
                            <LayananBox 
                                key={key}
                                name={field.name}
                                id={field.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                category={field.is_category}
                            />
                        ))}
                    </div>
                )}
            </Modal>
        </React.Fragment>
    )
}

export default Layanan
