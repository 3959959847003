import React from 'react';
import ImageInv from '../../../assets/icon-inv.png';
import { FormatDate, FormatRp } from '../../../helpers/Formatter';
import { IDetailTransaction } from '../../pages/pasar/transaksi/ModelTransaction';
interface IProps {
    data: IDetailTransaction
}

function InvoiceUser(props: IProps) {
    const data = props.data;
    return (
        <div className="w-50% px-12">
            <div className="px-10 mb-29">
                <img style={{ height: 20 }} src={ImageInv} alt={"Invoice"} />
            </div>
            <section className="px-10">
                <h2>Invoice</h2>
                <table>
                    <tbody>
                        <tr>
                            <td className="text-sm">Nomor</td>
                            <td className="text-xs">:</td>
                            <td className="text-xs">{data.invoiceNumber}</td>
                        </tr>
                        <tr>
                            <td className="text-sm">Tanggal</td>
                            <td className="text-xs">:</td>
                            <td className="text-xs">{FormatDate(data.orderDate)}</td>
                        </tr>
                        <tr>
                            <td className="text-sm">Pembayaran</td>
                            <td className="text-xs">:</td>
                            <td className="text-xs">{data.payment.paymentMethod.toUpperCase()}, {data.payment.paymentMethod === "va"? data.moPayment.bankName.toUpperCase() : data.payment.paymentProvider.toUpperCase()}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <div>
                <div className="divider" />
            </div>
            <section >
                <div className="px-10">
                    <table className="tbl-items ">
                        <thead>
                            <tr >
                                <th className="text-sm pb-12">Nama Produk</th>
                                <th className="text-sm text-center pb-12">Jumlah</th>
                                <th className="text-sm text-center pb-12">Diskon</th>
                                <th className="text-sm text-right pb-12">Harga Barang</th>
                                <th className="text-sm text-right pb-12">Sub Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.itemOrder.map((value, key) => {
                                    return <tr key={key}>
                                        <td className="text-xs">{value.itemName}</td>
                                        <td className="text-center text-xs">{value.qty}</td>
                                        <td className="text-center text-xs">{value.isDiscountPercentage ? value.itemDiscount + "%" : FormatRp(value.itemDiscount)}</td>
                                        <td className="text-right text-xs">{FormatRp(value.itemPrice)}</td>
                                        <td className="text-right text-xs"> {value.isDiscountPercentage ? FormatRp(Math.round((value.itemPrice - value.itemDiscount / 100) * value.qty)) : FormatRp((value.itemPrice - value.itemDiscount) * value.qty)} </td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between bg-subtotal">
                    <div className="text-md font-w700">
                        Subtotal
                </div>
                    <div className="text-right text-md font-w700">{FormatRp(data.totalOrder)}</div>
                </div>
            </section>
            <div>
                <div className="divider" />
            </div>
            <section className="footer-inv">
                <div className="footer-item-inv">
                    <div className="flex justify-between px-10">
                        <div className="text-md font-w500 ">
                            Diskon
                        </div>
                        <div className="text-md font-w500 text-red">
                            {FormatRp(data.totalDiscount)}
                        </div>
                    </div>
                    <div className="flex justify-between px-10">
                        <div className="text-md font-w500">
                            Ongkos Kirim
                        </div>
                        <div className="text-md font-w500">
                            {FormatRp(data.deliveryFee  + data.adminFee)}
                        </div>
                    </div>
                    <div className="flex justify-between px-10">
                        <div className="text-md font-w500">
                            Diskon Ongkos Kirim
                        </div>
                        <div className="text-md font-w500 text-red">
                            Rp 0
                        </div>
                    </div>
                    <div className="flex justify-between px-10">
                        <div className="text-md font-w500">
                            Pengemasan
                        </div>
                        <div className="text-md font-w500">
                            {FormatRp(Math.round(data.serviceFee))}
                        </div>
                    </div>
                    <div className="flex justify-between bg-total">
                        <div className="text-md font-w700">
                            Total
                        </div>
                        <div className="text-md font-w700">
                            {FormatRp(Math.round(data.totalPay))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default InvoiceUser;