import React, {useState} from 'react'
import axios from "axios"
import {base_url} from "../../../config/url-config"
import {uploadphoto} from "../../../config/endpoint"
import Upload from "antd/lib/upload"
import notification from "antd/lib/notification"
import Spin from "antd/lib/spin"
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import "./uploadphoto.scss"

interface Props {
    featureName: string
    getLink: (imgLink:string) => void
    imageURL: string
}

const UploadPhoto: React.FC<Props> = (props) => {
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

    const handleUpload = async (option:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", option.file)
        formData.append("directory", props.featureName)
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            props.getLink(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description: 'Terjadi kesalahan pada saat upload foto.',
            });
        }
    }

    return (
        <React.Fragment>
            <Upload 
                showUploadList={false} 
                customRequest={handleUpload}
            >
                <div className="upload-photo">
                    {loadingUpload ? (
                        <div className="loading-container">
                            <Spin indicator={<LoadingOutlined style={{ fontSize: "40px", color: "#009b00" }} spin />} />
                        </div>
                    ) : (
                            props.imageURL === "" ? (
                                <div className="container-no-pict">
                                    <i className="far fa-image fa-6x" />
                                </div>
                            ) : (
                                    <img
                                        src={props.imageURL}
                                        alt={`${props.featureName}-img`}
                                        className="pict"
                                    />
                                )
                        )}
                </div>
            </Upload>
        </React.Fragment>
    )
}

export default UploadPhoto
