import React from "react"
import {IDetail} from "../TransactionModel"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {Row, Col} from "antd"
import {Link} from "react-router-dom"

function TableColumns() {
    const columns = [
        {
            title: "No. Order",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.invoiceNumber}</div>
                    <div>{data.merchantName}</div>
                    <div>{FormatDate(data.orderDate)}</div>
                </div>
            ),
            width: "25%"
        },
        {
            title: "Total Pesanan",
            render: (data:IDetail) => (
                <div>{FormatRp(data.totalPrice)}</div>
            ),
            width: "17%"
        },
        {
            title: "Biaya Layanan",
            render: (data:IDetail) => (
                <div>{FormatRp(data.deliveryFee)}</div>
            ),
            width: "15%"
        },
        {
            title: "Provider Pembayaran",
            render: (data:IDetail) => (
                <div>{data.paymentData !== null ? data.paymentData.paymentProvider : "-"}</div>
            ),
            width: "15%"
        },
        {
            title: "Status",
            render: (data:IDetail) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.status} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.paymentData !== null ? data.paymentData.paymentState : "-"}
                        </Col>
                    </Row>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Aksi",
            render: (data:IDetail) => (
                <Link to={`/jasa/transaksi/detail?bookingID=${data.id}`} >
                    <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                        <i className="far fa-eye" />
                    </div>
                </Link>
            )
        }
    ]

    return {
        columns
    }
}

export default TableColumns