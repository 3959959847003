import React from 'react'
import {Row, Col, DatePicker} from "antd"
import {ReportBox} from "../../../components/molecules"
import {BarChart} from "../../../components/atoms"

import "./beranda.scss"
const { RangePicker } = DatePicker;

const Beranda: React.FC = () => {
    return (
        <React.Fragment>
            <div className="beranda-container">
                <div>
                    <div className="rangepicker-container">
                        <DatePicker className="home-monthpicker" picker="month" placeholder="Pilih Bulan" />
                    </div>
                    <Row style={{padding: "10px 7px"}} gutter={[16, 22]}>
                        <Col span={4}>
                            <ReportBox
                                boxicon={<i className="fas fa-check" />}
                                boxname="Completed"
                                boxvalue={
                                    <div>
                                        <div>
                                            200 Order
                                        </div>
                                        <div>
                                            Rp 10.000.000
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-times" />}
                                boxname="Cancel"
                                boxvalue={
                                    <div>
                                        5 Order
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-times-circle" />}
                                boxname="Reject"
                                boxvalue={
                                    <div>
                                        0 Order
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-shopping-cart" />}
                                boxname="Total Order"
                                boxvalue={
                                    <div>
                                        195 Order
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-heart" />}
                                boxname="Item Favorit"
                                boxvalue={
                                    <div>
                                        <div>
                                            Bakmi Goreng
                                        </div>
                                        <div>
                                            Bakmi Kuah
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4}>
                            <ReportBox
                                boxicon={<i className="fas fa-money-bill-wave" />}
                                boxname="Cash"
                                boxvalue={
                                    <div>
                                        <div>
                                            100 Order
                                        </div>
                                        <div>
                                            Rp 5.000.000
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-credit-card" />}
                                boxname="Cashless"
                                boxvalue={
                                    <div>
                                        <div>
                                            100 Order
                                        </div>
                                        <div>
                                            Rp 5.000.000
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-comment-dots" />}
                                boxname="Review"
                                boxvalue={
                                    <div>
                                        60 review
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-frown" />}
                                boxname="Komplain"
                                boxvalue={
                                    <div>
                                        5 Order
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <ReportBox
                                boxicon={<i className="fas fa-star" />}
                                boxname="Rating"
                                boxvalue={
                                    <div>
                                        <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </div>

                <div className="chart-section">
                    <div className="chart-rangepicker-container">
                        <RangePicker className="home-rangepicker" picker="month" placeholder={["Bulan Awal", "Bulan Akhir"]} />
                    </div>
                    <Row style={{paddingTop: "13px"}}>
                        <Col span={11} className="chartbar-container">
                            <div style={{width: "100%", height: "100%"}}>
                                <BarChart
                                    chartTitle="Pendapatan"
                                    labels={["Januari", "Februari", "Maret", "April"]}
                                    datasets={[
                                        {
                                            label: "Pendapatan",
                                            data: [
                                                10000000,
                                                8000000,
                                                7000000,
                                                6000000
                                            ],
                                            backgroundColor: "#028f02"
                                        }
                                    ]}
                                    stepSizeY={Math.max(...[10000000, 8000000, 7000000, 6000000])/5}
                                />
                            </div>
                        </Col>
                        <Col span={11} offset={2} className="chartbar-container">
                            <BarChart
                                chartTitle="Transaksi"
                                labels={["Januari", "Februari", "Maret", "April"]}
                                datasets={[
                                    {
                                        label: "Completed",
                                        data: [
                                            1000,
                                            800,
                                            700,
                                            600
                                        ],
                                        backgroundColor: "#028f02"
                                    },
                                    {
                                        label: "Cancel",
                                        data: [
                                            100,
                                            80,
                                            70,
                                            60
                                        ],
                                        backgroundColor: "orange"
                                    },
                                    {
                                        label: "Reject",
                                        data: [
                                            50,
                                            30,
                                            25,
                                            30
                                        ],
                                        backgroundColor: "red"
                                    },
                                    {
                                        label: "Order",
                                        data: [
                                            1150,
                                            910,
                                            795,
                                            690
                                        ],
                                        backgroundColor: "#005300"
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Beranda
