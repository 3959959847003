import {useState, useEffect} from "react"
import { useHistory } from "react-router"
import {ModelDataMerchant, initialDataMerchant, IOperational} from "../ModelMerchantFood"
import { base_url } from "../../../../config/url-config"
import { merchantFood } from "../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"

export function useModelDetail(id:string | null = null) {
    const history = useHistory()
    const [ fields, setFields ] = useState<ModelDataMerchant>(initialDataMerchant);
    const [loading, setLoading] = useState<boolean>(false)
    const [operational, setOperational] = useState<IOperational[]>([
        { active: false, day: 0, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 1, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 2, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 3, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 4, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 5, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 6, merchantID: id, openHour: null, closeHour: null },
    ])

    const fetchData = async (id: string) => {
        setLoading(true)
        try {

            const url = `${base_url}/${merchantFood}/detil/${id}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.get(url, { headers: headers });

            if (res.data.statusCode === 200) {
                setFields(res.data.payload)
                
                // createNewOperational(res.data.payload.operational)
                if (res.data.payload.operationHours !== undefined) {
                    let newOperationHours = [...operational]
                    for (let i = 0; i < res.data.payload.operationHours.length; i++) {
                        newOperationHours[res.data.payload.operationHours[i].day].active = true
                        newOperationHours[res.data.payload.operationHours[i].day].openHour = res.data.payload.operationHours[i].openHour
                        newOperationHours[res.data.payload.operationHours[i].day].closeHour = res.data.payload.operationHours[i].closeHour
                    }
                    setOperational(newOperationHours)
                } 
                setLoading(false)
            } else {
                throw new Error(res.data);
            }

        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data merchant',
                    placement: "topRight"
                });
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        id !== null && fetchData(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const submitOperationHourStatus = (e: any, index: number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].active = e.target.checked
        setOperational(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time: any, timeString: any, index: number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].openHour = timeString[0]
        newOperationHours[index].closeHour = timeString[1]
        setOperational(newOperationHours)
    }

    const submitOperationalHour = () => {
        // const weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const newOperationHours = (operational.filter(operationHour => operationHour.active === true))
        newOperationHours.forEach(newOperationHour => {
            delete newOperationHour.active
            newOperationHour.merchantID === null && delete newOperationHour.merchantID
        })
        console.log(newOperationHours);
        setFields({...fields, operationHours: newOperationHours})
    }

    const handleSubmit = async (values: ModelDataMerchant) => {
        // console.log(values);
        // console.log(operational);
        // console.log(fields);
        
        const data = {
            ...fields,
            ...values
        }

        data.sharingFeeIsInPercentage = JSON.parse(data.sharingFeeIsInPercentage.toString());
        data.sharingFee = Number(values.sharingFee);
        data.location = {
            latitude: Number(values.latitude),
            longitude: Number(values.longitude)
        }
        // data.distance = Number(values.distance);
        data.urlPhoto = fields.urlPhoto;
        // data.operationHours = operational.filter(value => value.active);
        data.orderFee = Number(values.orderFee);
        data.orderFeeIsInPercentage = JSON.parse(data.orderFeeIsInPercentage.toString());

        console.log(data);
        
        setLoading(true)
        try {
            const url = id !== null ? `${base_url}/${merchantFood}/update/${id}` : `${base_url}/${merchantFood}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };

            const res = id !== null ? await axios.put(url, data, { headers: headers }) : await axios.post(url, data, { headers: headers })
            if (res.data.statusCode === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Data merchant berhasil diperbaharui.',
                });
                setLoading(false)
                history.goBack()
            } else {
                notification["error"]({
                    message: 'Ups!',
                    description: res.data.message,
                });
                setLoading(false)
            }

            throw new Error(res.data);
        } catch (error) {
            if (error.response) {

                notification["error"]({
                    message: 'Ups!',
                    description:
                        'Terjadi kesalahan pada saat memperbaharui data merchant.',
                });
            }
            setLoading(false)
        }
    }

    return {
        fields,
        setFields,
        loading,
        operational,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        handleSubmit
    }
}