import React, {useState, useEffect} from 'react'
import {Modal, FeatureButton, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Select, Row, Col, DatePicker, notification} from "antd"
import {base_url} from "../../../../config/url-config"
import {layanan} from "../../../../config/endpoint"
import axios from 'axios'
import "./filter.scss"

const { RangePicker } = DatePicker

interface Props {
    handleFilter: (values: {}) => void
}

const FilterVoucher: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)
    const [dataLayanan, setDataLayanan] = useState<{}[]>([]);
    const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
  
    useEffect(() => {
        getLayanan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggle = () => {
        setModal(!modal)
        form.resetFields();
    }

    const handleSubmit = (values: {}) => {
        props.handleFilter(values)
        toggle()
    }

    const getLayanan = async () => {
        try {
            const url = `${base_url}/${layanan}`;
            const res = await axios.get(url, { headers: headers });

            setDataLayanan(res?.data?.payload)
        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data layanan',
                    placement: "topRight"
                });
            }
        }
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-filter" style={{marginRight: "5px"}} />}
                label="Filter" 
                onClick={toggle}
            />
            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Tanggal Periode"
                                name="start_date"
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Layanan"
                                name="layanan_id"
                            >
                                <Select placeholder="Pilih Layanan">
                                    {dataLayanan?.map((ele:any, key:number) => (
                                        <Select.Option key={key} value={ele.id}>{ele.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Jenis Voucher"
                                name="voucher_category"
                            >
                                <Select placeholder="Pilih Jenis Voucher">
                                    <Select.Option value="potongan">Potongan</Select.Option>
                                    <Select.Option value="cashback">Cashback</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Status"
                                name="is_active"
                            >
                                <Select placeholder="Pilih Status">
                                    <Select.Option value="true">Aktif</Select.Option>
                                    <Select.Option value="false">Tidak Aktif</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                label="Terlihat"
                                name="visible"
                            >
                                <Select placeholder="Pilih Status">
                                    <Select.Option value="true">Ya</Select.Option>
                                    <Select.Option value="false">Tidak</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterVoucher
