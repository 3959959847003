import {useState, useEffect} from "react"
import {IList} from "../AkunModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {merchantaccount, merchantjasa} from "../../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = (merchantId:string = "") => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IList>({
        currentPage: 1,
        limit: 10,
        totalPage: 1,
        payload: []
    })
    const [params, setParams] = useState<{
        entityID: string|null,
        page: number,
        limit: number,
        q: string
    }>({
        entityID: merchantId,
        page: 1,
        limit: 10,
        q: ""
    })
    const [merchant, setMerchant] = useState<{
        name:string,
        id:string
    }>({
        id: "",
        name: ""
    })
    const [visible, setVisible] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}, merchantId:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantaccount}`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            fetchMerchant(merchantId)
            setFields(res.data)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data akun',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    const fetchMerchant = async(merchantId:string) => {
        try {
            const url = `${base_url}/${merchantjasa}/${merchantId}`

            const res = await axios.get(url, {
                headers: headers
            })
            setMerchant({name: res.data.payload.name, id:res.data.payload.id})
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params, merchantId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, merchantId])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleDelete = async (userId:string) => {
        toggle()
        try {
            const url = `${base_url}/${merchantaccount}/${userId}`

            await axios.delete(url, {
                headers: headers,
                params: {entityID: merchantId}
            })
            notification.success({
                message: 'Yuhuu!',
                description: 'Menghapus data akun berhasil!',
                placement:"topRight"
            });
            fetchData(params, merchantId)
        } catch (error) {
            notification.error({
                message: "Ups!",
                description:
                  'Terjadi kesalahan saat menghapus akun',
                placement: "bottomRight",
            });
        }
    }

    return {
        loading,
        fields,
        params,
        merchant,
        visible,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        toggle,
        handleDelete
    }
}