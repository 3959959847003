import React from 'react'
import {Modal, Row, Col, Button} from "antd"

import "./modalconfirmation.scss"

interface Props {
    visible: boolean
    toggle: () => any
    submitDelete?: any
    loading?: boolean
}

const ModalConfirmation: React.FC<Props> = (props) => {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.toggle}
            footer={[<div key="footer"></div>]}
            className="modal-confirmation"
        >
            <Row>
                <Col span={8}>
                    <i className="fas fa-exclamation-triangle warning-logo" />
                </Col>
                <Col span={16}>
                    <div className="confirmation-title">Hey, Tunggu!!</div>
                    <div className="confirmation-paragraph">Apakah anda yakin akan menghapus data ini?</div>
                </Col>
            </Row>
            <div className="action-section">
                <Button className="button-cancel" onClick={props.toggle}>Tidak</Button>
                <Button 
                    className="button-okey" 
                    onClick={props.submitDelete}
                    loading={props.loading}
                >
                    Ya
                </Button>
            </div>
        </Modal>
    )
}

export default ModalConfirmation
