export interface IList {
    currentPage: number
    limit: number
    totalPage: number
    payload: {}[]
}

export interface IDetail {
    id: string
    name: string
    nameShow: string
    icon: string
    isActive: boolean
    sort: number
}

export const initialDataDetail = {
    id: "",
    name: "",
    nameShow: "",
    icon: "",
    isActive: false,
    sort: 0,
}
