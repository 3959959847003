// import numeral from "numeral"
import moment from "moment";
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';



// export const FormatRp = (number: number | null) => {
//     return `Rp. ${numeral(number).format("0,0.00")}`;
// };
export const FormatRp = (number: number | null) => {
    if (number !== undefined && number !== null) {
        return number.toLocaleString('id-DE', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 2
        });
    }
    return `-`
};

export const FormatDate = (date?: string | null) => {
    return moment(date).format("DD MMMM YYYY, HH:mm:ss");
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const customQueryParams = (values: Object) => {
    const asArray = Object.entries(values);
    const filterU = asArray.filter(([key, value]) => value !== undefined && value !== "");
    const toObject = Object.fromEntries(filterU);
    return toObject;
}

interface IQuery {
    queryParams: URLSearchParams,
    handleQueryParams: () => void,
    value: any,
    reset: () => void
}

export const useQueryParams = () => {
    const location = useLocation();
    const history = useHistory();
    let queryParams = new URLSearchParams(location.search);

    const handleQueryParams = () => {
        queryParams.forEach((value, key) => {

            if (value === "") {
                queryParams.delete(key)
            }
        })

        history.push(location.pathname + '?' + queryParams.toString());
    };

    const reset = () => {
        history.push({ search: '' });
    }

    const dataQuery: IQuery = {
        queryParams,
        handleQueryParams,
        value: qs.parse(location.search, { ignoreQueryPrefix: true }),
        reset
    }

    return dataQuery;
}

export const transactionStatusName = (status:any) => {
    if(status === "UNPAID") {
        return "Menunggu Konfirmasi"
    } else if(status === "APPROVED") {
        return "Menunggu Pembayaran"
    } else if(status === "PAID") {
        return "Pembayaran Berhasil"
    } else if(status === "PROCESS") {
        return "Proses Pengerjaan"
    } else if(status === "PROCESS_DONE") {
        return "Pengerjaan Selesai"
    } else if(status === "COMPLAINED") {
        return "Komplain Pesanan"
    } else if(status === "DONE") {
        return "Pesanan Selesai"
    } else if(status === "CANCELED") {
        return "Pesanan Dibatalkan"
    } else if(status === "REJECTED") {
        return "Pesanan Ditolak"
    } else if(status === "PROCESS_REFUND") {
        return "Proses Pengembalian"
    } else if(status === "REFUND") {
        return "Pengembalian Selesai"
    } else if(status === "EXPIRED") {
        return "Pesanan Kadaluarsa"
    } else {
        return "-"
    }
}

export const transactionStatusPaymentName = (status:any) => {
    if(status === "CREATED" || status === "UNPAID" || status === null) {
        return "Belum dibayar"
    } else if(status === "PROCESS") {
        return "Sedang diproses"
    } else if(status === "PAID") {
        return "Sudah dibayar"
    } else if(status === "REFUND") {
        return "Pengembalian Berhasil"
    } else if(status === "EXPIRED") {
        return "Pembayaran Kadaluarsa"
    } else if(status === "FAILED") {
        return "Pembayaran Gagal"
    } else if(status === "CANCELED") {
        return "Pembayaran Batal"
    } else {
        return "-"
    }
}

const GREEN = "#399846";
const ORANGE = "#F2994A";
const RED = "#D81712";

export const transactionStatusNameColor = (status:any) => {
    if(status === "UNPAID" || status === "COMPLAINED" || status === "REJECTED" || status === "CANCELED" || status === "CREATED" || status === null || status === "EXPIRED" || status === "FAILED") {
        return RED
    } else if(status === "APPROVED" || status === "PROCESS" || status === "PROCESS_REFUND") {
        return ORANGE
    } else if(status === "PAID" || status === "PROCESS_DONE" || status === "DONE" || status === "REFUND") {
        return GREEN
    } else {
        return "#000000"
    }
}

export const transactionButtonName = (status:any, payment:any, isRefund:boolean = false) => {
    if(status === "UNPAID" && payment === null) {
        return {primary: "Terima", danger: "Tolak"}
    } else if(status === "APPROVED" && (payment === null || payment === "CREATED" || payment === "UNPAID")) {
        return {primary: "Proses", primaryDisabled: true, danger: "Batal"}
    } else if(status === "PAID" && payment === "PAID") {
        return {primary: "Proses", danger: "Batal"}
    } else if(status === "PROCESS" && payment === "PAID") {
        return {primary: "Proses Selesai", danger: null}
    } else if(status === "COMPLAINED" && isRefund && payment === "PAID") {
        return {primary: "Proses Refund", danger: null}
    } else if(status === "PROCESS_REFUND" && payment === "PAID") {
        return {primary: "Selesaikan Refund", danger: null}
    } else {
        return {primary: null, danger: null}
    }
}