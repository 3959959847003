import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IListService, initialDataList, IParams} from "../ServiceModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {venuehobby} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [fields, setFields] = useState<IListService>(initialDataList)
    const [loading, setLoading] = useState<boolean>(false)
    const [params, setParams] = useState<IParams>({
        merchantID: localStorage.getItem("merchantId"),
        pageNumber: 1,
        pageLimit: 10,
        search: ""
    })
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${venuehobby}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {...queryparams, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data service',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleFilter = (values:{isActive:string|boolean, isOpen:string|boolean}) => {
        if (values.isActive !== undefined && values.isOpen !== undefined) {
            values["isActive"] = values.isActive === "true" ? true : false
            values["isOpen"] = values.isOpen === "true" ? true : false
            setParams({...params, ...values, pageNumber: 1})
        } else if (values.isActive === undefined) {
            values["isOpen"] = values.isOpen === "true" ? true : false
            setParams({...params, ...values, pageNumber: 1})
        } else if (values.isOpen === undefined) {
            values["isActive"] = values.isActive === "true" ? true : false
            setParams({...params, ...values, pageNumber: 1})
        }
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantId"),
            pageLimit: 10,
            pageNumber: 1,
            search: ""
        })
    }

    const handleUpdateStatus = async(id:number, isActive:boolean) => {
        try {
            const url = `${base_url}/v3/hobby/venue/${id}/status`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.put(url, {isActive: isActive}, {
                headers: headers
            })
            notification.success({
                message: "Yuhuuu",
                description:
                    'Mengubah status service berhasil!',
                placement: "topRight",
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam menghapus data service',
                placement: "topRight",
            });
            fetchData(params)
        }
    }

    const handleDelete = async(id:number) => {
        setLoading(true)
        try {
            const url = `${base_url}/${venuehobby}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.delete(url, {
                headers: headers
            })
            toggle()
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Menghapus data service berhasil!',
                placement: "bottomRight",
            });
            setLoading(false)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
            setLoading(false)
        }
    }

    return {
        fields,
        loading,
        setLoading,
        params,
        setParams,
        visible,
        setVisible,
        fetchData,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handleUpdateStatus,
        handleDelete
    }
}