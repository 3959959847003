import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IList} from "../AyopopModel"
import {base_url} from "../../../../config/url-config"
import {ayopop} from "../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<string>("")
    const [fields, setFields] = useState<IList>({
        data: [],
        totalData: 0
    })
    const [params, setParams] = useState<{
        pageNumber: number
        pageLimit: number
    }>({
        pageNumber: 1,
        pageLimit: 10
    })
    const [balance, setBalance] = useState<number>(0)
    const [hideMenu, setHideMenu] = useState<boolean>(false)
    const [biller, setBiller] = useState<string[]>([])
    const [category, setCategory] = useState<string[]>([])

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${ayopop}/list`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios(url, {
                headers: headers,
                params: {...queryparams, ...qp.value}
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data produk ayopop',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const fetchBalance = async() => {
        try {
            const url = `${base_url}/v2/virtual-product/dashboard/ayopop/balance`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setBalance(res.data.payload.balance)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data balance ayopop',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
        fetchBalance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleFilter = (values:any) => {
        for (var key in values) {
            if (values[key] === undefined) {
                delete values[key]
            } 
        }
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            pageNumber: 1,
            pageLimit: 10
        })
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    const handlePageChange = (page:any, pageSize:any) => {
        setParams({...params, pageNumber:page})
    }

    const handleUpdate = async(values:{id:string}) => {
        setLoading(`switch${values.id}`)
        try {
            const url = `${base_url}/${ayopop}/update`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.put(url, values, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            setLoading("")
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const handleParams = async() => {
        try {
            const url = `${base_url}/v2/virtual-product/dashboard/list/filter/params`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            // console.log(res);
            setBiller(res.data.payload.biller)
            setCategory(res.data.payload.category)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data',
                placement:"topRight"
            });
        }
    }

    const handleRefreshProduct = async() => {
        try {
            const url = `${base_url}/${ayopop}/list/auto-update`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.get(url, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data',
                placement:"topRight"
            });
        }
    }

    const handleDownloadBulk = async() => {
        function generateCSV(res:any) {
            const url = window.URL.createObjectURL(
                new Blob([res.data], { type: 'application/csv' })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `bulk.csv`);
            document.body.appendChild(link);
            link.click();
      
            let data = {
                code: 200,
                message: 'Download CSV Success',
            };
            return data;
        }

        setLoading("downloadBulk")
        try {
            const url = `${base_url}/v2/virtual-product/dashboard/product/template/product-config/download`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            generateCSV(res)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat download bulk',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const handleUploadTemplate = async(file:any) => {
        // console.log(info);
        const data = new FormData()
        data.append("template", file)

        setLoading("uploadBulk")
        try {
            await axios.post(`${base_url}/v2/virtual-product/dashboard/product/import/product-config`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil mengunggah template`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengunggah template',
                placement:"topRight"
            })
            fetchData(params)
        }
    }

    return {
        loading,
        fields,
        params,
        balance,
        hideMenu,
        biller,
        category,
        handleFilter,
        handleReset,
        handleSubmitSearch,
        handleHideMenu,
        handlePageChange,
        handleUpdate,
        handleParams,
        handleRefreshProduct,
        handleDownloadBulk,
        handleUploadTemplate
    }
}