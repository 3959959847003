import React, {useState} from 'react'
import axios from 'axios'
import {base_url} from "../../../../config/url-config"
import {bannerConfig} from "../../../../config/endpoint"
import {notification, Form, Row, Col} from "antd"
import {FeatureButton, Modal, OkButton, BackButton} from "../../../../components/atoms"
import FormPaymentConfig from "../form/form"

import "./create.scss"
import { ModelBannerConfig } from '../ModelBannerConfig'

interface Props {
    fetchData: (queryparams:{}) => void
    params: {
        pageNumber:number
        pageLimit:number
        search:string
    }
}


const CreateBannerConfig: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<ModelBannerConfig>({
        image:"",
        title:"",
        body:'',
        isActive:false,
        data:{
            merchantID:"",
            click:"",
        },
        appName:["com.manyoption"]
    });

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    // FUNCTION FOR SET NEW PHOTO LINK //
    const submitPhoto = (url:string) => {
          console.log(url);
        setFields({...fields, image: url})
    }

    const handleChange = (changedValues:{}) => {
        console.log(changedValues);
        setFields({...fields, ...changedValues})
    }   

    const handleSubmit = async(values:ModelBannerConfig) => {
        const url:string = `${base_url}/${bannerConfig}`;
        const data ={...fields, ...values};

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
        try {
            const res = await axios.post(url, data, {
                headers: headers,
            })
            if (res.status === 200) {
                toggle()
                form.resetFields()
                setFields({
                    image:"",
                    title:"",
                    body:'',
                    isActive:false,
                    data:{
                        merchantID:"",
                        click:""
                    },
                    appName:["com.manyoption"]
                });
                notification.success({
                    message: "Yuhuuu",
                    description:
                      'Menambahkan data banner berhasil!',
                    placement: "bottomRight",
                });
                props.fetchData(props.params)
            }
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan pada saat membuat data',
                placement: "bottomRight",
            });
        }
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                label="Tambah"
                onClick={toggle}
            />

            <Modal
                title="Tambah Banner"
                onCancel={toggle}
                visible={modal}
            > 
                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                >
                    <FormPaymentConfig 
                        fields={fields} 
                        submitPhoto={submitPhoto}
                    />
                    <Row>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateBannerConfig
