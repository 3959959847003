import React, {createContext} from "react"
import {useModelList} from "./ListContext"

export const DetailContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList
    }

    return (
        <DetailContext.Provider value={context}>
            {props.children}
        </DetailContext.Provider>
    )
}

export default ContextProvider