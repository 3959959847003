import React from 'react'
import {useLocation} from "react-router-dom"
import {Row, Col, Button} from "antd"
import {PageTitle} from "../../../components/atoms"
import {UserAccount} from "../../../components/molecules"
import { useNavigate } from '../../../utils/navigate'
import "./header.scss"

const Header: React.FC = () => {
    const location = useLocation();
    const { goBack } = useNavigate()
    const pageTitle = location.pathname.split("/").filter((arr) => arr !== "").map((word) => word[0].toUpperCase() + word.substr(1)).join(" ");

    return (
        <Row className="header-container" align="middle">
            <Col span={18} className="page-title-container" style={{display:'flex', alignItems:"center"}}>
                <Button onClick={() => goBack()} type="primary" style={{marginRight: 20}} size="large" shape="circle" icon={<i style={{marginTop: "4px"}} className="fas fa-arrow-left" />} />
                <PageTitle pageTitle={pageTitle} />
            </Col>
            <Col span={6} className="user-info-container">
                <div className="user-info" style={{display:'flex', alignItems:"center"}}>
                    <UserAccount />
                </div>
            </Col>
        </Row>
    )
}

export default Header
