import React, {useState} from 'react'
import {FeatureButton, Modal, Loading} from "../../../../../components/atoms"
import {Button, Upload} from "antd"

interface Props {
    handleDownloadTemplate: () => void
    handleUploadTemplate: (info:any) => void
    loading: string
}

const UploadItem: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleUpload = (option:any) => {
        props.handleUploadTemplate(option.file)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-download" style={{ marginRight: "5px" }} />}
                label="Upload" 
                onClick={toggle}
            />

            <Modal
                title="Form Upload"
                onCancel={toggle}
                visible={modal}
            >
                <Button 
                    className="btn-download-template"
                    onClick={props.handleDownloadTemplate}
                    loading={props.loading === "downloadBulk" ? true : false}
                >
                    Download Template
                </Button>
                <div className="upload-box">
                    {props.loading === "uploadBulk" ? <Loading /> : (
                        <Upload 
                            name="template"
                            className="upload-bulk"
                            accept="application/csv"
                            showUploadList={false}
                            customRequest={handleUpload}
                        >
                            <div style={{textAlign: "center", width: "100%"}}>
                                <i className="fas fa-upload fa-3x" />
                            </div>
                            <div className="upload-text">Unggah File</div>
                            <div className="information-text">
                                <div>Klik unggah file untuk upload file template bulk.</div>
                                <div>Patikan file yang anda unggah memiliki format csv.</div>
                            </div>
                        </Upload>
                    )}
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default UploadItem
