import React, {useState} from 'react'
import axios from 'axios';
import {Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Row, Col, Input, Upload, notification, Spin} from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import {base_url} from "../../../../../config/url-config"
import {uploadphoto} from "../../../../../config/endpoint"

interface Props {
    image:string,
    name:string,
    handleEdit:(values:{image:string, name:string}) => void
}

const EditCategory: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [image, setImage] = useState<string>(props.image)

    const toggle = () => {
        setModal(!modal) 
    }

    const handleEdit = async(values:{image:any, name:string}) => {
        values['image'] = image
        console.log(values)
        props.handleEdit(values)
        toggle()
        form.resetFields()
    }

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setImage(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload foto.',
            });
        }
    }

    return (
        <React.Fragment>
            <i className="far fa-edit edit-icon" onClick={toggle} />

            <Modal
                title="Ubah Kategori"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    form={form}
                    onFinish={handleEdit}
                >
                 <Row style={{paddingBottom: "10px"}}>
                        <Col span={7}>Icon</Col>
                        <Col> 
                            <Form.Item
                                name="image"
                                valuePropName="file"
                                initialValue={props.image}
                            >
                                <Upload 
                                    showUploadList={false} 
                                    onChange={handleUpload}
                                >
                                    <div className="upload-photo">
                                        {loadingUpload ? (
                                            <div style={{paddingTop: "25px"}}>
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                            </div>
                                        ) : (
                                            image === "" ? (
                                                <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                    <i className="far fa-image fa-6x" />
                                                </div>
                                            ) : (
                                                <img src={image} alt="merchant-hobby-logo" style={{width: "90px", height: "90px", marginTop: "4px"}} />
                                            )
                                        )}
                                    </div>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}} align="middle">
                        <Col span={7}>Nama Kategori</Col>
                        <Col span={17}>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Nama Kategori wajib diisi!' }]}
                                initialValue={props.name}
                            >
                                <Input 
                                    placeholder="Input nama kategori" 
                                    className="form-input-category"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default EditCategory
