import React from 'react'
import {IDetailMerchant, IOperationHour, IMerchantBank} from "../MerchantHobbyModel"
import {Form, Row, Col, Input, Button, Switch, Select} from "antd"
import {UploadPhoto} from "../../../../components/atoms"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom"
import FormBankAccount from "./formbankaccount/FormBankAccount"
import FormOperationalHour from "./formoperationalhour/FormOperationalHour"
import Tag from "./tag/Tag"
import {OkButton, BackButton} from "../../../../components/atoms"

import "./formmerchanthobby.scss"

interface Props {
    data: IDetailMerchant
    operationHours: IOperationHour[]
    submitPhoto: (url:string) => void
    submitOperationHourStatus: (e:any, index:number) => void
    submitOperationHourOpenCLose: (time:any, timeString:any, index:number) => void
    submitOperationalHour: () => void
    submitBank: (values:IMerchantBank) => void
    newTag: string
    handleDeleteTag: (tag:string) => void
    handleEditTag: (tag:string, index:number) => void
    submitNewTag: (values:{}) => void
    handleChangeTag: (e:any) => void
    handleSubmit: (values:{
        ID:string,
        Image:string, 
        tags:string[], 
        operationHour:{}[], 
        Status:boolean|string,
        IsSharingFeePercentage:boolean|string, 
        SharingFee:any,
        MaxDistance:any,
        Email:string,
        PhoneNumber:string,
        Latitude:any,
        Longitude:any,
        merchantBank:{}
    }) => void
}

const FormMerchantHobby: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <Form 
                onFinish={props.handleSubmit}
            >
                    <Row>
                        <Col span={12} className="left-section">
                            <Row className="row-form">
                                <Col>
                                    <Form.Item
                                        name="Image"
                                        valuePropName="file"
                                        initialValue={props.data.Image}
                                    >
                                        <UploadPhoto 
                                            featureName="Hobi"
                                            getLink={props.submitPhoto}
                                            imageURL={props.data.Image}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Nama Merchant</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="Name"
                                        initialValue={props.data.Name}
                                        rules={[{ required: true, message: 'Nama merchant wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nama merchant"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Deskripsi Merchant</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="Description"
                                        initialValue={props.data.Description}
                                        rules={[{ required: true, message: 'Deskripsi merchant wajib diisi!' }]}
                                    >
                                        <Input.TextArea 
                                            className="form-input"
                                            rows={3} 
                                            placeholder="Input deskripsi merchant"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Tags</Col>
                                <Col span={14}>
                                    <Row style={{paddingBottom: "5px", paddingLeft: "2px"}}>
                                        {props.data.tags.map((tag:string, key:number) => (
                                            <Col span={12} key={key} style={{paddingRight: "8px", paddingBottom: "10px"}}>
                                                <Tag
                                                    index={key}
                                                    tag={tag}
                                                    handleEditTag={props.handleEditTag}
                                                    handleDeleteTag={props.handleDeleteTag}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col span={17}>
                                            <Input
                                                className="form-input"
                                                placeholder="Input tag baru" 
                                                onChange={props.handleChangeTag} 
                                                value={props.newTag}
                                            />
                                        </Col>
                                        <Col span={7} style={{paddingLeft: "12px"}}>
                                            <Button
                                                className="button-save-edit" 
                                                style={{width: "100%"}} 
                                                onClick={props.submitNewTag}
                                            >
                                                +
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Jarak Maksimal</Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="MaxDistance"
                                        initialValue={props.data.MaxDistance}
                                        rules={[
                                            { required: true, message: 'Jarak maksimal wajib diisi!' },
                                            { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Jarak yang anda input melebihi batas!'}
                                        ]}
                                    >
                                        <Input
                                            className="form-input" 
                                            type="number"
                                            placeholder="Input jarak maksimal"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{paddingLeft: "10px"}}>
                                    <Input 
                                        className="form-input"
                                        value="meter"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Sharing Fee</Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="SharingFee"
                                        initialValue={props.data.SharingFee}
                                        rules={[
                                            { required: true, message: 'Sharing fee wajib diisi!' },
                                            { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Sharing fee yang anda input melebihi batas!'}
                                        ]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            type="number"
                                            min="0"
                                            placeholder="Input sharing fee"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{paddingLeft: "10px"}}>
                                    <Form.Item
                                        name="IsSharingFeePercentage"
                                        initialValue={props.data.IsSharingFeePercentage ? "%" : "idr"}
                                        rules={[{ required: true, message: 'Satuan sharing fee wajib dipilih!' }]}
                                    >
                                        <Select>
                                            <Select.Option value="%">%</Select.Option>
                                            <Select.Option value="idr">idr</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Alamat</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="Address"
                                        initialValue={props.data.Address}
                                        rules={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                    >
                                        <Input.TextArea 
                                            rows={2} 
                                            className="form-input" 
                                            placeholder="Input alamat merchant"
                                            value={props.data.Address}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Email</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="Email"
                                        initialValue={props.data.Email}
                                        rules={[
                                            { required: true, message: 'Email merchant wajib diisi!' },
                                            { type: "email", message: 'Format email yang kamu isi salah!' },
                                        ]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input email merchant"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>No. Telepon</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="PhoneNumber"
                                        initialValue={props.data.PhoneNumber}
                                        rules={[
                                            { required: true, message: 'Nomor Telepon merchant wajib diisi!'},
                                            { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                        ]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nomor telepon merchant"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>  
                        </Col>
                        <Col span={12} className="right-section">
                            <Row className="row-form">
                                <Col span={7}>Latitude</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="Latitude"
                                        initialValue={props.data.Latitude}
                                        rules={[{ required: true, message: 'Latitude wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            type="number"
                                            placeholder="Input latitude"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Longitude</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="Longitude"
                                        initialValue={props.data.Longitude}
                                        rules={[{ required: true, message: 'Longitude wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            type="number"
                                            placeholder="Input longitude"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Jam Operasional</Col>
                                <Col span={12}>
                                    <FormOperationalHour 
                                        data={props.operationHours}
                                        submitOperationHourStatus={props.submitOperationHourStatus}
                                        submitOperationHourOpenCLose={props.submitOperationHourOpenCLose}
                                        submitOperationalHour={props.submitOperationalHour}
                                    />
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Info Akun Bank</Col>
                                <Col span={12}>
                                    <FormBankAccount
                                        data={props.data.merchantBank}
                                        submitBank={props.submitBank}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Status Aktif</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="IsActive"
                                        valuePropName= "checked"
                                        initialValue={props.data.IsActive}
                                    >
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            // defaultChecked={data.IsActive} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Buka/Tutup</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Status"
                                        valuePropName= "checked"
                                        initialValue={props.data.Status === "OPEN" ? true : false}
                                    >
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />} 
                                            // defaultChecked={data.Status === "OPEN" ? true : false} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <OkButton>Simpan</OkButton>
                                <Link to="/hobi">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row> 
                </Form>
        </React.Fragment>
    )
}

export default FormMerchantHobby
