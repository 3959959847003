import React, {createContext} from "react"
import {useModelList} from "./ContextList"
import {useModelDetail} from "./ContextDetail"

export const TransactionJasaContext = createContext<any>(null)

const ContextProvider:React.FC<{children:any}> = (props) => {
    const context = {
        useModelList,
        useModelDetail
    }

    return (
        <TransactionJasaContext.Provider value={context}>
            {props.children}
        </TransactionJasaContext.Provider>
    )
}

export default ContextProvider