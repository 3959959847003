import React, {useContext} from 'react'
import {AccountJasaContext} from "../context/ContextProvider"
import {FeatureButton, Modal, BackButton, OkButton, Loading} from "../../../../../components/atoms"
import {Form, Row, Col, Input, Select} from "antd"

import "./createakun.scss"

interface Props {
    merchantName:string,
    merchantId:string,
    fetchData:any,
    params:{},
}

const CreateAkun: React.FC<Props> = (props) => {
    const context = useContext(AccountJasaContext)
    const {
        loading,
        modal,
        role,
        pass,
        passconfirm,
        setpass,
        handleSubmit,
        toggle
    } = context.useModelDetail(props.merchantId, props.fetchData, props.params)
    
    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                label="Tambah" 
                onClick={toggle}
            />
            <Modal
                title="Tambah Akun"
                visible={modal}
                onCancel={toggle}
            >   
                {loading ? <Loading /> : null}
                <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Nama"
                                name="name"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama user" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="No. Handphone"
                                name="phone"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nomor handphone" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input email" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            {/* <Form.Item
                                label="Email"
                                name="email"
                                // initialValue={props.fields.venue.name}
                            > */}
                            <Row style={{paddingBottom: "8px"}}>
                                <Col span={24}>
                                    Merchant
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Input
                                        value={props.merchantName}
                                        className="form-input" 
                                        placeholder="Input email" 
                                        disabled
                                    />
                                </Col>
                            </Row>
                            {/* </Form.Item> */}
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Role"
                                name="roleID"
                                initialValue={role.filter((each:any) => each.name === "jasa:owner").length > 0 ? role.filter((each:any) => each.name === "jasa:owner")[0].id : ""}
                            >
                                <Select 
                                    // defaultValue="lucy" 
                                    // onChange={handleChange}
                                    disabled
                                >
                                    {role.map((datum:{id:string, name:string}, key:number) => (
                                        <Select.Option key={key} value={datum.id}>{datum.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Password"
                                name="password"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input.Password
                                    name="password"
                                    className="form-input" 
                                    placeholder="Input password" 
                                    onChange={setpass}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "20px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Konfirmasi Password"
                                name="passwordConfirm"
                                hasFeedback={passconfirm === null ? false : true}
                                validateStatus={passconfirm ? "success" : passconfirm === null ? "validating" : "warning"}
                                // initialValue={props.fields.venue.name}
                            >
                                <Input.Password
                                    name="passwordConfirm"
                                    className="form-input" 
                                    placeholder="Input ulang password" 
                                    onChange={setpass}
                                    disabled={pass === "" ? true : false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <BackButton onClick={toggle}>Kembali</BackButton>
                            <OkButton>
                                Simpan
                            </OkButton>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateAkun
