import {useState, useEffect} from "react"
import {useHistory} from "react-router"
import {IDetailMerchant, initialDataDetail, IOperationHour, initialDataOperationHourDummmy} from "../MerchantHobbyModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchanthobby} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(merchantId:string = "") {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetailMerchant>(initialDataDetail)
    const [operationHours, setOperationHours] = useState<IOperationHour[]>(initialDataOperationHourDummmy)
    const [newTag, setNewTag] = useState<string>("")

    const fetchData = async (id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchanthobby}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setFields(res.data.payload)
            if (res.data.payload.operationHour.length > 0) {
                // let newOperationHours = [...operationHours]
                let newOperationHours = [
                    {active: false, day: 0, openHour: null, closeHour: null},
                    {active: false, day: 1, openHour: null, closeHour: null},
                    {active: false, day: 2, openHour: null, closeHour: null},
                    {active: false, day: 3, openHour: null, closeHour: null},
                    {active: false, day: 4, openHour: null, closeHour: null},
                    {active: false, day: 5, openHour: null, closeHour: null},
                    {active: false, day: 6, openHour: null, closeHour: null},
                ]
                for (let i = 0; i < res.data.payload.operationHour.length; i++) {
                    newOperationHours[res.data.payload.operationHour[i].day].active = true
                    newOperationHours[res.data.payload.operationHour[i].day].openHour = res.data.payload.operationHour[i].openHour
                    newOperationHours[res.data.payload.operationHour[i].day].closeHour = res.data.payload.operationHour[i].closeHour
                }
                setOperationHours(newOperationHours)
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        merchantId !== "" && fetchData(merchantId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])

    const submitPhoto = (url:string) => {
        setFields({...fields, Image: url})
    }

    const submitOperationHourStatus = (e:any, index:number) => {
        const newOperationHours = [...operationHours]
        newOperationHours[index].active = e.target.checked
        setOperationHours(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time:any, timeString:any, index:number) => {
        const newOperationHours = [...operationHours]
        newOperationHours[index].openHour = timeString[0]
        newOperationHours[index].closeHour = timeString[1]
        setOperationHours(newOperationHours)
    }

    const submitOperationalHour = () => {
        const newOperationHours = (operationHours.filter(operationHour => operationHour.active === true))
        newOperationHours.forEach(newOperationHour => delete newOperationHour.active)
        setFields({...fields, operationHour: newOperationHours})
    }

    const submitBank = (values:{bankName: string, accountName: string, accountNumber: string}) => {
        setFields({...fields, merchantBank: values})
    }

    const handleDeleteTag = (tag:string) => {
        const newarr = fields.tags.filter(oldtag => oldtag !== tag)
        setFields({...fields, tags: newarr})
    }

    const handleEditTag = (tag:string, index:number) => {
        const newarr = [...fields.tags]
        newarr[index] = tag
        setFields({...fields, tags: newarr})
    }

    const handleChangeTag = (e:any) => {
        setNewTag(e.target.value)
    }

    const submitNewTag = () => {
        if (newTag !== "") {
            const newarr = [...fields.tags, newTag]
            setFields({...fields, tags: newarr})
            setNewTag("")
        }
    }

    const handleSubmit = async(values:{
        ID:string,
        Image:string, 
        Status:boolean|string,
        tags:string[], 
        operationHour:{}[], 
        IsSharingFeePercentage:boolean|string,
        SharingFee:any,
        MaxDistance:any,
        Latitude:any,
        Longitude:any,
        merchantBank:{}
    }) => {
        values["ID"] = fields.ID
        values["Image"] = fields.Image
        values["Status"] = values.Status ? "OPEN" : "CLOSED"
        values["IsSharingFeePercentage"] = values.IsSharingFeePercentage === "%" ? true : false
        values["SharingFee"] = parseInt(values.SharingFee)
        values["MaxDistance"] = parseInt(values.MaxDistance)
        values["Latitude"] = parseFloat(values.Latitude)
        values["Longitude"] = parseFloat(values.Longitude)
        values["tags"] = fields.tags
        values["operationHour"] = fields.operationHour
        values["merchantBank"] = fields.merchantBank

        setLoading(true)
        try {
            const url = `${base_url}/${merchanthobby}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.patch(url, values, {
                headers: headers,
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Data merchant berhasil diperbaharui.',
            });
            setLoading(false)
            history.goBack()
        } catch (error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan pada saat memperbaharui data merchant.',
            });
            setLoading(false)
        }
    }

    const handleSubmitCreate = async(values:{
        Image:string, 
        Status:boolean|string,
        tags:string[], 
        operationHour:{}[], 
        IsSharingFeePercentage:boolean|string,
        SharingFee:any,
        MaxDistance:any,
        Latitude:any,
        Longitude:any,
        merchantBank:{}
    }) => {
        values["Image"] = fields.Image
        values["Status"] = values.Status ? "OPEN" : "CLOSED"
        values["IsSharingFeePercentage"] = values.IsSharingFeePercentage === "%" ? true : false
        values["SharingFee"] = parseInt(values.SharingFee)
        values["MaxDistance"] = parseInt(values.MaxDistance)
        values["Latitude"] = parseFloat(values.Latitude)
        values["Longitude"] = parseFloat(values.Longitude)
        values["tags"] = fields.tags
        values["operationHour"] = fields.operationHour
        values["merchantBank"] = fields.merchantBank
        // console.log(values);

        setLoading(true)
        try {
            const url = `${base_url}/${merchanthobby}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.post(url, values, {
                headers: headers,
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Berhasil menambahkan data merchant.',
            });
            setLoading(false)
            history.goBack()
        } catch (error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan pada saat membuat data merchant.',
            });
            setLoading(false)
        }
    }

    return {
        fetchData,
        fields,
        setFields,
        loading,
        operationHours,
        newTag,
        submitPhoto,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        submitBank,
        handleDeleteTag,
        handleEditTag,
        handleChangeTag,
        submitNewTag,
        handleSubmit,
        handleSubmitCreate
    }
}