import React, { useState } from 'react'
import {Row, Col, Input} from "antd"
import EditLayanan from './editlayanan/EditLayanan'

interface Props {
    name:string,
    id:string,
    handleEdit: (id:string, value:{}) => void,
    handleDelete: (id:string) => void,
    category:string
}

const LayananBox: React.FC<Props> = (props) => {
    const [editStat, setEditStat] = useState<boolean>(false)
    // const [name, setName] = useState<string>("")

    const handleChangeEditStat = () => {
        setEditStat(!editStat)
    }

    // const handleChangeInput = (e:any) => {
    //     setName(e.target.value)
    // }

    const handleSaveEdit = (values:{}) => {
        props.handleEdit(props.id, values)
        setEditStat(false)
    }

    const detailValue = {
        name: props.name,
        is_category: props.category,
    }

    return (
        <Row className="category-box">
            <EditLayanan modal={editStat} handleSubmit={handleSaveEdit} onCancel={handleChangeEditStat} detailValue={detailValue} />
            <Col span={21}>
                {props.name}
                {/* {editStat ? <Input defaultValue={props.name} onChange={handleChangeInput} /> : props.name} */}
            </Col>
           <Col span={3} className="action-section">
                <i className="far fa-edit edit-icon" onClick={handleChangeEditStat} /> 
                {/* 
                {editStat ? (
                    <div>
                        <i className="far fa-save save-icon" onClick={handleSaveEdit} /> 
                        <i className="fas fa-times cancel-icon" onClick={handleChangeEditStat} />
                    </div>
                ) : (
                    <div>
                        <i className="far fa-edit edit-icon" onClick={handleChangeEditStat} /> 
                        <i className="far fa-trash-alt delete-icon" onClick={() => props.handleDelete(props.id)} />
                    </div>
                )}
                */}
            </Col>
        </Row>
    )
}

export default LayananBox
