import React, {useState} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import { categoryhobby } from '../../../../../config/endpoint'
import {Button, Form, Row, Col, Input, notification} from "antd"
import {Modal, BackButton, OkButton} from "../../../../../components/atoms"

import "./createcategory.scss"


interface Props {
    fetchData: () => void
}

const CreateCategory: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async(values:{}) => {
        try {
            const res = await axios.post(`${base_url}/${categoryhobby}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            // console.log(res);
            if (res.status === 200) {
                toggle()
                props.fetchData()
                form.resetFields();
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat membuat kategori.',
            });
        }
    }

    return (
        <React.Fragment>
            <Button 
                className="btn-create-category"
                onClick={toggle}
            >
                Tambah Kategori
            </Button>

            <Modal
                onCancel={toggle}
                title="Tambah Kategori"
                visible={modal}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label="Nama Kategori"
                                rules={[{ required: true, message: 'Nama kategori wajib diisi!' }]}
                            >
                                <Input 
                                    placeholder="Input nama kategori" 
                                    className="form-input"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateCategory
