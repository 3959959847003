import React from 'react'
import { Menu } from "antd";
import menus from "../../../utils/menus"
import {useLocation, Link} from "react-router-dom"

import "./sidemenu.scss"
const {SubMenu, Item} = Menu

const SideMenu: React.FC = () => {
    const location = useLocation();

    // console.log(location);

    return (
        <div className="sidemenu-container">
            <Menu
                mode="inline"
                // defaultSelectedKeys={["0"]}
                selectedKeys={["/" + location.pathname.split("/")[1]]}
                // selectedKeys={location.pathname}
                style={{ height: "100%", borderRight: 0 }}
            >
                {menus.map((menu: {name: string; url?: any; icon: any; submenus?: { name: string; url: any;}[]}, key) => {
                    if (menu.submenus) {
                        return (
                            <SubMenu key={key} icon={menu.icon} title={menu.name}>
                                {menu.submenus.map((submenu) => (
                                    <Item key={submenu.url}>
                                        <Link to={submenu.url} className="menu-link">
                                            {submenu.name}
                                        </Link>
                                    </Item>
                                ))}
                            </SubMenu>
                        );
                    } else {
                        return (
                            <Item key={menu.url} icon={menu.icon}>
                                <Link to={menu.url} className="menu-link">
                                    {menu.name}
                                </Link>
                            </Item>
                        );
                    }
                }
                )}
            </Menu>
        </div>
    )
}

export default SideMenu
