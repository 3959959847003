import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {FeatureButton, Modal, BackButton, OkButton, Loading} from "../../../../../components/atoms"
import {Form, Row, Col, Input, Select, notification} from "antd"

import "./createakun.scss"

interface Props {
    merchantName:string,
    merchantId:string,
    fetchData:any,
    params:{},
}

const CreateAkun: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)
    const [role, setRole] = useState<{id:string, name:string}[]>([])
    const [pass, setPass] = useState<string>("")
    const [passconfirm, setPassconfirm] = useState<boolean|null>(null)
    const [fields, setFields] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: "",
    })

    const toggle = () => {
        setModal(!modal)
    }

    const fetchRole = async () => {
        try {
            const res = await axios.get(`${base_url}/v3/client/role`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {downline: true}
            })
            setRole(res.data.payload)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchRole()
    }, [])

    const setpass = (e:any) => {
        e.target.name === "password" ? setPass(e.target.value) : (
            e.target.value === pass ? setPassconfirm(true) : setPassconfirm(false)
        )
    }

    const handleSubmit = async (values: {entityID:string, passwordConfirm?:string}) => {
        values["entityID"] = props.merchantId
        delete values.passwordConfirm
        console.log(values);
        if (passconfirm === true) {
            setLoading(true)
            try {
                const res = await axios.post(`${base_url}/v3/client/user/role`, values, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    }
                })
                if (res.status === 200) {
                    notification.success({
                        message: 'Yuhuu!',
                        description:
                          'Menambahkan data akun berhasil!',
                        placement:"topRight"
                    });
                    setLoading(false)
                    toggle()
                    setFields({
                        name: "",
                        phone: "",
                        email: "",
                        password: "",
                        passwordConfirm: ""
                    })
                    setPassconfirm(null)
                    props.fetchData(props.params, props.merchantId)
                }
            } catch (error) {
                setLoading(false)
                notification.error({
                    message: 'Ups!',
                    description:
                      'Pastikan form yang anda isi telah benar!',
                    placement:"topRight"
                });
            }
        } else {
            notification.error({
                message: 'Ups!',
                description:
                  'Pastikan form yang anda isi telah benar!',
                placement:"topRight"
            });
        }
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                label="Tambah" 
                onClick={toggle}
            />
            <Modal
                title="Tambah Akun"
                visible={modal}
                onCancel={toggle}
            >   
                {loading ? <Loading /> : null}
                <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                    requiredMark={false}
                >
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Nama"
                                name="name"
                                initialValue={fields.name}
                                rules={[{ required: true, message: 'Nama wajib diisi!' }]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama user" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="No. Handphone"
                                name="phone"
                                initialValue={fields.phone}
                                rules={[
                                    { required: true, message: 'Nomor Telepon merchant wajib diisi!'},
                                    { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                ]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nomor handphone" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                initialValue={fields.email}
                                rules={[
                                    { required: true, message: 'Email merchant wajib diisi!' },
                                    { type: "email", message: 'Format email yang kamu isi salah!' },
                                ]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input email" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Row style={{paddingBottom: "8px"}}>
                                <Col span={24}>
                                    Merchant
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Input
                                        value={props.merchantName}
                                        className="form-input" 
                                        placeholder="Input email" 
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Role"
                                name="roleID"
                                initialValue="5faa050d8550fb1f9c83ae79"
                            >
                                <Select
                                    disabled
                                >
                                    {role.map((datum:{id:string, name:string}, key) => (
                                        <Select.Option key={key} value={datum.id}>{datum.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Password"
                                name="password"
                                initialValue={fields.password}
                                rules={[{ required: true, message: 'Password wajib diisi!' }]}
                            >
                                <Input.Password
                                    name="password"
                                    className="form-input" 
                                    placeholder="Input password" 
                                    onChange={setpass}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "20px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Konfirmasi Password"
                                name="passwordConfirm"
                                hasFeedback={passconfirm === null ? false : true}
                                validateStatus={passconfirm ? "success" : passconfirm === null ? "validating" : "warning"}
                                initialValue={fields.passwordConfirm}
                            >
                                <Input.Password
                                    name="passwordConfirm"
                                    className="form-input" 
                                    placeholder="Input ulang password" 
                                    onChange={setpass}
                                    disabled={pass === "" ? true : false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <BackButton onClick={toggle}>Kembali</BackButton>
                            <OkButton>
                                Simpan
                            </OkButton>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateAkun
