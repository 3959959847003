import React, {useState} from 'react'
import {Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Select, Row, Col, Button, Input} from "antd"

interface Props {
    onCancel:() => void,
    handleSubmit:(values:{}) => void,
    modal: boolean,
    detailValue:({
        name:string,
        is_category:string
    })
}

const EditLayanan: React.FC<Props> = (props) => {
    const [form] = Form.useForm();

    const handleSubmit = async(values:{}) => {
        console.log(values);
        props.handleSubmit(values)
        props.onCancel()
    }

    return (
        <React.Fragment>
            <Modal
                title="Edit Layanan"
                visible={props.modal}
                onCancel={props.onCancel}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{
                        name: props.detailValue.name,
                        is_category: String(props.detailValue.is_category),
                    }}
                >
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Layanan"
                                rules={[{ required: true, message: 'Nama Layanan wajib diisi!' }]}
                            >
                                <Input 
                                    placeholder="Input nama layanan" 
                                    className="form-input-category"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                hidden
                                label="Kategori"
                                name="is_category"
                                rules={[{ required: true, message: 'Kategori wajib dipilih!' }]}
                                initialValue="1"
                            >
                                <Select placeholder="Pilih">
                                    <Select.Option value="1">Ya</Select.Option>
                                    <Select.Option value="0">Tidak</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={props.onCancel}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default EditLayanan
