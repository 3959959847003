import React from 'react'
import ContextProvider from './context/ContextProvider'
import {Route} from 'react-router-dom'

import List from './list/List'
import DetailCustomer from './detail/Detail'

import './Customer.scss'

const Customer: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/customer" exact={true} component={List}/>
            <Route path="/customer/detail" exact={false} render={() => <DetailCustomer />} />
        </ContextProvider>
    )
}

export default Customer
