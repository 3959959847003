export interface IListAkun {
    totalPage: number
    currentPage: number
    limit: number
    payload: IDetailAkun[]
}

export interface IDetailAkun {
    email: string
    id: string
    name: string
    phone: string
}

export const initialDataList = {
    totalPage: 1,
    currentPage: 1,
    limit: 10,
    payload: []
}

export const initialDataDetail = {
    email: "",
    id: "",
    name: "",
    phone: ""
}