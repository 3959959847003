import React, {useContext} from 'react'
import {TransactionJasaAllContext} from "../context/ContextProvider"
import {useQueryParams} from "../../../../helpers/Formatter"
import TableColumns from "./Columns"
import {PageLayout} from "../../../templates"
import FilterTransactionJasa from "../filtertransactionjasa/FilterTransactionJasa"
import {FeatureButton} from "../../../../components/atoms"
import {Search, Table} from "../../../../components/atoms"
import {Pagination, Row, Col} from "antd"
import "./list.scss"

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(TransactionJasaAllContext)
    const {
        fetchData,
        params,
        loading,
        fields,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    } = context.useModelList()
    const {
        setDataEdit,
        handleAccept,
        handleReject,
        handleNextStep
    } = context.useModelDetail("", fetchData, params);
    const {columns} = TableColumns(setDataEdit, handleAccept, handleReject, handleNextStep)

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <FilterTransactionJasa handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('q')?.toString()}
                />}
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            rowKey="id"
                            pagination={false}
                            rowClassName={(record:{status:string}, index:number) => record.status === "UNPAID" || record.status === "PAID" || record.status === "PROCESS" ? 'new-transaction' :  'read-transaction'}
                            scroll={{y: 'calc(100vh - 23em)'}}
                            loading={loading}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <span>{`Total ${fields.count} Transaksi`}</span>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.currentPage} 
                                pageSize={10} 
                                total={fields.totalPage * fields.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
