import React, {useState, useEffect} from 'react'
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Row, Col, Select, DatePicker} from "antd"

const { RangePicker } = DatePicker;

interface Props {
    fetchParams: () => void
    options: {
        ayopopState: string[]
        biller: string[]
        category: string[]
        paymentMethod: string[]
        paymentProvider: string[]
        paymentState: string[]
    }
    handleFilter: (values:{}) => void
}

const Filter: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    useEffect(() => {
        modal && props.fetchParams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    const handleSubmit = (values:{}) => {
        props.handleFilter(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                title="Form Filter"
                onCancel={toggle}
                visible={modal}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    requiredMark={false}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label="Kategori Produk"
                            >
                                <Select placeholder="Pilih kategori produk" style={{ width: "100%" }}>
                                    {props.options.category.map((each, key) => (
                                        <Select.Option key={key} value={each}>
                                            {each}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentProvider"
                                label="Provider Pembayaran"
                            >
                                <Select placeholder="Pilih provider pembayaran" style={{ width: "100%" }}>
                                    {props.options.paymentProvider.map((each, key) => (
                                        <Select.Option key={key} value={each}>
                                            {each}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentMethod"
                                label="Metode Pembayaran"
                            >
                                <Select placeholder="Pilih metode pembayaran" style={{ width: "100%" }}>
                                    {props.options.paymentMethod.map((each, key) => (
                                        <Select.Option key={key} value={each}>
                                            {each}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentState"
                                label="Status Pembayaran"
                            >
                                <Select placeholder="Pilih status pembayaran" style={{ width: "100%" }}>
                                    {props.options.paymentState.map((each, key) => (
                                        <Select.Option key={key} value={each}>
                                            {each}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="ayopopState"
                                label="Status Ayopop"
                            >
                                <Select placeholder="Pilih status ayopop" style={{ width: "100%" }}>
                                    {props.options.ayopopState.map((each, key) => (
                                        <Select.Option key={key} value={each}>
                                            {each}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="createdAt"
                                label="Tanggal Transaksi"
                                rules={[{ required: true, message: 'Tanggal transaksi wajib diisi!' }]}
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
