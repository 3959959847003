import {useState, useEffect} from "react"
import {IList} from "../TransactionModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {orderjasa} from "../../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = () => {
    const [fields, setFields] = useState<IList>({
        currentPage: 1,
        limit: 10,
        totalPage: 1,
        data: []
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [params, setParams] = useState<{
        merchantID:string|null,
        page:number,
        q:string
    }>({
        merchantID: localStorage.getItem("merchantId"),
        page: 1,
        q: ""
    })
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async (params:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${orderjasa}`

            const res = await axios.get(url, {
                headers: headers,
                params: params
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
       fetchData(params)
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handleFilter = (values:{}) => {
        // console.log(values);
        setParams({...params, ...values, page: 1})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantId"),
            page: 1,
            q: ""
        })
    }

    return {
        fields,
        loading,
        params,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    }
}