import React, {useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../config/url-config"

import "./settlement.scss"

const Settlement: React.FC = () => {
    // const [fields, setFields] = useState<{}[]>([])

    const fetchData = async() => {
        try {
            const res = await axios.get(`${base_url}/v2/mopayment/disbursement/transactions`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            console.log(res);
            
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <React.Fragment>
            
        </React.Fragment>
    )
}

export default Settlement
