import React from 'react'
import {Row, Col} from "antd"

import "./pagetitle.scss"

interface Props {
    pageTitle: string
}

const PageTitle: React.FC<Props> = (props) => {
    return (
        <Row className="page-title">
            <Col className="title">
                {props.pageTitle}
            </Col>
        </Row>
    )
}

export default PageTitle
