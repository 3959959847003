import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Edit from "./edititem/EditItem"
import Create from "./createitem/CreateItem"
import Detail from "./detailitem/DetailItem"

const Item: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/food/item" exact={true} render={() => <List />} />
            <Route path="/food/item/ubah" exact={true} render={() => <Edit />} />
            <Route path="/food/item/tambah" exact={true} render={() => <Create />} />
            <Route path="/food/item/detail" exact={true} render={() => <Detail />} />
        </ContextProvider>
    )
}

export default Item
