import React, { useState } from 'react'
import { Modal, BackButton, OkButton } from "../../../../../../components/atoms"
import axios from 'axios'
import { base_url } from "../../../../../../config/url-config"
import { Button, Form, Input, Row, Col, notification } from "antd"

import "./createcategory.scss"
import { useQuery } from '../../../../../../helpers/Formatter'

interface Props {
    fetchData: (params:{}) => void
    path?: string
    isItem?: boolean
    params?: any
}

const CreateCategory: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const query = useQuery();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async (values: {}) => {

        try {
            const data = props.isItem ? { ...values, merchant_id: query.get("id") } : values
            const res = await axios.post(`${base_url + props.path}`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            if (res.status === 200 || res.status === 201) {
                form.resetFields();
                toggle()
                props.fetchData(props.params)
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan pada saat membuat kategori.',
            });
        }
    }

    return (
        <React.Fragment>
            <Button
                className="btn-create-category"
                onClick={toggle}
            >
                Tambah Kategori
            </Button>

            <Modal
                title="Tambah Kategori"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nama Kategori"
                                rules={[{ required: true, message: 'Nama kategori wajib diisi!' }]}
                            >
                                <Input
                                    placeholder="Input nama kategori"
                                    className="form-input-category"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px" }}>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateCategory
