import {useState} from "react"

export interface IListMerchant {
    page: number
    limit: number
    records: IDetailMerchant[]
    total_record: number
}

export interface IParams {
    pageLimit: number
    pageNumber: number
    search: string
    isNewest?: boolean
}

export interface IOperationHour {
    active?: boolean
    day: number
    openHour: string | null
    closeHour: string | null
}

export interface IMerchantBank {
    bankName: string
    accountName: string
    accountNumber: string
}

export interface IDetailMerchant {
    ID: string
    Image: string
    Name: string
    Description: string
    Type: string
    Address: string
    Email: string
    PhoneNumber: string
    Latitude: number | null
    Longitude: number | null
    MaxDistance: number | null
    SharingFee: number | null
    IsSharingFeePercentage: boolean
    avgRating: number
    MerchantAccountID: string 
    tags: string[]
    IsActive: boolean
    Status: string
    CreatedAt: string
    UpdatedAt: string
    updatedBy: string
    merchantBank: IMerchantBank
    operationHour: IOperationHour[]
}

export const initialDataList = {
    page: 1, 
    limit: 10,
    records: [],
    total_record: 0
}

export const initialParams = {
    pageLimit: 10,
    pageNumber: 1,
    search: "",
    isNewest: true
}

export const initialDataOperationHour = [
    {day:0, openHour: "", closeHour: ""},
    {day:1, openHour: "", closeHour: ""},
    {day:2, openHour: "", closeHour: ""},
    {day:3, openHour: "", closeHour: ""},
    {day:4, openHour: "", closeHour: ""},
    {day:5, openHour: "", closeHour: ""},
    {day:6, openHour: "", closeHour: ""}
]

export const initialDataOperationHourDummmy = [
    {active: false, day: 0, openHour: null, closeHour: null},
    {active: false, day: 1, openHour: null, closeHour: null},
    {active: false, day: 2, openHour: null, closeHour: null},
    {active: false, day: 3, openHour: null, closeHour: null},
    {active: false, day: 4, openHour: null, closeHour: null},
    {active: false, day: 5, openHour: null, closeHour: null},
    {active: false, day: 6, openHour: null, closeHour: null},
]

export const initialDataMerchantBank = {
    bankName: "", 
    accountName: "", 
    accountNumber: ""
}

export const initialDataDetail = {
    ID: "",
    Image: "",
    Name: "",
    Description: "",
    Type: "",
    Address: "",
    Email: "",
    PhoneNumber: "",
    Latitude: null,
    Longitude: null,
    MaxDistance: null,
    SharingFee: null,
    IsSharingFeePercentage:false,
    avgRating: 0,
    MerchantAccountID: "",
    tags: [],
    IsActive: false,
    Status: "",
    CreatedAt: "",
    UpdatedAt: "",
    updatedBy: "",
    merchantBank: initialDataMerchantBank,
    operationHour: initialDataOperationHour
}


export function useModelParams() {
    const [params, setParams] = useState<IParams>(initialParams)

    return {
        params, 
        setParams
    }
}