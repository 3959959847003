import React, {useContext} from 'react'
import {TransactionJasaContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {Pagination, Row, Col} from "antd"
import FilterTransaction from "../filtertransaction/FilterTransaction"
import {FeatureButton, Search, Table, BackButton} from "../../../../../components/atoms"
import {PageLayout} from "../../../../templates"
import {Link} from "react-router-dom"

import "./list.scss"

const List: React.FC = () => {
    const context = useContext(TransactionJasaContext)
    const {
        fields,
        loading,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    } = context.useModelList()
    const {columns} = TableColumns()

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <FilterTransaction handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                rightTop={<Search onKeyPress={(e:any) => handleSubmitSearch(e)} />}
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            rowKey="id"
                            pagination={false}
                            rowClassName={(record:{status:string}, index:number) => record.status === "UNPAID" || record.status === "PAID" || record.status === "PROCESS" ? 'new-transaction' :  'read-transaction'}
                            loading={loading}
                            // scroll={{y: 550}}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/jasa">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.currentPage} 
                                pageSize={10} 
                                total={fields.totalPage * fields.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
