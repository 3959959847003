import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {TransactionPaymentContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../templates"
import {Loading, BackButton} from "../../../../components/atoms"
import {Row, Col} from "antd"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import {FormatRp, FormatDate} from "../../../../helpers/Formatter"
import {Link} from "react-router-dom"

const Detail: React.FC = () => {
    const location = useLocation()
    const payment_id = location.search.split("=")[1]
    const context = useContext(TransactionPaymentContext)
    const {
        loading,
        fields
    } = context.useModelDetail(payment_id)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Transaksi" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomor Invoice" value={fields.origin === "ipl" ? fields.invoice_ipl.invoiceNumber : fields.invoice.invoiceNumber} />
                                <ItemDetail label="Asal Transaksi" value={fields.origin} />
                                <ItemDetail label="Tanggal Transaksi" value={FormatDate(fields.transDt)} />
                                <ItemDetail label="Payment Provider" value={fields.paymentProvider} />
                                <ItemDetail label="Payment Method" value={fields.payment_method} />
                                {fields.payment_method === "va" ? <ItemDetail label="Bank" value={fields.bank} /> : null}
                                <ItemDetail label="Biaya Admin" value={FormatRp(fields.adminFee)} />
                                <ItemDetail label="Jumlah Transaksi" value={FormatRp(fields.amount)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail User" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama" value={fields.user.name} />
                                <ItemDetail label="No. Telepon" value={fields.user.phoneNumber} />
                                <ItemDetail label="Email" value={fields.user.email} />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col>
                                <Link to="/payment-transaction">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
