import React from "react";
import ReactDOM from "react-dom";
import ContextProvider from "./context/ContextProvider"
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";

ReactDOM.render(
    <ContextProvider>
        <App />
    </ContextProvider>
, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
serviceWorker.registerServiceWorkerFCM()
