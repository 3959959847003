import React, {useContext} from 'react'
import {VoucherContext} from "../context/ContextProvider"
import {Link, useLocation} from "react-router-dom"
import TableColumns from "./Columns"
import {PageLayout} from "../../../../templates"
import FilterVoucher from "../filtervoucher/FilterVoucher"
import {Search, Table, BackButton, FeatureButton} from "../../../../../components/atoms"
import {Pagination, Row, Col} from "antd"

import "./voucher.scss"

const Voucher: React.FC = () => {
    const accountID = useLocation().search.split("=")[1]
    const context = useContext(VoucherContext)
    const {
        fields,
        queryParams,
        loading,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    } = context.useModelList(accountID)
    const {columns} = TableColumns()

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search onKeyPress={(e:any) => handleSubmitSearch(e)} />}
                leftTop={
                    <div>
                        <FilterVoucher handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            rowKey="transactionID"
                            pagination={false}
                            loading={loading}
                            // scroll={{y: 550}}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/hobi">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={queryParams.pageNumber} 
                                pageSize={10} 
                                total={fields.totalData} 
                                onChange={handlePageChange} 
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default Voucher
