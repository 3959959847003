import React, {useContext, useState} from 'react'
import axios from "axios"
import {CategoryContext} from "../context/ContextProvider"
import {FeatureButton, Modal, BackButton, OkButton, Loading} from "../../../../components/atoms"
import {Form, Row, Col, Input, Switch, Upload, Spin, notification} from "antd"
import { CloseOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import {base_url} from "../../../../config/url-config"
import {uploadphoto} from "../../../../config/endpoint"
import "./createmerchantcategory.scss"

interface Props {
    fetchData:(queryparams:{}) => void,
    params:{}
}

const CreateMerchantCategory: React.FC<Props> = (props) => {
    const context = useContext(CategoryContext)
    const {
        loading,
        modal,
        fields,
        handleSubmit,
        toggleModal,
        submitPhoto,
    } = context.useModelDetail(props.fetchData, props.params)
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            submitPhoto(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload foto.',
            });
        }
    }

    return (
        <React.Fragment>
            <FeatureButton
                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                label="Tambah" 
                onClick={toggleModal}
            />
            <Modal
                title="Tambah Kategori Merchant"
                visible={modal}
                onCancel={toggleModal}
            >   
                {loading ? <Loading /> : null}
                <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={7}>Icon</Col>
                        <Col> 
                            <Form.Item
                                name="icon"
                                valuePropName="file"
                                initialValue={fields.icon}
                            >
                                <Upload 
                                    showUploadList={false} 
                                    onChange={handleUpload}
                                >
                                    <div className="upload-photo">
                                        {loadingUpload ? (
                                            <div style={{paddingTop: "25px"}}>
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                            </div>
                                        ) : (
                                            fields.icon === "" ? (
                                                <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                    <i className="far fa-image fa-6x" />
                                                </div>
                                            ) : (
                                                <img src={fields.icon} alt="merchant-hobby-logo" style={{width: "100px", height: "80px", marginTop: "10px"}} />
                                            )
                                        )}
                                    </div>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={7}>Nama Kategori</Col>
                        <Col span={17}>
                            <Form.Item
                                name="name"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama kategori" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={7}>Nama Tampil</Col>
                        <Col span={17}>
                            <Form.Item
                                name="nameShow"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama tampil" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={7}>Urutan</Col>
                        <Col span={17}>
                            <Form.Item
                                name="sort"
                                // initialValue={props.fields.venue.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input urutan" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={7}>Status</Col>
                        <Col span={17}>
                            <Form.Item
                                name="isActive"
                                // initialValue={props.fields.venue.name}
                            >   
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                            />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <BackButton onClick={toggleModal}>Batal</BackButton>
                            <OkButton>
                                Simpan
                            </OkButton>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateMerchantCategory
