import React, { useContext } from 'react'
import {FoodContext} from "../context/ContextProvider"
import { DetailPageLayout } from "../../../templates"
import { Loading } from "../../../../components/atoms"
import FormMerchant from "../form/form"
import { useQuery } from '../../../../helpers/Formatter'

const EditMerchantJasa: React.FC = () => {
    const query = useQuery();
    const context = useContext(FoodContext)
    const {
        fields,
        setFields,
        loading,
        operational,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        handleSubmit
    } = context.useModelDetail(query.get("id"))

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormMerchant
                        data={fields}
                        setFields={setFields}
                        operational={operational}
                        submitOperationHourStatus={submitOperationHourStatus}
                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                        submitOperationalHour={submitOperationalHour}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default EditMerchantJasa
