import {useState} from "react"

export interface IListShopper {
    total_record: number
    total_page: number
    records: IDetailSHopper[]
}

export interface IDetailArea {
    id: number
    name: string
}

export interface IDetailSHopper {
    id: number
    imageURL: string
    identityURL: string
    drivingLicenseURL: string
    description: string
    name: string
    phoneNumber: string
    email: string
    areaID: number | string
    area: IDetailArea
    areaName: string
    address: string
    registerDate: string
    isActive: boolean
    createdBy?: string
    updatedBy: string
    deletedBy?: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
}

export const initialDataList = {
    total_record: 0,
    total_page: 1,
    records: []
}

export const initialDataDetail = {
    id: 0,
    imageURL: "",
    identityURL: "",
    drivingLicenseURL: "",
    description: "",
    name: "",
    phoneNumber: "",
    email: "",
    areaID: "",
    area: {id: 0, name: ""},
    areaName: "",
    address: "",
    registerDate: "",
    isActive: false,
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    createdAt: "",
    updatedAt: ""
}

export function useModelDetailShopper() {
    const [fields, setFields] = useState<IDetailSHopper>(initialDataDetail)

    return {
        fields,
        setFields
    }
}

export function useModelListShopper() {
    const [fields, setFields] = useState<IListShopper>(initialDataList)

    return {
        fields,
        setFields
    }
}