import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detailpasar/DetailPasar"
import Create from "./createpasar/CreatePasar"
import Edit from "./editpasar/EditPasar"
import Item from "./item/Item"

import "./pasar.scss"

const Pasar: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/pasar" exact={true} render={() => <List />} />
            <Route path="/pasar/detail" exact={true} render={() => <Detail />} />
            <Route path="/pasar/tambah" exact={true} render={() => <Create />} />
            <Route path="/pasar/ubah" exact={true} render={() => <Edit />} />
            <Route path="/pasar/item" exact={false} render={() => <Item />} />
        </ContextProvider>
    )
}

export default Pasar
