import React, {useEffect, useContext} from 'react'
import {PaymentConfigContext} from "../context/ContextProvider"
import {IDetail} from "../PaymentConfigModel"
import {Form, Row, Col, Select, Input, Switch} from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import "./formpaymentconfig.scss"

interface Props {
    fields: IDetail
    handleDirectChange: (value:any, option:any) => void
}

const FormPaymentConfig: React.FC<Props> = (props) => {
    const context = useContext(PaymentConfigContext)
    const {
        paymentOrigins,
        apartments,
        paymentProviders,
        paymentMethods,
        banks,
        fetchpaymentorigin,
        fetchApartment,
        fetchProvider,
        fetchPaymentMethod,
        fetchBank
    } = context.useModelForm()

    useEffect(() => {
        fetchpaymentorigin()
        if (props.fields.origin === "ipl" || props.fields.origin === "merchant" || props.fields.origin === "purchase-token" || props.fields.origin === "facility-reservation") {
            fetchApartment()
        }
        if (props.fields.origin !== null) {
            fetchProvider(props.fields.origin)
        }
        if (props.fields.origin !== null && props.fields.paymentProvider !== null) {
            fetchPaymentMethod(props.fields.origin, props.fields.paymentProvider)
        }
        if (props.fields.paymentProvider === "nicepay" || props.fields.paymentProvider === "xfers") {
            fetchBank(props.fields.paymentProvider)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields])

    return (
        <React.Fragment>
            <Row className="row-form">
                <Col span={24}>
                    <Form.Item
                        name="origin"
                        label="Asal Transaksi"
                        initialValue={props.fields.origin}
                        // valuePropName="select"
                    >
                        <Select
                            placeholder="Pilih Asal Transaksi"
                            style={{width: "100%"}}
                        >
                            {paymentOrigins.map((paymentOrigin:string, key:number) => (
                                <Select.Option key={key} value={paymentOrigin}>{paymentOrigin}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {props.fields.origin === "ipl" || props.fields.origin === "merchant" || props.fields.origin === "purchase-token" || props.fields.origin === "facility-reservation" ? (
                <Row className="row-form">
                    <Col span={24}>
                        <Form.Item
                            name="apartmentID"
                            label="Apartemen"
                            initialValue={props.fields.apartmentID}
                        >
                            <Select
                                placeholder="Pilih Apartemen"
                                style={{width: "100%"}}
                                onChange={props.handleDirectChange}
                            >
                                {apartments.map((apartment:{id:string, name:string}, key:number) => (
                                    <Select.Option key={key} className="apartmentName" value={apartment.id}>{apartment.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>        
                    </Col>
                </Row>
            ) : null}
            <Row className="row-form">
                <Col span={12} style={{paddingRight: "10px"}}>
                    <Form.Item
                        name="paymentProvider"
                        label="Provider Pembayaran"
                        initialValue={props.fields.paymentProvider}
                    >
                        <Select
                            placeholder="Pilih Provider"
                            style={{width: "100%"}}
                            disabled={props.fields.origin === null ? true : false}
                        >
                            {paymentProviders.map((paymentProvider:string, key:number) => (
                                <Select.Option key={key} value={paymentProvider}>{paymentProvider}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="paymentMethodCode"
                        label="Metode Pembayaran"
                        initialValue={props.fields.paymentMethod}
                    >
                        <Select
                            placeholder="Pilih Metode"
                            style={{width: "100%"}}
                            disabled={props.fields.paymentProvider === null ? true : false}
                            onChange={props.handleDirectChange}
                        >
                            {paymentMethods.map((paymentMethod:{paymentMethodCode:string, paymentMethod:string}, key:number) => (
                                <Select.Option key={key} className="paymentMethodCode" value={paymentMethod.paymentMethodCode}>{paymentMethod.paymentMethod}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {props.fields.paymentMethod === "virtual_bank_account" || props.fields.paymentMethod === "va" ? (
                <div>
                    <Row style={{marginBottom: "8px"}}>
                        <Col>
                            Bank
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="bank"
                                initialValue={props.fields.bank !== null ? props.fields.bank.map(each => each.bankID) : []}
                            >
                                <Select
                                    mode="multiple"
                                    showArrow
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Pilih Bank"
                                    // defaultValue={['a10', 'c12']}
                                    onChange={props.handleDirectChange}
                                >
                                    {banks.map((bank:any, key:number) => (
                                        <Select.Option key={key} className={bank.code} value={bank.id}>{bank.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ) : null}
            <Row style={{marginBottom: "8px"}}>
                <Col>
                    Biaya Transaksi
                </Col>
            </Row>
            <Row className="row-form">
                <Col span={19} style={{paddingRight: "10px"}}>
                    <Form.Item
                        name="paymentValue"
                        initialValue={props.fields.paymentValue}
                    >
                        <Input 
                            type="number"
                            placeholder="Input biaya transaksi"
                            className="form-input" 
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        name="isPercentage"
                        initialValue={props.fields.isPercentage ? "true" : "false"}
                    >
                        <Select
                            style={{width: "100%"}}
                        >
                            <Select.Option value="true">%</Select.Option>
                            <Select.Option value="false">IDR</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{marginBottom: "8px"}}>
                <Col>Maksimal Transaksi</Col>
            </Row>
            <Row className="row-form">
                <Col span={4}>
                    <Form.Item
                        name="isMaxValue"
                        valuePropName= "checked"
                        initialValue={props.fields.isMaxValue}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />} 
                        />
                    </Form.Item>
                </Col>
                <Col span={15} style={{paddingRight: "10px"}}>
                    <Form.Item
                        name="maxValue"
                        initialValue={props.fields.maxValue}
                    >
                        <Input 
                            type="number"
                            placeholder="Input maksimal transaksi"
                            className="form-input" 
                            disabled={props.fields.isMaxValue ? false : true}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Input 
                        className="form-input" 
                        value="IDR"
                        disabled
                    />
                </Col>
            </Row>
            <Row style={{marginBottom: "8px"}}>
                <Col>Batas Waktu</Col>
            </Row>
            <Row style={{marginBottom: "20px"}}>
                <Col span={19} style={{paddingRight: "10px"}}>
                    <Form.Item
                        name="paymentTimeMinute"
                        initialValue={props.fields.paymentTimeMinute}
                    >
                        <Input 
                            type="number"
                            placeholder="Input batas waktu"
                            className="form-input" 
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Input 
                        className="form-input" 
                        value="Menit"
                        disabled
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default FormPaymentConfig
