import {IDetailMerchant, initialDataDetail as initialDetailMerchant} from "../MerchantHobbyModel"

export interface IListService {
    page: number
    limit: number
    records: {}[]
    total_record: number
}

export interface IParams {
    merchantID: string | null
    pageNumber: number
    pageLimit: number
    search: string
}

export interface ITimeRate {
    hour?: any
    timeStart: string
    timeEnd: string
    deposit: number | null
    rate: number | null
    discount: number | null
    isDiscPercentage: string | boolean
}

export interface IOperation {
    id?: number
    active?: boolean
    day: number
    openHour: string
    closeHour: string
    timeRate: ITimeRate[]
}

export interface IVenue {
    id?: number
    merchantID?: string | undefined | null
    name: string
    picURL: string[]
    description: string
    address: string
    phone: string
    maxBooking: any
    maxDuration: any 
    minDuration: any
    durationBfrReservation: number | null
    isActive: boolean
    isOpen: boolean
    cancelationFee: any 
    isCancelationPercentage: boolean | string
    createdAt?: string
    createBy?: string
    updatedAt?: string
    updatedBy?: string
}

export interface IDetailService {
    merchant?: IDetailMerchant
    operation: IOperation[]
    venue: IVenue
}

export const initialDataList = {
    page: 1, 
    limit: 10,
    records: [],
    total_record: 0
}

export const initialParams = {
    merchantID: "",
    pageNumber: 1,
    pageLimit: 10,
    search: ""
}

export const initialDataVenue = {
    id: 0,
    merchantID: "",
    picURL: [""],
    name: "",
    address: "",
    description: "",
    phone: "",
    maxBooking: null,
    maxDuration: null, 
    minDuration: null,
    durationBfrReservation: null,
    isActive: false,
    isOpen: false,
    cancelationFee: null,
    isCancelationPercentage: "",
    createdAt: "",
    createBy: "",
    updatedAt: "",
    updatedBy: ""
}

export const initialDataDetail = {
    merchant: initialDetailMerchant,
    operation: [],
    venue: initialDataVenue
}

export const initialDataOperations = [
    {active:false, day: 0, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
    {active:false, day: 1, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
    {active:false, day: 2, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
    {active:false, day: 3, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
    {active:false, day: 4, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
    {active:false, day: 5, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
    {active:false, day: 6, openHour:"00:00", closeHour:"00:00", timeRate: [
        {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
    ]},
]