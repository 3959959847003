import React, {createContext} from "react"
import {useModelList} from "./ListContext"
import {useModelDetail} from "./DetailContext"
import {useModelListAssignShopper} from "./AssignShopperContext"
import {useModelRefund} from "./RefundContext"

export const PasarTransactionContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList,
        useModelDetail,
        useModelListAssignShopper,
        useModelRefund
    }

    return (
        <PasarTransactionContext.Provider value={context}>
            {props.children}
        </PasarTransactionContext.Provider>
    )
}

export default ContextProvider