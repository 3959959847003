import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {notification, Row, Col} from "antd"
import {DetailPageLayout} from "../../../templates"
import {CancelButton, Loading} from "../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import {FormatRp, FormatDate} from "../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {BackButton, OkButton} from "../../../../components/atoms"

import "./detailtransactionhobby.scss"
import { bookinghobby } from '../../../../config/endpoint'

const DetailTransactionHobby: React.FC = () => {
    const location = useLocation()
    const bookingId = location.search.split("=")[1]
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        booking:{
            ID:string,
            invoiceNumber:string,
            CreatedAt:string,
            bookingState:string,
            bookingDate:string,
            bookingEndDate:string,
            duration:number,
            userName:string,
            totalPrice:number,
            totalPriceDetail:{
                depositUsed:number,
                rates:{
                    rate:{
                        timeStart:string,
                        timeEnd:string,
                        rate:number,
                        discount:number,
                        isDiscPercentage:boolean
                    },
                    totalHour:number
                }[],
                totalPrice:number
            }
            userEmail:string,
            userPhone:string
        },
        merchant:{},
        package:{},
        payment:{
            adminFee:number,
            totalAmount:number
        },
        rate:{},
        venue:{
            name:string,
            address:string
        }
    }>({
        booking:{
            ID: "",
            invoiceNumber: "",
            CreatedAt: "",
            bookingState: "",
            bookingDate: "",
            bookingEndDate: "",
            duration: 0,
            userName: "",
            totalPrice: 0,
            totalPriceDetail: {
                depositUsed:0,
                rates: [],
                totalPrice:0
            },
            userEmail: "",
            userPhone: ""
        },
        merchant:{},
        package:{},
        payment:{
            adminFee: 0,
            totalAmount:0
        },
        rate:{},
        venue:{
            name: "",
            address: ""
        }
    })

    const fetchData = async(id:string) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${bookinghobby}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading(false)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(bookingId)
    }, [bookingId])

    const handleAccept = () => {
        if (fields.booking.bookingState === "REQUESTED") {
            handleUpdateTransaction("WAITING-PAYMENT")
        } else if (fields.booking.bookingState === "RESCHEDULE-REQUESTED") {
            handleUpdateTransaction("BOOKED")
        }
    }

    const handleReject = () => {
        handleUpdateTransaction("REJECTED")
    }

    const handleUpdateTransaction = async(status:string) => {
        const value = {id: fields.booking.ID, bookingState: status}
        setLoading(true)
        try {
            const res = await axios.patch(`${base_url}/${bookinghobby}`, value, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            // console.log(res);
            if (res.status === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                      'Memperbaharui status transaksi berhasil.',
                });
                setLoading(false)
                fetchData(bookingId)
            }
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    return (
        <React.Fragment>
             <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        {fields.booking.bookingState === "REQUESTED" || fields.booking.bookingState === "RESCHEDULE-REQUESTED" ? (
                            <Row>
                                <Col span={24}>
                                    <OkButton 
                                        onClick={handleAccept}
                                    >
                                        Terima
                                    </OkButton>
                                    <CancelButton label="Tolak" onClick={handleReject} />
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Booking" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomer Booking" value={fields.booking.invoiceNumber === "" ? "-" : fields.booking.invoiceNumber} />
                                <ItemDetail label="Tanggal Order" value={FormatDate(fields.booking.CreatedAt)} />
                                <ItemDetail label="Status Booking" value={fields.booking.bookingState} />
                                <ItemDetail label="Waktu Mulai Booking" value={FormatDate(fields.booking.bookingDate)} />
                                <ItemDetail label="Waktu Berakhir Booking" value={FormatDate(fields.booking.bookingEndDate)} />
                                <ItemDetail label="Durasi Booking" value={`${fields.booking.duration/3600} Jam`} />
                                <ItemDetail label="User" value={fields.booking.userName === "" ? "-" : fields.booking.userName} />
                                <ItemDetail label="User Email" value={fields.booking.userEmail === "" ? "-" : fields.booking.userEmail} />
                                <ItemDetail label="Telepon User" value={fields.booking.userPhone === "" ? "-" : fields.booking.userPhone} />
                                <ItemDetail label="Nama Venue" value={fields.venue.name} />
                                <ItemDetail label="Alamat Venue" value={fields.venue.address} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Rates" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Periode</Col>
                                            <Col span={6}>Harga Per Jam</Col>
                                            <Col span={3}>Durasi</Col>
                                            <Col span={5}>Diskon</Col>
                                            <Col span={5}><div style={{float: "right"}}>Total Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.booking.totalPriceDetail.rates.map((rate, key) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{border: "1px solid black"}}>
                                                <Col span={5}>{rate.rate.timeStart} - {rate.rate.timeEnd}</Col>
                                                <Col span={6}>{FormatRp(rate.rate.rate)}</Col>
                                                <Col span={3}>{rate.totalHour} Jam</Col>
                                                <Col span={5}>{rate.rate.isDiscPercentage ? `${rate.rate.discount}%` : FormatRp(rate.rate.discount)}</Col>
                                                <Col span={5}><div style={{float: "right"}}>{FormatRp((rate.rate.rate - (rate.rate.isDiscPercentage ? rate.rate.discount * rate.rate.rate / 100 : rate.rate.discount)) * rate.totalHour)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Deposit" value={<div style={{textAlign:"right"}}>{FormatRp(fields.booking.totalPriceDetail.depositUsed)}</div>} />
                                <ItemDetail label="Total" value={<div style={{textAlign:"right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.booking.totalPriceDetail.totalPrice)}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Pembayaran" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Total Pesanan" value={<div style={{textAlign: "right"}}>{FormatRp(fields.booking.totalPrice)}</div>} />
                                <ItemDetail label="Biaya Admin" value={<div style={{textAlign: "right"}}>{FormatRp(fields.payment.adminFee)}</div>} />
                                <ItemDetail label="Total Bayar" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.payment.totalAmount)}</div>} />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                <Link to="/booking">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailTransactionHobby
