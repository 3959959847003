import React, {useState, useEffect} from 'react'
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Row, Col, Select} from "antd"

import "./filter.scss"

interface Props {
    handleParams: () => void
    biller: string[]
    category: string[]
    handleFilter: (values:{}) => void
}

const Filter: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    useEffect(() => {
        modal && props.handleParams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    const handleSubmit = (values:{}) => {
        toggle()
        props.handleFilter(values)
    }

    return (
        <React.Fragment>
            <FeatureButton
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                title="Form Filter"
                onCancel={toggle}
                visible={modal}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label="Kategori"
                            >
                                <Select placeholder="Pilih kategori" style={{ width: "100%" }}>
                                    {props.category.map((each, key) => (
                                        <Select.Option key={key} value={each}>{each}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="biller"
                                label="Biller"
                            >
                                <Select placeholder="Pilih biller" style={{ width: "100%" }}>
                                    {props.biller.map((each, key) => (
                                        <Select.Option key={key} value={each}>{each}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="productStatus"
                                label="Status"
                            >
                                <Select placeholder="Pilih status" style={{ width: "100%" }}>
                                    <Select.Option value="true">Aktif</Select.Option>
                                    <Select.Option value="false">Tidak Aktif</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
