import React, {useState} from 'react'
import {IDetail} from "../AyopopModel"
import {Modal, UploadPhoto, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Row, Col, Input} from "antd"

interface Props {
    data: IDetail
    handleUpdate: (values:{id: string; isActive: boolean; logo: string; margin: number; }) => void
}

const Edit: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>(props.data)

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    const handleSubmitLogo = (url:string) => {
        setFields({...fields, logo: url})
    }

    const handleSubmit = (values:any) => {
        props.handleUpdate({id: fields.id, isActive: fields.isActive, logo: fields.logo, margin: parseInt(values.margin)})
        toggle()
    }

    return (
        <React.Fragment>
            <div 
                className="button-action" 
                style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}
                onClick={toggle}
            >
                <i className="fas fa-edit" />
            </div>

            <Modal 
                title="Form Edit"
                onCancel={toggle}
                visible={modal}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="logo"
                                initialValue={props.data.logo}
                            >
                                <UploadPhoto 
                                    featureName="ayopop"
                                    getLink={handleSubmitLogo}
                                    imageURL={fields.logo}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="code"
                                label="Kode Produk"
                                initialValue={fields.products.code}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input kode produk"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nama Produk"
                                initialValue={fields.products.name}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama produk"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label="Kategori Produk"
                                initialValue={fields.products.category}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input kategori produk"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="biller"
                                label="Biller"
                                initialValue={fields.products.biller}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input biller"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="margin"
                                label="Margin"
                                initialValue={fields.margin}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input margin"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Edit
