import React, {useState} from 'react'
import {Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Select, Row, Col, Button, Input} from "antd"

interface Props {
    handleSubmit:(values:{}) => void
}

const CreateLayanan: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal) 
    }

    const handleSubmit = async(values:{}) => {
        console.log(values);
        props.handleSubmit(values)
        toggle()
        form.resetFields()
    }


    return (
        <React.Fragment>
            <Button 
                className="btn-create-category"
                onClick={toggle}
            >
                Tambah Layanan
            </Button>

            <Modal
                title="Tambah Layanan"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Layanan"
                                rules={[{ required: true, message: 'Nama Layanan wajib diisi!' }]}
                            >
                                <Input 
                                    placeholder="Input nama layanan" 
                                    className="form-input-category"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                hidden
                                label="Kategori"
                                name="is_category"
                                initialValue="1"
                                rules={[{ required: true, message: 'Kategori wajib dipilih!' }]}
                            >
                                <Select placeholder="Pilih">
                                    <Select.Option value="1">Ya</Select.Option>
                                    <Select.Option value="0">Tidak</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="button-section-filter-merchant">
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateLayanan
