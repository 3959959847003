import React, { useEffect } from 'react';
import { useInvoice } from '../../../context/context-invoice';
import InvoiceShopper from './invoice-shopper';
import InvoiceUser from './invoice-user';
import './invoice.scss';

interface IProps { }

const Invoice: React.FC<IProps> = (props) => {
    const CtxInvoice = useInvoice();
    useEffect(() => {
        if (CtxInvoice.invoice.isReady) {
            window.print();
        }

        window.onafterprint = function () {
            CtxInvoice.setInvoice({ ...CtxInvoice.invoice.data, isReady: false });
            CtxInvoice.setBulkInvoice({ data: [], isReady: false });
        }

    }, [CtxInvoice]);

    if (!CtxInvoice.invoice.isReady) {
        return null;
    }

    return (
        <div className="invoice">
            <InvoiceShopper data={CtxInvoice.invoice.data} />
            <InvoiceUser data={CtxInvoice.invoice.data} />
        </div>
    );
}

export default Invoice;