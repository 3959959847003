import React, {useState} from 'react'
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {uploadphoto, merchantcategory} from "../../../../config/endpoint"
import {notification, Form, Row, Col, Upload, Spin, Input, Button, Select, Switch} from "antd"
import { LoadingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom"
import Tag from "./tag/Tag"
import FormBankAccount from "./formbankaccount/FormBankAccount"
import FormOperationalHour from "./formoperationalhour/FormOperationalHour"
import FormProcessSchedule from "./formprocessschedule/FormProcessSchedule"
import {BackButton, OkButton} from "../../../../components/atoms"

import "./formmerchantjasa.scss"
import { validatePriority } from '../helper/merchantjasa'
 
interface Props {
    data:{
        image:string,
        name:string,
        description:string,
        tags:string[],
        maxDistance:number|null,
        merchantFee:number|null,
        merchantFeeType:string,
        address:string,
        email:string,
        phone:string,
        latitude:string,
        longitude:string,
        isActive:boolean,
        status:string,
        priority:number,
        categories:any,
        bankDetail: {
            accountName:string,
            accountNumber:string,
            bankName:string
        }
    },
    submitPhoto: (path:string) => void
    newTag:string
    handleDeleteTag: (tag:string) => void,
    handleEditTag: (tag:string, index:number) => void,
    handleChangeTag: (e:any) => void,
    submitNewTag: () => void,
    operational: {
        active?:boolean, 
        day:number, 
        open:string|null, 
        close:null|string
    }[],
    schedule:any,
    submitBank: (values:{bankName: string, accountName: string, accountNumber: string}) => void,
    submitOperationHourStatus: (e:any, index:number) => void,
    submitOperationHourOpenCLose: (time:any, timeString:any, index:number) => void,
    submitOperationalHour: () => void,
    submitChangeProcessSchedule: (time:any, timeString:any, key:number) => void,
    submitAddProcessSchedule: () => void,
    submitDeleteProcessSchedule: (key:number) => void,
    submitProcessSchedule: () => void,
    handleSubmit: (values:{
        image:string, 
        isActive:boolean,
        status:any,
        tags:string[], 
        operational:{},
        merchantFee:any,
        maxDistance:any,
        latitude:any,
        longitude:any,
        bankDetail:{},
        type:string
    }) => void,
    paramMerchantId: string,
    fieldsList:any,
}

const FormMerchantJasa: React.FC<Props> = (props) => {
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [category, setCategory] = useState([]);

    React.useEffect(() => {
        getCategory()
    }, [])

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            props.submitPhoto(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload foto merchant.',
            });
        }
    }

    const getCategory = async () => {
        try {
            const url = `${base_url}/${merchantcategory}`

            const res = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {
                    sortBy: "sort",
                    sortType: "ASC",
                    limit: 100
                }
            })
            const response = {
                currentPage: res.data?.payload?.currentPage,
                limit: res.data?.payload?.limit,
                totalPage: res.data?.payload?.totalPage,
                payload: res.data?.payload?.data
            }
            setCategory(response.payload)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat mengakses data kategori',
                placement:"topRight"
            });
        }
    }

    return (
        <React.Fragment>
            <Form 
                onFinish={props.handleSubmit}
            >
                <Row>
                    <Col span={12} className="left-section">
                        <Row className="row-edit-hobby">
                            <Col span={7}>Foto Merchant</Col>
                            <Col>
                                <Form.Item
                                    name="image"
                                    valuePropName="file"
                                    initialValue={props.data.image}
                                >
                                    <Upload 
                                        showUploadList={false} 
                                        onChange={handleUpload}
                                    >
                                        <div className="upload-photo">
                                            {loadingUpload ? (
                                                <div style={{paddingTop: "25px"}}>
                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                                </div>
                                            ) : (
                                                props.data.image === "" ? (
                                                    <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                        <i className="far fa-image fa-6x" />
                                                    </div>
                                                ) : (
                                                    <img src={props.data.image} alt="merchant-hobby-logo" style={{width: "100px", height: "80px", marginTop: "10px"}} />
                                                )
                                            )}
                                        </div>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Nama Merchant</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="name"
                                    initialValue={props.data.name}
                                    rules={[{ required: true, message: 'Nama merchant wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input nama merchant"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Kategori</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="categories"
                                    rules={[{ required: true, message: 'Kategori wajib dipilih!' }]}
                                    initialValue={props.data?.categories?.map((ele:any) => {
                                        return ele.id
                                    })}
                                >
                                     <Select
                                        placeholder="Pilih Kategori"
                                        mode="multiple"
                                        allowClear
                                    > 
                                        {category.map((ele:any, key:number) => (
                                            <Select.Option key={key} value={ele.id}>{ele.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Deskripsi Merchant</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="description"
                                    initialValue={props.data.description}
                                    rules={[{ required: true, message: 'Deskripsi merchant wajib diisi!' }]}
                                >
                                    <Input.TextArea 
                                        rows={3} 
                                        className="form-input" 
                                        placeholder="Input deskripsi merchant"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Tags</Col>
                            <Col span={14}>
                                <Row style={{paddingBottom: "5px", paddingLeft: "2px"}}>
                                    {props.data.tags.map((tag:string, key:number) => (
                                        <Col span={12} key={key} style={{paddingRight: "8px", paddingBottom: "10px"}}>
                                            <Tag
                                                index={key}
                                                tag={tag}
                                                handleEditTag={props.handleEditTag}
                                                handleDeleteTag={props.handleDeleteTag}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                                <Row>
                                    <Col span={19}>
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input tag baru" 
                                            onChange={props.handleChangeTag} 
                                            value={props.newTag}
                                        />
                                    </Col>
                                    <Col span={5} style={{paddingLeft: "12px"}}>
                                        <Button
                                            className="button-save-edit" 
                                            style={{width: "100%"}} 
                                            onClick={props.submitNewTag}
                                        >
                                            +
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Jarak Maksimal</Col>
                            <Col span={10}>
                                <Form.Item
                                    name="maxDistance"
                                    initialValue={props.data.maxDistance}
                                    rules={[
                                        { required: true, message: 'Jarak maksimal wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Jarak yang anda input melebihi batas!'}
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input jarak maksimal"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{paddingLeft: "10px"}}>
                                <Input 
                                    className="form-input"
                                    value="Km"
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Sharing Fee</Col>
                            <Col span={10}>
                                <Form.Item
                                    name="merchantFee"
                                    initialValue={props.data.merchantFee}
                                    rules={[
                                        { required: true, message: 'Sharing fee wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Sharing fee yang anda input melebihi batas!'}
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input sharing fee"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{paddingLeft: "10px"}}>
                                <Form.Item
                                    name="merchantFeeType"
                                    initialValue={props.data.merchantFeeType}
                                    rules={[{ required: true, message: 'Satuan sharing fee wajib dipilih!' }]}
                                >
                                    <Select>
                                        <Select.Option value="PERCENTAGE">%</Select.Option>
                                        <Select.Option value="FIXED">idr</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Alamat</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="address"
                                    initialValue={props.data.address}
                                    rules={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                >
                                    <Input.TextArea 
                                        rows={2} 
                                        className="form-input" 
                                        placeholder="Input alamat merchant"
                                        value={props.data.address}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Email</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="email"
                                    initialValue={props.data.email}
                                    rules={[
                                        { required: true, message: 'Email merchant wajib diisi!' },
                                        { type: "email", message: 'Format email yang kamu isi salah!' },
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input email merchant"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>No. Telepon</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="phone"
                                    initialValue={props.data.phone}
                                    rules={[
                                        { required: true, message: 'Nomor Telepon merchant wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input nomor telepon merchant"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>  
                    </Col>
                    <Col span={12} className="right-section">
                        <Row className="row-edit-hobby">
                            <Col span={7}>Latitude</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="latitude"
                                    initialValue={props.data.latitude}
                                    rules={[{ required: true, message: 'Latitude wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input latitude"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Longitude</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="longitude"
                                    initialValue={props.data.longitude}
                                    rules={[{ required: true, message: 'Longitude wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input jarak longitude"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Jam Operasional</Col>
                            <Col span={12}>
                                <FormOperationalHour 
                                    data={props.operational}
                                    submitOperationHourStatus={props.submitOperationHourStatus}
                                    submitOperationHourOpenCLose={props.submitOperationHourOpenCLose}
                                    submitOperationalHour={props.submitOperationalHour}
                                />
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Info Akun Bank</Col>
                            <Col span={12}>
                                <FormBankAccount
                                    data={props.data.bankDetail}
                                    submitBank={props.submitBank}
                                />
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Jam Pengerjaan</Col>
                            <Col span={12}>
                                <FormProcessSchedule 
                                    dataOperational={props.operational}
                                    data={props.schedule}
                                    submitChangeProcessSchedule={props.submitChangeProcessSchedule}
                                    submitAddProcessSchedule={props.submitAddProcessSchedule}
                                    submitDeleteProcessSchedule={props.submitDeleteProcessSchedule}
                                    submitProcessSchedule={props.submitProcessSchedule}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7}>Status Aktif</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="isActive"
                                    valuePropName= "checked"
                                    initialValue={props.data.isActive}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        // defaultChecked={data.IsActive} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7}>Buka/Tutup</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="status"
                                    valuePropName= "checked"
                                    initialValue={props.data.status === "OPEN" ? true : false}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />} 
                                        // defaultChecked={data.Status === "OPEN" ? true : false} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Prioritas</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="priority"
                                    initialValue={props.data.priority}
                                    rules={[
                                        { required: true, message: 'Prioritas wajib diisi!' },
                                        { validator: (_, value) => validatePriority(props.fieldsList, value, props.paramMerchantId) ? Promise.reject('Nomor Priority telah dipakai') : Promise.resolve() }
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input Prioritas"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{paddingTop: "20px"}}>
                    <Col span={24}>
                        <Form.Item>
                            <OkButton>Simpan</OkButton>
                            <Link to="/jasa">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Form.Item>
                    </Col>
                </Row> 
            </Form>
        </React.Fragment>
    )
}

export default FormMerchantJasa
