import {useState, useEffect} from "react"
import {useHistory} from "react-router"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itempasar} from "../../../../../config/endpoint"
import {IDetailItem, initialDataDetailItem} from "../ModelItem"
import {notification} from "antd"

export function useModelDetail(id:number | null = null) {
    const history = useHistory()
    const [fields, setFields] = useState<IDetailItem>(initialDataDetailItem)
    const [loading, setLoading] = useState<string>("")
    const [newTag, setNewTag] = useState<string>("")
    const [categoryValue, setCategoryValue] = useState<number[]>([])
    
    const fetchData = async() => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${itempasar}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setFields(res.data.payload)
            const temporaryCategory:number[] = []
            res.data.payload.itemCategory.map((category:{categoryID:number}, key:number) => {
                return temporaryCategory.push(category.categoryID)
            })
            setCategoryValue(temporaryCategory)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data pasar',
                placement:"topRight"
            });
            history.goBack()
            setLoading("")
        }
    }

    useEffect(() => {
        id !== null && fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSetImage = (url:string) => {
        setFields({...fields, imageURL:[url]})
    }

    const handleDeleteTag = (tag:string) => {
        const newarr = fields.tags.filter(oldtag => oldtag !== tag)
        setFields({...fields, tags: newarr})
    }

    const handleEditTag = (tag:string, index:number) => {
        const newarr = [...fields.tags]
        newarr[index] = tag
        setFields({...fields, tags: newarr})
    }

    const handleChangeTag = (e:any) => {
        setNewTag(e.target.value)
    }

    const submitNewTag = () => {
        if (newTag !== "") {
            const newarr = [...fields.tags, newTag]
            setFields({...fields, tags: newarr})
            setNewTag("")
        }
    }

    const handleSubmit = async(values:IDetailItem) => {
        const marketId:any = localStorage.getItem("marketID")
        values["marketID"] = parseInt(marketId)
        values["imageURL"] = fields.imageURL
        values["isDiscountPercentage"] = values.isDiscountPercentage === "true" ? true : false
        values["price"] = parseInt(values.price)
        values["discount"] = parseInt(values.discount)
        values["tags"] = fields.tags
        const newCategory = values.itemCategory.map(category => ({categoryID: category, marketID: parseInt(marketId)}))
        const newValues = {...values, itemCategory: newCategory}

        try {
            id !== null ? (
                await axios.put(`${base_url}/${itempasar}/${id}`, newValues, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    },
                })
            ) : (
                await axios.post(`${base_url}/${itempasar}`, newValues, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    },
                })
            )
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Data pasar berhasil ${id !== null ? "diperbaharui" : "dibuat"}.`,
            });
            setLoading("")
            history.goBack()
        } catch(error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  `Terjadi kesalahan pada saat ${id !== null ? "memperbaharui" : "membuat"} data pasar.`,
            });
            setLoading("")
        }
    }

    return {
        fields,
        setFields,
        categoryValue,
        fetchData,
        loading,
        handleSetImage,
        newTag,
        handleDeleteTag,
        handleEditTag,
        handleChangeTag,
        submitNewTag,
        handleSubmit
    }
}