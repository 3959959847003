import React, {createContext} from "react"
import {useModelList} from "./ContextList"
// import {useModelDetail} from "./ContextDetail"

export const BannerConfigContext = createContext<any>(null)

const ContextProvider:React.FC<{children:any}> = (props) => {
    const context = {
        useModelList,
        // useModelDetail
    }

    return (
        <BannerConfigContext.Provider value={context}>
            {props.children}
        </BannerConfigContext.Provider>
    )
}

export default ContextProvider