import React, { useContext } from 'react'
import {ItemContext} from "../context/ContextProvider"
import { DetailPageLayout } from "../../../../templates"
import { Loading } from "../../../../../components/atoms"
import FormItem from "../formitem/FormItem"

import "./createitem.scss"
import { useQuery } from '../../../../../helpers/Formatter'

const CreateItem: React.FC = () => {
    const query = useQuery();
    const context = useContext(ItemContext)
    const {
        fields,
        setFields,
        category,
        loading,
        submitPhoto,
        handleSubmit
    } = context.useModelDetail(null, query.get("id"))

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormItem
                        data={fields}
                        setData={setFields}
                        category={category}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default CreateItem
