import React, {createContext} from 'react'
import {useModelList} from "./ListContext"

export const CategoryContext = createContext<any>(null)

const ContextProvider: React.FC = (props) => {
    const context = {
        useModelList
    }

    return (
        <CategoryContext.Provider value={context}>
            {props.children}
        </CategoryContext.Provider>
    )
}

export default ContextProvider
