export const validatePriority = (listData:[], currentPritotiy:string, paramMerchantId:string) => {
  const isValid = listData.some((val:{priority:number, id:string}) => val.priority === parseInt(currentPritotiy, 10) && val.id !== paramMerchantId);

  return isValid;
}

export const defaultMinutesDisabled = [
  0,1,2,3,4,5,6,7,8,9,
  10,11,12,13,14,15,16,17,18,19,20,
  21,22,23,24,25,26,27,28,29,30,31,
  32,33,34,35,36,37,38,39,30,31,32,
  33,34,35,36,37,38,39,40,41,42,43,
  44,45,46,47,48,49,50,51,52,53,54,
  55,56,57,58,59
]

//dummy const id for construct post data schedule
export const operationalEnum:number[] = [5,1,6,0,4,2,3];