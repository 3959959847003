import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {account, merchanthobby} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelList(merchantId:string) {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        currentPage: number,
        limit: number,
        totalPage: number,
        payload: {}[]
    }>({
        currentPage: 1,
        limit: 10,
        totalPage: 1,
        payload: []
    })
    const [params, setParams] = useState<{
        entityID: string | null,
        page: number,
        limit: number,
        q: string
    }>({
        entityID: localStorage.getItem("merchantId"),
        page: 1,
        limit: 10,
        q: ""
    })
    const [merchant, setMerchant] = useState<{
        Name: string,
        ID: string
    }>({
        ID: "",
        Name: ""
    })
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async (queryparams: {}, merchantId: string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${account}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            fetchMerchant(merchantId)
            setFields(res.data)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data akun',
                placement: "topRight"
            });
            setLoading(false)
        }
    }

    const fetchMerchant = async (merchantId: string) => {
        try {
            const url = `${base_url}/${merchanthobby}/${merchantId}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setMerchant({ Name: res.data.payload.Name, ID: res.data.payload.ID })
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData(params, merchantId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, merchantId])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            setParams({ ...params, q: e.target.value })
        }
    }

    const handlePageChange = (page: number, pageSize: number | undefined) => {
        setParams({ ...params, page: page })
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleDelete = async (userId: string) => {
        toggle()
        try {
            const url = `${base_url}/${account}/${userId}/role`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.delete(url, {
                headers: headers,
                params: { entityID: localStorage.getItem("merchantId") }
            })
            notification.success({
                message: 'Yuhuu!',
                description: 'Menghapus data akun berhasil!',
                placement: "topRight"
            });
            fetchData(params, merchantId)
        } catch (error) {
            notification.error({
                message: "Ups!",
                description:
                    'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
            fetchData(params, merchantId)
        }
    }

    return {
        loading,
        fields,
        params,
        merchant,
        visible,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        toggle,
        handleDelete
    }
}