import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {HobbyContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../templates"
import {Loading, BackButton} from "../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../components/molecules"
import {Row, Col} from "antd"
import {FormatDate, FormatRp} from "../../../../helpers/Formatter"
import {IOperationHour} from "../MerchantHobbyModel"
import {Link} from "react-router-dom"

import "./detailmerchanthobby.scss"

const DetailMerchantHobby: React.FC = () => {
    const location = useLocation()
    const merchantId = location.search.split("=")[1]
    const context = useContext(HobbyContext)
    const {
        fields,
        loading
    } = context.useModelDetail(merchantId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.Image} alt="merchant-pict" className="merchant-photo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Merchant" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Merchant" value={fields.Name} />
                                <ItemDetail label="Deskripsi Merchant" value={fields.Description} />
                                <ItemDetail label="Tipe Merchant" value={fields.Type} />
                                <ItemDetail label="Alamat" value={fields.Address} />
                                <ItemDetail label="Email" value={fields.Email} />
                                <ItemDetail label="No. Telepon" value={fields.PhoneNumber} />
                                <ItemDetail label="Latitude" value={fields.Latitude} />
                                <ItemDetail label="Longitude" value={fields.Longitude} />
                                <ItemDetail label="Jarak Maksimal" value={`${fields.MaxDistance} meter`} />
                                <ItemDetail label="Sharing Fee" value={<div style={{textAlign: "right"}}>{fields.IsSharingFeePercentage ? `${(fields.SharingFee)}%` : `${FormatRp(fields.SharingFee)}`}</div>} />
                                <ItemDetail label="Rating" value={fields.avgRating} />
                                <ItemDetail 
                                    label="Tags" 
                                    value={
                                        <Row>
                                            {fields.tags.map((tag:string, key:number) => (
                                                <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                    <div className="tag">
                                                        {tag}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    } 
                                />
                                <ItemDetail label="Tanggal Dibuat" value={FormatDate(fields.CreatedAt)} />
                                <ItemDetail label="Tanggal Diubah" value={FormatDate(fields.UpdatedAt)} />
                                <ItemDetail label="Diubah Oleh" value={fields.updatedBy === "" ? "-" : fields.updatedBy} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Info Akun Bank" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Bank" value={fields.merchantBank.bankName} />
                                <ItemDetail label="Nama Akun" value={fields.merchantBank.accountName} />
                                <ItemDetail label="Nomor Akun" value={fields.merchantBank.accountNumber} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Jam Operasional" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={14}>
                                        <Row className="custom-table-head-container">
                                            <Col span={8}>Hari</Col>
                                            <Col span={11}>Jam Buka</Col>
                                            <Col span={5}>Jam Tutup</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {fields.operationHour.map((operationhour:IOperationHour, key:number) => (
                                    <Row className="custom-table-body-section" key={key}>
                                        <Col span={14}>
                                            <Row className="custom-table-body-container">
                                                <Col span={8}>{operationhour.day === 0 ? "Minggu" : operationhour.day === 1 ? "Senin" : operationhour.day === 2 ? "Selasa" : operationhour.day === 3 ? "Rabu" : operationhour.day === 4 ? "Kamis" : operationhour.day === 5 ? "Jumat" : operationhour.day === 6 ? "Sabtu" : "-" }</Col>
                                                <Col span={11}>
                                                    {operationhour.openHour}
                                                </Col>
                                                <Col span={5}>
                                                    {operationhour.closeHour}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col>
                                <Link to="/hobi">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailMerchantHobby
