import React from 'react'
import {Pagination} from 'antd'
import {PageLayout} from '../../../templates'
import {Table} from '../../../../components/atoms'

const Transaksi: React.FC = () => {
    const columns = [
        {
            title: "User",
        },
        {
            title: "Terpakai",
        },
        {
            title: "Maksimal Pemakaian",
        },
        {
            title: "Waktu Pakai",
        },
        {
            title: "Nomor Tranksasi",
        },
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <h2>MMOXKKKS2</h2>
                    </div>
                }
                content={
                    <div>
                        <Table
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            pageSize={5}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default Transaksi