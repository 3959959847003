import { useState } from 'react';

export interface IPagination {
    page: number;
    limit: number;
    totalPage: number;
    totalData: number;
}

export interface IModelData {
    pagination: IPagination,
    data: {}[]
}

export interface IModelItem {
    categories?: ICategories[];
    description?: string;
    discount?: any;
    discountIsInPercentage: boolean;
    harga?: any;
    isPromo?: boolean;
    is_order_fee_percentage: boolean;
    is_sharing_fee_percentage: boolean;
    merchant_id?: string | null | undefined;
    name: string;
    order_fee: number;
    sharing_fee: number;
    status: boolean;
    tags: string[];
    urlphoto: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface ICategories {
    id: string;
    name: string;
}

export const initialItem = {
    categories: [],
    description: "",
    discount: null,
    discountIsInPercentage: false,
    harga: null,
    isPromo: false,
    is_order_fee_percentage: false,
    is_sharing_fee_percentage: true,
    merchant_id: "",
    name: "",
    order_fee: 0,
    sharing_fee: 0,
    status: false,
    createdAt: "",
    updatedAt: "",
    tags: [],
    urlphoto: ""
}

export function useModelMerchant() {
    const [fields, setFields] = useState<IModelItem>(initialItem);

    return {
        fields,
        setFields,
    }
}