import {useState, useEffect, useContext} from "react"
import {useLocation} from "react-router"
import {useQuery, useQueryParams} from "../../../../../helpers/Formatter"
import {ContextStore} from "../../../../../context/context-store"
import {IListTransaction, initialDataList} from "../ModelTransaction"
import {IListPasar, initialDataList as initialDataPasar} from "../../ModelPasar"
import axios from "axios"
import moment from "moment"
import {base_url} from "../../../../../config/url-config"
import {transaksipasar, listpasar} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelList() {
    const location = useLocation()
    const query = useQuery()
    const qp = useQueryParams();
    var today = new Date();
    const {notif} = useContext(ContextStore)
    const [fields, setFields] = useState<IListTransaction>(initialDataList)
    const [loading, setLoading] = useState<string>("")
    const [params, setParams] = useState<{
        // pageLimit: number
        pageNumber: any
        search:string
        isNewest: boolean
        isSuperAdmin: boolean
        status: string
        startDate: string
        endDate: string
    }>({
        // pageLimit: qp.value.pageLimit === undefined ? 10 : qp.value.pageLimit,
        pageNumber: query.get("page"),
        search: "",
        isNewest: true,
        isSuperAdmin: true,
        status: "PAID,APPROVED,PROCESS,SEND",
        startDate: "2020-01-01",
        endDate: today.getFullYear()+'-'+(today.getMonth()+1 < 10 ? `0${today.getMonth()+1}` : today.getMonth())+'-'+(today.getDate() < 10 ? `0${today.getDate()}` : today.getDate())+" 15:00:00"
    })
    const [pasar, setPasar] = useState<IListPasar>(initialDataPasar)

    const fetchData = async(params:{}) => {
        console.log("fetchData running");
        
        setLoading("fetch")
        try {
            const url = `${base_url}/${transaksipasar}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {
                    ...params,
                    ...qp.value
                }
            })
            setLoading("")
            setFields(res.data.payload)
        } catch (error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
        }
    }

    const fetchPasar = async() => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${listpasar}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {isAll: true}
            })
            setPasar(res.data.payload)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data pasar',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        fetchPasar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, notif, location.search])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            qp.queryParams.delete('pageNumber');
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
        }
    }

    const handleFilter = (values:any) => {
        qp.queryParams.delete("pageNumber");

        for (var key in values) {

            if (values[key] !== undefined && values[key] !== null) {
                if (key === "createdAt") {
                    qp.queryParams.set('startDate', moment(values.createdAt[0]).format("YYYY-MM-DD"));
                    qp.queryParams.set('endDate', moment(values.createdAt[1]).format("YYYY-MM-DD"));
                } else {
                    qp.queryParams.set(key, values[key]);
                }
            } else if (key === 'createdAt') {
                qp.queryParams.delete('startDate')
                qp.queryParams.delete('endDate')
            } else {
                qp.queryParams.delete(key)

            }
        }
        qp.handleQueryParams()
        setParams({...params, pageNumber: 1})
    }

    const handleReset = () => {
        qp.reset();
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        qp.queryParams.set('pageNumber', page.toString());
        qp.handleQueryParams()
    }

    function onShowSizeChange(current:any, pageSize:any) {
        // console.log(current, pageSize);
        qp.queryParams.set('pageLimit', pageSize)
    }

    const handleUpdateStatus = async(id:string, status:string) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${transaksipasar}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.put(url, {status: status}, {
                headers: headers
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui status`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui status',
                placement:"topRight"
            });
        }
    }

    return {
        fields,
        setParams,
        pasar,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        fetchData,
        params,
        handlePageChange,
        onShowSizeChange,
        handleUpdateStatus
    }
}