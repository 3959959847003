import {useState, useEffect} from "react"
import {IList} from "../VoucherModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {deposit} from "../../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = (accountID:string = "") => {
    const [fields, setFields] = useState<IList>({
        totalData: 0,
        data:[]
    })
    const [queryParams, setQueryParams] = useState({
        accountID: accountID,
        pageNumber: 1,
        pageLimit: 10,
    })
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${deposit}/transactions`

            const res = await axios.get(url, {
                headers: headers,
                params: params
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data voucher',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(queryParams)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            if (e.target.value === "") {
                setQueryParams({
                    accountID: accountID,
                    pageNumber: 1,
                    pageLimit: 10,
                })
            } else {
                setQueryParams({...queryParams, ...{transactionNumber: e.target.value}, pageNumber: 1})
            }
         }
    }

    const handleFilter = (values:{}) => {
        setQueryParams({...queryParams, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setQueryParams({
            accountID: accountID,
            pageNumber: 1,
            pageLimit: 10,
        })
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setQueryParams({...queryParams, pageNumber: page})
    }

    return {
        fields,
        queryParams,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange
    }
}