import React from "react"
import {IDetail} from "../PaymentConfigModel"
import {FormatRp} from "../../../../helpers/Formatter"
import {Switch, Menu, Row, Col, Dropdown} from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import EditPaymentConfig from "../editpaymentconfig/EditPaymentConfig"
import {ModalConfirmation} from "../../../../components/atoms"

function TableColumns(
    handleUpdateStatus: (id:string, newData:any) => void,
    loadingSwitch: string,
    fetchData: (params:any) => void,
    params: any,
    toggle: () => void,
    visible: boolean,
    handleDelete: (payment_id:string) => void
) {
    const columns = [
        {
            title: "Asal Transaksi",
            dataIndex: "origin",
        },
        {
            title: "Nama Apartement",
            render: (data:IDetail) => (
                <div>{data.apartmentName === "" ? "-" : data.apartmentName}</div>
            )
        },
        {
            title: "Provider Pembayaran",
            dataIndex: "paymentProvider",
        },
        {
            title: "Metode Pembayaran",
            dataIndex: "paymentMethod",
        },
        {
            title: "Biaya Transaksi",
            render: (data:IDetail) => (
                <div>{data.isPercentage ? `${data.paymentValue}%` : FormatRp(data.paymentValue)}</div>
            )
        },
        {
            title: "Batas Waktu Transaksi",
            render: (data:IDetail) => (
                <div>{data.paymentTimeMinute} Menit</div>
            )
        },
        {
            title: "Status",
            render: (data:IDetail) => {
                const handleChange = (checked: boolean) => {
                    const newData = {...data, isActive: checked}
                    handleUpdateStatus(data.payment_id, newData)
                }
                
                return (
                    <div>
                        <Switch
                            loading={loadingSwitch === data.payment_id ? true : false}
                            defaultChecked={data.isActive}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={handleChange}
                        />
                    </div>
                )
            }
        },
        {
            title: "Aksi",
            render: (data:IDetail) => {

                const menu = (
                    <Menu style={{width: "100px"}}>
                        <Menu.Item>
                            <EditPaymentConfig 
                                data={data} 
                                fetchData={fetchData} 
                                params={params}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <Row onClick={toggle}>
                                <Col span={8}>
                                    <i className="far fa-trash-alt" />
                                </Col>
                                <Col span={16}>
                                    Hapus
                                </Col>
                            </Row>
                            <ModalConfirmation 
                                visible={visible} 
                                toggle={toggle}
                                submitDelete={() => handleDelete(data.payment_id)}
                            />
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        },
    ]

    return {
        columns
    }
}

export default TableColumns