import {useState, useEffect} from "react"
import {useLocation} from "react-router"
import {IList, initialDataList} from "../ModelTransactionFood"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {transactionfood, merchantFood} from "../../../../config/endpoint"
import {useQueryParams} from "../../../../helpers/Formatter"
import moment from "moment"

export function useModelList() {
    const location = useLocation()
    const qp = useQueryParams()
    const [fields, setFields] = useState<IList>(initialDataList)
    const [loading, setLoading] = useState<boolean>(false)
    const [params, setParams] = useState<{
        page: number
        limit: number
        isSuperAdmin: boolean
        sortBy: string
        // search: string
    }>({
        page: 0,
        limit: 10,
        isSuperAdmin: true,
        sortBy: "updated_at"
        // search: ""
    })
    const [merchant, setMerchant] = useState<{
        id: string
        name: string
    }[]>([])

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${transactionfood}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {
                    ...queryparams,
                    ...qp.value
                }
            })
            setFields(res.data)
            setLoading(false)
        } catch {

        }
    }

    const fetchMerchant = async() => {
        try {
            const url = `${base_url}/${merchantFood}`
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-Type': "application/json"
            }

            const res = await axios.get(url, {
                headers: headers,
                params: {isAll: true}
            })
            setMerchant(res.data.payload)
        } catch {

        }
    }

    useEffect(() => {
        fetchData(params)
        fetchMerchant()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            // qp.queryParams.delete('pageNumber');
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, page: 0})
        }
    }

    const handleFilter = (values: any) => {
        qp.queryParams.delete("pageNumber");

        for (var key in values) {

            if (values[key] !== undefined && values[key] !== null) {
                if (key === "createdAt") {
                    qp.queryParams.set('createdFrom', moment(values.createdAt[0]).format("YYYY-MM-DD"));
                    qp.queryParams.set('createdTo', moment(values.createdAt[1]).format("YYYY-MM-DD"));
                } else {
                    qp.queryParams.set(key, values[key]);
                }
            } else if (key === 'createdAt') {
                qp.queryParams.delete('createdFrom')
                qp.queryParams.delete('createdTo')
            } else {
                qp.queryParams.delete(key)

            }
        }
        qp.handleQueryParams()
        setParams({...params, page: 0})
    }

    const handleReset = () => {
        qp.reset();
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        const currentPage = page - 1;
        setParams({ ...params, page: currentPage })
    }

    return {
        fields,
        merchant,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        loading,
        handlePageChange
    }
}