import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detail/Detail"

const Transaction: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/food/transaksi" exact={true} render={() => <List />} />
            <Route path="/food/transaksi/detail" exact={true} render={() => <Detail />} />
        </ContextProvider>
    )
}

export default Transaction
