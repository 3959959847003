import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detail/Detail"
import Create from "./create/Create"
import Edit from "./edit/Edit"

const Item: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/pasar/item" exact={true} render={() => <List />} />
            <Route path="/pasar/item/detail" exact={true} render={() => <Detail />} />
            <Route path="/pasar/item/tambah" exact={true} render={() => <Create />} />
            <Route path="/pasar/item/ubah" exact={true} render={() => <Edit />} />
        </ContextProvider>
    )
}

export default Item
