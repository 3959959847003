import React from 'react'
import {Button} from "antd"

import "./okbutton.scss"

interface Props {
    onClick?:() => void
    loading?: boolean
    disabled?: boolean
}

const OkButton: React.FC<Props> = (props) => {
    return (
        <Button
            onClick={props.onClick}
            className="ok-button"
            htmlType="submit"
            loading={props.loading}
            disabled={props.disabled}
        >
            {props.children}
        </Button>
    )
}

export default OkButton
