import React from 'react'
import {Row, Col} from "antd"
import EditCategory from "./editcategory/EditCategory"
import DeleteCategory from "./deletecategory/DeleteCategory"

interface Props {
    image:string,
    name:string,
    id:string,
    handleEdit: (id:string, values:{image:string, name:string}) => void,
    handleDelete: (id:string) => void,
}

const CategoryBox: React.FC<Props> = (props) => {
    return (
        <Row className="category-box">
            <Col span={3}>
                <img alt="" width="25" height="25" src={props.image} />
            </Col>  
            <Col span={18}>
                {props.name}
            </Col>
            <Col span={3} className="action-section">
                <div>
                    <EditCategory image={props.image} name={props.name} handleEdit={(values:{image:string, name:string}) => props.handleEdit(props.id, values)} />{" "} 
                    <DeleteCategory handleDelete={() => props.handleDelete(props.id)}/>
                </div>
            </Col>
        </Row>
    )
}

export default CategoryBox
