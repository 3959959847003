import React, {useState, useEffect, useContext} from 'react'
import {TransactionPaymentContext} from "../context/ContextProvider"
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Row, Col, Select} from "antd"

interface Props {
    handleFilter: (values:{}) => void
}

const Filter: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const context = useContext(TransactionPaymentContext)
    const {
        origin,
        provider,
        method,
        fetchOrigin,
        fetchProvider,
        fetchMethod,
    } = context.useModelFilter()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    useEffect(() => {
        modal && fetchOrigin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    const handleSubmit = (values:{}) => {
        // console.log(values);
        props.handleFilter(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="origin"
                                label="Origin Transaksi"
                            >
                                <Select 
                                    placeholder="Pilih origin" 
                                    style={{ width: "100%" }}
                                    onChange={fetchProvider}
                                >
                                    {origin.map((each:string, key:number) => (
                                        <Select.Option key={key} value={each}>{each}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentProvider"
                                label="Payment Provider"
                            >
                                <Select 
                                    placeholder="Pilih payment provider" 
                                    style={{ width: "100%" }}
                                    onChange={fetchMethod}
                                    disabled={provider.length > 0 ? false : true}
                                >
                                    {provider.map((each:string, key:number) => (
                                        <Select.Option key={key} value={each}>{each}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentMethod"
                                label="Payment Method"
                            >
                                <Select 
                                    placeholder="Pilih payment method" 
                                    style={{ width: "100%" }}
                                    disabled={method.length > 0 ? false : true}
                                >
                                    {method.map((each:{paymentMethod:string, paymentMethodCode:string}, key:number) => (
                                        <Select.Option key={key} value={each.paymentMethod}>{each.paymentMethod}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="paymentState"
                                label="Status Payment"
                            >
                                <Select placeholder="Pilih status payment" style={{ width: "100%" }}>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="UNPAID">UNPAID</Select.Option>
                                    <Select.Option value="FAILED">FAILED</Select.Option>
                                    <Select.Option value="PAID">PAID</Select.Option>
                                    <Select.Option value="CANCELED">CANCELED</Select.Option>
                                    <Select.Option value="REFUND">REFUND</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
