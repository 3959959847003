import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {ItemContext} from "../context/ContextProvider"
import {ICategory} from "../ModelItem"
import {Loading} from "../../../../../components/atoms"
import {DetailPageLayout} from "../../../../templates"
import {Row, Col} from "antd"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {BackButton} from "../../../../../components/atoms"

import "./detail.scss"

const Detail: React.FC = () => {
    const location = useLocation()
    const marketID = location.search.split("=")[1]
    const context = useContext(ItemContext)
    const {
        fields,
        loading
    } = context.useModelDetail(marketID)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading === "fetch" ? <Loading /> : (
                    <div>
                        <Row>
                            {fields.imageURL.map((url:string, key:number) => (
                                <Col key={key} style={{paddingRight: "10px"}}>
                                    <img src={url} alt="venue-pict" className="venue-photo" />
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Item" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Item" value={fields.name} />
                                <ItemDetail label="Kategori Item" value={
                                    <Row>
                                        {fields.itemCategory.map((category:ICategory, key:number) => (
                                            <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                <div className="tag">
                                                    {category.categoryName}
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                } />
                                <ItemDetail label="Deskripsi Item" value={fields.description} />
                                <ItemDetail 
                                    label="Tags" 
                                    value={
                                        <Row>
                                            {fields.tags.map((tag:string, key:number) => (
                                                <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                    <div className="tag">
                                                        {tag}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    } 
                                />
                                <ItemDetail label="Harga" value={<div style={{textAlign: "right"}}>{fields.price !== null ? FormatRp(fields.price) : "-"}</div>} />
                                <ItemDetail label="Diskon" value={<div style={{textAlign: "right"}}>{fields.discount !== null ? fields.isDiscountPercentage ? `${fields.discount}%` : FormatRp(fields.discount) : "-"}</div>} />
                                <ItemDetail label="Dibuat Pada" value={fields.createdAt !== undefined ? fields.createdAt === "" ? "-" : FormatDate(fields.createdAt) : "-"} />
                                <ItemDetail label="Dibuat Oleh" value={fields.createdBy !== "" ? fields.createdBy : "-"} />
                                <ItemDetail label="Diubah Pada" value={fields.updatedAt !== undefined ? fields.updatedAt === "" ? "-" : FormatDate(fields.updatedAt) : "-"} />
                                <ItemDetail label="Diubah Oleh" value={fields.updatedBy !== "" ? fields.updatedBy : "-"} />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col>
                                <Link to="/pasar/item">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}      
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
