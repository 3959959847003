import React from 'react'
import ContextProvier from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"

const Detail: React.FC = () => {
    return (
        <ContextProvier>
            <Route path="/customer/detail" exact={true} component={List} />
        </ContextProvier>
    )
}

export default Detail
