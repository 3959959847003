import {useState, useEffect} from "react"
import {IListMerchant, initialDataList} from "../MerchantJasaModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchantjasa} from "../../../../config/endpoint"
import {notification} from "antd"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import moment from "moment";

export const useModelList = () => {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<string>("")
    const [visible, setVisible] = useState<boolean>(false)
    const [fields, setFields] = useState<IListMerchant>(initialDataList)
    const [params, setParams] = useState<{
        page: number
        q: string
        sortBy: string
    }>({
        page: 1,
        q: "",
        sortBy: "-updatedAt"
    })

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${merchantjasa}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {...queryparams, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading("")
            // console.log(res);
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            qp.queryParams.set("q", e.target.value);
            qp.handleQueryParams();
            setParams({...params, page: 1})
         }
    }


    const toggle = () => {
        setVisible(!visible)
    }

    const handleDelete = async(id:string) => {
        setLoading("delete")
        try {
            const url = `${base_url}/${merchantjasa}/${id}/admin`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.delete(url, {
                headers: headers
            })
            toggle()
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Menghapus data merchant berhasil!',
                placement: "bottomRight",
            });
            setLoading("")
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan saat menghapus data merchant',
                placement: "bottomRight",
            });
            setLoading("")
        }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleSetMerchantId = (id:string) => {
        localStorage.setItem("merchantId", id)
    }

    const handleFilter = (values:{createdAt?:any[], startDate?:string, endDate?:string, isActive:boolean|string, status:string, categoryID:string}) => {
        // console.log(values);
        values["startDate"] = values.createdAt !== undefined ? moment(values.createdAt[0]).format("YYYY-MM-DD") : undefined
        values["endDate"] = values.createdAt !== undefined ? moment(values.createdAt[1]).format("YYYY-MM-DD") : undefined
        delete values.createdAt

        values.categoryID && qp.queryParams.set("categoryID", values.categoryID);
        values.isActive && qp.queryParams.set("isActive", values.isActive.toString());
        values.status && qp.queryParams.set("status", values.status.toString());
        values.startDate && qp.queryParams.set("startDate", values.startDate.toString());
        values.endDate && qp.queryParams.set("endDate", values.endDate.toString());
        qp.handleQueryParams();
        setParams({ ...params, ...values, page: 1})
    }

    const handleReset = () => {
        setParams({
            page: 1,
            q: "",
            sortBy: "-updatedAt",
        })
        qp.reset()
    }

    const handleUpdateStatus = async(checked: boolean, id:string) => {
        setLoading(`switch${id}`)
        try {
            const url = `${base_url}/${merchantjasa}/${id}/admin`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.put(url, {isActive: checked}, {
                headers: headers,
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Status merchant berhasil diperbaharui.',
            });
            setLoading("")
            fetchData(params)
        } catch(error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan saat memperbaharui status merchant',
                placement: "bottomRight",
            });
            setLoading("")
            fetchData(params)
        }
    }

    return {
        fields,
        params,
        loading,
        visible,
        handleSubmitSearch,
        toggle,
        handleDelete,
        handlePageChange,
        handleSetMerchantId,
        handleFilter,
        handleReset,
        handleUpdateStatus
    }
}