import {useState, useEffect} from "react"
import {IDetail} from "../PaymentConfigModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {paymentconfig} from "../../../../config/endpoint"
import {notification, Form} from "antd"

export const useModelDetail = (formType:string = "", data:any = {}, fetchData: (params:any) => void, params:any) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>({
        bank: null,
        created_at: "",
        created_by: "",
        isActive: false,
        isMaxValue: false,
        isPercentage: false,
        maxValue: 0,
        mitra: null,
        origin: null,
        paymentMethod: null,
        paymentMethodCode: null,
        paymentProvider: null,
        paymentTimeMinute: null,
        paymentValue: null,
        payment_id: "",
        updated_at: "",
        updated_by: ""
    })

    useEffect(() => {
        formType === "edit" && setFields(data)
    }, [formType, data])

    const toggle = () => {
        setModal(!modal)
    }

    const handleChange = (changedValues:{}) => {
        setFields({...fields, ...changedValues})
    }  

    const handleDirectChange = (value:any, option:any) => {
        if (option.className === "apartmentName") {
            setFields({...fields, [option.className]: option.children, apartmentID: value})
        } else if (option.className === "paymentMethodCode") {
            setFields({...fields, [option.className]: value, paymentMethod: option.children})
        } else {
            const newData:any = []

            option.forEach((each:{children: string, className:string, value:string}) => {
                let objct:{bankName?:string, bankCode?:string, bankID?:string} = {}

                objct.bankName = each.children
                objct.bankCode = each.className
                objct.bankID = each.value

                newData.push(objct)
            })
            setFields({...fields, bank: newData})
        }
    }

    const handleSubmit = async(values:{
        isPercentage:string|boolean
        maxValue:any
        paymentTimeMinute:any
        paymentValue:any
        apartmentID:undefined|string
        apartmentName:any
        paymentMethodCode:undefined|string
        paymentMethod:any
        isActive:boolean
        isMaxValue:boolean
        bank:any
    }) => {
        values["isPercentage"] = values.isPercentage === "true" ? true : false
        values["maxValue"] = values.maxValue === null || values.isMaxValue === false ? 0 : parseInt(values.maxValue)
        values["paymentTimeMinute"] = values.paymentTimeMinute === null ? 0 : parseInt(values.paymentTimeMinute)
        values["paymentValue"] = values.paymentValue === null ? 0 : parseInt(values.paymentValue)
        values["isActive"] = fields.isActive
        if (values.apartmentID !== undefined) {
            values["apartmentName"] = fields.apartmentName
        }
        if (values.paymentMethodCode !== undefined) {
            values["paymentMethod"] = fields.paymentMethod
        }
        values["bank"] = fields.bank
        try {
            const url = formType === "edit" ? `${base_url}/${paymentconfig}/update/${data.payment_id}` : `${base_url}/${paymentconfig}/create`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            formType === "edit" ? (
                await axios.put(url, values, {
                    headers: headers,
                })
            ) : (
                await axios.post(url, values, {
                    headers: headers,
                })
            )
            toggle()
            form.resetFields()
            notification.success({
                message: "Yuhuuu",
                description:
                    `${formType === "edit" ? "Mengubah" : "Menambahkan"} data payment config berhasil!`,
                placement: "bottomRight",
            });
            fetchData(params)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    `Terjadi kesalahan pada saat ${formType === "edit" ? "memperbaharui" : "menambahkan"} data`,
                placement: "bottomRight",
            });
        }
    }

    return {
        modal,
        fields,
        toggle,
        handleChange,
        handleDirectChange,
        handleSubmit
    }
}