import React from 'react'
import {Modal} from "../../../../components/atoms"
import {Row, Col} from "antd"
import "./detailtransactionjasa.scss";

interface Props {
    type: string,
    visible: boolean,
    onCancel: () => any,
    footerButton:any,
    inputReason?:any
}

const ModalDisclaimer: React.FC<Props> = (props) => {
    const { type, visible, onCancel, footerButton, inputReason } = props;

    return ( 
        <Modal
            title={
                type === "Terima" ? "Terima Pesanan" :
                type === "Tolak" || type === "Reason Reject" ? "Tolak Pesanan" :
                type === "Proses" ? "Proses Pesanan" :
                type === "Batal" || type === "Reason Cancel" ? "Batal Pesanan" :
                type === "Proses Selesai" ? "Selesaikan Pesanan" :
                type === "Proses Refund" ? "Proses Refund" :
                type === "Selesaikan Refund" ? "Selesaikan Refund" : 
                ""
            }
            visible={visible}
            onCancel={onCancel}
        >
            <div style={{width: "100%", height: "1px", background: "#EEE", marginTop: "-10px", marginBottom: "10px"}}/>
            <Row>
                <Col span={24}>
                    {type === "Terima" ? "Anda siap memberikan layanan terbaik Anda dan menerima pesanan ini?" :
                     type === "Tolak" ? "Apakah Anda yakin untuk menolak pesanan ini?" : 
                     type === "Proses" ? "Apakah Anda yakin untuk memproses pesanan ini?" :
                     type === "Batal" ? "Apakah Anda yakin untuk membatalkan pesanan ini?" : 
                     type === "Proses Selesai" ? "Apakah Anda yakin untuk menyelesaikan pesanan ini?" :
                     type === "Proses Refund" ? "Apakah Anda yakin untuk memproses refund?" :
                     type === "Selesaikan Refund" ? "Apakah Anda yakin untuk menyelesaikan refund?" :
                     type === "Reason Cancel" || type === "Reason Reject" ? "Sampaikan alasan mengapa pesanan ini ditolak:" :
                    ""}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {inputReason}
                </Col>
            </Row>
            <Row style={{paddingTop: "10px"}}>
                <Col span={24} style={{textAlign: "center"}}>
                    {footerButton}
                </Col>
            </Row>
        </Modal>   
    )
}

export default ModalDisclaimer
