import {useState, useEffect} from "react"
import {useQuery} from "../../../../../helpers/Formatter"
import {useHistory} from "react-router"
import {IModelItem, initialItem, ICategories} from "../ModelItem"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {categoryfood, itemfood} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(product_id:string | null = null, id:string | null = null) {
    const query = useQuery()
    const history = useHistory()
    const [fields, setFields] = useState<IModelItem>(initialItem)
    const [category, setCategory] = useState<ICategories[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchCategory = async () => {
        try {
            const res = await axios.get(`${base_url}/${categoryfood}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {merchantId: query.get("id")}
            })
            setCategory(res.data.payload);
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
        }
    }

    const fetchData = async (itemId: string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${itemfood}/detil/${itemId}`;

            const res = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            // console.log(res);

            setFields({ ...res.data.payload, tags: res.data.payload.tags === undefined ? [] : res.data.payload.tags })
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
            setLoading(false)
            // console.log(error);
        }
    }

    useEffect(() => {
        product_id !== null && fetchData(product_id)
        fetchCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product_id])

    const submitPhoto = (path: string) => {
        setFields({ ...fields, urlphoto: path })
    }

    const handleSubmit = async (values: any) => {
        values["tags"] = fields.tags
        values["harga"] = parseInt(values.harga)
        values["discount"] = parseInt(values.discount)
        values["merchantId"] = id
        
        setLoading(true)
        try {
            const url = product_id !== null ? `${base_url}/v3/food/product/update/${product_id}` : `${base_url}/v3/food/product`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = product_id !== null ? await axios.put(url, values, { headers: headers }) : await axios.post(url, values, { headers: headers })

            if (res.status === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Menambahkan data merchant berhasil.',
                });
                setLoading(false)
                history.goBack();
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
            setLoading(false)
        }
    }

    return {
        fields,
        setFields,
        category,
        loading,
        submitPhoto,
        handleSubmit
    }
}