import React, {useContext} from 'react'
import {Link} from "react-router-dom"
import {CustomerContext} from '../context/ContextProvider'
import {PageLayout} from '../../../templates'
import {useQueryParams} from "../../../../helpers/Formatter"
import {Search, Table, FeatureButton} from '../../../../components/atoms'
import {Menu, Row, Col, Dropdown, Pagination} from 'antd'
import Filter from "../filter/Filter";
import Report from "../report/Report";

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(CustomerContext)
    const {
        fields,
        loading,
        handlePageChange,
        handleSubmitSearch,
        handleReset,
    } = context.useModelList()
    const columns = [
        {
            title: "Nama Pengguna",
            render: (data: {name:string}) => (
                <div>{data.name === "" ? "-" : data.name}</div>
            ),
        },
        {
            title: "Nomor Handphone",
            render: (data: {phone:string}) => (
                <div>{data.phone === "" ? "-" : data.phone}</div>
            ),
        },
        {
            title: "Email",
            render: (data: {email:string}) => (
                <div>{data.email === "" ? "-" : data.email}</div>
            ),
        },
        {
            title: "Point",
            render: () => (
                <div>-</div>
            ),
        },
        {
            title: "Terakhir Log In",
            render: () => (
                <div>-</div>
            ),
        },
        {
            title: "Tanggal Daftar",
            render: () => (
                <div>-</div>
            ),
        },
        {
            title: "Action",
            render: (data:{id:number, name:string}) => {
                const menu = (
                    <Menu style={{width: "150px"}}>
                        <Menu.Item>
                            <Link to={`/customer/detail?id=${data.id}&name=${data.name}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Point
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        },
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <Report />
                        <Filter /> 
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table
                            data={fields.data}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                            scroll={{y: 'calc(100vh - 23em)'}}
                            loading={loading}
                        />
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('q')?.toString()}
                />}
                footer={
                     <Row>
                        <Col span={12}>
                            <span>{`Total ${fields.data.length} Customer`}</span>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.pagination.currentPage} 
                                pageSize={10} 
                                total={fields.pagination.totalPage * fields.pagination.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
