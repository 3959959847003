

export interface IListTransaction {
    total_record: number
    total_page: number
    page: number
    records: IDetailTransaction[]
}

export interface IDetailItemOrders {
    id: number
    itemID: number
    itemName: string
    itemPrice: number
    itemDiscount: number //float
    isDiscountPercentage: boolean
    qty: number
    note: string 
}

export interface IDetailPayment {
    id: number
    paymentMethod: string
    paymentMethodCode: string
    paymentProvider: string
    bankCode: string
}

export interface IDetailMoPayment {
    id: number
    paymentID: string
    paymentState: string
    trxID: string
    totalAmount: number
    adminFee: number
    userPayNumber: string
    bankName: string
    paymentMethod: string
    paymentProvider: string
    duration: number
}

export interface IDetailTransaction {
    id: string
    invoiceNumber: string
    userID: string
    userName: string
    userPhone: string
    userEmail: string
    marketID: number
    marketName: string
    orderDate: string
    status: string
    address: string
    latitude: number | null //float
    longitude: number | null //float
    deliveryID: number
    deliveryName: string
    addressNote: string
    estimasi: string
    shopperID: number
    shopperName: string
    shopperPhoneNumber: string
    areaID: number
    areaName: string
    totalOrder: number | null //float
    serviceFee: number | null //float
    adminFee: number | null //float
    deliveryFee: number | null //float
    totalDiscount: number | null //float
    totalPromo: number | null //float
    totalPay: number | null //float
    isRated: boolean
    isComplained: boolean
    itemOrder: IDetailItemOrders[]
    payment: IDetailPayment
    refundOrder: {
        id?: number
        refundCode?: string
        refundNote: string
        createdAt?: string | null
        createdBy?: string
        refundOrderDetail: {
            id: number
            itemDiscount: number
            itemID: number
            itemName: string
            itemPrice: number
            itemQty: number
            refundOrderID: number
        }[]
        total: number
        updatedAt?: null | string
    }
    moPayment: IDetailMoPayment
    createdBy: string
    updatedBy: string
    doneAt: string
    createdAt: string
    updatedAt: string
}

export const initialDataList = {
    total_record: 0,
    total_page: 1,
    page: 1,
    records: []
}

export const initialDataDetail = {
    id: "",
    invoiceNumber: "",
    userID: "",
    userName: "",
    userPhone: "",
    userEmail: "",
    marketID: 0,
    marketName: "",
    orderDate: "",
    status: "",
    address: "",
    latitude: null, //float
    longitude: null, //float
    deliveryID: 0,
    deliveryName: "",
    addressNote: "",
    estimasi: "",
    shopperID: 0,
    shopperName: "",
    shopperPhoneNumber: "",
    areaID: 0,
    areaName: "",
    totalOrder: null, //float
    serviceFee: null, //float
    adminFee: null, //float
    deliveryFee: null, //float
    totalDiscount: null, //float
    totalPromo: null, //float
    totalPay: null, //float
    isRated: false,
    isComplained: false,
    itemOrder: [],
    payment: {
        id: 0,
        paymentMethod: "",
        paymentMethodCode: "",
        paymentProvider: "",
        bankCode: ""
    },
    refundOrder: {  
        id: 1,  
        refundNote: "",
        refundCode: "",
        refundOrderDetail: [],
        total: 0
    },
    moPayment: {
        id: 0,
        paymentID: "",
        paymentState: "",
        trxID: "",
        totalAmount: 0,
        adminFee: 0,
        userPayNumber: "",
        bankName: "",
        paymentMethod: "",
        paymentProvider: "",
        duration: 0
    },
    createdBy: "",
    updatedBy: "",
    doneAt: "",
    createdAt: "",
    updatedAt: ""
}

