import React, {useState, useEffect} from 'react'
import axios from "axios"
import {useLocation} from "react-router-dom"
import {base_url} from "../../../config/url-config"
import {bookinghobby} from "../../../config/endpoint"
import {FormatDate, FormatRp} from "../../../helpers/Formatter"
import moment from "moment"
import {notification, Row, Col, Pagination} from "antd"
import {useQueryParams} from "../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {PageLayout} from "../../templates"
import FilterTransactionHobby from "./filtertransactionhobby/FilterTransactionhobby"
import {Search, Table, FeatureButton} from "../../../components/atoms"
// import uuid from "uuid"

import "./transactionhobbyall.scss"

const TransactionHobby: React.FC = () => {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        page:number,
        total_page:number,
        total_record:number,
        limit:number,
        records:{}[]
    }>({
        page: 1,
        total_page: 0,
        total_record: 0,
        limit: 10,
        records: []
    })
    const [params, setParams] = useState<{
        pageNumber:number,
        // search:string
    }>({
        pageNumber: 1,
        // search: ""
    })

    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${bookinghobby}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {...params, ...qp.value}
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams()
            setParams({...params, pageNumber: 1})
         }
    }

    const handleFilter = (values:{}) => {
        // console.log(values);
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            pageNumber: 1,
            // search: ""
        })
    }

    const columns = [
        {
            title: "No. Order",
            render: (data:{booking:{invoiceNumber:string, CreatedAt:string, bookingDate:string, bookingEndDate:string}, merchant:{Name:string}}) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.booking.invoiceNumber}</div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.merchant !== null ? data.merchant.Name : "-"}</div>
                    <div>{FormatDate(data.booking.CreatedAt)}</div>
                    <div>{`${moment(data.booking.bookingDate).format("DD MMMM YYYY")}, ${moment(data.booking.bookingDate).format("HH:mm")} - ${moment(data.booking.bookingEndDate).format("HH:mm")}`}</div>
                </div>
            ),
            width: "25%"
        },
        {
            title: "Total Pesanan",
            render: (data:{booking:{totalPriceDetail:{totalPrice:number}}}) => (
                <div>{FormatRp(data.booking.totalPriceDetail.totalPrice)}</div>
            ),
            width: "17%"
        },
        {
            title: "Biaya Layanan",
            render: (data:{payment:{adminFee:number}}) => (
                <div>{data.payment !== null ? FormatRp(data.payment.adminFee) : "-"}</div>
            ),
            width: "15%"
        },
        {
            title: "Provider Pembayaran",
            render: (data:{payment:{paymentProvider:string}}) => (
                <div>{data.payment !== null ? data.payment.paymentProvider : "-"}</div>
            ),
            width: "15%"
        },
        {
            title: "Status",
            render: (data:{payment:{paymentState:string}, booking:{bookingState:string}}) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.booking.bookingState} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.payment !== null ? data.payment.paymentState : "-"}
                        </Col>
                    </Row>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Aksi",
            render: (data:{booking:{ID:string}}) => (
                <Link to={`/booking/detail?bookingID=${data.booking.ID}`} >
                    <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                        <i className="far fa-eye" />
                    </div>
                </Link>
            )
        }
    ]

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <FilterTransactionHobby handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.records}
                            // rowKey={record:{booking:{ID:string}} => record.booking.ID}
                            // rowKey="key"
                            // scroll={{y: 550}}
                            rowClassName={(record:{booking:{bookingState:string}}, index:number) => record.booking.bookingState === "REQUESTED" || record.booking.bookingState === "RESCHEDULE-REQUESTED" ? 'new-transaction' :  'read-transaction'}
                            pagination={false}
                            loading={loading}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            {`Total ${fields.total_record} Transaksi`}
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.page} 
                                pageSize={10} 
                                total={fields.total_record} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default TransactionHobby
