import { useState, useEffect, useContext } from "react"
import { ContextStore } from "../../../../../context/context-store"
import { IListTransaction, initialDataList } from "../ModelTransaction"
import { IListPasar, initialDataList as initialDataPasar } from "../../ModelPasar"
import axios from "axios"
import moment from "moment"
import { base_url } from "../../../../../config/url-config"
import { transaksipasar, listpasar } from "../../../../../config/endpoint"
import { notification } from "antd"
import { useQueryParams } from "../../../../../helpers/Formatter"
import { useLocation } from "react-router"

export function useModelList() {
    const { notif } = useContext(ContextStore)
    const [fields, setFields] = useState<IListTransaction>(initialDataList)
    const [loading, setLoading] = useState<string>("")
    const [params, setParams] = useState<{
        // pageLimit: number
        pageNumber: number
        search: string
        isNewest: boolean
        isSuperAdmin: boolean
    }>({
        // pageLimit: 10,
        pageNumber: 1,
        search: "",
        isNewest: true,
        isSuperAdmin: true
    })
    const [pasar, setPasar] = useState<IListPasar>(initialDataPasar)
    const qp = useQueryParams();
    const location = useLocation();


    const fetchData = async (params: {}) => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${transaksipasar}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {
                    ...params,
                    ...qp.value
                }
            })
            setLoading("")
            setFields(res.data.payload)
        } catch (error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                    'Terjadi kesalahan saat mengakses data transaksi',
                placement: "topRight"
            });
        }
    }

    const fetchPasar = async () => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${listpasar}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: { isAll: true }
            })
            setPasar(res.data.payload)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                    'Terjadi kesalahan saat mengakses data pasar',
                placement: "topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        fetchPasar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search, notif])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            qp.queryParams.delete('pageNumber');
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
        }
    }

    const handleFilter = (values: any) => {
        qp.queryParams.delete("pageNumber");

        for (var key in values) {

            if (values[key] !== undefined && values[key] !== null) {
                if (key === "createdAt") {
                    qp.queryParams.set('startDate', moment(values.createdAt[0]).format("YYYY-MM-DD"));
                    qp.queryParams.set('endDate', moment(values.createdAt[1]).format("YYYY-MM-DD"));
                } else {
                    qp.queryParams.set(key, values[key]);
                }
            } else if (key === 'createdAt') {
                qp.queryParams.delete('startDate')
                qp.queryParams.delete('endDate')
            } else {
                qp.queryParams.delete(key)

            }
        }
        qp.handleQueryParams()
        setParams({...params, pageNumber: 1})
    }

    const handleReset = () => {
        qp.reset();
    }

    const handlePageChange = (page: number, pageSize: number | undefined) => {
        qp.queryParams.set('pageNumber', page.toString());
        qp.handleQueryParams()
        // setParams({ ...params, pageNumber: page })
    }

    function onShowSizeChange(current:any, pageSize:any) {
        // console.log(current, pageSize);
        qp.queryParams.set('pageLimit', pageSize)
    }

    return {
        fields,
        setParams,
        pasar,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        fetchData,
        params,
        handlePageChange,
        onShowSizeChange
    }
}