import React, { useState, useContext } from "react";
import { MainAppContext } from "./context/ContextProvider"
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { ContextStore } from "./context/context-store"
import Login from "./containers/pages/login/Login";
import { MainLayout } from "./containers/templates";

import "./style/custom-antd.scss"
import "./style/universal-style.scss"
import ContextInvoice from "./context/context-invoice";

function PrivateRoute({ component: Component, props }: any) {
  return (
    <Route
      {...props}
      render={props =>
        localStorage.getItem("refreshToken") !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const App: React.FC = () => {
  const context = useContext(MainAppContext)
  const {
    fetchNotification,
    notif,
    notifToken,
    handleUpdateStatusRead,
    handleDeleteTokenNotif
  } = context.useModelMainApp()
  // const {fields} = context.useModelNotification()
  const [login, setLogin] = useState<{ isLogin: boolean }>({
    isLogin: false
  })
  const [contextData, setContextData] = useState<{ refreshToken: string | null, merchantID: string | null }>({
    refreshToken: null,
    merchantID: null
  })

  const setLoginStatus = (status: boolean) => {
    setLogin({ ...login, isLogin: status })
  }

  const setRefreshToken = (refreshToken: string) => {
    setContextData({ ...contextData, refreshToken: refreshToken })
  }

  const setMerchantID = (merchantID: string) => {
    setContextData({ ...contextData, merchantID: merchantID })
  }

  const data = {
    fetchNotification,
    login,
    setLoginStatus,
    contextData,
    setRefreshToken,
    setMerchantID,
    notif,
    notifToken,
    handleUpdateStatusRead,
    handleDeleteTokenNotif
  }

  return (
    <React.Fragment>
      <ContextInvoice>
        <BrowserRouter>
          <ContextStore.Provider value={data}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" component={MainLayout} />
            </Switch>
          </ContextStore.Provider>
        </BrowserRouter>
      </ContextInvoice>
    </React.Fragment>
  );
};

export default App;
