import React, {useContext} from 'react'
import {JasaContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {PageLayout} from "../../../templates"
import {FeatureButton, Search, Table} from "../../../../components/atoms"
import FilterMerchantJasa from "../filtermerchantjasa/FilterMerchantJasa"
import {Link} from "react-router-dom"
import {Pagination} from "antd"
import {useQueryParams} from "../../../../helpers/Formatter"

import "./list.scss"

const MerchantJasa: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(JasaContext)
    const {
        fields,
        params,
        loading,
        visible,
        handleSubmitSearch,
        toggle,
        handleDelete,
        handlePageChange,
        handleSetMerchantId,
        handleFilter,
        handleReset,
        handleUpdateStatus
    } = context.useModelList()
    const {columns} = TableColumns(
        toggle, 
        loading,
        visible,
        handleDelete,
        handleSetMerchantId,
        handleUpdateStatus
    )

    return (
        <React.Fragment>
            <PageLayout
                leftTop={
                    <div>
                        <Link to="/jasa/tambah">
                            <FeatureButton 
                                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                                label="Tambah"
                            /> 
                        </Link>
                        <FilterMerchantJasa
                            handleFilter={handleFilter}
                        />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                rightTop={<Search 
                    value={qp.queryParams.get('q')?.toString()}
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.data}
                            rowKey="id"
                            pagination={false}
                            loading={loading === "fetch" ? true : false}
                            scroll={{y: 'calc(100vh - 23em)'}}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            current={params.page} 
                            pageSize={fields.limit} 
                            total={fields.totalPage * fields.limit} 
                            onChange={handlePageChange} 
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default MerchantJasa
