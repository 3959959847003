import React from 'react'
import {Switch, Route, Redirect} from "react-router-dom"
import routes from "../../../utils/routes"

import "./maincontent.scss"

const MainContent: React.FC = () => {
    return (
        // <BrowserRouter>
            <div className="maincontent-container">
                <Switch>
                    {routes.map((route, key) => {
                        return <Route key={key} path={route.path} exact={route.path === "/hobi" || route.path === "/jasa" ||  route.path === "/pasar" || route.path === "/shopper" || route.path === "/order" || route.path === "/transaksi-pasar" || route.path === "/transaksi-ongoing" || route.path === "/food" || route.path === "/order-food" || route.path === "/payment-transaction" || route.path === "/ayopop-transaction" ? false : true } component={route.component} />;
                    })}
                    <Redirect from="/" to="/beranda" />
                </Switch>
            </div>
        // </BrowserRouter>
    )
}

export default MainContent
