import React, { useContext, useEffect, useState } from 'react'
import { ContextStore } from "../../../context/context-store"
import { Layout, Row, Col } from "antd"
import { Sidebar, Header, MainContent } from "../../organisms"

import "./mainlayout.scss"
import Invoice from '../invoice'
import InvoiceBulk from '../invoice/invoice-bulk'
const { Content } = Layout

const MainLayout: React.FC = () => {
    const {
        fetchNotification
    } = useContext(ContextStore)

    const [windowDimensions, setWindowDimension] = useState<{width:number, height: number}>({width: window.innerWidth, height: window.innerHeight})

    const handleResize = () => {
        setWindowDimension({...windowDimensions, width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {
        fetchNotification()
        window.addEventListener("resize", handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log(windowDimensions);

    return (
        <React.Fragment>
            <Invoice />
            <InvoiceBulk />
            <Layout  >
                <Sidebar />
                <Layout>
                    <Content>
                        <Header />
                        <Row className="page-container">
                            <Col span={24} className="page">
                                <MainContent />
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        </React.Fragment>
    )
}

export default MainLayout
