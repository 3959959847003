import React, { useState } from 'react'
import { FeatureButton, Modal, BackButton, OkButton } from "../../../../components/atoms"
import { Form, Row, Col, Select, DatePicker } from "antd"

const { RangePicker } = DatePicker;

interface Props {
    handleFilter: (values: {}) => void
}

const Filter: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields();
    }

    const handleSubmit = (values: {}) => {
        props.handleFilter(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                onCancel={toggle}
                title="Filter Pasar"
                visible={modal}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="isActive"
                                label="Status"
                            >
                                <Select placeholder="Pilih status" style={{ width: "100%" }}>
                                    <Select.Option value="true">Aktif</Select.Option>
                                    <Select.Option value="false">Tidak Aktif</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="createdAt"
                                label="Tanggal Buat"
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
