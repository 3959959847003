import React, {useState, useContext, useEffect} from 'react'
import {ContextStore} from "../../../context/context-store"
import axios from "axios"
import {base_url} from "../../../config/url-config"
import {Redirect, useHistory} from "react-router-dom"
import {Row, Col, Form, Input, Button, notification } from "antd"
import {Loading} from "../../../components/atoms"
import moLogo from "../../../assets/logo.png"
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import "./login.scss"

const Login: React.FC = () => {
    const history = useHistory()
    const {setLoginStatus, login} = useContext(ContextStore)
    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = async(values:{username: string, password: string}) => {
        setLoading(true)
        try {
            const loginres = await axios.post(`${base_url}/v3/client/auth`, values, {
                headers: {
                    "Authorization": `Basic ${btoa(`${values.username}:${values.password}`)}`,
                    "x-origin": base_url === 'https://staging-api.manyoption.co.id' ? "staging-admin-merchant.manyoption.co.id" : "admin-merchant.manyoption.co.id" 
                }
            })

            console.log(loginres);
            console.log(loginres.data.payload.roles.filter((role:string) => role.includes("hobi:owner") || role.includes("jasa:owner")));
            
            if (loginres.status === 200) {
                setLoading(false)
                localStorage.setItem("accessToken", loginres.data.payload.token.accessToken)
                localStorage.setItem("refreshToken", loginres.data.payload.token.refreshToken)
                localStorage.setItem("userName", loginres.data.payload.user.name)
                localStorage.setItem("userId", loginres.data.payload.user.id)
                setLoginStatus(true)
            }   
        } catch (error) {
            if (error.response.status !== 200) {
                setLoading(false)
                notification.open({
                    message: 'Whoops !',
                    description: error.response.status === 400 ? 
                        "Format email yang anda masukan salah." 
                        : error.response.status === 401 ? 
                        "Cek kembali email dan password yang anda input." 
                        : "Maaf, Terjadi kesalahan pada sistem kami",
                    icon: <i className="far fa-frown-open" style={{color: "red"}} />
                });
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("accessToken") !== null ) {
            // <Redirect to="/merchant" />
            history.push("/")
        }
        // localStorage.getItem("accessToken") === null ? <Redirect to="/login" /> : <Redirect to="/merchant" />
    }, [history])

    console.log(login);

    if(login.isLogin === false) {
        return (
            <div>
                <Row justify="center" align="middle" className="login-container">
                    {loading ? (
                        <Loading />
                    ) : null}
                    <Col xs={14} sm={8} lg={12} xl={12}>
                        <Row justify="center">
                            <Col xs={24} sm={24} lg={10} xl={10}>
                                <img src={moLogo} alt="mo-aja-logo" className="company-logo" />
                            </Col>
                        </Row>

                        <Row justify="center" className="form-container">
                            <Col xs={24} sm={24} lg={10} xl={10}>
                                <Form
                                    layout="vertical"
                                    requiredMark={false}
                                    onFinish={handleSubmit}
                                >
                                    <Row style={{paddingBottom: "10px"}}>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Email"
                                                name="username"
                                                rules={[
                                                    { required: true, message: "Silahkan input email!" },
                                                ]}
                                            >
                                                <Input placeholder="email" prefix={<UserOutlined />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: "20px"}}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="password"
                                                label="Kata Sandi"
                                                rules={[
                                                    {
                                                    required: true,
                                                    message: "Silahkan input kata sandi!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    placeholder="kata sandi"
                                                    prefix={<LockOutlined />}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                {/* <Link to="/"> */}
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        htmlType="submit"
                                                        size="large"
                                                        className="login-button"
                                                        block
                                                        // onClick={handleSubmit}
                                                    >
                                                        Masuk
                                                    </Button>
                                                {/* </Link> */}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>

                        <Row justify="center" className="footer-container">
                            <Col xs={24} sm={24} lg={10} xl={10}>
                                <div className="footer-paragraph">
                                    <div>©2018 PT. Nusantara Aplikasi Apartindo.</div> 
                                    <div>All Rights Reserved.</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return <Redirect to="/merchant" />
    }
}

export default Login