import {useState, useEffect} from "react"
import {IDetail} from "../TransactionModel"
import {base_url} from "../../../../../config/url-config"
import {transactionayopop} from "../../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"

export function useModelDetail(id:string | null = null) {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>({
        id: "",
        invoiceNumber: "",
        bill: 0,
        margin: 0,
        total: 0,
        ayopopStatus: "",
        createdAt: "",
        payment: {
            adminFee: 0,
            bankName: "",
            invoiceAmount: 0,
            invoiceID: "",
            invoiceNumber: "",
            paymentID: "",
            paymentMethod: "",
            paymentProvider: "",
            paymentState: "",
            totalAmount: 0,
            transactionDate: "",
            trxID: "",
            userPayNumber: ""
        },
        product: {
            accountNumber: "",
            amount: 0,
            billDetails: [],
            category: "",
            customerDetail: [],
            customerName: "",
            inquiryId: 0,
            productCode: "",
            productDetails: [],
            productName: "",
            refNumber: "",
            totalAdmin: 0,
            validity: ""
        },
        updatedAt: ""
    })

    const fetchData = async() => {
        setLoading(true)
        try {
            const url = `${base_url}/${transactionayopop}/detail/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail transaksi ayopop',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        loading,
        fields
    }
}