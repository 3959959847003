import React, {useContext} from 'react'
import {ItemContext} from "../context/ContextProvider"
import {Loading} from "../../../../../components/atoms"
import Form from "../form/Form"

import "./create.scss"

const Create: React.FC = () => {
    const context = useContext(ItemContext)
    const {
        fields,
        categoryValue,
        loading,
        handleSetImage,
        newTag,
        handleDeleteTag,
        handleEditTag,
        handleChangeTag,
        submitNewTag,
        handleSubmit
    } = context.useModelDetail()
    
    return (
        <React.Fragment>
            {loading === "fetch" ? <Loading /> : (
                <Form 
                    fields={fields}
                    categoryValue={categoryValue}
                    handleSetImage={handleSetImage}
                    newTag={newTag}
                    handleSubmit={handleSubmit}
                    handleDeleteTag={handleDeleteTag}
                    handleEditTag={handleEditTag}
                    handleChangeTag={handleChangeTag}
                    submitNewTag={submitNewTag}
                />
            )}
        </React.Fragment>
    )
}

export default Create
