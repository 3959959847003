import {useState} from "react"

export interface IDetailPasar {
    id: number
    imageURL: string[]
    marketCode?: string
    name: string
    description: string
    address: string
    latitude: any //float
    longitude: any //float
    serviceFee: any //float
    isServiceFeePercentage: boolean | string
    tags: string[]
    operation: IOperation[]
    isActive: boolean
    isOpen: boolean
    createdBy: string
    updatedBy: string
    deletedBy: string
    createdAt: string
    updatedAt: string
    deletedAt: string
}

export interface IListPasar {
    total_record: number
    total_page: number
    records: IDetailPasar[]
}

export interface IOperation {
    active?: boolean
    day: number 
    openHour: string | null 
    closeHour: string | null
}

export const initialDataList = {
    total_record: 0,
    total_page: 1,
    records: []
}

export const initialDataDetail = {
    id: 0,
    imageURL: [""],
    name: "",
    description: "",
    address: "",
    latitude: "", //float
    longitude: "", //float
    serviceFee: "", //float
    isServiceFeePercentage: false,
    tags: [],
    operation: [],
    isActive: false,
    isOpen: false,
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: ""
}

export const initialDataOperationHours = [
    {active: false, day: 0, openHour: null, closeHour: null},
    {active: false, day: 1, openHour: null, closeHour: null},
    {active: false, day: 2, openHour: null, closeHour: null},
    {active: false, day: 3, openHour: null, closeHour: null},
    {active: false, day: 4, openHour: null, closeHour: null},
    {active: false, day: 5, openHour: null, closeHour: null},
    {active: false, day: 6, openHour: null, closeHour: null},
]

export function useModelDetailPasar() {
    const [fields, setFields] = useState<IDetailPasar>(initialDataDetail)
    return {
        fields,
        setFields
    }
}

export function useModelOperationHours() {
    const [operationHours, setOperationHours] = useState<IOperation[]>(initialDataOperationHours)
    return {
        operationHours,
        setOperationHours
    }
}