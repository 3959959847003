import React, {useContext} from 'react'
import {useLocation, Link} from "react-router-dom"
import {KasproContext} from '../context/ContextProvider'
import {Row, Col, Image} from "antd"
import {DetailPageLayout} from "../../../templates"
import {Loading, BackButton} from "../../../../components/atoms"
import {ItemDetail} from "../../../../components/molecules"
import "./detail.scss"

const Detail: React.FC = () => {
    const location = useLocation()
    const voucherId = location.search.split("=")[1]
    const context = useContext(KasproContext)
    const {
        loading,
        fields,
    } = context.useModelDetail(voucherId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> :
                <div>
                    <Row>
                        <Col span={16}>
                            <ItemDetail label="Kategori Top Up" value={fields.promo_code} />
                            <ItemDetail borderNone label="Logo" value={
                                <Image 
                                    src={fields.image} 
                                    alt="voucher-pict" 
                                    width={80}
                                    height={80}
                                    style={{borderRadius: "10px"}} 
                                />
                            } />
                            <ItemDetail label="Bank/Mitra" value={fields.name} />
                            <ItemDetail label="Kode Penyedia" value={fields.name} />
                            <ItemDetail label="Deskripsi" value={
                                <div dangerouslySetInnerHTML={{ __html: fields.description}} />
                            } />
                            <ItemDetail label="Status" value={fields.is_active === "1" ? "Active" : "InActive"} />
                            <ItemDetail label="Dibuat" value={fields.is_active === "1" ? "Active" : "InActive"} />
                            <ItemDetail label="Diubah" value={fields.is_active === "1" ? "Active" : "InActive"} />
                        </Col>
                    </Row>

                    <Row style={{paddingTop: "20px"}}>
                        <Col>
                            <Link to="/kaspro">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                    </Row>
                </div>}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
