import React, {useContext} from 'react'
import {ServiceContext} from "../context/ContextProvider"
import {useLocation} from "react-router-dom"
import {Loading} from "../../../../../components/atoms"
import FormService from "../formservice/FormService"

import "./editservice.scss"

const EditService: React.FC = () => {
    const id = parseInt(useLocation().search.split("=")[1])
    const context = useContext(ServiceContext)
    const {
        fields,
        operations,
        loading,
        handleSetImgURL,
        addUploadPhoto,
        handleCheckedDay,
        handleSubmitTimeRate,
        handleSubmitSchedule,
        handleSubmitVenue
    } = context.useModelDetail(id)

    return (
        <React.Fragment>
            {loading ? <Loading /> : (
                <FormService 
                    onFinish={handleSubmitVenue} 
                    fields={fields} 
                    addUploadPhoto={addUploadPhoto} 
                    handleSetImgURL={handleSetImgURL}
                    handleCheckedDay={handleCheckedDay}
                    operations={operations}
                    handleSubmitTimeRate={handleSubmitTimeRate}
                    handleSubmitSchedule={handleSubmitSchedule}
                />
            )}
        </React.Fragment>
    )
}

export default EditService
