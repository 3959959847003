import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itemjasa} from "../../../../../config/endpoint"
import {notification} from "antd"

export const useModelCategory = () => {
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        name:string,
        id:string
    }[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async() => {
        setLoading(true)
        try {
            const url = `${base_url}/${itemjasa}/category`

            const res = await axios.get(url, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async(values:{}) => {
        try {
            const url = `${base_url}/${itemjasa}/category`

            await axios.post(url, values, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            fetchData()
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat membuat kategori.',
            });
        }
    }

    const handleEdit = async(id:string, value:string) => {
        try {
            const url = `${base_url}/${itemjasa}/category/${id}`
            const newValue = {name: value}

            await axios.put(url, newValue, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            fetchData()
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat menghapus data kategori.',
            });
        }
    }

    const handleDelete = async(id:string) => {
        try {
            const url = `${base_url}/${itemjasa}/category/${id}`

            await axios.delete(url, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            fetchData()
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat meghapus data kategori.',
            });
        }
    }

    const toggle = () => {
        setModal(!modal)
    }

    return {
        modal,
        fields,
        loading,
        handleSubmit,
        handleEdit,
        handleDelete,
        toggle
    }
}