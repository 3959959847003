export interface IListArea {
    total_record: number
    total_page: number
    records: IDetailArea[]
    limit: number
    page: number
}

export interface IDetailArea {
    id: number
    name: string
}

export const initialDataList = {
    total_record: 0,
    total_page: 1,
    records: [],
    limit: 10,
    page: 1
}

export const initialDataDetail = {
    id: 0,
    name: ""
}