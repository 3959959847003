import React from 'react'
import {FeatureButton} from '../../../../components/atoms'

const Report: React.FC = () => {
    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-download"/> Export</div>}
            />
        </React.Fragment>
    )
}

export default Report
