import {useState, useEffect} from "react"
import {IDetailTransaction, initialDataDetail} from "../ModelTransaction"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {transaksipasar} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(id:string = "") {
    const [fields, setFields] = useState<IDetailTransaction>(initialDataDetail)
    const [loading, setLoading] = useState<string>("")

    const fetchData = async(id:string) => {
        setLoading("fetch")
        try {
            const res = await axios.get(`${base_url}/${transaksipasar}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setFields(res.data.payload)
            setLoading("")
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(id)
    }, [id])

    const handleUpdateStatus = async(status:string) => {
        setLoading("updateStatus")
        try {
            await axios.put(`${base_url}/${transaksipasar}/${id}`, {status: status}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui status`,
            });
            fetchData(id)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui status',
                placement:"topRight"
            });
        }
    }

    return {
        fields,
        loading,
        handleUpdateStatus
    }
}