import React, {useContext} from 'react'
import {ShopperContext} from "../context/ContextProvider"
import {IDetailSHopper} from "../ModelSHopper"
import {Form, Row, Col, Input, Select, Switch} from "antd"
import {UploadPhoto} from "../../../../components/atoms"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {OkButton, BackButton} from "../../../../components/atoms"
import {Link} from "react-router-dom"

interface Props {
    fields: IDetailSHopper
    handleSetImageURL: () => void
    handleSetIdentityURL: () => void
    handleSetDrivingLicenseURL: () => void
    handleSubmit: () => void
}

const FormShopper: React.FC<Props> = (props) => {
    const context = useContext(ShopperContext)
    const {
        areas
    } = context.useModelListArea(true)

    return (
        <React.Fragment>
            <Form
                onFinish={props.handleSubmit}
            >
                <Row>
                    <Col span={16}>
                        <Row className="row-form">
                            <Col>
                                <Form.Item
                                    name="imageURL"
                                    valuePropName="file"
                                    initialValue={props.fields.imageURL}
                                >
                                    <UploadPhoto 
                                        featureName="shopper"
                                        imageURL={props.fields.imageURL}
                                        getLink={props.handleSetImageURL}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Nama</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="name"
                                    initialValue={props.fields.name}
                                    rules={[{ required: true, message: 'Nama wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input nama"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>No. Telepon</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="PhoneNumber"
                                    initialValue={props.fields.phoneNumber}
                                    rules={[
                                        { required: true, message: 'Nomor Telepon merchant wajib diisi!'},
                                        { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input nomor telepon"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Email</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="Email"
                                    initialValue={props.fields.email}
                                    rules={[
                                        { required: true, message: 'Email merchant wajib diisi!' },
                                        { type: "email", message: 'Format email yang kamu isi salah!' },
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input email"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Area</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="areaID"
                                    initialValue={props.fields.areaID === "" ? null : props.fields.areaID}
                                    rules={[{ required: true, message: 'Area wajib dipilih!' }]}
                                >
                                    <Select
                                        placeholder="Pilih area"
                                    >
                                        {areas.records.map((area:{id:number, name:string}, key:number) => (
                                            <Select.Option key={key} value={area.id}>{area.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Alamat</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="address"
                                    initialValue={props.fields.address}
                                    rules={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                >
                                    <Input.TextArea 
                                        rows={3} 
                                        className="form-input" 
                                        placeholder="Input alamat shopper"
                                        value={props.fields.address}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Status Aktif</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="isActive"
                                    valuePropName= "checked"
                                    initialValue={props.fields.isActive}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        // defaultChecked={data.IsActive} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Foto KTP</Col>
                            <Col>
                                <Form.Item
                                    name="identityURL"
                                    valuePropName="file"
                                    initialValue={props.fields.identityURL}
                                >
                                    <UploadPhoto 
                                        featureName="shopper"
                                        imageURL={props.fields.identityURL}
                                        getLink={props.handleSetIdentityURL}
                                    />
                                </Form.Item>
                            </Col>
                        </Row> 
                        <Row className="row-form">
                            <Col span={7}>Foto SIM</Col>
                            <Col>
                                <Form.Item
                                    name="drivingLicenseURL"
                                    valuePropName="file"
                                    initialValue={props.fields.drivingLicenseURL}
                                >
                                    <UploadPhoto 
                                        featureName="shopper"
                                        imageURL={props.fields.drivingLicenseURL}
                                        getLink={props.handleSetDrivingLicenseURL}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>  
                    </Col>
                </Row>
                <Row style={{paddingTop: "20px"}}>
                    <Col span={24}>
                        <Form.Item>
                            <OkButton>Simpan</OkButton>
                            <Link to="/shopper">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Form.Item>
                    </Col>
                </Row> 
            </Form>
        </React.Fragment>
    )
}

export default FormShopper
