import React from "react"
import ContextProvider from "../context/ContextProvider"
import List from "./list/List"

const Area: React.FC = () => {
    return (
        <ContextProvider>
            <List />
        </ContextProvider>
    )
}

export default Area