import React, {createContext} from "react"
import {useModelList} from "./ListContext"
import {useModelDetail} from "./DetailContext"
import {useModelFilter} from "./FilterContext"

export const TransactionPaymentContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList,
        useModelDetail,
        useModelFilter
    }

    return (
        <TransactionPaymentContext.Provider value={context}>
            {props.children}
        </TransactionPaymentContext.Provider>
    )
}

export default ContextProvider