import moment from "moment";

const range = (start:any, end:any) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export const disabledDate = (current:any) => {
  // Can not select days before today and today
  return current && current < moment().endOf('day').subtract(1, 'day');
}

export const disabledRangeTime = (_:object, type:any) => {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
}