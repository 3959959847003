import {useState, useEffect} from "react"
import {IListAkun, initialDataList} from "../ModelAkun"
import {useQuery} from "../../../../../helpers/Formatter"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {merchantaccount} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelList() {
    const query = useQuery()
    const [fields, setFields] = useState<IListAkun>(initialDataList)
    const [params, setParams] = useState<{
        entityID: string | null,
        page: number,
        limit: number,
        q: string
    }>({
        entityID: query.get("id"),
        page: 1,
        limit: 10,
        q: ""
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async (queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantaccount}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields(res.data)
            setLoading(false);
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data akun',
                placement: "topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page: number, pageSize: number | undefined) => {
        setParams({ ...params, page: page })
    }

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            setParams({ ...params, q: e.target.value, page: 1 })
        }
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleDelete = async (userId: string) => {
        toggle()
        try {
            const url = `${base_url}/${merchantaccount}/${userId}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.delete(url, {
                headers: headers,
                params: { entityID: localStorage.getItem("merchantId") }
            })
            notification.success({
                message: 'Yuhuu!',
                description: 'Menghapus data akun berhasil!',
                placement: "topRight"
            });
            fetchData(params)
        } catch (error) {
            notification.error({
                message: "Ups!",
                description:
                    'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
        }
    }

    return {
        fields,
        params,
        loading,
        visible,
        fetchData,
        handlePageChange,
        handleSubmitSearch,
        toggle,
        handleDelete
    }
}