import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IModelData} from "../ModelItem"
import {base_url} from "../../../../../config/url-config"
import {itemfood} from "../../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<string>("")
    const [fields, setFields] = useState<IModelData>({
        data: [],
        pagination: {
            page: 0,
            limit: 0,
            totalData: 0,
            totalPage: 0
        }
    })
    const [params, setParams] = useState<{}>({
        // merchantId: localStorage.getItem("merchantId"),
        page: 0,
        limit: 10
    })
    const [visible, setVisible] = useState<boolean>(false)

    const fetchData = async (params: {}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${itemfood}`;
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
            const res = await axios.get(url, {
                headers: headers,
                params: {...params, ...qp.value}
            })
            setFields({ data: res.data.payload, pagination: res.data.pagination })
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                    'Terjadi kesalahan saat mengakses item merchant',
                placement: "topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            // setParams({ ...params, keyword: e.target.value })
            qp.queryParams.set("keyword", e.target.value);
            qp.handleQueryParams();
            setParams({...params, page: 0})
        }
    }

    const handleFilter = (values: {}) => {
        setParams({ ...params, ...values, page: 0 })
    }

    const handleReset = () => {
        setParams({
            // merchantId: localStorage.getItem("merchantId"),
            page: 0,
            limit: 10
        })
    }

    const handlePageChange = (page: number | undefined, pageSize: number | undefined) => {
        let currentPage = Number(page) - 1;
        setParams({ ...params, page: currentPage })
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleUpdateStatus = async(id:string, status:boolean) => {
        setLoading(`switch${id}`)
        try {
            await axios.put(`${base_url}/${itemfood}/updatestatus`, {id: id, status: status}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch (error) {

        }
    }

    const handleDelete = async (id: string) => {
        setLoading("delete")
        try {
            const res = await axios.delete(`${base_url}/${itemfood}/delete/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: { entityID: localStorage.getItem("merchantId") }
            })
            // console.log(res);
            if (res.data.statusCode === 200) {
                toggle()
                fetchData(params)
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Menghapus data service berhasil!',
                    placement: "bottomRight",
                });
                setLoading("")
            }
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
            setLoading("")
        }
    }

    return {
        fields,
        visible,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        toggle,
        handleUpdateStatus,
        handleDelete
    }
}