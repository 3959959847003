import React from 'react'
import {Route} from "react-router-dom"
import HobbyContextProvider from "./context/ContextProvider"
import ListMerchantHobby from "./list/List"
import DetailMerchantHobby from "./detailmerchanthobby/DetailMerchantHobby"
import CreateMerchantHobby from "./createmerchanthobby/CreateMerchantHobby"
import EditMerchantHobby from "./editmerchanthobby/EditMerchantHobby"
import Service from "./service/Service"
import Account from "./akun/Akun"
import Voucher from "./voucher/Voucher"
import Transaction from "./transaction/Transaction"

const MerchantHobby: React.FC = () => {
    return (
        <HobbyContextProvider>
            <Route path="/hobi" exact={true} component={ListMerchantHobby} />
            <Route path="/hobi/detail" exact={true} component={DetailMerchantHobby} />
            <Route path="/hobi/tambah" exact={true} component={CreateMerchantHobby} />
            <Route path="/hobi/ubah" exact={true} component={EditMerchantHobby} />
            <Route path="/hobi/service" exact={false} component={Service} />
            <Route path="/hobi/akun" exact={false} component={Account} />
            <Route path="/hobi/voucher" exact={false} component={Voucher} />
            <Route path="/hobi/transaksi" exact={false} component={Transaction} />
        </HobbyContextProvider>
    )
}

export default MerchantHobby
