import React, {createContext} from "react"
import {useModelList} from "./ContextList"

export const VoucherContext = createContext<any>(null)

const ContextProvider:React.FC<{children:any}> = (props) => {
    const context = {
        useModelList
    }

    return (
        <VoucherContext.Provider value={context}>
            {props.children}
        </VoucherContext.Provider>
    )
}

export default ContextProvider