import React, { useContext } from 'react'
import {useLocation} from "react-router-dom"
import {KasproContext} from '../context/ContextProvider'
import {DetailPageLayout} from "../../../templates"
import {Loading} from "../../../../components/atoms"
import FormVoucher from '../form/Form'

import "./edit.scss"

const Edit: React.FC = () => {
    const location = useLocation()
    const voucherId = location.search.split("=")[1]
    const context = useContext(KasproContext)
    const {
        loading,
        fields,
        submitPhoto,
        handleSubmit
    } = context.useModelDetail(voucherId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormVoucher
                        data={fields}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Edit
