import React from 'react'
import {Button} from "antd"

import "./featurebutton.scss"

interface Props {
    label: any
    onClick?: () => any
    icon?: any
}

const FeatureButton: React.FC<Props> = (props) => {
    return (
        <Button
            onClick={props.onClick}
            className="feature-button"
            icon={props.icon}
            // size="large"
        >
            {/* <Row
                align="middle"
                justify="center"
                style={{height: "100%"}}
            >
                <Col> */}
                    {props.label}
                {/* </Col>
            </Row> */}
        </Button>
    )
}

export default FeatureButton
