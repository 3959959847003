import React, {useContext} from 'react'
import {BannerConfigContext} from "../context/ContextProvider"
import {Menu, Row, Col, Dropdown, Pagination} from "antd"
import {FormatDate} from "../../../../helpers/Formatter"
import {PageLayout} from "../../../templates"
import CreateBannerConfig from "../create/Create"
import {Search, Table, ModalConfirmation} from "../../../../components/atoms"
import EditBannerConfig from '../edit/edit';

import "./list.scss"
import Avatar from 'antd/lib/avatar/avatar'
import { ModelBannerConfig } from '../ModelBannerConfig'

const List: React.FC = () => {
    const context = useContext(BannerConfigContext)
    const {
        params,
        fields,
        visible,
        loading,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleDelete,
        toggle
    } = context.useModelList()

    const columns = [
        {
            title: "Banner",
            render: (data:{image:string}) => <Avatar shape="square" size={64} src={data.image} />
        },
        {
            title: "Judul",
            dataIndex: "title",
        },
        {
            title: "Tanggal Buat",
            render: (data:{createdAt:String|null}) => data.createdAt !=null? FormatDate(data.createdAt.toString()):"-"
        },
        {
            title: "Tanggal Ubah",
            render: (data:{updatedAt:String|null}) => data.updatedAt !=null? FormatDate(data.updatedAt.toString()):"-"
        },
        {
            title: "Aksi",
            render: (data:ModelBannerConfig) => {
                const menu = (
                    <Menu style={{width: "100px"}}>
                        <Menu.Item>
                            <EditBannerConfig
                                data={data} 
                                fetchData={fetchData} 
                                params={params}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <Row onClick={toggle}>
                                <Col span={8}>
                                    <i className="far fa-trash-alt" />
                                </Col>
                                <Col span={16}>
                                    Hapus
                                </Col>
                            </Row>
                            <ModalConfirmation 
                                visible={visible} 
                                toggle={toggle}
                                submitDelete={() => handleDelete(data.id)}
                            />
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        },
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <CreateBannerConfig 
                            fetchData={fetchData} 
                            params={params}
                        />
                        {/* <FilterPaymentConfig /> */}
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)}     
                />}
                content={
                    <div>
                        <Table 
                            data={fields.data}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                            loading={loading}
                            // scroll={{y: 550}}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            current={params.pageNumber} 
                            pageSize={10} 
                            total={fields.totalData} 
                            onChange={handlePageChange} 
                            showSizeChanger={false}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default List
