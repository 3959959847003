import React, {createContext} from "react"
import {useModelList} from "./ListContext"
import {useModelDetail} from "./DetailContext"
import {useModelListCategory} from "./CategoryContext"

export const ItemContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList,
        useModelDetail,
        useModelListCategory
    }

    return (
        <ItemContext.Provider value={context}>
            {props.children}
        </ItemContext.Provider>
    )
}

export default ContextProvider