import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {base_url} from "../../../../config/url-config"
import {bannerConfig} from "../../../../config/endpoint"
import { Form} from "antd"
import {Row, Col} from "antd"
import {Modal, OkButton, BackButton} from "../../../../components/atoms"
import FormBanner from "../form/form"

import "./editpaymentconfig.scss"
import { ModelBannerConfig } from '../ModelBannerConfig'
import { notificationDangger, notificationSuccess } from '../../../../components/atoms/notification/Notification'

interface Props {
    data:ModelBannerConfig,
    fetchData: (queryparams:{}) => void
    params: {
        pageNumber:number
        pageLimit:number
        search:string
    }
}

const EditBannerConfig: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<ModelBannerConfig>({
            image:"",
            title:"",
            body:'',
            isActive:false,
            data:{
                merchantID:"",
                click:""
            },
            appName:["com.manyoption"]
    })

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    const submitPhoto = (url:string) => {
        console.log(url);
      setFields({...fields, image: url})
  }

    const handleChange = (changedValues:{}) => {
        // console.log(changedValues);
        setFields({...fields, ...changedValues})
    }   

   

    const handleSubmit = async(values:ModelBannerConfig) => {
        const url:string = `${base_url}/${bannerConfig}`;
        const data ={...fields, ...values};
       
        try {
            const res = await axios.put(`${url}/${props.data.id}`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            if (res.status === 200) {
                toggle()
                form.resetFields()
                setFields({
                    image:"",
                    title:"",
                    body:'',
                    isActive:false,
                    data:{
                        merchantID:"",
                        click:""
                    },
                    appName:["com.manyoption"]
                });
                notificationSuccess({
                    message:"Yuhuuu",
                    description:"Mengubah data banner berhasil!",
                    placement:'bottomRight'
                })

                props.fetchData(props.params)
            }
        } catch (error) {
            notificationDangger({
                message: "Ups",
                description:
                  'Terjadi kesalahan pada saat memperbaharui data',
                placement: "bottomRight",
            });
        }
    }

    useEffect(() => {
        setFields(props.data)
    }, [props.data])

    return (
        <React.Fragment>
            <Row onClick={toggle}>
                <Col span={8}>
                    <i className="far fa-edit" />
                </Col>
                <Col span={16}>
                    Ubah
                </Col>
            </Row>

            <Modal
                onCancel={toggle}
                title="Edit Banner"
                visible={modal}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                >
                    <FormBanner 
                        fields={fields} 
                        submitPhoto={submitPhoto}
                    />
                    <Row>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default EditBannerConfig
