import React, { useState} from 'react'
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {uploadphoto} from "../../../../config/endpoint"
import {Form, Row, Col,  notification, Input, Switch, Upload, Spin,} from "antd"
import { CloseOutlined, CheckOutlined, LoadingOutlined, InboxOutlined } from '@ant-design/icons';

import "./formpaymentconfig.scss"
import Avatar from 'antd/lib/avatar/avatar'
import { PropsFormBanner } from '../ModelBannerConfig'


const FormBanner: React.FC<PropsFormBanner> = (props) => {

    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            props.submitPhoto(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload upload foto .',
            });
        }
    }
   
    
    return (
        <React.Fragment>
            <Row style={{marginBottom: "8px"}}>
                <Col span={24}>
                    <Upload 
                        showUploadList={false} 
                        onChange={handleUpload} 
                        >
                       <div className="upload-photo" style={{width:'100%', padding:"5px", borderRadius:'5px', minWidth:'150px'}}>
                            {loadingUpload ? (
                                <div style={{paddingTop: "25px"}}>
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                </div>
                            ) : (
                                props.fields.image === "" ? (
                                    <div style={{width:'100%'}} > 
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined style={{fontSize:40}} />
                                        </p>
                                        <p className="ant-upload-text">Klik untuk upload image banner!</p>
                                  
                                        </div>
                                ) : (
                                    <Avatar shape="square" style={{width:'100%',height:'100%', }} src={props.fields.image}  />
                                )
                            )}
                        </div>
                    </Upload>
                </Col>
            </Row>
            <Row style={{marginBottom: "8px"}}>
                <Col>
                    Judul
                </Col>
            </Row>
            <Row style={{marginBottom: "10px"}}>
                <Col span={24} >
                    <Form.Item
                        name="title"
                        initialValue={props.fields.title}
                    >
                        <Input 
                            type="text"
                            placeholder="Judul Banner"
                            className="form-input" 
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{marginBottom: "8px"}}>
                <Col>Status</Col>
            </Row>
            <Row style={{marginBottom: "10px"}}>
                <Col span={4}>
                    <Form.Item
                        name="isActive"
                        valuePropName= "checked"
                        initialValue={props.fields.isActive}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />} 
                        />
                    </Form.Item>
                </Col>
               
            </Row>
           
        </React.Fragment>
    )
}

export default FormBanner
