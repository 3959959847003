import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {bookinghobby} from "../../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        page:number,
        total_page:number,
        total_record:number,
        limit:number,
        records:{}[]
    }>({
        page: 1,
        total_page: 0,
        total_record: 0,
        limit: 10,
        records: []
    })
    const [params, setParams] = useState<{
        merchantID:string|null,
        pageNumber:number,
        search:string
    }>({
        merchantID: localStorage.getItem("merchantId"),
        pageNumber: 1,
        search: ""
    })

    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${bookinghobby}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: params
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, search: e.target.value, pageNumber: 1})
         }
    }

    const handleFilter = (values:{}) => {
        console.log(values);
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantId"),
            pageNumber: 1,
            search: ""
        })
    }

    return {
        loading,
        fields,
        params,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    }
}