import React from 'react'
import {FeatureButton} from '../../../../components/atoms'

const Filter: React.FC = () =>{
    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter"/> Filter</div>}
            />
        </React.Fragment>
    )
}

export default Filter