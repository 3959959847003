import React from 'react'
import { Row, Col } from "antd"

import "./pagelayout.scss"

interface Props {
    leftTop?: any,
    rightTop?: any,
    content?: any,
    footer?: any
}

const PageList: React.FC<Props> = (props) => {
    return (
        <div className="pagelist-container">
            <Row className="pagelist-top-container">
                <Col
                    span={19}
                    className="left-top"
                >
                    {props.leftTop}
                </Col>
                <Col
                    span={5}
                    className="right-top"
                >
                    {props.rightTop}
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    className="pagelist-content-container"
                >
                    {props.content}
                </Col>
            </Row>
            <Row className="pagelist-footer-container">
                <Col span={24}>
                    {props.footer}
                </Col>
            </Row>
        </div>
    )
}

export default PageList
