import React, {createContext} from "react"
import {useModelList} from "./ContextList"
import {useModelDetail} from './ContextDetail'
import {useModelLayanan} from './ContextLayanan'

export const VoucherContext = createContext<any>(null)

const ContextProvider:React.FC<{children:any}> = (props) => {
    const context = {
        useModelList,
        useModelDetail,
        useModelLayanan
    }

    return (
        <VoucherContext.Provider value={context}>
            {props.children}
        </VoucherContext.Provider>
    )
}

export default ContextProvider