import React from 'react';
import ImageInv from '../../../assets/icon-inv.png';
import { FormatDate, FormatRp } from '../../../helpers/Formatter';
import { IDetailTransaction } from '../../pages/pasar/transaksi/ModelTransaction';

interface IProps {
    data: IDetailTransaction
}

function InvoiceShopper(props: IProps) {

    const data = props.data;
    const items = data.itemOrder.map((value) => {
        value.subtotal = Math.round(((value.itemPrice - ((10 / 100) * value.itemPrice))) * value.qty);
        return value;
    })
    const totals = items.reduce((value, element) => {
        return value + element.subtotal;
    }, 0)

    return (
        <div className="w-50% px-12 border-right">
            <div className="mb-29 flex justify-between" >
                <div >
                    <img style={{ height: 20 }} src={ImageInv} alt={"Invoice"} />
                </div>
                <h5 className="text-lg font-w600">List Belanja</h5>
            </div>
            <div className="flex justify-between">
                <div className="w-50%">
                    <table>
                        <tbody>
                            <tr>
                                <td className="text-sm">Nomor</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.invoiceNumber}</td>
                            </tr>
                            <tr>
                                <td className="text-sm">Nama Cust.</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.userName}</td>
                            </tr>
                            <tr>
                                <td className="text-sm">No. Ponsel Cust.</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.userPhone}</td>
                            </tr>
                            <tr>
                                <td className="text-sm">Alamat</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">
                                    {data.address}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-sm">Catatan Alamat</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.addressNote || "-"}</td>
                            </tr>
                            <tr>
                                <td className="w-40% text-sm">Estimasi Pengiriman</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs"> {data.estimasi}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div >
                    <table>
                        <tbody>
                            <tr>
                                <td className="text-sm">Tanggal Pemesanan</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{FormatDate(data.orderDate)}</td>
                            </tr>
                            <tr>
                                <td className="text-sm">Nama Shopper</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.shopperName}</td>
                            </tr>
                            <tr>
                                <td className="text-sm">No. Ponsel Shopper</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.shopperPhoneNumber}</td>
                            </tr>
                            <tr>
                                <td className="text-sm">Area</td>
                                <td className="text-xs">:</td>
                                <td className="text-xs">{data.areaName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="divider" ></div>
            <div>
                <table className="tbl-items ">
                    <thead>
                        <tr className="text-sm">
                            <th className="">Nama Produk</th>
                            <th className="text-center" >Jumlah</th>
                            <th className="text-center " >Adm</th>
                            <th className="text-center ">Shp</th>
                            <th className="text-center">Catatan</th>
                            <th className="text-right ">Harga Maksimal</th>
                        </tr>
                    </thead>
                    <tbody className="text-xs">
                        {

                            items.map((value, key) => {
                                return <tr key={key}>
                                    <td>{value.itemName}</td>
                                    <td className="text-center">{value.qty}</td>
                                    <td className="text-center ">
                                        <div className="flex justify-center">
                                            <div className="box"></div>
                                        </div>
                                    </td>
                                    <td className="text-center ">
                                        <div className="flex justify-center">
                                            <div className="box"></div>
                                        </div>
                                    </td>
                                    <td className="text-center">{value.note || "-"}</td>
                                    <td className="text-right">{FormatRp(value.subtotal)}</td>
                                    {/* (1000-10%*1000)*3 */}
                                </tr>
                            })
                        }

                    </tbody>
                </table>
                <div className="flex justify-between bg-total">
                    <div className="text-md font-w700">
                        Total
                    </div>
                    <div className="text-md font-w700">
                        {FormatRp(totals)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceShopper;