import React, {useContext} from 'react'
import {useQueryParams} from "../../../../../helpers/Formatter"
import {AyopopTransactionContext} from "../context/ContextProvider"
import {PageLayout} from "../../../../templates"
import Filter from "../filter/Filter"
import {Search, Table, FeatureButton} from "../../../../../components/atoms"
import TableColumns from "./Columns"
import {Row, Col, Pagination} from "antd"

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(AyopopTransactionContext)
    const {
        fields,
        loading,
        handlePageChange,
        handleSubmitSearch,
        fetchParams,
        options,
        handleFilter,
        handleReset,
        handleRefreshStatus
    } = context.useModelList()
    const {columns} = TableColumns(handleRefreshStatus)

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e: any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <Filter 
                            fetchParams={fetchParams}
                            options={options}  
                            handleFilter={handleFilter} 
                        />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.data}
                            rowKey="id"
                            loading={loading === "fetch" ? true : false}
                            pagination={false}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            {`Total ${fields.totalData} Transaksi`}
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination
                                current={fields.currentPage}
                                pageSize={10}
                                total={fields.totalData}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
