import { notification } from 'antd';

interface ModelNotification{
    message?:string,
    description?:string,
    placement?:'topLeft'|"topRight"|'bottomRight'|'bottomLeft',

}

export const notificationSuccess =(props:ModelNotification) => {
    return notification.success({message:props.message, description:props.description, placement:props.placement})
}

export const notificationDangger =(props:ModelNotification) => {
    return notification.error({message:props.message, description:props.description, placement:props.placement})
}