import {useState, useEffect} from "react"
import {IDetail} from "../TransactionModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {orderjasa} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(bookingId:string = "") {
    const [fields, setFields] = useState<IDetail>({
        id: "",
        invoiceNumber: "",
        orderNumber: 0,
        userName: "",
        orderDate: "",
        status: "",
        address: "",
        addressLatitude: "",
        addressLongitude: "",
        addressNote: "",
        adminFee: 0,
        cancelReason: null,
        createdAt: "",
        orderNote: "",
        deliveryFee: 0,
        deliveryType: "",
        isReviewed: false,
        merchantName: "",
        orderDateExpired: null,
        totalQty: 0,
        updatedAt: "",
        userEmail: "",
        userID: "",
        userPhone: "",
        orderType: "",
        totalPrice: 0,
        totalDiscount: 0,
        totalPromo: 0,
        totalPay: 0,
        sharingFee: 0,
        paymentData: {
            adminFee: 0,
            bank: "",
            bankName: "",
            checkoutURL: "",
            duration: 0,
            expiredAt: "",
            invoiceAmount: 0,
            invoiceID: "",
            invoiceNumber: "",
            paymentID: "",
            paymentMethod: "",
            paymentProvider: "",
            paymentState: "",
            token: "",
            totalAmount: 0,
            transactionDate: "",
            trxID: "",
            userPayNumber: ""
        },
        items: [],
        merchantID: ""
    })
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async (id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${orderjasa}/${id}`

            const res = await axios.get(url, {
                headers: headers
            })
            setLoading(false)
            setFields(res.data.payload)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(bookingId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId])

    const handleAccept = () => {
        handleUpdateTransaction("APPROVE")
    }

    const handleReject = () => {
        handleUpdateTransaction("CANCEL")
    }

    const handleUpdateTransaction = async(status:string) => {
        const value = {orderID: fields.id, state: status, merchantID: fields.merchantID}
        setLoading(true)
        try {
            const url = `${base_url}/${orderjasa}/byMerchant/confirm`

            await axios.post(url, value, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    const handleNextStep = async() => {
        const value = {orderID: fields.id, merchantID: fields.merchantID}
        setLoading(true)
        try {
            const url = `${base_url}/${orderjasa}/byMerchant/next`

            await axios.post(url, value, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    return {
        fields,
        loading,
        handleAccept,
        handleReject,
        handleUpdateTransaction,
        handleNextStep
    }
}