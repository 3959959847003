import React, {useEffect, useContext} from 'react'
import {PasarTransactionContext} from "../context/ContextProvider"
import {Row, Col, Modal, Select, Input, Button} from "antd"
import {OkButton, BackButton} from "../../../../../components/atoms"

import "./refund.scss"

interface Props {
    orderID: string
    invoiceNumber: string
    itemOrder: {
        itemID:number, 
        itemName:string,
        qty:number
    }[]
}

const Refund: React.FC<Props> = (props) => {
    const context = useContext(PasarTransactionContext)
    const {
        itemOrderAll,
        setItemOrderAll,
        fields,
        setFields,
        modal,
        toggle,
        handleSelect,
        handleUpdateQty,
        handleAddRow,
        handleDeleteRow,
        handleNote,
        loading,
        handleSubmit
    } = context.useModelRefund()

    useEffect(() => {
        modal === true && setFields({...fields, orderID: props.orderID})
        setItemOrderAll(props.itemOrder)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orderID, props.itemOrder, modal])

    // congsole.log(fields);

    return (
        <React.Fragment>
            <Row onClick={toggle}>
                <Col span={8}>
                    <i className="fas fa-shopping-basket" />
                </Col>
                <Col span={16}>
                    Refund
                </Col>
            </Row>

            <Modal
                title="Form Refund"
                onCancel={toggle}
                visible={modal}
                className="modal-refund"
                footer={[<div key="footer"></div>]}
            >
                <Row className="item-detail-container" style={{marginBottom: "15px"}}>
                    <Col span={6} className="label-container">
                        No. Order
                    </Col>
                    <Col span={13}>
                        <div
                            className="value-container"
                            style={{color: "#009b00"}}
                        >
                            {props.invoiceNumber}
                        </div>
                    </Col>
                </Row>
                <Row className="table-head-operation-hour">
                    <Col span={24}>
                        <Row className="table-head-container">
                            <Col span={8} style={{paddingLeft: "5px"}}>Item</Col>
                            <Col span={5} className="head-col-day" style={{paddingLeft: "5px"}}>Kuantiti</Col>
                            <Col span={11} className="head-col-hours" style={{paddingLeft: "5px"}}>Harga Satuan Setelah Diskon</Col>
                        </Row>
                    </Col>
                </Row>
                {fields.refundOrderDetail.map((item:{itemID:number, itemQty:number, itemPrice:number, itemDiscount:number}, key:number) => (
                    <Row className="table-body-data" key={key}>
                        <Col span={8} style={{padding: "0px 5px"}}>
                            <Select 
                                placeholder="Item" 
                                style={{width: "100%"}}
                                onChange={handleSelect}
                                value={item.itemID === 0 ? undefined : item.itemID}
                                dropdownMatchSelectWidth={false}
                            >
                                {props.itemOrder.map((item:{itemID:number, itemName:string}, i:number) => (
                                    <Select.Option 
                                        key={`${i},${key}`} 
                                        value={item.itemID}
                                        disabled={fields.refundOrderDetail.filter((field:{itemID:number}) => field.itemID === item.itemID).length > 0 ? true : false}
                                    >
                                        {item.itemName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={5} style={{padding: "0px 5px"}}>
                            <Input 
                                className="refund-quantity-form" 
                                type="number"
                                placeholder="Kuantiti"
                                value={item.itemID === 0 ? "" : item.itemQty}
                                min="1"
                                max={item.itemID === 0 ? "1" : itemOrderAll.filter((itemOrder:{itemID:number}) => itemOrder.itemID === item.itemID)[0].qty}
                                disabled={item.itemID === 0 ? true : false}
                                onKeyPress={(e) => e.preventDefault()}
                                onChange={e => handleUpdateQty(e, key)}
                            />
                        </Col>
                        <Col span={7} style={{paddingLeft: "5px"}}>
                            <Input 
                                className="form-input" 
                                type="number"
                                placeholder="Harga"
                                value={item.itemID === 0 ? "" : item.itemPrice - item.itemDiscount}
                                disabled
                            />
                        </Col>
                        <Col span={4} style={{paddingLeft: "10px"}}>
                            <Row>
                                <Col span={12}>
                                    <Button
                                        disabled={itemOrderAll.length === key + 1 ? true : false}
                                        onClick={handleAddRow}
                                        className="add-refund-btn"
                                    >
                                        +
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    {key !== 0 ? (
                                        <Button
                                            className="delete-refund-btn"
                                            onClick={() => handleDeleteRow(key)}
                                        >
                                            x
                                        </Button>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ))}
                <Row className="table-body-data">
                    <Col span={8} style={{padding: "0px 5px"}} />
                    <Col span={5} style={{padding: "4px 5px", fontWeight: "bold", fontSize: "15px"}}>
                        Total
                    </Col>
                    <Col span={11} style={{paddingLeft: "5px", paddingRight: "10px"}}>
                        <Input 
                            className="form-input" 
                            type="number"
                            placeholder="Total Refund"
                            value={fields.total}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="table-body-data">
                    <Col span={24}>
                        <Row>
                            <Col style={{paddingLeft: "5px", paddingBottom: "5px"}}>
                                Catatan Refund
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{padding: "0px 5px"}}>
                                <Input.TextArea 
                                    className="form-input"
                                    rows={2} 
                                    placeholder="Input catatan refund"
                                    onChange={e => handleNote(e)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{paddingTop: "20px", textAlign: "center"}}>
                    <Col span={24}>
                        <BackButton
                            onClick={toggle}
                        >
                            Kembali
                        </BackButton>
                        <OkButton
                            onClick={handleSubmit}
                            loading={loading === "submit" ? true : false}
                        >
                                Simpan
                        </OkButton>
                    </Col>
                    </Row>
            </Modal>
        </React.Fragment>
    )
}

export default Refund
