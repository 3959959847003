import {useState, useEffect} from "react"
import {useHistory} from "react-router" 
import {IDetailService, initialDataDetail, IOperation, initialDataOperations, IVenue} from "../ServiceModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {venuehobby} from "../../../../../config/endpoint"
import {notification} from "antd"
import moment from "moment"

export function useModelDetail(id:number | null = null) {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetailService>(initialDataDetail)
    const [operations, setOperations] = useState<IOperation[]>(initialDataOperations)

    const fetchData = async (id:number) => {
        setLoading(true)
        try {
            const url = `${base_url}/${venuehobby}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
            })
            setFields(res.data.payload)
            setLoading(false)
            for (let i = 0; i < res.data.payload.operation.length; i++) {
                let newOperations = [...operations]
                newOperations[res.data.payload.operation[i].day].active = true
                newOperations[res.data.payload.operation[i].day].openHour = res.data.payload.operation[i].openHour
                newOperations[res.data.payload.operation[i].day].closeHour = res.data.payload.operation[i].closeHour
                newOperations[res.data.payload.operation[i].day].timeRate = res.data.payload.operation[i].timeRate
                newOperations[res.data.payload.operation[i].day].id = res.data.payload.operation[i].id
                setOperations(newOperations)
            }
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        id !== null && fetchData(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSetImgURL = (url:string, index:number) => {
        fields.venue.picURL[index] = url
    }

    const addUploadPhoto = () => {
        setFields({...fields, venue: {...fields.venue, picURL: [...fields.venue.picURL, ""]}})
    }

    const handleCheckedDay = (index:number, active:boolean) => {
        const newOperations = [...operations]
        newOperations[index].active = active
        setOperations(newOperations)
    }

    const handleSubmitTimeRate = (
        values:{
            deposit:any, 
            discount:any, 
            timeStart:string,
            timeEnd:string,
            isDiscPercentage:any,
            rate:any,
            hour:any
        }, 
        indextime:number, 
        indexday:number
    ) => {
        values["deposit"] = parseInt(values.deposit)
        values["discount"] = parseInt(values.discount)
        values["timeStart"] = moment(values.hour[0]).format("H:mm")
        values["timeEnd"] = moment(values.hour[1]).format("H:mm")
        values["isDiscPercentage"] = values.isDiscPercentage === "%" ? true : false
        values["rate"] = parseInt(values.rate)
        delete values.hour
        operations[indexday].timeRate[indextime] = values

        // to set closeHour
        let hoursEnd = values.timeEnd.split(":")
        let minutesEnd = parseInt(hoursEnd[0]) * 60 + parseInt(hoursEnd[1])
        let hoursClose = operations[indexday].closeHour.split(":")
        let minutesClose = parseInt(hoursClose[0]) * 60 + parseInt(hoursClose[1])
        let newMinutesClose = Math.max(minutesEnd, minutesClose)
        let newCloseHour = `${Math.floor(newMinutesClose/60)}:${newMinutesClose%60 >= 10 ? newMinutesClose%60 : `0${newMinutesClose%60}`}`
        operations[indexday].closeHour = newCloseHour

        // to set openHour
        let hoursStart = values.timeStart.split(":")
        let minutesStart = parseInt(hoursStart[0]) * 60 + parseInt(hoursStart[1])
        let hoursOpen = operations[indexday].openHour.split(":")
        let minutesOpen = parseInt(hoursOpen[0]) * 60 + parseInt(hoursOpen[1])
        let newMinutesOpen = Math.min(minutesStart, minutesOpen)
        let newOpenHour = `${Math.floor(newMinutesOpen/60)}:${newMinutesOpen%60 >= 10 ? newMinutesOpen%60 : `0${newMinutesOpen%60}`}`
        operations[indexday].openHour = operations[indexday].openHour === "00:00" ? values.timeStart : newOpenHour
    }

    const handleSubmitSchedule = () => {
        const newOperations = (operations.filter(operation => operation.active === true))
        newOperations.forEach(newOperation => delete newOperation.active)
        setFields({...fields, operation: newOperations})
    }

    const handleSubmitVenue = async(values: IVenue) => {
        values["cancelationFee"] = parseInt(values.cancelationFee)
        values["isCancelationPercentage"] = values.isCancelationPercentage === "%" ? true : false
        values["maxBooking"] = parseInt(values.maxBooking)
        values["maxDuration"] = parseInt(values.maxDuration) * 3600
        values["minDuration"] = parseInt(values.minDuration) * 3600
        values["merchantID"] = localStorage.getItem("merchantId")
        values["picURL"] = fields.venue.picURL
        setFields({...fields, venue: {...fields.venue, ...values}})
        fields["venue"] = values
        id === null && delete fields.merchant

        setLoading(true)
        try {
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            if (id !== null) {
                const url = `${base_url}/${venuehobby}/${id}`

                await axios.patch(url, fields, {
                    headers: headers,
                })
            } else {
                const url = `${base_url}/${venuehobby}`

                await axios.post(url, fields, {
                    headers: headers,
                })
            }
            notification.success({
                message: "Yuhuuu",
                description:
                    'Mengubah data service berhasil!',
                placement: "bottomRight",
            });
            setLoading(false)
            history.goBack()
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengubah data service',
                placement: "bottomRight",
            });
            setLoading(false)
        }
    }

    return {
        loading,
        fields,
        fetchData,
        operations,
        handleSetImgURL,
        addUploadPhoto,
        handleCheckedDay,
        handleSubmitTimeRate,
        handleSubmitSchedule,
        handleSubmitVenue
    }
}