import React, {useContext} from 'react'
import {OnGoingPasarTransactionContext} from "../context/ContextProvider"
import {Row, Col, Select} from "antd"
import {Modal, OkButton, BackButton} from "../../../../../components/atoms"

interface Props {
    id: string
    fetchData: any
    params: {}
    status?: string
    handleUpdateStatus?: any
}

const Shopper: React.FC<Props> = (props) => {
    const context = useContext(OnGoingPasarTransactionContext)
    const {
        areas, 
        fields,
        shoppers,
        loading,
        modal,
        active,
        handleChangeArea,
        handleChangeShopper,
        toggle,
        handleSubmit
    } = context.useModelListAssignShopper(props.id)

    const handleSubmitShopper = () => {
        console.log("handleSubmitShopper function running");
        
        handleSubmit()
        props.fetchData(props.params)
        props.status === "PAID" && props.handleUpdateStatus(props.id, "APPROVED")
    }

    return (
        <React.Fragment>
            {props.status === "PAID" ? (
                <Row 
                    onClick={toggle}
                    style={{color: "green"}}
                >
                    <Col span={8}>
                        <i className="fas fa-check" />
                    </Col>
                    <Col span={16}>
                        Approved
                    </Col>
                </Row>
            ) : (
                <Row onClick={toggle}>
                    <Col span={8}>
                        <i className="fas fa-user-cog" />
                    </Col>
                    <Col span={16}>
                        Shopper
                    </Col>
                </Row>
            )}

            <Modal
                title={props.status === "PAID" ? "Form Approved" : "Form Shopper"}
                onCancel={toggle}
                visible={modal}
            >
                <div className="row-form">
                    <Row style={{marginBottom: "5px"}}>
                        <Col span={24}>Area</Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Select 
                                placeholder="Pilih area" 
                                style={{width: "100%"}} 
                                loading={loading === "fetchArea" ? true : false}
                                value={fields.areaID}
                                onChange={handleChangeArea}
                            >
                                {areas.records.map((area:{name:string, id:number}, key:number) => (
                                    <Select.Option key={key} value={area.id}>{area.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </div>
                <div className="row-form">
                    <Row style={{marginBottom: "5px"}}>
                        <Col span={24}>Shopper</Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Select 
                                placeholder="Pilih Shopper" 
                                style={{width: "100%"}} 
                                loading={loading === "fetchArea" ? true : false}
                                value={fields.shopperID}
                                onChange={handleChangeShopper}
                                disabled={active}
                            >
                                {shoppers.records.map((area:{name:string, id:number}, key:number) => (
                                    <Select.Option key={key} value={area.id}>{area.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </div>
                <Row style={{paddingTop: "20px", textAlign: "center"}}>
                    <Col span={24}>
                        <BackButton
                            onClick={toggle}
                        >
                            Kembali
                        </BackButton>
                        <OkButton
                            onClick={handleSubmitShopper}
                            loading={loading === "submit" ? true : false}
                        >
                            Simpan
                        </OkButton>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default Shopper
