import React, { useContext } from 'react'
import {FoodContext} from "../context/ContextProvider"
import { DetailPageLayout } from "../../../templates"
import { Loading } from "../../../../components/atoms"
import FormMerchant from '../form/form'

const Create: React.FC = () => {
    const context = useContext(FoodContext)
    const {
        fields,
        setFields,
        operational,
        loading,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        handleSubmit
    } = context.useModelDetail()

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormMerchant
                        data={fields}
                        setFields={setFields}
                        operational={operational}
                        submitOperationHourStatus={submitOperationHourStatus}
                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                        submitOperationalHour={submitOperationalHour}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Create
