import React, {createContext} from "react"
import {useModelMainApp} from "./MainContext"

export const MainAppContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelMainApp,
    }

    return (
        <MainAppContext.Provider value={context}>
            {props.children}
        </MainAppContext.Provider>
    )
}

export default ContextProvider