import React, { useContext } from 'react'
import {AccountContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import { PageLayout } from "../../../../templates"
import CreateAkun from "../createakun/CreateAkun"
import { Search, Table, BackButton } from "../../../../../components/atoms"
import { Pagination, Row, Col } from "antd"
import { Link } from "react-router-dom"

import "./list.scss"
import { useQuery } from '../../../../../helpers/Formatter'

const List: React.FC = () => {
    const query = useQuery();
    const context = useContext(AccountContext)
    const {
        fields,
        params,
        loading,
        visible,
        fetchData,
        handlePageChange,
        handleSubmitSearch,
        toggle,
        handleDelete
    } = context.useModelList()
    const {columns} = TableColumns(query.get("name"), toggle, visible, handleDelete) 

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search
                    onKeyPress={(e: any) => handleSubmitSearch(e)}
                />}
                leftTop={
                    <CreateAkun
                        merchantName={query.get("name")}
                        // merchantId={queryParams}
                        fetchData={fetchData}
                        params={params}
                    />
                }
                content={
                    <React.Fragment>
                        <div>
                            <Table
                                columns={columns}
                                data={fields.payload ? fields.payload : []}
                                rowKey="id"
                                pagination={false}
                                loading={loading}
                            // scroll={{y: 550}}
                            />
                        </div>
                    </React.Fragment>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/food">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination
                                current={fields.currentPage}
                                pageSize={10}
                                total={fields.totalPage * fields.limit}
                                onChange={handlePageChange}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
