import React, { useEffect } from 'react';
import { useInvoice } from '../../../context/context-invoice';
import InvoiceShopper from './invoice-shopper';
import InvoiceUser from './invoice-user';
import './invoice.scss';

interface IProps { }

const InvoiceBulk: React.FC<IProps> = (props) => {
    const CtxInvoice = useInvoice();


    useEffect(() => {
        if (CtxInvoice.bulkInvoice.isReady) {
            window.print();

        }

    }, [CtxInvoice]);


    if (!CtxInvoice.bulkInvoice.isReady) {
        return null;
    }

    return (
        <div>
            {
                CtxInvoice.bulkInvoice.data.length > 0 ? CtxInvoice.bulkInvoice.data.map((value, key) => {
                    return <div className="invoice" key={key}>
                        <InvoiceShopper data={value} />
                        <InvoiceUser data={value} />
                    </div>
                }) : null
            }
        </div>
    );
}

export default InvoiceBulk;