export const operationalDays: { id:number, key:string, name:string }[] = [
  {
    id: 3,
    key: 'Sunday',
    name: 'Minggu',
  },
  {
    id: 1,
    key: 'Monday',
    name: 'Senin',
  },
  {
    id: 5,
    key: 'Tuesday',
    name: 'Selasa',
  },
  {
    id: 6,
    key: 'Wednesday',
    name: 'Rabu',
  },
  {
    id: 4,
    key: 'Thursday',
    name: 'Kamis',
  },
  {
    id: 0,
    key: 'Friday',
    name: 'Jumat',
  },
  {
    id: 2,
    key: 'Saturday',
    name: 'Sabtu',
  },
];