export interface IListMerchant {
    count: number
    currentPage: number
    data: IDetailMerchant[]
    limit: number
    totalPage: number
}

export interface IDetailMerchant {
    address: string
    avgReview: string
    bankDetail: {
        bankName: string 
        accountName: string
        accountNumber: string
    }
    code: string
    createdAt: string
    description: string
    email: string
    id: string
    image: string
    isActive: boolean
    isOpen: boolean
    latitude: string
    longitude: string
    maxDistance: number
    merchantFee: number
    merchantFeeType: string
    name: string
    operationHour: {
        open: string
        close: string
    }
    operational: {}
    schedule:any
    parentID: string
    paymentAccountID: string
    phone: string
    rate: string
    rateCount: number
    tags: string[]
    type: string
    updatedAt: string
}

export const initialDataList = {
    count: 0,
    currentPage: 1,
    data: [],
    limit: 10,
    totalPage: 1
}

export const initialDataDetail = {
    address: "",
    avgReview: "",
    bankDetail: {
        bankName: "", 
        accountName: "",
        accountNumber: ""
    },
    code: "",
    createdAt: "",
    description: "",
    email: "",
    id: "",
    image: "",
    isActive: false,
    isOpen: false,
    latitude: "",
    longitude: "",
    maxDistance: 0,
    merchantFee: 0,
    merchantFeeType: "",
    name: "",
    operationHour: {
        open: "",
        close: ""
    },
    operational: {},
    schedule: [],
    parentID: "",
    paymentAccountID: "",
    phone: "",
    rate: "",
    rateCount: 0,
    tags: [],
    type: "",
    updatedAt: ""
}