import React, { useEffect } from 'react'
import { Input, Form } from "antd"

import "./search.scss"
import { useQueryParams } from '../../../helpers/Formatter';

interface Props {
    name?: string,
    onChange?: () => any
    onKeyPress?: (e: any) => void,
    value?: string
}

const Search: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const qp = useQueryParams();

    useEffect(() => {
        if (props.value === undefined) {
            form.resetFields();
        } else {
            form.setFieldsValue({ search: props.value });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, qp.value])

    return (
        <div>
            <Form form={form}>
                <Form.Item
                    name="search"
                    className="search-input"
                >
                    <Input
                        placeholder="Cari..."
                        onKeyPress={props.onKeyPress}
                        onChange={props.onChange}
                        prefix={<i className="fas fa-search" style={{ color: "#cecece" }}></i>}
                    />
                </Form.Item>
            </Form>
        </div>
    )
}

export default Search
