import React from 'react'
import ContextProvider from "./context/ContextProvider"
import List from "./list/List"

import "./category.scss"

const Category: React.FC = () => {
    return (
        <ContextProvider>
            <List />
        </ContextProvider>
    )
}

export default Category
