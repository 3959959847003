import {useEffect, useState} from "react"
import {IDetail, initialDataDetail} from "../ModelTransactionFood"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {transactionfood} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(id:string) {
    const [fields, setFields] = useState<IDetail>(initialDataDetail)
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async(orderId:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${transactionfood}/detil/${orderId}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch(error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(id)
    }, [id])

    const handleUpdateStatus = async(status:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${transactionfood}/updatestatus`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}


            await axios.put(url, {orderId: id, status: status}, {
                headers: headers,
            })
            setLoading(false)
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui status`,
            });
            fetchData(id)
        } catch(error) {
            setLoading(false)
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui status',
                placement:"topRight"
            });
        }
    }

    return {
        fields,
        loading,
        handleUpdateStatus
    }
}