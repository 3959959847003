import React, {useState, useEffect} from 'react'
import {FeatureButton, Modal, Loading} from "../../../../components/atoms"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {categoryhobby} from "../../../../config/endpoint"
import {notification} from "antd"
import CreateCategory from "./createcategory/CreateCategory"
import CategoryBox from "./CategoryBox"

import "./category.scss"

const Category: React.FC = () => {
    const [modal, setModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        id:number,
        category:string
    }[]>([])

    const toggle = () => {
        setModal(!modal)
    }

    const fetchData = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${categoryhobby}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleEdit = async(values:{}) => {
        try {
            const res = await axios.patch(`${base_url}/${categoryhobby}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            if (res.status === 200) {
                fetchData()
            }
            // console.log(res);
            
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat menghapus data kategori.',
            });
        }
    }

    const handleDelete = async(id:number) => {
        try {
            const res = await axios.delete(`${base_url}/v3/hobby/category/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            // console.log(res);
            if (res.status === 200) {
                fetchData()
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat meghapus data kategori.',
            });
        }
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-clipboard-list" style={{marginRight: "5px"}} />}
                label="Kategori" 
                onClick={toggle} 
            />

            <Modal
                onCancel={toggle}
                title="Kategori"
                visible={modal}
            >
                <CreateCategory fetchData={fetchData} />
                {loading ? <Loading /> : (
                    <div>
                        {fields.map((field, key) => (
                            <CategoryBox 
                                key={key}
                                name={field.category}
                                id={field.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
            </Modal>
        </React.Fragment>
    )
}

export default Category
