import React, { useContext } from 'react'
import {useQueryParams} from "../../../../helpers/Formatter"
import { PasarContext } from "../context/ContextProvider"
import TableColumns from "./Columns"
import { Link } from "react-router-dom"
import { PageLayout } from "../../../templates"
import { Search, Table, FeatureButton } from "../../../../components/atoms"
import Filter from "../filter/Filter"
import { Pagination } from "antd"

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(PasarContext)
    const {
        fields,
        loading,
        params,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        handleSetMarketId,
        handleUpdateStatus,
        visible,
        setVisible,
        handleDelete
    } = context.useModelList()
    const { columns } = TableColumns(
        handleUpdateStatus,
        loading,
        visible,
        setVisible,
        handleDelete,
        handleSetMarketId
    )

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search 
                    onKeyPress={(e: any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <Link to="/pasar/tambah">
                            <FeatureButton label={<div><i className="fas fa-plus-square" /> Tambah</div>} />
                        </Link>
                        <Filter handleFilter={handleFilter} />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.records}
                            rowKey="id"
                            loading={loading === "fetch" ? true : false}
                            pagination={false}
                        />
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination
                            current={params.pageNumber}
                            pageSize={10}
                            total={fields.total_record}
                            onChange={handlePageChange}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default List
