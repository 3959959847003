import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {ShopperContext} from "../context/ContextProvider"
import {Loading} from "../../../../components/atoms"
import {DetailPageLayout} from "../../../templates"
import Form from "../form/Form"

import "./edit.scss"

const Edit: React.FC = () => {
    const location = useLocation()
    const shopperId = location.search.split("=")[1]
    const context = useContext(ShopperContext)
    const {
       fields,
       loading,
       handleSetImageURL,
       handleSetIdentityURL,
       handleSetDrivingLicenseURL,
       handleSubmit
    } = context.useModelDetail(shopperId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading === "fetch" ? <Loading /> : (
                    <Form 
                        fields={fields}
                        handleSetImageURL={handleSetImageURL}
                        handleSetIdentityURL={handleSetIdentityURL}
                        handleSetDrivingLicenseURL={handleSetDrivingLicenseURL}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Edit
