import {useState, useEffect} from "react"
import { useHistory } from "react-router"
import {ModelDataVoucher, initialDataVoucher} from "../ModelKaspro"
import { base_url } from "../../../../config/url-config"
import {kaspro } from "../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"

export function useModelDetail(id:string | null = null) {
    const history = useHistory()
    const [fields, setFields] = useState<ModelDataVoucher>(initialDataVoucher);
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async (id: string) => {
        setLoading(true)
        try {
            setTimeout(() => {
                setFields(JSON.parse(localStorage.getItem("dataEditKaspro") || '{}'))
                setLoading(false)
            }, 1000)
        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data kaspro',
                    placement: "topRight"
                });
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        if(id !== null) {
            fetchData(id)
        }
    }, [id])

    const handleSubmit = async (values:any) => {
        values['category'] = JSON.parse(values.category)
        values['image'] = fields.image

        // console.log(values)
        setLoading(true)
        try {
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            
            if(id !== null) {
                const url =`${base_url}/${kaspro}/top-up/${id}`;

                await axios.put(url, values, { 
                    headers: headers 
                })
            } else {
                const url =`${base_url}/${kaspro}/top-up`;

                await axios.post(url, values, { 
                    headers: headers 
                })
            }

            notification["success"]({
                message: 'Yeay!',
                description:
                    `Data kaspro berhasil ${id !== null ? "diperbaharui" : "ditambahkan"}.`,
            });
            setLoading(false)
            history.goBack()
        } catch (error) {
            if (error.response) {

                notification["error"]({
                    message: 'Ups!',
                    description:
                        `Terjadi kesalahan pada saat ${id !== null ? "memperbaharui" : "menambahkan"} data kaspro.`,
                });
            }
            setLoading(false)
        }
    }

    const submitPhoto = (path:string) => {
        setFields({...fields, image: path})
    }

    return {
        fields,
        loading,
        submitPhoto,
        handleSubmit
    }
}