import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {notification} from "antd"
import moment from "moment"
import { voucher } from "../../../../config/endpoint"


export const useModelList = (idVoucher:string = "") => {
    const [params, setParams] = useState<{
        page:number
        limit:number
        q:string
    }>({
        page: 1,
        limit: 10,
        q: ""
    })

    const [fields, setFields] = useState<{
        pagination:any
        data: {}[]
    }>({
        pagination: {},
        data: []
    })

    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<string>("")
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${voucher}`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields({
                pagination:res.data.pagination,
                data:res.data.payload
            })

            setLoading("")
        } catch (error) {
            setLoading("false")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data Voucher',
                placement:"topRight"
            });
        }
    }

    const fetchDataTransaction = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${voucher}/${idVoucher}/transaction`

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields({
                pagination:res.data.pagination,
                data:res.data.payload
            })

            setLoading("")
        } catch (error) {
            setLoading("false")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data Voucher Transaksi',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        if(idVoucher !== "") {
            fetchDataTransaction(params)
        } else {
            fetchData(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleFilter = (values:{start_date?:string, end_date?:string, layanan_id?:string, is_active?:any, voucher_category?:string, visible?:any}) => {
        values["is_active"] = values.is_active === "true" ? "1" : values.is_active === "false" ? "0" : undefined
        values["visible"] = values.visible === "true" ? "1" : values.visible === "false" ? "0" : undefined
        values["start_date"] = values.start_date !== undefined ? moment(values.start_date[0]).format("YYYY-MM-DD") : undefined
        values["end_date"] = values.start_date !== undefined ? moment(values.start_date[1]).format("YYYY-MM-DD") : undefined
        
        if (values.visible === undefined && values.is_active === undefined && values.end_date === undefined && values.start_date === undefined && values.layanan_id === undefined ) {
            delete values.is_active
            delete values.visible
            delete values.start_date
            delete values.end_date
            delete values.layanan_id
        } else if (values.is_active === undefined) {
            delete values.is_active
        } else if (values.visible === undefined) {
            delete values.visible
        } else if (values.voucher_category === undefined) {
            delete values.voucher_category
        }
        
        setParams({...params, ...values, page: 1})
    }

    const handleDelete = async(id:number) => {
        setLoading("delete")
        try {
            await axios.delete(`${base_url}/${voucher}/${id}`, {
                headers: headers
            })
            setLoading("")
            setVisible(false)
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus data voucher`,
            });
            fetchData(params)
        } catch(error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menghapus data voucher',
                placement:"topRight"
            });
        }
    }

    const checkValidityDate = (data:any) => moment().isAfter(moment(data.expired_date));

    const handleUpdateStatus = async(checked:boolean, data:any) => {
        setLoading(`switch${data.id}`)

        try {
            const urlVoucherDetail = `${base_url}/${voucher}/${data.id}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.get(urlVoucherDetail, { headers: headers });
            const dataDetailVoucher = res?.data?.payload

            if(checkValidityDate(dataDetailVoucher)) {
                setLoading("")
                return notification["error"]({
                    message: 'Gagal!',
                    description:
                        'Status voucher gagal diperbaharui. Lakukan pembaharuan data!',
                });
            }

            const formData = new FormData()
            formData.append("is_active", checked ? "1" : "0")
            formData.append("promo_code", dataDetailVoucher.promo_code)
            formData.append("description", dataDetailVoucher.description)
            formData.append("image", dataDetailVoucher.image)
            formData.append("voucher_category", dataDetailVoucher.voucher_category)
            formData.append("nominal_type", dataDetailVoucher.nominal_type)
            formData.append("nominal", dataDetailVoucher.nominal)
            formData.append("maximal_nominal", dataDetailVoucher.maximal_nominal)
            formData.append("start_date", dataDetailVoucher.start_date)
            formData.append("expired_date", dataDetailVoucher.expired_date)
            formData.append("is_visible", dataDetailVoucher.is_visible)
            formData.append("minimal_transaction", dataDetailVoucher.minimal_transaction)
            formData.append("limit_promo_total", dataDetailVoucher.limit_promo_total)
            formData.append("limit_per_days", dataDetailVoucher.limit_per_days)
            formData.append("limit_per_user", dataDetailVoucher.limit_per_user)
            formData.append("included_layanan", dataDetailVoucher.included_layanan)
            formData.append("excluded_layanan", dataDetailVoucher.excluded_layanan)
            formData.append("included_category", dataDetailVoucher.included_category)
            formData.append("excluded_category", dataDetailVoucher.excluded_category)
            formData.append("merchant", dataDetailVoucher.merchant)
            formData.append("payment_provider", dataDetailVoucher.payment_provider)
            formData.append("maximal_transaction", dataDetailVoucher.maximal_transaction)
            formData.append("name", dataDetailVoucher.name)

            try {
                const url = `${base_url}/${voucher}/${data.id}`

                await axios.put(url, formData, {
                    headers: headers,
                })
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Status voucher berhasil diperbaharui.',
                });
                setLoading("")
                fetchData(params)
            } catch(error) {
                notification.error({
                    message: "Ups",
                    description:
                      'Terjadi kesalahan saat memperbaharui status voucher',
                    placement: "bottomRight",
                });
                setLoading("")
                fetchData(params)
            }
        } catch {
           notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan saat memperbaharui status voucher',
                placement: "bottomRight",
            });
            setLoading("")
            fetchData(params)
        }
    }

    const handleUpdateVisible = async(checked:boolean, data:any) => {
        setLoading(`switch${data.id}`)

        try {
            const urlVoucherDetail = `${base_url}/${voucher}/${data.id}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.get(urlVoucherDetail, { headers: headers });
            const dataDetailVoucher = res?.data?.payload

            if(checkValidityDate(dataDetailVoucher)) {
                setLoading("")
                return notification["error"]({
                    message: 'Gagal!',
                    description:
                        'Terlihat voucher gagal diperbaharui. Lakukan pembaharuan data!',
                });
            }

            const formData = new FormData()
            formData.append("is_visible", checked ? "1" : "0")
            formData.append("promo_code", dataDetailVoucher.promo_code)
            formData.append("description", dataDetailVoucher.description)
            formData.append("image", dataDetailVoucher.image)
            formData.append("voucher_category", dataDetailVoucher.voucher_category)
            formData.append("nominal_type", dataDetailVoucher.nominal_type)
            formData.append("nominal", dataDetailVoucher.nominal)
            formData.append("maximal_nominal", dataDetailVoucher.maximal_nominal)
            formData.append("start_date", dataDetailVoucher.start_date)
            formData.append("expired_date", dataDetailVoucher.expired_date)
            formData.append("is_active", dataDetailVoucher.is_active)
            formData.append("minimal_transaction", dataDetailVoucher.minimal_transaction)
            formData.append("limit_promo_total", dataDetailVoucher.limit_promo_total)
            formData.append("limit_per_days", dataDetailVoucher.limit_per_days)
            formData.append("limit_per_user", dataDetailVoucher.limit_per_user)
            formData.append("included_layanan", dataDetailVoucher.included_layanan)
            formData.append("excluded_layanan", dataDetailVoucher.excluded_layanan)
            formData.append("included_category", dataDetailVoucher.included_category)
            formData.append("excluded_category", dataDetailVoucher.excluded_category)
            formData.append("merchant", dataDetailVoucher.merchant)
            formData.append("payment_provider", dataDetailVoucher.payment_provider)
            formData.append("maximal_transaction", dataDetailVoucher.maximal_transaction)
            formData.append("name", dataDetailVoucher.name)

            try {
                const url = `${base_url}/${voucher}/${data.id}`

                await axios.put(url, formData, {
                    headers: headers,
                })
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Terlihat voucher berhasil diperbaharui.',
                });
                setLoading("")
                fetchData(params)
            } catch(error) {
                notification.error({
                    message: "Ups",
                    description:
                      'Terjadi kesalahan saat memperbaharui terlihat voucher',
                    placement: "bottomRight",
                });
                setLoading("")
                fetchData(params)
            }
        } catch {
           notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan saat memperbaharui terlihat voucher',
                placement: "bottomRight",
            });
            setLoading("")
            fetchData(params)
        }
    }

    const handleReset = () => {
        setParams({
            limit: 10,
            page: 1,
            q: ""
        })
    }

    const toggle = () => {
        setVisible(!visible)
    }

    return {
        params,
        fields,
        visible,
        loading,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        handleDelete,
        handleUpdateStatus,
        handleUpdateVisible,
        handleFilter,
        handleReset,
        toggle
    }
}