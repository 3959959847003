import React from "react"
import {ModalConfirmation} from "../../../../../components/atoms"

function TableColumns(
    visible: boolean,
    merchant:{name: string},
    toggle: () => void,
    handleDelete: (id:string) => void
) {
    const columns = [
        {
            title: "Nama",
            render: (data:{name:string}) => (
                <div style={{fontWeight: "bold", color: "black"}}>{data.name}</div>
            ),
            width: "20%"
        },
        {
            title: "Nomor Handphone",
            dataIndex: "phone",
            width: "20%"
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "25%"
        },
        {
            title: "Nama Merchant",
            render: () => (
                <div>{merchant.name}</div>
            ),
            width: "25%"
        },
        {
            title: "Aksi",
            render: (data:{id:string}) => (
                <div>
                    <i 
                        className="far fa-trash-alt" 
                        style={{color: "red", cursor: "pointer"}} 
                        onClick={toggle}
                    />
    
                    <ModalConfirmation 
                        visible={visible} 
                        toggle={toggle}
                        submitDelete={() => handleDelete(data.id)}
                    />
                </div>
            )
        },
    ]

    return {
        columns
    }
}

export default TableColumns