import {useState, useEffect} from "react"
import {IDetail} from "../../merchantjasa/transaction/TransactionModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {orderjasa, merchantChat} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(
    bookingId:string = "",
    fetchDataList:(params:{}) => void,
    params:{}
) {
    const [loading, setLoading] = useState<boolean>(false)
    const [fieldsChat, setFieldsChat] = useState<any>([])
    const [countChat, setCountChat] = useState<any>({})
    const [promoCode, setPromoCode] = useState<string>("");
    const [fields, setFields] = useState<IDetail>({
        id: "",
        invoiceNumber: "",
        orderNumber: 0,
        userName: "",
        orderDate: "",
        status: "",
        address: "",
        addressLatitude: "",
        addressLongitude: "",
        addressNote: "",
        adminFee: 0,
        cancelReason: null,
        createdAt: "",
        orderNote: "",
        deliveryFee: 0,
        deliveryType: "",
        isReviewed: false,
        merchantName: "",
        orderDateExpired: null,
        totalQty: 0,
        updatedAt: "",
        userEmail: "",
        userID: "",
        userPhone: "",
        orderType: "",
        totalPrice: 0,
        totalDiscount: 0,
        totalPromo: 0,
        totalPay: 0,
        sharingFee: 0,
        orderComplaint: {
            isRefund: false,
            note: "",
            problem: "",
            provider: "",
            receiverName: "",
            receiverPhone: "",
            solution: "",
            urlPhoto: [],
            urlPhotoRefund: []
        },
        paymentData: {
            adminFee: 0,
            bank: "",
            bankName: "",
            checkoutURL: "",
            duration: 0,
            expiredAt: "",
            invoiceAmount: 0,
            invoiceID: "",
            invoiceNumber: "",
            paymentID: "",
            paymentMethod: "",
            paymentProvider: "",
            paymentState: "",
            token: "",
            totalAmount: 0,
            transactionDate: "",
            trxID: "",
            userPayNumber: ""
        },
        items: [],
        merchantID: ""
    })
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async (id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${orderjasa}/${id}`

            const res = await axios.get(url, {
                headers: headers
            })
            setLoading(false)
            setFields(res.data.payload)
            if(id !== "") {
                getPromoCode(res?.data?.payload?.voucher === null ? "" : res?.data?.payload?.voucher?.id_voucher)
            }
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    const fetchDataChat = async (id:string) => {
        try {
            const url = `${base_url}/${merchantChat}/${id}`

            const res = await axios.get(url, {
                headers: headers
            })
            setFieldsChat(res.data.payload)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail pesan transaksi',
                placement:"topRight"
            });
        }
    }

    const fetchDataChatCount = async (id:string) => {
        try {
            const url = `${base_url}/${merchantChat}/${id}/count`

            const res = await axios.get(url, {
                headers: headers
            })
            setCountChat(res.data.payload)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail pesan count transaksi',
                placement:"topRight"
            });
        }
    }

   const getPromoCode = async (id: string) => {
        try {
            const url = `${base_url}/v3/voucher/promo-voucher/${id}`;
            const res = await axios.get(url, { headers: headers });

            setPromoCode(res?.data?.payload?.promo_code)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                    'Terjadi kesalahan saat mengakses kode promo',
                placement: "topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(bookingId)
        if(bookingId !== "") {
            fetchDataChat(bookingId)
            fetchDataChatCount(bookingId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId])

    const handleAccept = () => {
        handleUpdateTransaction("APPROVE")
    }

    const handleReject = (status:string, reason:string) => {
        if(status === "REJECT") {
            handleNextStep("REJECT", reason);
        } else {
            handleUpdateTransaction(status, reason)
        }
    }

    const handleUpdateTransaction = async(status:string, reason?:string) => {
        let value = {}

        if(status === "CANCEL") {
            value = {orderID: fields.id, state: status, reason: reason}
        } else {
            value = {orderID: fields.id, state: status, merchantID: fields.merchantID}
        }

        setLoading(true)
        try {
            const url = status === "CANCEL" ? `${base_url}/${orderjasa}/confirm` : `${base_url}/${orderjasa}/byMerchant/confirm`

            await axios.post(url, value, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
            fetchDataList(params)
            localStorage.setItem("page", "1")
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    const handleNextStep = async(status?:string, reason?:string) => {
        const valueNextStep = () => {
            if(status === "PROCESS") {
                return {status: "PROCESS_DONE"}
            } else if (status === "COMPLAINED") {
                return {status: "PROCESS_REFUND"}
            } else if (status === "PROCESS_REFUND") {
                return {status: "REFUND"}
            } else if (status === "REJECT") {
                return {status: "REJECTED", reason: reason}
            } else {
                return {orderID: fields.id}
            }
        }

        const value = valueNextStep()

        setLoading(true)
        try {
            const statusCondition = status === "PROCESS" || status === "COMPLAINED" || status === "PROCESS_REFUND" || status === "REJECT";
            const url = statusCondition ? `${base_url}/${orderjasa}/${fields.id}/status` : `${base_url}/${orderjasa}/byMerchant/next`

            if(statusCondition) {
                await axios.put(url, value, {
                    headers: headers
                })
            } else {
                await axios.post(url, value, {
                    headers: headers
                })
            }
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
            fetchDataList(params)
            localStorage.setItem("page", "1")
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    const handleUpdatePhotoRefund = async (urlPhotoRefund:any) => {
        const payload = {
            ...fields.orderComplaint,
            "urlPhotoRefund": fields.orderComplaint.urlPhotoRefund !== null ? [...fields.orderComplaint.urlPhotoRefund, urlPhotoRefund] : [urlPhotoRefund]
        }
        console.log(payload, "payloadPhotoRefund");
        try {
            const url = `${base_url}/${orderjasa}/${fields.id}/complain`

            await axios.put(url, payload, {
                headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
            })
            fetchData(bookingId)
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui photo refund berhasil.',
            });
            localStorage.setItem("page", "1")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail transaksi',
                placement:"topRight"
            });
        }
    }

    const handleSubmitChat = async(values:any) => {
        let value = {
            "receiverID": fields.merchantID,
            "receiverName": fields.merchantName,
            "message": values.message,
            "data": {},
            "type": "JASA"
        }
        if(values.message !== undefined && values.message !== "") {
            setLoading(true)
            try {
                const url = `${base_url}/${merchantChat}/${bookingId}`

                await axios.post(url, value, {
                    headers: headers
                })
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Pesan berhasil dikirim.',
                });
                setLoading(false)
                fetchDataChat(bookingId)
            } catch (error) {
                setLoading(false)
                notification["error"]({
                    message: 'Ups!',
                    description:
                      'Pesan gagal dikirim.',
                });
            }
        }
    }

    const setDataEdit = (data:{}) => {
        setFields({...fields, ...data})
    }
    return {
        loading,
        fields,
        fieldsChat,
        countChat,
        promoCode,
        setDataEdit,
        handleAccept,
        handleReject,
        handleUpdateTransaction,
        handleNextStep,
        handleUpdatePhotoRefund,
        handleSubmitChat
    }
}