import React, {useContext} from 'react'
import {ItemContext} from "../../context/ContextProvider"
import {FeatureButton, Modal} from "../../../../../../components/atoms"
import CreateCategory from "../createcategory/CreateCategory"
import {Loading} from "../../../../../../components/atoms"
import CategoryBox from "./CategoryBox"
import {IDetailCategory} from "../ModelCategory"
import {Pagination} from "antd"

import "./list.scss"

const List: React.FC = () => {
    const context = useContext(ItemContext)
    const {
        categories,
        loading,
        toggle,
        handlePageChange,
        modal,
        handleSubmit,
        handleEdit,
        handleDelete
    } = context.useModelListCategory()

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-clipboard-list" style={{marginRight: "5px"}} />}
                label="Kategori" 
                onClick={toggle} 
            />

            <Modal
                title="Kategori"
                onCancel={toggle}
                visible={modal}
            >
                <CreateCategory 
                    handleSubmit={handleSubmit}
                />
                {loading ? <Loading /> : (
                    <div>
                        {categories.records.map((category:IDetailCategory, key:number) => (
                            <CategoryBox 
                                key={key}
                                name={category.name}
                                id={category.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
                <div style={{paddingTop: "10px", paddingBottom: "20px", textAlign: "right"}}>
                    <Pagination 
                        current={categories.page} 
                        pageSize={5} 
                        total={categories.total_record} 
                        onChange={handlePageChange} 
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default List
