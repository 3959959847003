import React from 'react'
import {Row, Col} from "antd"

import "./itemdetail.scss"

interface Props {
    label: string,
    value?: any,
    borderNone?: any
}

const ItemDetail: React.FC<Props> = (props) => {
    return (
        <Row className="item-detail-container">
            <Col span={12} className="label-container">
                {props.label}
            </Col>
            <Col span={12}>
                <div
                    style={{border: props.borderNone ? 0 : "0.5px solid #BDBDBD"}}
                    className="value-container"
                >
                    {props.value}
                </div>
            </Col>
        </Row>
    )
}

export default ItemDetail
