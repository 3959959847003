import React from "react"
import { IDetailTransaction } from "../ModelTransaction"
import { Row, Col, Menu, Dropdown } from "antd"
import { FormatDate, FormatRp } from "../../../../../helpers/Formatter"
import Shopper from "../shopper/Shopper"
import Refund from "../refund/Refund"
import { Link } from "react-router-dom"
// import { useInvoice } from "../../../../../context/context-invoice"

function TableColumns(fetchData: any, params: {}) {
    // const CtxInvoice = useInvoice();

    const columns = [
        {
            title: "No. Order",
            render: (data: IDetailTransaction) => (
                <div>
                    <div style={{ fontWeight: "bold", color: "black" }}>{data.invoiceNumber}</div>
                    <div style={{ fontWeight: "bold", color: "black" }}>{data.marketName}</div>
                    <div>{FormatDate(data.orderDate)}</div>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Total Pesanan",
            render: (data: IDetailTransaction) => (
                <div>{data.totalOrder !== null ? FormatRp(data.totalOrder) : 0}</div>
            ),
            width: "15%"
        },
        {
            title: "Biaya Layanan",
            render: (data: IDetailTransaction) => (
                <div>{data.serviceFee !== null ? FormatRp(data.serviceFee) : 0}</div>
            ),
            width: "15%"
        },
        {
            title: "Pembayaran",
            render: (data: IDetailTransaction) => (
                <div>
                    <div>{data.payment.paymentProvider}</div>
                    <div>{data.payment.paymentMethod}</div>
                </div>
            ),
            width: "10%"
        },
        {
            title: "Shopper",
            render: (data: IDetailTransaction) => (
                <div>
                    <div>{data.shopperName !== "" ? data.shopperName : "-"}</div>
                    <div>{data.shopperPhoneNumber !== "" ? data.shopperPhoneNumber : "-"}</div>
                </div>
            ),
            width: "10%"
        },
        {
            title: "Status",
            render: (data: IDetailTransaction) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.status}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.moPayment.paymentState}
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            title: "Aksi",
            render: (data: IDetailTransaction) => {
                // console.log(data.status);

                const menu = (
                    <Menu style={{ width: "110px" }} id="button-id">
                        <Menu.Item
                            hidden={data.status !== "PAID" && data.status !== "APPROVED" && data.status !== "PROCESS" ? true : false}
                        >
                            <Shopper
                                id={data.id}
                                fetchData={fetchData}
                                params={params}
                            />
                        </Menu.Item>
                        <Menu.Item
                            hidden={data.status === "APPROVED" ? false : data.status === "PROCESS" ? false : data.status === "SEND" ? false : true}
                            disabled={data.refundOrder.id !== 0 ? true : false}
                        >
                            <Refund
                                orderID={data.id}
                                invoiceNumber={data.invoiceNumber}
                                itemOrder={data.itemOrder}
                            />
                        </Menu.Item>
                        {/* <Menu.Item onClick={() => CtxInvoice.setInvoice({ isReady: true, data })}>
                            <Row>
                                <Col span={8}>
                                    <i className="fas fa-print" />
                                </Col>
                                <Col span={16}>
                                    Invoice
                                </Col>
                            </Row>
                        </Menu.Item> */}
                        <Menu.Item>
                            <Link to={`/transaksi-pasar/detail?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Detail
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{ color: "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer" }}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        }
    ]

    return {
        columns
    }
}


export default TableColumns