import React from 'react'
import {Row, Col} from "antd"

import "./detailtitlesection.scss"

interface Props {
    title: string,
   	right?: any,
    borderNone?: any
}

const DetailTitleSection: React.FC<Props> = (props)=>  {
    return (
        <Row justify="space-between" align="middle" className="detail-title-container">
            <Col>
                <div className="detail-section-title">{props.title}</div>
            </Col>
            {props.right}
            {props.borderNone ? null : <div className="detail-section-line" />}
        </Row>
    )
}

export default DetailTitleSection
