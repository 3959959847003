import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detail/Detail"

const TransactionPayment: React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/payment-transaction" exact={true} render={() => <List />} />
            <Route path="/payment-transaction/detail" exact={true} render={() => <Detail />} />
        </ContextProvider>
    )
}

export default TransactionPayment
