import React, {useContext} from 'react'
import {TransactionHobbyContext} from "../context/ContextProvider"
import {useLocation} from "react-router-dom"
import {Row, Col} from "antd"
import {DetailPageLayout} from "../../../../templates"
import {Loading, CancelButton, BackButton, OkButton} from "../../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"

import "./transactiondetail.scss"

const TransactionDetail: React.FC = () => {
    const location = useLocation()
    const bookingId = location.search.split("=")[1]
    const context = useContext(TransactionHobbyContext)
    const {
        fields,
        loading,
        handleAccept,
        handleReject
    } = context.useModelDetail(bookingId)
    
    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        {fields.booking.bookingState === "REQUESTED" || fields.booking.bookingState === "RESCHEDULE-REQUESTED" ? (
                            <Row>
                                <Col span={24}>
                                    <OkButton 
                                        onClick={handleAccept}
                                    >
                                        Terima
                                    </OkButton>
                                    <CancelButton label="Tolak" onClick={handleReject} />
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Booking" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomer Booking" value={fields.booking.invoiceNumber === "" ? "-" : fields.booking.invoiceNumber} />
                                <ItemDetail label="Tanggal Order" value={FormatDate(fields.booking.CreatedAt)} />
                                <ItemDetail label="Status Booking" value={fields.booking.bookingState} />
                                <ItemDetail label="Waktu Mulai Booking" value={FormatDate(fields.booking.bookingDate)} />
                                <ItemDetail label="Waktu Berakhir Booking" value={FormatDate(fields.booking.bookingEndDate)} />
                                <ItemDetail label="Durasi Booking" value={`${fields.booking.duration/3600} Jam`} />
                                <ItemDetail label="User" value={fields.booking.userName === "" ? "-" : fields.booking.userName} />
                                <ItemDetail label="User Email" value={fields.booking.userEmail === "" ? "-" : fields.booking.userEmail} />
                                <ItemDetail label="Telepon User" value={fields.booking.userPhone === "" ? "-" : fields.booking.userPhone} />
                                <ItemDetail label="Nama Venue" value={fields.venue.name} />
                                <ItemDetail label="Alamat Venue" value={fields.venue.address} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Rates" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Periode</Col>
                                            <Col span={6}>Harga Per Jam</Col>
                                            <Col span={3}>Durasi</Col>
                                            <Col span={5}>Diskon</Col>
                                            <Col span={5}><div style={{float: "right"}}>Total Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.booking.totalPriceDetail.rates.map((rate:any, key:number) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{border: "1px solid black"}}>
                                                <Col span={5}>{rate.rate.timeStart} - {rate.rate.timeEnd}</Col>
                                                <Col span={6}>{FormatRp(rate.rate.rate)}</Col>
                                                <Col span={3}>{rate.totalHour} Jam</Col>
                                                <Col span={5}>{rate.rate.isDiscPercentage ? `${rate.rate.discount}%` : FormatRp(rate.rate.discount)}</Col>
                                                <Col span={5}><div style={{float: "right"}}>{FormatRp((rate.rate.rate - (rate.rate.isDiscPercentage ? rate.rate.discount * rate.rate.rate / 100 : rate.rate.discount)) * rate.totalHour)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Deposit" value={<div style={{textAlign:"right"}}>{FormatRp(fields.booking.totalPriceDetail.depositUsed)}</div>} />
                                <ItemDetail label="Total" value={<div style={{textAlign:"right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.booking.totalPriceDetail.totalPrice)}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Pembayaran" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Total Pesanan" value={<div style={{textAlign: "right"}}>{FormatRp(fields.booking.totalPrice)}</div>} />
                                <ItemDetail label="Biaya Admin" value={<div style={{textAlign: "right"}}>{FormatRp(fields.payment.adminFee)}</div>} />
                                <ItemDetail label="Total Bayar" value={<div style={{textAlign: "right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.payment.totalAmount)}</div>} />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                <Link to="/hobi/transaksi">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default TransactionDetail
