import {useState} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {merchantcategory} from "../../../../config/endpoint"
import {notification} from "antd"
import {IDetail,  initialDataDetail} from "../MerchantCategoryModel"

export const useModelDetail = (
    fetchData:(queryparams:{}) => void,
    params:{}
) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>(initialDataDetail)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const handleSubmit = async (values: {
        name: string
        nameShow: string
        icon: string
        isActive: boolean
        sort: number
    }) => {
        values["icon"] = fields.icon
        setLoading(true)
        try {
            const url = fields.id !== "" ? `${base_url}/${merchantcategory}/${fields.id}` : `${base_url}/${merchantcategory}`

            fields.id !== "" ? (
                await axios.put(url, values, {
                    headers: headers,
                })
            ) : (
                await axios.post(url, values, {
                    headers: headers,
                })
            )
            notification.success({
                message: 'Yuhuu!',
                description:
                    `${fields.id !== "" ? "Mengubah": "Menambahkan"} data kategori berhasil!`,
                placement:"topRight"
            });
            fetchData(params)
            setLoading(false)
            toggleModal()
        } catch (error) {
            setLoading(false)
            notification.error({
                message: 'Ups!',
                description:
                  'Pastikan form yang anda isi telah benar!',
                placement:"topRight"
            });
        }
    }

    const handleDelete = async () => {
        toggleModal()
        try {
            const url = `${base_url}/${merchantcategory}/${fields.id}`

            await axios.delete(url, {
                headers: headers
            })
            notification.success({
                message: 'Yuhuu!',
                description: 'Menghapus data kategori berhasil!',
                placement:"topRight"
            });
            fetchData(params)
        } catch (error) {
            fetchData(params)
            notification.error({
                message: "Ups!",
                description:
                  'Terjadi kesalahan saat menghapus kategori',
                placement: "bottomRight",
            });
        }
    }

    const submitPhoto = (path:string) => {
        setFields({...fields, icon: path})
    }

    const toggleModal = () => {
        setModal(!modal)
    }

    const setDataEdit = (data:{}) => {
        setFields({...fields, ...data})
    }

    return {
        fields,
        setDataEdit,
        loading,
        modal,
        handleSubmit,
        handleDelete,
        toggleModal,
        submitPhoto
    }
}