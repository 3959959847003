import {useState, useEffect} from "react"
import {IListCategory, initialDataList} from "../ModelCategory"

export function useModelList() {
    const [fields, setFields] = useState<IListCategory>(initialDataList)
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)

    const fetchData = () => {
        setLoading(true)
        setFields({
            total_record: 0,
            total_page: 1,
            records: []
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    return {
        fields,
        loading,
        fetchData,
        modal,
        toggle
    }
} 
