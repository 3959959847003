import React, {useState, useContext} from 'react'
import {ShopperContext} from "../context/ContextProvider"
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../components/atoms"
import {Form, Row, Col, Select} from "antd"

import "./filter.scss"

interface Props {
    handleFilter: (values:{}) => void
}

const Filter: React.FC<Props> = (props) =>  {
    const context = useContext(ShopperContext)
    const {
        areas
    } = context.useModelListArea(true)
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields();
    }

    const handleSubmit = (values: {}) => {
        props.handleFilter(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>} 
                onClick={toggle}
            />

            <Modal
                title="Filter Shopper"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Row className="row-form">
                                <Col span={24}>
                                    <Form.Item
                                        name="areaID"
                                        label="Area"
                                    >
                                        <Select placeholder="Pilih area" style={{width: "100%"}}>
                                            {areas.records.map((area:{name:string, id:number}, key:number) => (
                                                <Select.Option key={key} value={area.id}>{area.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={24}>
                                    <Form.Item
                                        name="isActive"
                                        label="Status"
                                    >
                                        <Select placeholder="Pilih status" style={{width: "100%"}}>
                                            <Select.Option value="true">Aktif</Select.Option>
                                            <Select.Option value="false">Tidak Aktif</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px", textAlign: "center"}}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
