const staging = 'https://staging-api.manyoption.co.id'
const production = 'https://api.manyoption.co.id'
const demo = 'https://demo-api.manyoption.co.id'
let burl;

// export const base_url = process.env.REACT_APP_ENV === "production" ? production : staging
switch (process.env.REACT_APP_ENV) {
    case "production":
     burl = production;
        break;
    case "demo":
     burl = demo;
        break;
    default:
     burl = staging;
        break;
}

export const base_url = burl;
