import React, {createContext} from "react"
import {useModelList} from "./ListContext"
import {useModelDetail} from "./DetailContext"

export const PasarContext = createContext<any>(null)

const ContextProvider:React.FC = (props) => {
    const context = {
        useModelList,
        useModelDetail
    }

    return (
        <PasarContext.Provider value={context}>
            {props.children}
        </PasarContext.Provider>
    )
}

export default ContextProvider