export const uploadphoto = "v3/utility/upload"
export const merchantaccount = "v3/client/user"

// MERCHANT HOBBY
export const merchanthobby = "v3/hobby/merchant"
export const venuehobby = "v3/hobby/venue"
export const categoryhobby = "v3/hobby/category"
export const bookinghobby = "v3/hobby/booking"

// MERCHANT JASA
export const merchantjasa = "v3/merchant"
export const itemjasa = "v3/merchant/item"
export const orderjasa = "v3/merchant/order"

// MERCHANT CATEGORY
export const merchantcategory = "v3/merchant/category"

// PASAR
export const listpasar = "v3/market/market"
export const itempasar = "v3/market/item"
export const kategoripasar = "v3/market/category"
export const shopper = "v3/market/shopper"
export const area = "v3/market/area"
export const transaksipasar = "v3/market/order"
export const refundorderpasar = "v3/market/refund" 

// ACCOUNT
export const account = "v3/client/user"
export const userrole = "v3/client/role"

// PAYMENT
export const paymentconfig = "v2/mopayment/config"
export const paymenttransaction = "v2/mopayment/core/payment/transaction"
export const deposit = "v2/mopayment/deposit"

// User Apps
export const bannerConfig = "v3/promo/banner"
export const voucher = "v3/voucher/promo-voucher"
export const layanan = "v3/voucher/layanan"
export const kaspro = "v3/client/kaspro"

// Notification
export const registTokenFCM = "v3/notification/user"
export const getNotifList = "v3/notification/dashboard"

// Merchant Food
export const merchantFood = "v3/food/merchant"
export const merchantFoodCategory = "v3/food/category"
export const itemfood = "v3/food/product"
export const categoryfood = "v3/food/product_category"
export const transactionfood = "v3/food/order"

// Ayopop 
export const ayopop = "v2/virtual-product/dashboard/product"
export const transactionayopop = "v2/virtual-product/dashboard/transaction"

// Payment Provider
export const paymentProviderJasa = "v2/mopayment/config/get/jasa"

// Chat
export const merchantChat = "v3/merchant/chat" 