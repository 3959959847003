import React, {useState, useEffect} from 'react'
import axios from "axios"
import {Form, Row, Col, Input, Switch, Select, notification, Upload, Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import {base_url} from "../../../../config/url-config"
import {uploadphoto, kaspro} from "../../../../config/endpoint"
import {Link} from "react-router-dom"
import {ModelDataVoucher} from '../ModelKaspro'
import {OkButton, BackButton} from "../../../../components/atoms"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import "./form.scss"

interface Props {
    data: ModelDataVoucher,
    submitPhoto: (path:string) => void,
    handleSubmit: (values:{}) => void
}

const FormKaspro: React.FC<Props> = (props) => {
    const [categoryKaspro, setCategoryKaspro] = useState<{}[]>([]);
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [editorHtml, setEditorHtml] = useState('');
    const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
  
    useEffect(() => {
        getCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCategory = async () => {
        try {
            const url = `${base_url}/${kaspro}/top-up/category`;
            const res = await axios.get(url, { headers: headers });

            setCategoryKaspro(res?.data?.payload)
        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data kategori',
                    placement: "topRight"
                });
            }
        }
    }

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: headers
            })
            props.submitPhoto(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload foto merchant.',
            });
        }
    }

    return (
        <React.Fragment>
            <Form 
                onFinish={props.handleSubmit}
            >
                    <Row>
                        <Col span={24} className="left-section" style={{marginTop: 10}}>
                            <Row className="row-form">
                                <Col span={7}>Kategori Top Up</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="category"
                                            rules={[
                                                { required: true, message: 'Kategori wajib diisi!' },
                                            ]}
                                            initialValue={props.data.category === null ? null : JSON.stringify(props.data.category)}
                                        >
                                        <Select placeholder="Pilih">
                                            {categoryKaspro.map((ele:any, key:number) => (
                                                <Select.Option key={key} value={JSON.stringify(ele)}>{ele.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Logo</Col>
                                <Col>
                                    <Form.Item
                                        name="image"
                                        valuePropName="file"
                                        initialValue={props.data.image}
                                    >
                                        <Upload 
                                            showUploadList={false} 
                                            onChange={handleUpload}
                                        >
                                            <div className="upload-photo">
                                                {loadingUpload ? (
                                                    <div style={{paddingTop: "25px"}}>
                                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                                    </div>
                                                ) : (
                                                    props.data.image === "" ? (
                                                        <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                            <i className="far fa-image fa-6x" />
                                                        </div>
                                                    ) : (
                                                        <img src={props.data.image} alt="merchant-hobby-logo" style={{width: "100px", height: "80px", marginTop: "10px"}} />
                                                    )
                                                )}
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Bank/Mitra</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="bankOrMitra"
                                        rules={[{ required: true, message: 'Nama Promo wajib diisi!' }]}
                                        initialValue={props.data.bankOrMitra}
                                    >
                                        <Input
                                            className="form-input" 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>    
                            <Row className="row-form">
                                <Col span={7}>Kode Penyedia</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="kode"
                                        rules={[{ required: true, message: 'Nama Promo wajib diisi!' }]}
                                        initialValue={props.data.kode}
                                    >
                                        <Input
                                            className="form-input" 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>  
                            <Row className="row-form">
                                <Col span={7}>Deskripsi</Col>
                                <Col span={14}>
                                    <div className="custom-editor">
                                        <Form.Item
                                            name="description"
                                            rules={[{ required: true, message: 'Deskripsi wajib diisi!'}]}
                                            initialValue={props.data.description.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}
                                        >
                                            <ReactQuill
                                                onChange={(html:any) => setEditorHtml(html)}
                                                value={editorHtml}
                                                modules={{
                                                  toolbar: [
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                    ['link'],
                                                  ]
                                                }}
                                                formats={[
                                                  'bold', 'italic', 'underline', 'strike',
                                                  'list', 'bullet', 'indent',
                                                  'link',
                                                ]}
                                                bounds={'.custom-editor'}
                                                placeholder={'Tulis Deskripsi...'}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Status</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        valuePropName="checked"
                                        initialValue={props.data.status}
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>                
                    </Row>

                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <OkButton>Simpan</OkButton>
                                <Link to="/kaspro">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row> 
                </Form>
        </React.Fragment>
    )
}

export default FormKaspro
