import {useState, useEffect} from "react"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {bookinghobby} from "../../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(bookingId:string = "") {
    const [fields, setFields] = useState<{
        booking:{
            ID:string,
            invoiceNumber:string,
            CreatedAt:string,
            bookingState:string,
            bookingDate:string,
            bookingEndDate:string,
            duration:number,
            userName:string,
            totalPrice:number,
            totalPriceDetail:{
                depositUsed:number,
                rates:{
                    rate:{
                        timeStart:string,
                        timeEnd:string,
                        rate:number,
                        discount:number,
                        isDiscPercentage:boolean
                    },
                    totalHour:number
                }[],
                totalPrice:number
            },
            userEmail:string,
            userPhone:string
        },
        merchant:{},
        package:{},
        payment:{
            adminFee:number,
            totalAmount:number
        },
        rate:{},
        venue:{
            name:string,
            address:string
        }
    }>({
        booking:{
            ID: "",
            invoiceNumber: "",
            CreatedAt: "",
            bookingState: "",
            bookingDate: "",
            bookingEndDate: "",
            duration: 0,
            userName: "",
            totalPrice: 0,
            totalPriceDetail: {
                depositUsed:0,
                rates: [],
                totalPrice:0
            },
            userEmail: "",
            userPhone: ""
        },
        merchant:{},
        package:{},
        payment:{
            adminFee: 0,
            totalAmount:0
        },
        rate:{},
        venue:{
            name: "",
            address: ""
        }
    })
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async(id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${bookinghobby}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(bookingId)
    }, [bookingId])

    const handleAccept = () => {
        if (fields.booking.bookingState === "REQUESTED") {
            handleUpdateTransaction("WAITING-PAYMENT")
        } else if (fields.booking.bookingState === "RESCHEDULE-REQUESTED") {
            handleUpdateTransaction("BOOKED")
        }
    }

    const handleReject = () => {
        handleUpdateTransaction("REJECTED")
    }

    const handleUpdateTransaction = async(status:string) => {
        const value = {id: fields.booking.ID, bookingState: status}
        setLoading(true)
        try {
            const url = `${base_url}/${bookinghobby}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.patch(url, value, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    return {
        fields,
        loading,
        handleAccept,
        handleUpdateTransaction,
        handleReject
    }
}