import React, {useContext} from 'react'
import {TransactionHobbyContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {Pagination, Row, Col} from "antd"
import {Link} from "react-router-dom"
import {PageLayout} from "../../../../templates"
import FilterTransaction from "../filtertransaction/FilterTransaction"
import {Search, Table, BackButton, FeatureButton} from "../../../../../components/atoms"

import "./list.scss"

const Transaction: React.FC = () =>  {
    const context = useContext(TransactionHobbyContext)
    const {
        loading,
        fields,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    } = context.useModelList()
    const {columns} = TableColumns()

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                leftTop={
                    <div>
                        <FilterTransaction handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.records}
                            rowClassName={(record:{booking:{bookingState:string}}, index:number) => record.booking.bookingState === "REQUESTED" || record.booking.bookingState === "RESCHEDULE-REQUESTED" ? 'new-transaction' :  'read-transaction'}
                            pagination={false}
                            loading={loading}
                            // scroll={{y: 550}}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/hobi">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.page} 
                                pageSize={10} 
                                total={fields.total_record} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default Transaction
