import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Create from "./create/create"
import Edit from "./edit/edit"
import Detail from "./detail/detail"
import Item from "./item/Item"
import Account from "./akun/Akun"
import Transaction from "./transaction/Transaction"

const MerchantFood:React.FC = () => {
    return (
        <ContextProvider>
            <Route path="/food" exact={true} render={() => <List />} />
            <Route path="/food/tambah" exact={true} render={() => <Create />} />
            <Route path="/food/ubah" exact={true} render={() => <Edit />} />
            <Route path="/food/detail" exact={true} render={() => <Detail />} />
            <Route path="/food/item" exact={false} render={() => <Item />} />
            <Route path="/food/akun" exact={false} render={() => <Account />} />
            <Route path="/food/transaksi" exact={false} render={() => <Transaction />} />
        </ContextProvider>
    )
}

export default MerchantFood
