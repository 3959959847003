import React, {useContext} from 'react'
import {useQueryParams} from "../../../../../helpers/Formatter"
import {ServiceContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {PageLayout} from "../../../../templates"
import {Link} from "react-router-dom"
import {Search, FeatureButton, Table, BackButton} from "../../../../../components/atoms"
import FilterService from "../filterservice/FilterService"
import {Pagination, Row, Col} from "antd" 

import "./list.scss"

const ListService: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(ServiceContext)
    const {
        fields,
        loading,
        visible,
        handlePageChange,
        handleSubmitSearch,
        toggle,
        handleFilter,
        handleReset,
        handleUpdateStatus,
        handleDelete
    } = context.useModelList()
    const {columns} = TableColumns(visible, toggle, handleDelete, handleUpdateStatus)

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <Link to={`/hobi/service/tambah`}>
                            <FeatureButton 
                                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                                label="Tambah" 
                            />
                        </Link>
                        <FilterService handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <React.Fragment>
                        <div>
                            <Table 
                                columns={columns} 
                                data={fields.records}
                                rowKey="id"
                                pagination={false}
                                // scroll={{y: 550}}
                                loading={loading}
                            />
                        </div>
                    </React.Fragment>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/hobi">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.page} 
                                pageSize={10} 
                                total={fields.total_record} 
                                onChange={handlePageChange} 
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default ListService
