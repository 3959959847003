import {useState, useEffect} from "react"
import { useHistory } from "react-router"
import {ModelDataVoucher, initialDataVoucher} from "../ModelVoucher"
import { base_url } from "../../../../config/url-config"
import { voucher } from "../../../../config/endpoint"
import axios from "axios"
import moment from "moment"
import {notification} from "antd"

export function useModelDetail(id:string | null = null) {
    const history = useHistory()
    const [fields, setFields] = useState<ModelDataVoucher>(initialDataVoucher);
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async (id: string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${voucher}/${id}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.get(url, { headers: headers });

            if (res.data.statusCode === 200) {
                setFields(res.data.payload)
                setLoading(false)
            } else {
                throw new Error(res.data);
            }

        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data voucher',
                    placement: "topRight"
                });
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        if(id !== null) {
            fetchData(id)
        }
    }, [id])

    const handleSubmit = async (values:any) => {
        const formData = new FormData()
        formData.append("promo_code", values.promo_code)
        formData.append("description", values.description)
        formData.append("image", fields.image)
        formData.append("voucher_category", values.voucher_category)
        formData.append("nominal_type", values.nominal_type)
        formData.append("nominal", values.nominal)
        formData.append("maximal_nominal", values.maximal_nominal)
        formData.append("start_date", moment(values.periode_voucher[0]).format("YYYY-MM-DD h:mm:ss"))
        formData.append("expired_date", moment(values.periode_voucher[1]).format("YYYY-MM-DD h:mm:ss"))
        formData.append("is_active", values.is_active ? "1" : "0")
        formData.append("is_visible", values.is_visible ? "1" : "0")
        formData.append("is_new_user", values.is_new_user ? "1" : "0")
        formData.append("minimal_transaction", values.minimal_transaction)
        formData.append("limit_promo_total", values.limit_promo_total)
        formData.append("limit_per_days", values.limit_per_days)
        formData.append("limit_per_user", values.limit_per_user)
        formData.append("included_layanan", values.included_layanan === undefined ? "" : values.included_layanan.map((ele:any) => JSON.parse(ele)).map((el:any) => el.id).join(","))
        formData.append("excluded_layanan", values.excluded_layanan === undefined ? "" : values.excluded_layanan.join(","))
        formData.append("included_category", values.included_category === undefined ? "" : values.included_category.join(","))
        formData.append("excluded_category", values.excluded_category === undefined ? "" : values.excluded_category.join(","))
        formData.append("merchant", values.merchant === undefined ? "" : values.merchant.join(","))
        formData.append("payment_provider", values.payment_provider === undefined ? "" : values.payment_provider.join(","))
        formData.append("maximal_transaction", values.maximal_transaction)
        formData.append("name", values.name)

        // const payload = {
        //  "promo_code": values.promo_code,
        //  "description": values.description,
        //  "image": fields.image !== "" ? fields.image : "https://staging-static.manyoption.co.id/merchant-hobby/05b0f60d-1098-4cd2-bc8d-e41241000115.png",
        //  "voucher_category": values.voucher_category,
        //  "nominal_type": values.nominal_type,
        //  "nominal": values.nominal,
        //  "maximal_nominal": values.maximal_nominal,
        //  "start_date": moment(values.periode_voucher[0]).format("YYYY-MM-DD h:mm:ss"),
        //  "expired_date": moment(values.periode_voucher[1]).format("YYYY-MM-DD h:mm:ss"),
        //  "is_active": values.is_active ? "1" : "0",
        //  "is_visible": values.is_visible ? "1" : "0",
        //  "minimal_transaction": values.minimal_transaction,
        //  "limit_promo_total": values.limit_promo_total,
        //  "limit_per_days": values.limit_per_days,
        //  "limit_per_user": values.limit_per_user,
        //  "included_layanan": values.included_layanan === undefined ? "" : values.included_layanan.map((ele:any) => JSON.parse(ele)).map((el:any) => el.id).join(","),
        //  "excluded_layanan": values.excluded_layanan === undefined ? "" : values.excluded_layanan.join(","),
        //  "included_category": values.included_category === undefined ? "" : values.included_category.join(","),
        //  "excluded_category": values.excluded_category === undefined ? "" : values.excluded_category.join(","),
        //  "merchant": values.merchant === undefined ? "" : values.merchant.join(","),
        //  "payment_provider": values.payment_provider === undefined ? "" : values.payment_provider.join(","),
        //  "maximal_transaction": values.maximal_transaction,
        //  "name": values.name,
        // }
        // console.log(payload)

        setLoading(true)
        try {
            const url = id !== null ? `${base_url}/${voucher}/${id}` : `${base_url}/${voucher}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };

            const res = id !== null ? await axios.put(url, formData, { headers: headers }) : await axios.post(url, formData, { headers: headers })
            
            if (res.data.statusCode === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        `Data voucher berhasil ${id !== null ? "diperbaharui" : "ditambahkan"}.`,
                });
                setLoading(false)
                history.goBack()
            } else {
                notification["error"]({
                    message: 'Ups!',
                    description: res.data.message,
                });
                setLoading(false)
            }

            throw new Error(res.data);
        } catch (error) {
            if (error.response) {

                notification["error"]({
                    message: 'Ups!',
                    description:
                        `Terjadi kesalahan pada saat ${id !== null ? "memperbaharui" : "menambahkan"} data voucher.`,
                });
            }
            setLoading(false)
        }
    }

    const submitPhoto = (path:string) => {
        setFields({...fields, image: path})
    }

    return {
        fields,
        loading,
        submitPhoto,
        handleSubmit
    }
}