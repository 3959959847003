import React, {useContext} from 'react'
import {Pagination, Row, Col} from 'antd'
import {PageLayout} from '../../../templates'
import {Table} from '../../../../components/atoms'
import {FormatDate} from "../../../../helpers/Formatter"
import {VoucherContext} from "../context/ContextProvider"

const Transaksi: React.FC = () => {
    const urlSearchParams:any = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const context = useContext(VoucherContext)
    const {
        fields,
        loading,
        handlePageChange,
    } = context.useModelList(params.id)

    const columns = [
        {
            title: "User",
            dataIndex: "username",
        },
        {
            title: "Terpakai",
            dataIndex: "voucher_used"
        },
        {
            title: "Maksimal Pemakaian",
            dataIndex: "voucher_limit_used"
        },
        {
            title: "Waktu Pakai",
            render: (data:{date_used:string}) => (
                <div>{FormatDate(data.date_used)}</div>
            ),
        },
        {
            title: "Nomor Tranksasi",
            dataIndex: "transaction_number"
        },
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <h2>{params.code}</h2>
                    </div>
                }
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            // rowKey="id"
                            pagination={false}
                            scroll={{y: 'calc(100vh - 23em)'}}
                            loading={loading === "fetch" ? true : false}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <span>{`Total ${fields.pagination.perpage_found} Voucher`}</span>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.pagination.current_page} 
                                pageSize={10} 
                                total={fields.pagination.total_page * fields.pagination.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default Transaksi