import React, {useContext} from 'react'
import {ServiceContext} from "../context/ContextProvider"
import {Loading} from "../../../../../components/atoms"
import FormService from "../formservice/FormService"

import "./createservice.scss"

const CreateService: React.FC = () => {
    const context = useContext(ServiceContext)
    const {
        fields,
        loading,
        operations,
        handleSetImgURL,
        addUploadPhoto,
        handleCheckedDay,
        handleSubmitTimeRate,
        handleSubmitSchedule,
        handleSubmitVenue
    } = context.useModelDetail()

    return (
        <React.Fragment>
            {loading ? <Loading /> : (
                <FormService 
                    onFinish={handleSubmitVenue} 
                    fields={fields} 
                    addUploadPhoto={addUploadPhoto} 
                    handleSetImgURL={handleSetImgURL}
                    handleCheckedDay={handleCheckedDay}
                    operations={operations}
                    handleSubmitTimeRate={handleSubmitTimeRate}
                    handleSubmitSchedule={handleSubmitSchedule}
                />
            )}
        </React.Fragment>
    )
}

export default CreateService
