import {useEffect, useState} from "react"
import {base_url} from "../../../../../config/url-config"
import {categoryfood} from "../../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"

export function useModelListCategory(merchantId:string, isItem:boolean) {
    const [categories, setCategories] = useState<{
        pagination: {
            page: number
            totalData: number
        }
        payload: {
            id: string,
            merchant_id: string,
            name: string
        }[]
    }>({
        pagination: {
            page: 0,
            totalData: 0
        },
        payload: []
    })
    const [params, setParams] = useState<{}>({
        merchantId: merchantId,
        page: 0
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${categoryfood}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }

            const res = await axios.get(url, { 
                headers: headers,
                params: queryparams
            })
            if (res.data.statusCode === 200) {
                setCategories(res.data)
            } else {
                notification.error({
                    message: 'Ups!',
                    description: res.data.message,
                });
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const toggle = () => {
        setModal(!modal)
    }

    const handleEdit = async (id: string, value: string) => {
        try {
            const data = isItem ? { name: value, merchant_id: merchantId } : { name: value };
            const res = await axios.put(`${base_url}/${categoryfood}/update/${id}`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            if (res.data.statusCode === 200) {
                fetchData(params)
            } else {
                notification.error({
                    message: 'Ups!',
                    description: res.data.message,
                });

            }

        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat menghapus data kategori.',
            });
        }
    }

    const handleDelete = async (id: string) => {
        try {
            const res = await axios.delete(`${base_url}/${categoryfood}/delete/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            if (res.data.statusCode === 200) {
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Menghapus data kategori berhasil!',
                    placement: "bottomRight",
                });
                fetchData(params)
            } else {
                notification.error({
                    message: 'Ups!',
                    description: res.data.message,
                });

            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat meghapus data kategori.',
            });
        }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page - 1})
    }

    return {
        categories,
        params,
        loading,
        modal,
        toggle,
        fetchData,
        handleEdit,
        handleDelete,
        handlePageChange
    }
}