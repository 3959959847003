import {useState, useEffect} from "react"
import {IListCategory, initialDataList} from "../category/ModelCategory"
import {notification} from "antd"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {kategoripasar} from "../../../../../config/endpoint"

export function useModelListCategory(isAll:boolean = false) {
    const [categories, setCategories] = useState<IListCategory>(initialDataList)
    const [params, setParams] = useState<{
        marketID: string | null
        pageLimit: number
        pageNumber: number
        search: string
        isAll: boolean
        isDashboard: boolean
    }>({
        marketID: localStorage.getItem("marketID"),
        pageLimit: 5,
        pageNumber: 1,
        search: "",
        isAll: isAll,
        isDashboard: true
    })
    const [modal, setModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/${kategoripasar}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            setCategories(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data kategori pasar',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async(values:{}) => {
        const marketId:any = localStorage.getItem("marketID")
        const fixvalues = {...values, marketID: parseInt(marketId)}
        try {
            await axios.post(`${base_url}/${kategoripasar}`, fixvalues, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            notification.success({
                message: 'Yeay!',
                description:
                  `Berhasil membuat kategori pasar.`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menghapus data kategori pasar',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    const handleEdit = async(id:number, values:{}) => {
        try {
            await axios.put(`${base_url}/${kategoripasar}/${id}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat memperbaharui data',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    const handleDelete = async(id:number) => {
        setLoading(true)
        try {
            await axios.delete(`${base_url}/${kategoripasar}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            notification.success({
                message: 'Yeay!',
                description:
                  `Berhasil menghapus kategori pasar.`,
            });
            fetchData(params)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat menghapus data kategori pasar',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    return {
        categories,
        loading,
        fetchData,
        handlePageChange,
        modal,
        toggle,
        handleSubmit,
        handleEdit,
        handleDelete
    }
}