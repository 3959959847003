import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IList} from "../TransactionModel"
import {base_url} from "../../../../../config/url-config"
import {transactionayopop} from "../../../../../config/endpoint"
import axios from "axios"
import {notification} from "antd"
import moment from "moment"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<string>("")
    const [fields, setFields] = useState<IList>({
        currentPage: 1,
        data: [],
        totalData: 0,
        totalPage: 1
    })
    const [params, setParams] = useState<{
        pageNumber: number
        pageLimit: number
    }>({
        pageNumber: 1,
        pageLimit: 10
    })
    const [options, setOptions] = useState<{
        ayopopState: string[]
        biller: string[]
        category: string[]
        paymentMethod: string[]
        paymentProvider: string[]
        paymentState: string[]
    }>({
        ayopopState: [],
        biller: [],
        category: [],
        paymentMethod: [],
        paymentProvider: [],
        paymentState: []
    })

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${transactionayopop}/list`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {...queryparams, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi ayopop',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const handlePageChange = (page:any, pageSize:any) => {
        setParams({...params, pageNumber:page})
    }

    const fetchParams = async() => {
        try {
            const url = `${base_url}/v2/virtual-product/dashboard/list/filter/params`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setOptions(res.data.payload)
        } catch {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data option params',
                placement:"topRight"
            });
        }
    }

    const handleFilter = (values:any) => {
        for (var key in values) {
            if (values[key] === undefined) {
                delete values[key]
            } else if (values["createdAt"] !== undefined) {
                values["startDate"] = `${moment(values.createdAt[0]).format("YYYY-MM-DD")}T00:00:00.000Z`
                values["endDate"] = `${moment(values.createdAt[1]).format("YYYY-MM-DD")}T23:59:00.000Z`
                delete values.createdAt
            }
        }
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            pageNumber: 1,
            pageLimit: 10
        })
    }

    const handleRefreshStatus = async(id:string, fitur:string) => {
        try {
            const url = `${base_url}/v2/virtual-product/dashboard/transaction/status/${fitur}/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            axios.get(url, {
                headers: headers
            })
            fetchData(params)
            notification.success({
                message: 'Yuhuuu!',
                description:
                  `Memperbaharui status ${fitur === "order" ? "order" : "payment"} ayopop berhasil`,
                placement:"topRight"
            })
        } catch(erro) {
            notification.error({
                message: 'Wops!',
                description:
                  `Terjadi kesalahan saat update status ${fitur === "order" ? "order" : "payment"} ayopop`,
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    return {
        loading,
        fields,
        handlePageChange,
        handleSubmitSearch,
        fetchParams,
        options,
        handleFilter,
        handleReset,
        handleRefreshStatus
    }
}