import React, {useState} from 'react'
import moment from "moment"
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Select, Row, Col, Form, DatePicker} from "antd"

import "./filtertransaction.scss"

const { RangePicker } = DatePicker;

interface Props {
    handleFilter: (values:{}) => void
}

const FilterTransaction: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{bookingState:string, date:any[], startDate:any, endDate:any}) => {
        if (values.bookingState === undefined) {
            values["startDate"] = `${moment(values.date[0]).format("YYYY-MM-DD")}T00:00:00`
            values["endDate"] = `${moment(values.date[1]).format("YYYY-MM-DD")}T23:59:59`
            props.handleFilter({startDate: values.startDate, endDate: values.endDate})
        } else if (values.date === undefined) {
            props.handleFilter({bookingState: values.bookingState})
        } else {
            values["startDate"] = `${moment(values.date[0]).format("YYYY-MM-DD")}T00:00:00`
            values["endDate"] = `${moment(values.date[1]).format("YYYY-MM-DD")}T23:59:59`
            props.handleFilter({startDate: values.startDate, endDate: values.endDate,bookingState: values.bookingState})
        }
        form.resetFields();
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                icon={<i className="fas fa-filter" style={{marginRight: "5px"}} />}
                label="Filter" 
                onClick={toggle}
            />
            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="bookingState"
                                        label="Status Transaksi"
                                    >
                                        <Select placeholder="Pilih status transaksi" style={{width: "100%"}}>
                                            <Select.Option value="BOOKED">Booked</Select.Option>
                                            <Select.Option value="REQUESTED">Requested</Select.Option>
                                            <Select.Option value="REJECTED">Rejected</Select.Option>
                                            <Select.Option value="RESCHEDULE-REQUESTED">Reschedule-Requested</Select.Option>
                                            <Select.Option value="RESCHEDULED">Reschedule</Select.Option>
                                            <Select.Option value="CANCELED">Canceled</Select.Option>
                                            <Select.Option value="WAITING-PAYMENT">Waiting-Payment</Select.Option>
                                            <Select.Option value="UNPAID">Unpaid</Select.Option>
                                            <Select.Option value="DONE">Done</Select.Option>
                                            <Select.Option value="EXPIRED">Expired</Select.Option>
                                            <Select.Option value="REFUND">Refund</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="date"
                                        label="Tanggal Transaksi"
                                    >
                                        <RangePicker 
                                            style={{width: "100%"}} 
                                            placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px", textAlign: "center"}}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterTransaction
