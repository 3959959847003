import React, {useContext} from 'react'
import {DetailContext} from '../context/ContextProvider'
import {PageLayout} from '../../../../templates'
import {Search, Table} from '../../../../../components/atoms'
import {Pagination, Row, Col} from 'antd'
import {useQueryParams, FormatDate} from "../../../../../helpers/Formatter"

const DetailCustomer: React.FC = () => {
    const qp = useQueryParams()
    const urlSearchParams:any = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const context = useContext(DetailContext)
    const {
        loading,
        fields,
        handleSubmitSearch,
        handlePageChange
    } = context.useModelList(queryParams.id)

    const columns = [
        {
            title: "Nomor Transaksi",
            dataIndex: "transaction_number",
        },
        {
            title: "Tanggal Transaksi",
            render: (data:{transaction_date:string}) => (
                <div>{FormatDate(data.transaction_date)}</div>
            ),
        },
        {
            title: "Jenis Transaksi",
            dataIndex: "transaction_type",
        },
        {
            title: "Point Sebelum",
            dataIndex: "point_previous"
        },
        {
            title: "Jumlah Point",
            dataIndex: "point_remaining"
        },
        {
            title: "Point Sesudah",
            dataIndex: "point_retrieving"
        }
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <h1>{fields.data[1]?.username === undefined ? queryParams.name : fields.data[1]?.username}</h1>
                        <h1>Point Terkumpul {fields.data[1]?.point_remaining === undefined ? "0" : fields.data[1]?.point_remaining} Point</h1>
                        <h1>Berlaku Hingga {fields.data[1]?.transaction_date === undefined ? "-" : FormatDate(fields.data[1]?.transaction_date)}</h1>
                    </div>
                }
                content={
                    <div style={{marginTop: 50}}>
                        <Table
                            data={fields.data}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                            loading={loading}
                        />
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('q')?.toString()}
                />}
                footer={
                     <Row>
                        <Col span={12}>
                            <span>{`Total ${fields.pagination.perpage_found} Customer`}</span>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.pagination.current_page} 
                                pageSize={10} 
                                total={fields.pagination.total_page * fields.pagination.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default DetailCustomer
