import React, {useContext} from 'react'
import {TransactionFoodAllContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {PageLayout} from "../../../templates"
import {Search, Table, FeatureButton} from "../../../../components/atoms"
import Filter from "../filter/Filter"
import {Pagination, Row, Col} from "antd"
import {useQueryParams} from "../../../../helpers/Formatter"

const List: React.FC = () => {
    const qs = useQueryParams();
    const context = useContext(TransactionFoodAllContext)
    const {
        fields,
        merchant,
        handleSubmitSearch,
        handleFilter, 
        handleReset,
        loading,
        handlePageChange
    } = context.useModelList()
    const {columns} = TableColumns()

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e: any) => handleSubmitSearch(e)}
                    value={qs.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <Filter 
                            handleFilter={handleFilter}
                            merchant={merchant}
                        />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table 
                            loading={loading}
                            columns={columns}
                            data={fields.payload}
                            rowKey="id"
                            pagination={false}
                            rowClassName={(record: { status: string }, index: number) => record.status === "PAID" || record.status === "APPROVED" || record.status === "PROCESS" || record.status === "SEND" ? 'new-transaction' : 'read-transaction'}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            {`Total ${fields.pagination.totalData} Transaksi`}
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination
                                current={fields.pagination.page + 1}
                                pageSize={fields.pagination.limit}
                                total={fields.pagination.totalData}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
