import React from 'react'
import ContextProvier from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./transactiondetail/TransactionDetail"

const Transaction: React.FC = () => {
    return (
        <ContextProvier>
            <Route path="/hobi/transaksi" exact={true} component={List} />
            <Route path="/hobi/transaksi/detail" exact={true} component={Detail} />
        </ContextProvier>
    )
}

export default Transaction
