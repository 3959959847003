import React, {useState} from 'react'
import {Form ,Button, Row, Col, Input} from "antd"
import {Modal, OkButton, BackButton} from "../../../../../components/atoms"

import "./formbankaccount.scss"

interface Props {
    data?: {bankName:string, accountName:string, accountNumber:string}|undefined
    name?: string|undefined
    id?: string|undefined
    submitBank:(values:{bankName: string, accountName: string, accountNumber: string}) => void
}

const FormBankAccount: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{bankName: string, accountName: string, accountNumber: string}) => {
        props.submitBank(values)
        toggle()
    }

    return (
        <React.Fragment>
            <Button className="button-save-edit" onClick={toggle}>
                <i className="far fa-eye" style={{fontSize: "16px"}} />
            </Button>
            <Modal 
                title="Form Akun Bank"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                label="Nama Bank"
                                name="bankName"
                                initialValue={props.data !== undefined ? props.data.bankName : ""}
                                rules={[{ required: true, message: 'Nama bank wajib diisi!' }]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama bank"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                label="Nama Akun"
                                name="accountName"
                                initialValue={props.data !== undefined ? props.data.accountName : ""}
                                rules={[{ required: true, message: 'Nama akun wajib diisi!' }]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama akun"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                label="Nomor Akun"
                                name="accountNumber"
                                initialValue={props.data !== undefined ? props.data.accountNumber : ""}
                                rules={[
                                    { required: true, message: 'Nomor akun wajib diisi!' },
                                    { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor akun harus berupa angka!'}
                                ]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nomor akun"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "15px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <BackButton
                                onClick={toggle}
                            >
                                Kembali
                            </BackButton>
                            <OkButton>
                                Simpan
                            </OkButton>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FormBankAccount
