import firebase from "@firebase/app"
import "@firebase/messaging"

const config = {
    // FOR STAGING
    // apiKey: "AIzaSyASCjkOXo32lVWavh22OniKZoagmcJhssQ",
    // authDomain: "mo-project-bf398.firebaseapp.com",
    // databaseURL: "https://mo-project-bf398.firebaseio.com",
    // projectId: "mo-project-bf398",
    // storageBucket: "mo-project-bf398.appspot.com",
    // messagingSenderId: "296009110148",
    // appId: "1:296009110148:web:56e3190d7c830d3022e8d1",
    // measurementId: "G-43BENY0JCC"

    // FOR PRODUCTION
    apiKey: "AIzaSyAgkYFdf1u45xidlZrLc7wbAWVzZ0uOcLU",
    authDomain: "manyoption.firebaseapp.com",
    databaseURL: "https://manyoption.firebaseio.com",
    projectId: "manyoption",
    storageBucket: "manyoption.appspot.com",
    messagingSenderId: "678114576649",
    appId: "1:678114576649:web:d3d50a64df5c808808ebd8",
    measurementId: "G-DE0XB2P3YH"
};
firebase.initializeApp(config)

export const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () => 
    new Promise((resolve, reject) => {
        messaging.requestPermission().then(() => messaging.getToken()).then((firebaseToken) => {
            resolve(firebaseToken);
        }).catch((err) => {
            reject(err);
        });
    }); 

export const onMessageListener = () =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
          console.log("payload from firebase",payload);
          
        resolve(payload);
      });
    });

    // messaging.onBackgroundMessage()

    // messaging.onBackgroundMessage((payload) => {
    //     console.log(payload);
        
    //     console.log('[firebase-messaging-sw.js] Received background message ', payload);
    //     // Customize notification here
    //     const notificationTitle = 'Background Message Title';
    //     const notificationOptions = {
    //       body: 'Background Message body.',
    //       icon: '/firebase-logo.png'
    //     };
      
    //     self.registration.showNotification(notificationTitle,
    //       notificationOptions);
    //   });