import React, { useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { PasarTransactionContext } from "../context/ContextProvider"
import { IDetailItemOrders } from "../ModelTransaction"
import { DetailPageLayout } from "../../../../templates"
import { Loading } from "../../../../../components/atoms"
import { Row, Col, Alert } from "antd"
import { OkButton, CancelButton, BackButton } from "../../../../../components/atoms"
import { DetailTitleSection, ItemDetail } from "../../../../../components/molecules"
import { FormatDate, FormatRp } from "../../../../../helpers/Formatter"

import "./detail.scss"

const Detail: React.FC = () => {
    const location = useLocation()
    const history = useHistory();
    const transactionID = location.search.split("=")[1]
    const context = useContext(PasarTransactionContext)
    const {
        fields,
        loading,
        handleUpdateStatus
    } = context.useModelDetail(transactionID)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading === "fetch" ? <Loading /> : (
                    <div>
                        {fields.status !== "CREATED" && fields.status !== "UNPAID" && fields.status !== "CANCELED" && fields.status !== "REJECTED" && fields.status !== "EXPIRED" && fields.status !== "DONE" && fields.status !== "FAILED" ? (
                            <div>
                                {fields.status === "PAID" && fields.shopperID === 0 ? <Alert message="Hi! Kamu belum bisa APPROVED transaksi sebelum memilih shopper. Silahkan pilih shopper terlebih dahulu ya!" type="success" /> : null}
                                <Row style={{ marginTop: "10px" }}>
                                    <Col span={24}>
                                        <OkButton
                                            onClick={() => handleUpdateStatus(fields.status === "PAID" ? "APPROVED" : fields.status === "APPROVED" ? "PROCESS" : fields.status === "PROCESS" ? "SEND" : "DONE")}
                                            disabled={fields.status === "PAID" && fields.shopperID === 0 ? true : false}
                                        >
                                            {fields.status === "PAID" ? "APPROVED" : fields.status === "APPROVED" ? "PROCESS" : fields.status === "PROCESS" ? "SEND" : "DONE"}
                                        </OkButton>
                                        <CancelButton
                                            label={fields.status === "PAID" ? "REJECTED" : "CANCELED"}
                                            onClick={() => handleUpdateStatus(fields.status === "PAID" ? "REJECTED" : "CANCELED")}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomer Order" value={fields.invoiceNumber} />
                                <ItemDetail label="User" value={fields.userName} />
                                <ItemDetail label="User Email" value={fields.userEmail === "" ? "-" : fields.userEmail} />
                                <ItemDetail label="Telepon User" value={fields.userPhone === "" ? "-" : fields.userPhone} />
                                <ItemDetail label="Tanggal Order" value={FormatDate(fields.orderDate)} />
                                <ItemDetail label="Status Order" value={fields.status} />
                                <ItemDetail label="Alamat Order" value={fields.address} />
                                <ItemDetail label="Catatan Alamat" value={fields.addressNote === "" ? "-" : fields.addressNote} />
                                <ItemDetail label="Estimasi Pengiriman" value={fields.estimasi === "" ? "-" : fields.estimasi} />
                                {/* <ItemDetail label="Biaya Layanan" value={<div style={{textAlign:"right"}}>{fields.serviceFee !== null ? FormatRp(fields.serviceFee) : 0}</div>} /> */}
                                <ItemDetail label="Tanggal Diubah" value={FormatDate(fields.updatedAt)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Shopper" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Shopper" value={fields.shopperName === "" ? "-" : fields.shopperName} />
                                <ItemDetail label="No. Telepon" value={fields.shopperPhoneNumber === "" ? "-" : fields.shopperPhoneNumber} />
                                <ItemDetail label="Email" value={fields.shopperEmail === "" ? "-" : fields.shopperEmail} />
                                <ItemDetail label="Area" value={fields.areaName === "" ? "-" : fields.areaName} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Item Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Pasar" value={<div style={{ fontWeight: "bold", color: "black" }}>{fields.marketName}</div>} />
                                <Row className="custom-table-head-section" style={{ marginTop: "5px" }}>
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Nama Item</Col>
                                            <Col span={7}>Note</Col>
                                            <Col span={4}>Kuantiti</Col>
                                            <Col span={4}>Diskon</Col>
                                            <Col span={4}><div style={{ float: "right" }}>Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.itemOrder.map((item: IDetailItemOrders, key: number) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{ border: "1px solid black" }}>
                                                <Col span={5}>{item.itemName}</Col>
                                                <Col span={7}>{item.note === "" ? "-" : item.note}</Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={4}>{item.isDiscountPercentage ? `${item.itemDiscount}%` : FormatRp(item.itemDiscount)}</Col>
                                                <Col span={4}><div style={{ float: "right" }}>{FormatRp(item.itemPrice)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Total Order" value={<div style={{ textAlign: "right" }}>{FormatRp(fields.totalRaw)}</div>} />
                                <ItemDetail label="Total Diskon" value={<div style={{ textAlign: "right", color: "red" }}>{FormatRp(fields.totalDiscount !== null ? fields.totalDiscount : 0)}</div>} />
                                <ItemDetail label="Total Promo" value={<div style={{ textAlign: "right", color: "red" }}>{FormatRp(fields.totalPromo !== null ? fields.totalPromo : 0)}</div>} />
                                <ItemDetail label="Biaya Layanan" value={<div style={{ textAlign: "right" }}>{fields.serviceFee !== null ? FormatRp(fields.serviceFee) : 0}</div>} />
                                <ItemDetail label="Biaya Pengiriman" value={<div style={{ textAlign: "right" }}>{FormatRp(fields.deliveryFee)}</div>} />
                                <ItemDetail label="Total" value={
                                    <div style={{ textAlign: "right", fontWeight: "bold", color: "black" }}>
                                        {fields.totalOrder !== null && fields.totalDiscount !== null && fields.totalPromo !== null ? FormatRp(fields.totalOrder + fields.serviceFee + fields.deliveryFee) : 0}
                                    </div>
                                } />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Pembayaran" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Status Pembayaran" value={fields.moPayment.paymentState} />
                                <ItemDetail label="Metode Pembayaran" value={fields.moPayment.paymentMethod === "" ? "-" : fields.moPayment.paymentMethod} />
                                <ItemDetail label="Provider Pembayaran" value={fields.moPayment.paymentProvider === "" ? "-" : fields.moPayment.paymentProvider} />
                                <ItemDetail label="Total Pesananan" value={
                                    <div style={{ textAlign: "right", fontWeight: "bold", color: "black" }}>
                                        {fields.totalOrder !== null && fields.totalDiscount !== null && fields.totalPromo !== null ? FormatRp(fields.totalOrder + fields.serviceFee + fields.deliveryFee) : 0}
                                    </div>
                                } />
                                <ItemDetail label="Biaya Admin" value={<div style={{ textAlign: "right" }}>{FormatRp(fields.adminFee !== null ? fields.adminFee : 0)}</div>} />
                                <ItemDetail label="Total Bayar" value={<div style={{ textAlign: "right", color: "black", fontWeight: "bold" }}>{FormatRp(fields.totalPay !== null ? fields.totalPay : 0)}</div>} />
                            </Col>
                        </Row>
                        {fields.refundOrder.id !== 0 ? (
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <DetailTitleSection title="Detail Pengembalian Item Order" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <Row className="custom-table-head-section">
                                            <Col span={24}>
                                                <Row className="custom-table-head-container">
                                                    <Col span={11}>Nama Item</Col>
                                                    <Col span={3}>Kuantiti</Col>
                                                    <Col span={10}><div style={{ float: "right" }}>Harga Setelah Diskon</div></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {fields.refundOrder.refundOrderDetail.map((item: { itemName: string, itemQty: number, itemPrice: number, itemDiscount: number }, key: number) => (
                                    <Row key={key}>
                                        <Col span={16}>
                                            <Row className="custom-table-body-section">
                                                <Col span={24}>
                                                    <Row className="custom-table-body-container" style={{ border: "1px solid black" }}>
                                                        <Col span={11}>{item.itemName}</Col>
                                                        <Col span={3} style={{ textAlign: "center" }}>{item.itemQty}</Col>
                                                        <Col span={10}>
                                                            <div style={{ float: "right" }}>
                                                                {FormatRp(item.itemPrice - item.itemDiscount)}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col span={16}>
                                        <ItemDetail label="Total" value={
                                            <div style={{ textAlign: "right", fontWeight: "bold", color: "black" }}>
                                                {FormatRp(fields.refundOrder.total)}
                                            </div>
                                        } />
                                        <ItemDetail
                                            label="Catatan Pengembalian"
                                            value={fields.refundOrder.refundNote}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Row style={{ paddingTop: "20px" }}>
                            <Col span={24}>
                                <BackButton onClick={() => history.goBack()}>
                                    Kembali
                                </BackButton>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
