import React, { useContext } from 'react'
import {FoodContext} from "../context/ContextProvider"
import { FeatureButton, Modal, Loading } from "../../../../components/atoms"
import CreateCategory from "./createcategory/CreateCategory"
import CategoryBox from "./CategoryBox"
import {Pagination} from "antd"

import "./category.scss"

const Category = () => {
    const context = useContext(FoodContext)
    const {
        categories,
        modal,
        loading,
        toggle,
        handleSubmit,
        handleEdit,
        handleDelete,
        handlePageChange
    } = context.useModelListCategory()

    return (
        <React.Fragment>
            <FeatureButton
                icon={<i className="fas fa-clipboard-list" style={{ marginRight: "5px" }} />}
                label="Kategori"
                onClick={toggle}
            />

            <Modal
                title="Kategori"
                onCancel={toggle}
                visible={modal}
            >
                <CreateCategory handleSubmit={handleSubmit} />
                {loading ? <Loading /> : (
                    <div>
                        {categories.payload.map((field:{id:string, name:string}, key:number) => (
                            <CategoryBox
                                key={key}
                                name={field.name}
                                id={field.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
                <div style={{paddingTop: "10px", paddingBottom: "20px", textAlign: "right"}}>
                    <Pagination 
                        current={categories.pagination.page + 1} 
                        pageSize={5} 
                        total={categories.pagination.totalData} 
                        onChange={handlePageChange} 
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Category
