import React, {useState, useEffect} from 'react'
import { useQueryParams } from "../../../../../helpers/Formatter"
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Row, Col, DatePicker, Select} from "antd"
import moment from "moment"

interface Props {
    handleFilter: (values:any) => void
    merchant: {id:string, name:string}[]
}

const Filter: React.FC<Props> = (props) => {
    const qp = useQueryParams()
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker;
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleShowModal = () => {
        toggle();
        var value = { ...qp.value };
        if (qp.queryParams.get("createdFrom") && qp.queryParams.get("createdTo")) {
            value["createdAt"] = [moment(qp.queryParams.get('createdFrom')), moment(qp.queryParams.get('createdTo'))];
        }

        if (qp.queryParams.get("marketID")) {
            value["marketID"] = Number(qp.queryParams.get("marketID"))
        }

        form.setFieldsValue(value);
    }

    const handleSubmit = (values: {}) => {
        props.handleFilter(values)
        form.resetFields();
        toggle();
    }

    useEffect(() => {
        if (Object.keys(qp.value).length < 1) {
            form.resetFields();
        }
    }, [qp.value, form])

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={handleShowModal}
            />

            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row className="row-form" >
                        <Col span={24}>
                            <Form.Item
                                name="createdAt"
                                label="Tanggal Transaksi"
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="merchantId"
                                label="Merchant"
                            >
                                <Select placeholder="Pilih Merchant" style={{ width: "100%" }}>
                                    {props.merchant.map((datum, key) => (
                                        <Select.Option value={datum.id} key={key}>{datum.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-form">
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="Status Transaksi"
                            >
                                <Select placeholder="Pilih status transaksi" style={{ width: "100%" }}>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="UNPAID">UNPAID</Select.Option>
                                    <Select.Option value="PAID">PAID</Select.Option>
                                    <Select.Option value="APPROVED">APPROVED</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="SEND">SEND</Select.Option>
                                    <Select.Option value="DONE">DONE</Select.Option>
                                    <Select.Option value="CANCELED">CANCELED</Select.Option>
                                    <Select.Option value="REJECTED">REJECTED</Select.Option>
                                    <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "10px" }}>
                        <Col span={24}>
                            <Form.Item
                                name="paymentState"
                                label="Status Pembayaran"
                            >
                                <Select placeholder="Pilih status pembayaran" style={{ width: "100%" }}>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="UNPAID">UNPAID</Select.Option>
                                    <Select.Option value="PAID">PAID</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="CANCELED">CANCELED</Select.Option>
                                    <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                    <Select.Option value="REFUND">REFUND</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "10px" }}>
                        <Col span={24}>
                            <Form.Item
                                name="paymentMethod"
                                label="Metode Pembayaran"
                            >
                                <Select placeholder="Pilih metode pembayaran" style={{ width: "100%" }}>
                                    <Select.Option value="ewallet">ewallet</Select.Option>
                                    <Select.Option value="va">va</Select.Option>
                                    <Select.Option value="cash">cash</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>{" "}
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Filter
