import {useState} from "react"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {paymentconfig} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelFilter() {
    const [origin, setOrigin] = useState<string[]>([])
    const [provider, setProvider] = useState<string[]>([])
    const [method, setMethod] = useState<{
        paymentMethod: ""
        paymentMethodCode: ""
    }[]>([])
    const [originVal, setOriginVal] = useState<string>("")

    const fetchOrigin = async() => {
        try {
            const url = `${base_url}/${paymentconfig}/get`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setOrigin(res.data.payload.paymentOrigin)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data origin payment',
                placement:"topRight"
            });
        }
    }

    const fetchProvider = async(value:string) => {
        setOriginVal(value)
        try {
            const url = `${base_url}/${paymentconfig}/get/${value}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setProvider(res.data.payload.paymentProvider)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data payment provider',
                placement:"topRight"
            });
        }
    }

    const fetchMethod = async(value:string) => {
        try {
            const url = `${base_url}/${paymentconfig}/get/${originVal}/${value}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setMethod(res.data.payload)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data payment method',
                placement:"topRight"
            });
        }
    }

    return {
        origin,
        provider,
        method, 
        fetchOrigin,
        fetchProvider,
        fetchMethod
    }
}