import React, {useContext} from 'react'
import {JasaContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../templates"
import {Loading} from "../../../../components/atoms"
import FormMerchantJasa from "../formmerchantjasa/FormMerchantJasa"

import "./createmerchantjasa.scss"

const CreateMerchantJasa: React.FC = () => {
    const context = useContext(JasaContext)
    const {
        fields,
        operational,
        schedule,
        newTag,
        loading,
        submitPhoto,
        handleDeleteTag,
        handleEditTag,
        handleChangeTag,
        submitNewTag,
        submitBank,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        submitChangeProcessSchedule,
        submitAddProcessSchedule,
        submitDeleteProcessSchedule,
        submitProcessSchedule,
        handleSubmit,
        fieldsList,
        paramMerchantId,
    } = context.useModelDetail()

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading && <Loading />}
                    <FormMerchantJasa
                        data={fields}
                        submitPhoto={submitPhoto}
                        newTag={newTag}
                        handleDeleteTag={handleDeleteTag}
                        handleEditTag={handleEditTag}
                        handleChangeTag={handleChangeTag}
                        submitNewTag={submitNewTag}
                        submitBank={submitBank}
                        operational={operational}
                        schedule={schedule}
                        submitOperationHourStatus={submitOperationHourStatus}
                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                        submitOperationalHour={submitOperationalHour}
                        submitChangeProcessSchedule={submitChangeProcessSchedule}
                        submitAddProcessSchedule={submitAddProcessSchedule}
                        submitDeleteProcessSchedule={submitDeleteProcessSchedule}
                        submitProcessSchedule={submitProcessSchedule}
                        handleSubmit={handleSubmit}
                        fieldsList={fieldsList}
                        paramMerchantId={paramMerchantId}
                    />
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default CreateMerchantJasa
