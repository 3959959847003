import React, {useContext} from 'react'
import {useQueryParams} from "../../../../helpers/Formatter"
import {TransactionPaymentContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {PageLayout} from "../../../templates"
import Filter from "../filter/Filter"
import Report from "../report/Report"
import {Search, Table, FeatureButton} from "../../../../components/atoms"
import {Row, Col, Pagination} from "antd"

import "./list.scss"

const List: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(TransactionPaymentContext)
    const {
        fields, 
        loading,
        params,
        handleDownloadReport,
        handleFilter,
        handleReset,
        handlePageChange,
        handleSubmitSearch,
        handleRefreshPayment
    } = context.useModelList()
    const {columns} = TableColumns(handleRefreshPayment)

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <Filter 
                            handleFilter={handleFilter}
                        />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                        <Report 
                            loading={loading}
                            handleDownloadReport={handleDownloadReport}
                        />
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e: any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            rowKey="payment_id"
                            pagination={false}
                            loading={loading === "fetch" ? true : false}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            {`Total ${fields.totalData} Transaksi`}
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination
                                current={params.pageNumber}
                                pageSize={10}
                                total={fields.totalData}
                                showSizeChanger={false}
                                onChange={handlePageChange}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
