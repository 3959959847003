import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {PasarContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../templates"
import {Loading} from "../../../../components/atoms"
import FormPasar from "../formpasar/FormPasar"

import "./editpasar.scss"

const EditPasar: React.FC = () => {
    const location = useLocation()
    const pasarId = location.search.split("=")[1]
    const context = useContext(PasarContext)
    const {
        fields,
        loading,
        newTag,
        handleEditTag, 
        handleDeleteTag,
        operationHours,
        handleChangeTag,
        submitNewTag,
        handleSetImageURL,
        submitOperationHourStatus,
        submitOperationHourOpenCLose,
        submitOperationalHour,
        handleSubmit
    } = context.useModelDetail(pasarId)

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormPasar 
                        data={fields}
                        handleEditTag={handleEditTag}
                        handleDeleteTag={handleDeleteTag}
                        operationHours={operationHours}
                        newTag={newTag}
                        handleChangeTag={handleChangeTag}
                        submitNewTag={submitNewTag}
                        handleSetImageURL={handleSetImageURL}
                        submitOperationHourStatus={submitOperationHourStatus}
                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                        submitOperationalHour={submitOperationalHour}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default EditPasar
