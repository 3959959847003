import React from "react"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"

function TableColumns() {
    const columns = [
        {
            title: "Nomor Transaksi",
            render: (data:{transactionNumber:string}) => (
                <div style={{fontWeight: "bold", color: "black"}}>{data.transactionNumber}</div>
            ),
            width: "12%"
        },
        {
            title: "Tanggal Transaksi",
            render: (data:{transactionDate:string}) => (
                <div>{FormatDate(data.transactionDate)}</div>
            ),
            width: "15%"
        },
        {
            title: "Jenis Transaksi",
            dataIndex: "type",
            width: "10%"
        },
        {
            title: "Deposit Sebelum",
            render: (data:{balanceBefore:number}) => (
                <div>{FormatRp(data.balanceBefore)}</div>
            ),
            width: "15%"
        },
        {
            title: "Jumlah",
            render: (data:{amount:number}) => (
                <div>{FormatRp(data.amount)}</div>
            ),
            width: "13%"
        },
        {
            title: "Deposit Sesudah",
            render: (data:{balanceAfter:number}) => (
                <div>{FormatRp(data.balanceAfter)}</div>
            ),
            width: "15%"
        },
        {
            title: "Asal Transaksi",
            dataIndex: "transactionType"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
    ]

    return {
        columns
    }
}

export default TableColumns