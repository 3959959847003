import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IList} from "../TransactionPaymentModel"
import axios from "axios"
import { base_url } from "../../../../config/url-config"
import {paymenttransaction} from "../../../../config/endpoint"
import {notification} from "antd"
import moment from "moment"

export function useModelList() {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<string>("")
    const [fields, setFields] = useState<IList>({
        data:[],
        totalData: 0
    })
    const [params, setParams] = useState<{
        pageNumber: number
        pageLimit: number
        // search: string
    }>({
        pageNumber: 1,
        pageLimit: 10,
        // search: ""
    })

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${paymenttransaction}/list`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: {...queryparams, ...qp.value}
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi payment',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handleDownloadReport = async(values:any) => {
        for (var key in values) {
            if (values[key] === undefined) {
                delete values[key]
            } else if (values["createdAt"] !== undefined) {
                values["startDate"] = `${moment(values.createdAt[0]).format("YYYY-MM-DD")}T00:00:00.000Z`
                values["endDate"] = `${moment(values.createdAt[1]).format("YYYY-MM-DD")}T23:59:00.000Z`
                delete values.createdAt
            }
        }
        
        function generateCSV(res:any) {
            console.log(res.data);
            // const buffer = Buffer.from(res.data)
            const url = window.URL.createObjectURL(
                new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report-payment.xlsx`);
            document.body.appendChild(link);
            link.click();
      
            let data = {
                code: 200,
                message: 'Download CSV Success',
            };
            return data;
        }

        setLoading("download")
        try {
            const url = `${base_url}/v2/mopayment/core/payment/report`
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            }

            const res = await axios.get(url, {
                headers: headers,
                params: values,
                responseType: 'arraybuffer'
            })
            generateCSV(res)
            setLoading("")
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat download report',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    const handleFilter = (values:any) => {
        for (var key in values) {
            if (values[key] === undefined) {
                delete values[key]
            } 
        }
        // console.log(values);
        setParams({...params, ...values, pageNumber: 1})
    }

    const handleReset = () => {
        setParams({
            pageNumber: 1,
            pageLimit: 10,
        })
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            // setParams({...params, search: e.target.value})
            qp.queryParams.set("search", e.target.value);
            qp.handleQueryParams();
            setParams({...params, pageNumber: 1})
         }
    }

    const handlePageChange = (page:any, pageSize:any) => {
        setParams({...params, pageNumber:page})
    }

    const handleRefreshPayment = async(paymentMethod:string, value:{invoiceNumber:string, origin:string}) => {
        try {
            const url = `${base_url}/v2/mopayment/${paymentMethod}/payment/status`
            const headers = {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            }

            axios.post(url, value, {
                headers:headers
            })
            fetchData(params)
            notification.success({
                message: 'Yuhuuu!',
                description:
                  'Memperbaharui status payment berhasil',
                placement:"topRight"
            })
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat update status payment',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    return {
        loading,
        fields,
        params,
        origin,
        handleDownloadReport,
        handleFilter,
        handleReset,
        handlePageChange,
        handleSubmitSearch,
        handleRefreshPayment
    }
}