import React, {useContext} from 'react'
import {ItemJasaContext} from "../context/ContextProvider"
import {DetailPageLayout} from "../../../../templates"
import {Loading} from "../../../../../components/atoms"
import FormItem from "../formitem/FormItem"

import "./createitem.scss"

const CreateItem: React.FC = () => {
    const context = useContext(ItemJasaContext)
    const {
        fields, 
        category, 
        loading,
        submitPhoto,
        handleSubmit
    } = context.useModelDetail()

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading && <Loading />}
                    <FormItem 
                        data={fields}
                        category={category}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                    />
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default CreateItem
