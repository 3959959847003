import React from 'react'
import {IDetailPasar} from "../ModelPasar"
import {Form, Row, Col, Input, Button, Select, Switch} from "antd"
import {UploadPhoto} from "../../../../components/atoms"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Tag from "./tag/Tag"
import Operational from "./operational/Operational"
import {OkButton, BackButton} from "../../../../components/atoms"
import {Link} from "react-router-dom"

import "./formpasar.scss"

interface Props {
    data: IDetailPasar
    handleDeleteTag: (tag:string) => void
    handleEditTag: (tag:string, index:number) => void
    operationHours: {}[]
    newTag?: string
    handleChangeTag?: () => void
    submitNewTag?: () => void
    handleSetImageURL: (url:string) => void
    submitOperationHourStatus: (e:any, index:number) => void
    submitOperationHourOpenCLose: (time:any, timeString:any, index:number) => void
    submitOperationalHour: () => void
    handleSubmit: () => void
}

const FormPasar: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <Form
                onFinish={props.handleSubmit}
            >
                <Row>
                    <Col span={12} className="left-section">
                        <Row className="row-form">
                            <Col>
                                <Form.Item
                                    name="imageURL"
                                    valuePropName="file"
                                    initialValue={props.data.imageURL[0]}
                                >
                                    <UploadPhoto 
                                        featureName="pasar"
                                        getLink={props.handleSetImageURL}
                                        imageURL={props.data.imageURL[0]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Nama Pasar</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="name"
                                    initialValue={props.data.name}
                                    rules={[{ required: true, message: 'Nama pasar wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input nama pasar"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Deskripsi Pasar</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="description"
                                    initialValue={props.data.description}
                                    rules={[
                                        { required: true, message: 'Deskripsi pasar wajib diisi!' },
                                        { max: 500, message: 'Deskripsi pasar masimal 500 karakter!' },
                                    ]}
                                >
                                    <Input.TextArea 
                                        className="form-input"
                                        rows={4} 
                                        placeholder="Input deskripsi pasar"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Tags</Col>
                            <Col span={14}>
                                <Row style={{paddingBottom: "5px", paddingLeft: "2px"}}>
                                    {props.data.tags.map((tag:string, key:number) => (
                                        <Col span={12} key={key} style={{paddingRight: "8px", paddingBottom: "10px"}}>
                                            <Tag
                                                index={key}
                                                tag={tag}
                                                handleEditTag={props.handleEditTag}
                                                handleDeleteTag={props.handleDeleteTag}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                                <Row>
                                    <Col span={17}>
                                        <Input
                                            className="form-input"
                                            placeholder="Input tag baru" 
                                            onChange={props.handleChangeTag} 
                                            value={props.newTag}
                                        />
                                    </Col>
                                    <Col span={7} style={{paddingLeft: "12px"}}>
                                        <Button
                                            className="button-save-edit" 
                                            style={{width: "100%"}} 
                                            onClick={props.submitNewTag}
                                        >
                                            +
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Biaya Layanan</Col>
                            <Col span={10}>
                                <Form.Item
                                    name="serviceFee"
                                    initialValue={props.data.serviceFee}
                                    rules={[
                                        { required: true, message: 'Biaya layanan wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Sharing fee yang anda input melebihi batas!'}
                                    ]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        min="0"
                                        placeholder="Input biaya layanan"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{paddingLeft: "10px"}}>
                                <Form.Item
                                    name="isServiceFeePercentage"
                                    initialValue={props.data.isServiceFeePercentage ? "%" : "idr"}
                                    rules={[{ required: true, message: 'Satuan sharing fee wajib dipilih!' }]}
                                >
                                    <Select>
                                        <Select.Option value="%">%</Select.Option>
                                        <Select.Option value="idr">idr</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Alamat</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="address"
                                    initialValue={props.data.address}
                                    rules={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                >
                                    <Input.TextArea 
                                        rows={3} 
                                        className="form-input" 
                                        placeholder="Input alamat pasar"
                                        value={props.data.address}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Latitude</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="latitude"
                                    initialValue={props.data.latitude}
                                    rules={[{ required: true, message: 'Latitude wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input latitude"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-form">
                            <Col span={7}>Longitude</Col>
                            <Col span={14}>
                                <Form.Item
                                    name="longitude"
                                    initialValue={props.data.longitude}
                                    rules={[{ required: true, message: 'Longitude wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        placeholder="Input longitude"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="right-section">
                        <Row className="row-form">
                            <Col span={7}>Jam Operasional</Col>
                            <Col span={12}>
                                <Operational
                                    data={props.operationHours}
                                    submitOperationHourStatus={props.submitOperationHourStatus}
                                    submitOperationHourOpenCLose={props.submitOperationHourOpenCLose}
                                    submitOperationalHour={props.submitOperationalHour}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7}>Status Aktif</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="isActive"
                                    valuePropName= "checked"
                                    initialValue={props.data.isActive}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        // defaultChecked={data.IsActive} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7}>Status Buka</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="isOpen"
                                    valuePropName= "checked"
                                    initialValue={props.data.isOpen}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        // defaultChecked={data.IsActive} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{paddingTop: "20px"}}>
                    <Col span={24}>
                        <Form.Item>
                            <OkButton>Simpan</OkButton>
                            <Link to="/pasar">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPasar
