import React, {useState, useEffect} from 'react'
import axios from "axios"
import moment from "moment"
import {Form, Row, Col, Input, Button, Switch, Select, Checkbox, DatePicker, notification, Upload, Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import {base_url} from "../../../../config/url-config"
import {uploadphoto, layanan, paymentconfig, merchantjasa} from "../../../../config/endpoint"
import {Link} from "react-router-dom"
import {ModelDataVoucher} from '../ModelVoucher'
import {OkButton, BackButton} from "../../../../components/atoms"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import "./form.scss"
import { disabledDate, disabledRangeTime } from './helper.voucher';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
    data: ModelDataVoucher,
    submitPhoto: (path:string) => void,
    handleSubmit: (values:{}) => void
}

const FormVoucher: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [inputLayanan, setInputLayanan] = useState<[]>(props.data.included_layanan_data);
    const [dataLayanan, setDataLayanan] = useState<{}[]>([]);
    const [dataCategoryLayanan, setDataCategoryLayanan] = useState<{}[]>([]);
    const [dataMerchant, setDataMerchant] = useState<{}[]>([]);
    const [paymentProvider, setPaymentProvider] = useState<{}[]>([]);
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [editorHtml, setEditorHtml] = useState('');
    const [searchMerchant, setSearchMerchant] = useState('');
    const [isPercentage, setIsPercentage] = useState<string>("percentage_rates")
    const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
  
    useEffect(() => {
        getLayanan();
        getCategoryLayanan();
        getPaymentProvider();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getMerchant(searchMerchant)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchMerchant]);

    const getLayanan = async () => {
        try {
            const url = `${base_url}/${layanan}`;
            const res = await axios.get(url, { headers: headers });

            setDataLayanan(res?.data?.payload)
        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data layanan',
                    placement: "topRight"
                });
            }
        }
    }

    const getCategoryLayanan = async() => {
        try {
            const url = `${base_url}/v2/virtual-product/dashboard/list/filter/params`

            const res = await axios.get(url, {
                headers: headers
            })
            setDataCategoryLayanan(res.data.payload.category)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses kategori layanan',
                placement:"topRight"
            });
        }
    }

    const getPaymentProvider = async () => {
        try {
            const url = `${base_url}/${paymentconfig}/get/${"jasa"}`;
            const res = await axios.get(url, { headers: headers });

            setPaymentProvider(res?.data?.payload?.paymentProvider)
        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data payment provider',
                    placement: "topRight"
                });
            }
        }
    }

    const getMerchant = async(search:string) => {
        try {
            const url = `${base_url}/${merchantjasa}`
            const res = await axios.get(url, {
                headers: headers,
                params: {
                    page: 1,
                    q: search,
                    sortBy: "-updatedAt"
                }
            })

            setDataMerchant(res?.data?.payload?.data);
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
        }
    }

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/${uploadphoto}`, formData, {
                headers: headers
            })
            props.submitPhoto(res.data.payload.fullpath)
            setLoadingUpload(false)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload foto voucher.',
            });
        }
    }

    const handleChangeTypeNominal = (type:string) => {
        form.setFieldsValue({ nominal: 0 });

        setIsPercentage(type)
    }

    return (
        <React.Fragment>
            <Form 
                form={form}
                onFinish={props.handleSubmit}
            >
                    <Row>
                        <Col style={{fontWeight: 900, fontSize:"20px", marginBottom: "10px"}}>General</Col>
                        <Col span={24} className="left-section">
                            <Row className="row-form">
                                <Col span={7}>Kode Promo</Col>
                                <Col span={14}>
                                    <Row>
                                        <Col span={18}>
                                            <Form.Item
                                                name="promo_code"
                                                rules={[{ required: true, message: 'Kode Promo Wajib Di isi!' }]}
                                                initialValue={props.data.promo_code}
                                            >
                                                <Input
                                                    className="form-input"   
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} style={{paddingLeft: "12px"}}>
                                            <Button
                                                className="button-save-edit" 
                                                style={{width: "100%"}} 
                                            >
                                                Generate
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Nama Promo</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Nama Promo wajib diisi!' }]}
                                        initialValue={props.data.name}
                                    >
                                        <Input
                                            className="form-input" 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>         
                            <Row className="row-form">
                                <Col span={7}>Gambar</Col>
                                <Col>
                                    <Form.Item
                                        name="image"
                                        valuePropName="file"
                                        initialValue={props.data.image}
                                    >
                                        <Upload 
                                            showUploadList={false} 
                                            onChange={handleUpload}
                                        >
                                            <div className="upload-photo">
                                                {loadingUpload ? (
                                                    <div style={{paddingTop: "25px"}}>
                                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                                    </div>
                                                ) : (
                                                    props.data.image === "" ? (
                                                        <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                            <i className="far fa-image fa-6x" />
                                                        </div>
                                                    ) : (
                                                        <img src={props.data.image} alt="merchant-hobby-logo" style={{width: "100px", height: "80px", marginTop: "10px"}} />
                                                    )
                                                )}
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Deskripsi</Col>
                                <Col span={14}>
                                    <div className="custom-editor">
                                        <Form.Item
                                            name="description"
                                            rules={[{ required: true, message: 'Deskripsi wajib diisi!'}]}
                                            initialValue={props.data.description}
                                        >
                                            <ReactQuill
                                                onChange={(html:any) => setEditorHtml(html)}
                                                value={editorHtml}
                                                modules={{
                                                  toolbar: [
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                    ['link'],
                                                  ]
                                                }}
                                                formats={[
                                                  'bold', 'italic', 'underline', 'strike',
                                                  'list', 'bullet', 'indent',
                                                  'link',
                                                ]}
                                                bounds={'.custom-editor'}
                                                placeholder={'Tulis Deskripsi...'}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Jenis Voucher</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="voucher_category"
                                            rules={[
                                                { required: true, message: 'Jenis Voucher wajib diisi!' },
                                            ]}
                                            initialValue={props.data.voucher_category}
                                        >
                                        <Select placeholder="Pilih">
                                            <Select.Option value="potongan">Potongan</Select.Option>
                                            <Select.Option value="cashback_point">Cashback Point</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Nominal</Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="nominal"
                                        rules={[
                                            { required: true, message: 'Nominal wajib diisi!' },
                                            { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Nominal yang anda input melebihi batas!'},
                                            {
                                                validator: (_, value) =>
                                                    isPercentage === "percentage_rates" && value > 100 ? Promise.reject('Persen tidak boleh lebih dari 100!') : Promise.resolve()
                                            },
                                        ]}
                                        initialValue={props.data.nominal}
                                    >
                                        <Input 
                                            className="form-input" 
                                            type="number"
                                            min="0"
                                            placeholder="0"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{paddingLeft: "10px"}}>
                                    <Form.Item
                                        name="nominal_type"
                                        rules={[{ required: true, message: 'Satuan Nominal wajib dipilih!' }]}
                                        initialValue={props.data.nominal_type}
                                    >
                                        <Select 
                                            value={isPercentage}
                                            onChange={(value:string) => handleChangeTypeNominal(value)}
                                        >
                                            <Select.Option value="percentage_rates">%</Select.Option>
                                            <Select.Option value="fixed_rates">idr</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Maksimal Nominal</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="maximal_nominal"
                                        rules={[{ required: true, message: 'Maksimal Nominal wajib diisi!' }]}
                                        initialValue={props.data.maximal_nominal}
                                    >
                                        <Input
                                            className="form-input" 
                                            type="number"
                                            placeholder="0"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>                      
                            <Row className="row-form">
                                <Col span={7}>Periode Voucher</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="periode_voucher"
                                        rules={[
                                            { type: 'array' as const, required: true, message: 'Please select time!' }
                                        ]}
                                        initialValue={
                                            props.data.start_date === "" || props.data.expired_date === "" ? null :
                                            [
                                                moment(props.data.start_date),
                                                moment(props.data.expired_date)
                                            ]   
                                        }
                                    >   
                                        <RangePicker disabledDate={disabledDate} disabledTime={(_:any, current) => disabledRangeTime(_, current)} showTime />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Status Voucher</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="is_active"
                                        valuePropName="checked"
                                        initialValue={props.data.is_active === "1"}
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Tampil di List</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="is_visible"
                                        valuePropName="checked"
                                        initialValue={props.data.is_visible === "1"}
                                    >
                                        <Checkbox>
                                            Ya
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>  
                            <Row className="row-form">
                                <Col span={7}>Untuk Pengguna Baru</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="is_new_user"
                                        valuePropName="checked"
                                        initialValue={props.data.is_new_user === "1"}
                                    >
                                        <Checkbox>
                                            Ya
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>                
                    </Row>
                    
                    <Row>
                        <Col span={24} className="left-section">
                            <Row>
                                <Col style={{fontWeight: 900, fontSize:"20px", marginBottom: "10px"}}>Pembatasan Penggunaan</Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Minimal Transaksi</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="minimal_transaction"
                                        rules={[
                                            { required: true, message: 'Minimal transaski wajib diisi!' },
                                            { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Minimal yang anda input melebihi batas!'}
                                        ]}
                                        initialValue={props.data.minimal_transaction}

                                    >
                                        <Input
                                            className="form-input" 
                                            type="number"
                                            placeholder="Tidak ada"

                                        />
                                    </Form.Item>
                                </Col>
                            </Row> 
                            <Row className="row-form">
                                <Col span={7}>Maksimal Transaksi</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="maximal_transaction"
                                        rules={[
                                            { required: true, message: 'Maksimal transaski wajib diisi!' },
                                            { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Maksimal yang anda input melebihi batas!'}
                                        ]}
                                        initialValue={props.data.maximal_transaction}
                                    >
                                        <Input
                                            className="form-input" 
                                            type="number"
                                            placeholder="Tidak ada"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> 
                            {/* <Row className="row-form">
                                <Col span={7}>Pengguna Individu</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="MaxDistance"
                                    >
                                        <Checkbox>
                                            Centang kotak ini jika promo tidak dapat digunakan bersama dengan promo lainnya.
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row className="row-form">
                                <Col span={7}>Layanan</Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="included_layanan"
                                        initialValue={props.data.included_layanan_data?.map((val:any) => JSON.stringify(val))}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Pilih Layanan"
                                            style={{ width: '100%' }}
                                            onChange={(val:any) => setInputLayanan(val.map((ele:any) => JSON.parse(ele)))}
                                        >
                                            {dataLayanan.map((value:any, key:number) => <Option value={JSON.stringify(value)} key={key}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Kategori Layanan</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="included_category"
                                            initialValue={props.data.included_category === "" ? [] : props.data.included_category.split(",")}
                                        >
                                        <Select
                                            disabled={inputLayanan.length === 0 ? true : inputLayanan.find((ele:any) => ele.is_category === 0)}
                                            mode="multiple"
                                            placeholder="Pilih Kategori Layanan"
                                            style={{ width: '100%' }}
                                        >
                                            {dataCategoryLayanan.map((value:any, key:number) => <Option value={value} key={key}>{value}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Kecualikan Layanan</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="excluded_layanan"
                                            initialValue={props.data.excluded_layanan === "" ? [] : props.data.excluded_layanan.split(",")}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Pilih Layanan"
                                                style={{ width: '100%' }}
                                            >
                                                {dataLayanan.map((value:any, key:number) => <Option value={value.id.toString()} key={key}>{value.name}</Option>)}
                                            </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Kecualikan Kategori Layanan</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="excluded_category"
                                            initialValue={props.data.excluded_category === "" ? [] : props.data.excluded_category.split(",")}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Pilih Kategori Layanan"
                                                style={{ width: '100%' }}
                                            >
                                                {dataCategoryLayanan.map((value:any, key:number) => <Option value={value} key={key}>{value}</Option>)}
                                            </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Merchant</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="merchant"
                                            initialValue={props.data.merchant === "" ? [] : props.data.merchant.split(",")}
                                        >
                                        <Select
                                            disabled={inputLayanan.length === 0 ? true : inputLayanan.find((ele:any) => ele.is_category === 1)}
                                            showSearch
                                            mode="multiple"
                                            placeholder="Pilih & Cari"
                                            style={{ width: '100%' }}
                                            onSearch={(val:any) => setSearchMerchant(val)}
                                        >
                                            {dataMerchant.map((value:any, key:number) => <Option value={value.name} key={key}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Provider Pembayaran</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="payment_provider"
                                            rules={[
                                                { required: true, message: 'Payment Provider wajib diisi!' },
                                            ]}
                                            initialValue={props.data.payment_provider === "" ? [] : props.data.payment_provider.split(",")}
                                        >
                                        <Select
                                            mode="multiple"
                                            placeholder="Pilih"
                                            style={{ width: '100%' }}
                                        >
                                            {paymentProvider.map((value:any, key:number) => <Option value={value} key={key}>{value}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className="left-section">
                            <Row>
                                <Col style={{fontWeight: 900, fontSize:"20px", marginBottom: "10px"}}>Limit Penggunaan</Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Limit Promo</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="limit_promo_total"
                                            initialValue={props.data.limit_promo_total}
                                        >
                                         <Input 
                                            className="form-input" 
                                            type="number"
                                            min="0"
                                            placeholder="0"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Limit Perhari</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="limit_per_days"
                                            initialValue={props.data.limit_per_days}
                                        >
                                         <Input 
                                            className="form-input" 
                                            type="number"
                                            min="0"
                                            placeholder="0"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-form">
                                <Col span={7}>Limit Per User</Col>
                                <Col span={14}>
                                    <Form.Item
                                            name="limit_per_user"
                                            initialValue={props.data.limit_per_user}
                                        >
                                        <Input 
                                            className="form-input" 
                                            type="number"
                                            min="0"
                                            placeholder="0"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <OkButton>Simpan</OkButton>
                                <Link to="/voucher">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row> 
                </Form>
        </React.Fragment>
    )
}

export default FormVoucher
