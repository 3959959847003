import React, { useContext } from 'react'
import {ItemContext} from "../context/ContextProvider"
import { Link } from "react-router-dom"
import { DetailPageLayout } from "../../../../templates"
import { Loading, BackButton } from "../../../../../components/atoms"
import { Row, Col } from "antd"
import { DetailTitleSection, ItemDetail } from "../../../../../components/molecules"
import { FormatRp, FormatDate, useQuery } from "../../../../../helpers/Formatter"

import "./detailitem.scss"

const DetailItem: React.FC = () => {
    const query = useQuery();
    const context = useContext(ItemContext)
    const {
        fields, 
        loading
    } = context.useModelDetail(query.get("product_id"))

    console.log(fields);

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.urlphoto} alt="item-pict" className="merchant-photo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Item" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Item" value={fields.name} />
                                <ItemDetail label="Deskripsi" value={fields.description} />
                                <ItemDetail label="Harga" value={<div style={{ textAlign: "right" }}>{FormatRp(Number(fields.harga))}</div>} />
                                <ItemDetail label="Diskon" value={<div style={{ textAlign: "right" }}>{fields.discountIsInPercentage ? `${fields.discount}%` : FormatRp(fields.discount)}</div>} />
                                <ItemDetail 
                                    label="Tags" 
                                    value={
                                        <Row>
                                            {fields.tags.map((tag:string, key:number) => (
                                                <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                    <div className="tag">
                                                        {tag}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    } 
                                />
                                <ItemDetail label="Status" value={fields.status ? "Aktif" : "Tidak Aktif"} />
                                <ItemDetail label="Promo" value={fields.isPromo ? "Aktif" : "Tidak Aktif"} />
                                <ItemDetail label="Dibuat Pada" value={FormatDate(fields.createdAt)} />
                                {/* <ItemDetail label="Dibuat Oleh" value={fields.createdByName !== null ? fields.createdByName : "-"} /> */}
                                <ItemDetail label="Diubah Pada" value={FormatDate(fields.updatedAt)} />
                                {/* <ItemDetail label="Diubah Oleh" value={fields.updatedByName !== null ? fields.updatedByName : "-"} /> */}
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px" }}>
                            <Col>
                                <Link to={`/food/item?id=${query.get("id")}`}>
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailItem
