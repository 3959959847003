import React, { useContext } from 'react'
import {KasproContext} from '../context/ContextProvider'
import {DetailPageLayout} from "../../../templates"
import FormVoucher from '../form/Form'

import "./create.scss"

const Create: React.FC = () => {
    const context = useContext(KasproContext)
    const {
        fields,
        submitPhoto,
        handleSubmit
    } = context.useModelDetail()

    return (
        <React.Fragment>
            <DetailPageLayout>
                    <FormVoucher
                        data={fields}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                    />
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Create
