import {useState, useEffect} from "react"
import {useQueryParams} from "../../../../helpers/Formatter"
import {useLocation} from "react-router-dom"
import {IList} from "../../merchantjasa/transaction/TransactionModel"
import axios from "axios"
import moment from "moment"
import {base_url} from "../../../../config/url-config"
import {orderjasa} from "../../../../config/endpoint"
import {notification} from "antd"

export const useModelList = () => {
    const qp = useQueryParams()
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IList>({
        count: 0,
        currentPage: 1,
        limit: 10,
        totalPage: 1,
        data: []
    })
    const [params, setParams] = useState<{
        page:number
    }>({
        page: 1
    })
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async (params:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${orderjasa}`

            const res = await axios.get(url, {
                headers: headers,
                params: {...params, ...qp.value}
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
       fetchData({...params, page: localStorage.getItem("page")})
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location.search])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
        localStorage.setItem("page", JSON.stringify(page))
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            qp.queryParams.set("q", e.target.value);
            qp.handleQueryParams();
            setParams({...params, page: 1})
            localStorage.setItem("page", "1")
         }
    }

    const handleFilter = (values:{nominalFrom:string, nominalTo:string, merchantID:string, paymentProvider:string, status:string, paymentState:string, date:any[], startDate:any, endDate:any}) => {
        // console.log(values);
        values["startDate"] = values.date !== undefined ? moment(values.date[0]).format("YYYY-MM-DD") : undefined
        values["endDate"] = values.date !== undefined ? moment(values.date[1]).format("YYYY-MM-DD") : undefined
        delete values.date

        values.nominalFrom && qp.queryParams.set("nominalFrom", values.nominalFrom.toString());
        values.nominalTo && qp.queryParams.set("nominalTo", values.nominalTo.toString());
        values.merchantID && qp.queryParams.set("merchantID", values.merchantID.toString());
        values.paymentProvider && qp.queryParams.set("paymentProvider", values.paymentProvider.toString());
        values.status && qp.queryParams.set("status", values.status.toString());
        values.paymentState && qp.queryParams.set("paymentState", values.paymentState.toString());
        values.startDate && qp.queryParams.set("startDate", values.startDate.toString());
        values.endDate && qp.queryParams.set("endDate", values.endDate.toString());
        qp.handleQueryParams();
        setParams({...params, ...values, page: 1})
        localStorage.setItem("page", "1")
    }

    const handleReset = () => {
        setParams({
            page: 1
        })
        qp.reset()
        localStorage.setItem("page", "1")
    }

    return {
        fetchData,
        loading,
        fields,
        params,
        handlePageChange,
        handleSubmitSearch,
        handleFilter,
        handleReset
    }
}