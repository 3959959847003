import {useState, useEffect} from "react"
import {IDetail} from "../TransactionPaymentModel"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {paymenttransaction} from "../../../../config/endpoint"
import {notification} from "antd"

export function useModelDetail(id:string | null = null) {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>({
        invoice: {
            id: "",
            invoiceNumber: "",
            merchantName: "",
            total: 0
        },
        invoice_ipl: {
            apartmentID: "",
            apartmentName: "",
            id: "",
            invoiceID: "",
            invoiceNumber: ""
        },
        bank: "",
        transDt: "",
        origin: "",
        paymentProvider: "",
        payment_id: "",
        payment_method: "",
        payment_state: "",
        amount: 0,
        adminFee: 0,
        user: {
            email: "",
            name: "",
            phoneNumber: ""
        }
    })

    const fetchData = async(payment_id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${paymenttransaction}/${payment_id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading(false)
        } catch(error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses detail transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        id !== null && fetchData(id)
    }, [id])

    return {
        loading,
        fields
    }
}