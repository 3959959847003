import React from "react"
import { useInvoice } from "../../../../../context/context-invoice"
import {IDetailTransaction} from "../ModelTransaction"
import {Row, Col, Menu, Dropdown} from "antd"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import Shopper from "../shopper/Shopper"
import Refund from "../refund/Refund"
import {Link} from "react-router-dom"

function TableColumns(
    fetchData:() => void, 
    params:{},
    handleUpdateStatus: (id:string, status:string) => void
) {
    const CtxInvoice = useInvoice();

    const columns = [
        {
            title: "No. Order",
            render: (data:IDetailTransaction) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.invoiceNumber}</div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.marketName}</div>
                    <div>{FormatDate(data.orderDate)}</div>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Total Pesanan",
            render: (data:IDetailTransaction) => (
                <div>{data.totalOrder !== null ? FormatRp(data.totalOrder) : 0}</div>
            ),
            width: "15%"
        },
        {
            title: "Biaya Layanan",
            render: (data:IDetailTransaction) => (
                <div>{data.serviceFee !== null ? FormatRp(data.serviceFee) : 0}</div>
            ),
            width: "15%"
        },
        {
            title: "Pembayaran",
            render: (data:IDetailTransaction) => (
                <div>
                    <div>{data.payment.paymentProvider}</div>
                    <div>{data.payment.paymentMethod}</div>
                </div>
            ),
            width: "10%"
        },
        {
            title: "Shopper",
            render: (data:IDetailTransaction) => (
                <div>
                    <div>{data.shopperName !== "" ? data.shopperName : "-"}</div>
                    <div>{data.shopperPhoneNumber !== "" ? data.shopperPhoneNumber : "-"}</div>
                </div>
            ),
            width: "10%"
        },
        {
            title: "Status",
            render: (data:IDetailTransaction) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.status} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.moPayment.paymentState}
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            title: "Aksi",
            render: (data:IDetailTransaction) => {
                
                const menu = (
                    <Menu style={{width: "130px"}} id="button-id">
                        <Menu.Item
                             hidden={data.status === "CREATED" ? true : data.status === "UNPAID" ? true : data.status === "CANCELED" ? true : data.status === "REJECTED" ? true : data.status === "EXPIRED" ? true : data.status === "DONE" ? true : data.status === "FAILED" ? true : false}
                        >
                            {data.status === "PAID" ? (
                                <Shopper 
                                    id={data.id} 
                                    fetchData={fetchData}
                                    params={params}
                                    status={data.status}
                                    handleUpdateStatus={handleUpdateStatus}
                                />
                            ) : (
                                <Row 
                                    onClick={() => handleUpdateStatus(data.id, data.status === "PAID" ? "APPROVED" : data.status === "APPROVED" ? "PROCESS" : data.status === "PROCESS" ? "SEND" : "DONE")}
                                    style={{color: "green"}}
                                >
                                    <Col span={8}>
                                        <i className="fas fa-check" />
                                    </Col>
                                    <Col span={16}>
                                       {data.status === "APPROVED" ? "Process": data.status === "PROCESS" ? "Send" : "Done"}
                                    </Col>
                                </Row>
                            )}
                        </Menu.Item>
                        <Menu.Item
                            hidden={data.status === "CREATED" ? true : data.status === "UNPAID" ? true : data.status === "CANCELED" ? true : data.status === "REJECTED" ? true : data.status === "EXPIRED" ? true : data.status === "DONE" ? true : data.status === "FAILED" ? true : false}
                        >
                            <Row 
                                onClick={() => handleUpdateStatus(data.id, data.status === "PAID" ? "REJECTED" : "CANCELED")} 
                                style={{color: "red"}}
                            >
                                <Col span={8}>
                                    <i className="fas fa-times" style={{fontSize: "18px"}} />
                                </Col>
                                <Col span={16}>
                                    {data.status === "PAID" ? "Rejected" : "Canceled"}
                                </Col>
                            </Row>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                            hidden={data.status === "APPROVED" ? false : data.status === "PROCESS" ? false : data.status === "SEND" ? false : true}
                            disabled={data.refundOrder.id !== 0 ? true : false}
                        >
                            <Refund 
                                orderID={data.id}
                                invoiceNumber={data.invoiceNumber} 
                                itemOrder={data.itemOrder}
                                fetchData={fetchData}
                                params={params}
                            />
                        </Menu.Item>
                        <Menu.Item
                            hidden={data.status === "PAID" ? true : data.status !== "APPROVED" && data.status !== "PROCESS" ? true : false}
                        >
                            <Shopper 
                                id={data.id} 
                                fetchData={fetchData}
                                params={params}
                            />
                        </Menu.Item>
                        <Menu.Item onClick={() => CtxInvoice.setInvoice({ isReady: true, data })}>
                            <Row>
                                <Col span={8}>
                                    <i className="fas fa-print" />
                                </Col>
                                <Col span={16}>
                                    Invoice
                                </Col>
                            </Row>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={`/transaksi-ongoing/detail?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Detail
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        }
    ]

    return {
        columns
    }
}


export default TableColumns