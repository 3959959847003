import React, { useContext, useState } from 'react'
import { useInvoice } from '../../../../../context/context-invoice'
import { PasarTransactionContext } from "../context/ContextProvider"
import TableColumns from "./Columns"
import { PageLayout } from "../../../../templates"
import Filter from "../filter/Filter"
import { Search, Table, FeatureButton } from "../../../../../components/atoms"
import { Row, Col, Pagination } from "antd"
import { useQueryParams } from '../../../../../helpers/Formatter'

const List: React.FC = () => {
    const CtxInvoice = useInvoice()
    const qs = useQueryParams();
    const context = useContext(PasarTransactionContext)
    const {
        fields,
        params,
        pasar,
        fetchData,
        loading,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        onShowSizeChange
    } = context.useModelList()
    const { columns } = TableColumns(fetchData, params)
    const [dataSelected, setDataSelected] = useState<{}[]>([])

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search value={qs.queryParams.get('search')?.toString()} onKeyPress={(e: any) => handleSubmitSearch(e)} />}
                leftTop={
                    <div>
                        <Filter
                            handleFilter={handleFilter}
                            pasar={pasar}
                        />
                        <FeatureButton
                            icon={<i className="fas fa-undo" style={{ marginRight: "5px" }} />}
                            label="Reset"
                            onClick={handleReset}
                        />
                        {dataSelected.length > 0 ? (
                            <FeatureButton
                                icon={<i className="fas fa-print" style={{ marginRight: "5px" }} />}
                                label="Invoice"
                                onClick={() => CtxInvoice.setBulkInvoice({ isReady: true, data: dataSelected })}
                            />
                        ) : null}
                    </div>
                }
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.records}
                            loading={loading === "fetch" ? true : false}
                            rowKey="id"
                            pagination={false}
                            rowClassName={(record: { status: string }, index: number) => record.status === "PAID" || record.status === "APPROVED" || record.status === "PROCESS" || record.status === "SEND" ? 'new-transaction' : 'read-transaction'}
                            rowSelection={{
                                type: "checkbox",
                                onChange: (selectedRowKeys: React.Key[], selectedRows: {}[]) => {
                                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                                    setDataSelected(selectedRows)
                                },
                            }}
                        />
                    </div>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            {`Total ${fields.total_record} Transaksi`}
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination
                                current={qs.queryParams.get('pageNumber') ? Number(qs.queryParams.get('pageNumber')) : params.pageNumber}
                                pageSize={qs.value.pageLimit === undefined ? 10 : qs.value.pageLimit}
                                total={fields.total_record}
                                onChange={handlePageChange}
                                showSizeChanger={true}
                                pageSizeOptions={["10","20","50","100","500"]}
                                onShowSizeChange={onShowSizeChange}
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
