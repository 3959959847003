export interface IPagination {
    current_page: number;
    limit: number;
    perpage_found: number;
}

export interface IListVoucher {
    pagination: IPagination;
    payload: ModelDataVoucher[];
    status_code: number;
}

export interface IOperational{
    active?:boolean
    day: number
    merchantID?: string | null | undefined
    openHour?: string | null
    closeHour?: null | string
}


export interface ModelDataVoucher {
    name: string;
    id: number;
    promo_code: string;
    payment_provider: string;
    description: string;
    image: string;
    layanan: string;
    voucher_category: string;
    nominal_type: string;
    nominal: number;
    merchant: string;
    maximal_nominal: number;
    limit_promo_total: number;
    limit_per_days: number;
    limit_per_user: number;
    limit_promo_total_used: number;
    limit_days_used: number;
    limit_user_used: number;
    start_date: string;
    expired_date: string;
    is_active: string;
    is_visible: string;
    is_new_user: string;
    minimal_transaction: number;
    maximal_transaction: number;
    included_layanan: string;
    included_category: string;
    excluded_layanan: string;
    excluded_category: string;
    included_layanan_data:any;
}

export const initialDataList = {
    pagination: {
        current_page: 1,
        limit: 10,
        perpage_found: 10
    },
    payload: [],
    status_code: 0
}

export const initialDataVoucher = {
    name: "",
    id: 0,
    promo_code: "",
    payment_provider: "",
    description: "",
    image: "",
    layanan: "",
    voucher_category: "",
    nominal_type: "percentage_rates",
    nominal: 0,
    maximal_nominal: 0,
    merchant: "",
    limit_promo_total: 0,
    limit_per_days: 0,
    limit_per_user: 0,
    limit_promo_total_used: 0,
    limit_days_used: 0,
    limit_user_used: 0,
    start_date: "",
    expired_date: "",
    is_active: "",
    is_visible: "",
    is_new_user: "",
    minimal_transaction: 0,
    maximal_transaction: 0,
    included_layanan: "",
    included_category: "",
    excluded_layanan: "",
    excluded_category: "",
    included_layanan_data: []
}