import React, {useContext, useState} from 'react'
import {useQueryParams} from "../../../../helpers/Formatter"
import {ShopperContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {Link} from "react-router-dom"
import Area from "../area/Area"
import {PageLayout} from "../../../templates/"
import {Search, Table, FeatureButton} from "../../../../components/atoms"
import Filter from "../filter/Filter"
import {Pagination} from "antd"

import "./list.scss"

const ListShopper: React.FC = () => {
    const qp = useQueryParams()
    const context = useContext(ShopperContext)
    const {
        fields,
        loading,
        visible,
        setVisible,
        handleUpdateStatus,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handlePageChange,
        handleDelete
    } = context.useModelList()
    const {columns} = TableColumns(
        handleUpdateStatus, 
        loading, 
        visible,
        setVisible,
        handleDelete
    )
    const [hideMenu, setHideMenu] = useState<boolean>(false)

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                    value={qp.queryParams.get('search')?.toString()}
                />}
                leftTop={
                    <div>
                        <Link to="/shopper/tambah">
                            <FeatureButton 
                                icon={<i className="fas fa-plus-square" style={{marginRight: "5px"}} />}
                                label="Tambah"
                            /> 
                        </Link>
                        <Filter handleFilter={handleFilter} />
                        <FeatureButton 
                            icon={<i className="fas fa-undo" style={{marginRight: "5px"}} />}
                            label="Reset" 
                            onClick={handleReset}
                        />
                        {hideMenu ? (
                            <span>
                                <Area />
                                <i 
                                    className="fas fa-chevron-circle-left"
                                    style={{cursor: "pointer", color: "#009b00"}}
                                    onClick={() => setHideMenu(!hideMenu)} 
                                />
                            </span>
                         ) : (
                            <i 
                                className="fas fa-chevron-circle-right" 
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={() => setHideMenu(!hideMenu)}
                            />
                         )}
                    </div>
                }
                content={
                    <div>
                        <div>
                            <Table
                                columns={columns}
                                data={fields.records}
                                rowKey="id"
                                pagination={false}
                                loading={loading === "fetch" ? true : false}
                                // scroll={{y: 550}}
                            />
                            {/* <div style={{padding: "20px 0px", textAlign: "right"}}>
                                <Pagination 
                                    current={fields.page} 
                                    pageSize={10} 
                                    total={fields.total_record} 
                                    onChange={handlePageChange} 
                                    showSizeChanger={false}
                                />
                            </div> */}
                        </div>
                    </div>
                }
                footer={
                    <div style={{textAlign: "right"}}>
                        <Pagination 
                            current={fields.page} 
                            pageSize={10} 
                            total={fields.total_record} 
                            onChange={handlePageChange} 
                            showSizeChanger={false}
                        />
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default ListShopper
