import React, {useContext} from 'react'
import {AccountJasaContext} from "../context/ContextProvider"
import {useLocation} from "react-router-dom"
import TableColumns from "./Columns"
import {PageLayout} from "../../../../templates"
import CreateAkun from "../createakun/CreateAkun"
import {Search, Table, BackButton} from "../../../../../components/atoms"
import {Pagination, Row, Col} from "antd"
import {Link} from "react-router-dom"

import "./list.scss"

const List: React.FC = () => {
    const merchantId = useLocation().search.split("=")[1]
    const context = useContext(AccountJasaContext)
    const {
        loading,
        fields,
        params,
        merchant,
        visible,
        fetchData,
        handleSubmitSearch,
        handlePageChange,
        toggle,
        handleDelete
    } = context.useModelList(merchantId)
    const {columns} = TableColumns(visible, merchant, toggle, handleDelete)

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                leftTop={
                    <CreateAkun 
                        merchantName={merchant.name}
                        merchantId={merchant.id}
                        fetchData={fetchData}
                        params={params}
                    />
                }
                content={
                    <React.Fragment>
                        <div>
                            <Table 
                                columns={columns} 
                                data={fields.payload}
                                rowKey="id"
                                pagination={false}
                                loading={loading}
                                // scroll={{y: 550}}
                            />
                        </div>
                    </React.Fragment>
                }
                footer={
                    <Row>
                        <Col span={12}>
                            <Link to="/jasa">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            <Pagination 
                                current={fields.currentPage} 
                                pageSize={10} 
                                total={fields.totalPage * fields.limit} 
                                onChange={handlePageChange} 
                            />
                        </Col>
                    </Row>
                }
            />
        </React.Fragment>
    )
}

export default List
