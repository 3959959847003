import React from 'react'
import ContextProvider from "./context/ContextProvider"
import {Route} from "react-router-dom"
import List from "./list/List"
import Create from "./create/Create"
import Edit from "./edit/Edit"
import Detail from "./detail/Detail"

import "./shopper.scss"

const Shopper: React.FC = () => {
    
    return (
        <ContextProvider>
            <Route path="/shopper" exact={true} render={() => <List />} />
            <Route path="/shopper/tambah" exact={true} render={() => <Create />} />
            <Route path="/shopper/ubah" exact={true} render={() => <Edit />} />
            <Route path="/shopper/detail" exact={true} render={() => <Detail />} />
        </ContextProvider>
    )
}

export default Shopper
